import React, { useState, useEffect, useContext } from 'react';

import { AppContext } from '../../contexts/AppProvider';

import { ReactComponent as UserIcon } from '../../assets/user.svg';

import { ReactComponent as WooIcon } from '../../assets/woocommerce.svg';

import { ReactComponent as DialogIcon } from '../../assets/dialogflow.svg';

function ItemCard(props) {

    const { currentIntegration, setCurrentIntegration } = useContext(AppContext);

    useEffect(() => {

    }, [])

    return (
        <div style={{
            marginTop: "10px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            background: (props.active) ? "#D9E2F1" : "#fff",
            padding: "10px",
            borderRadius: "10px"
        }} onClick={
            () => {

                if (props.name === "Woocommerce") {
                    props.onClick({
                        name: 'Woocommerce',
                        service: 'WOOCOMMERCE'
                    })
                }

                if (props.name === "DialogFlow") {
                    props.onClick({
                        name: 'Dialogflow',
                        service: 'DIALOGFLOW'
                    })
                }

            }
        }>
            <div>
                <div style={{
                    fontSize: "40px"
                }}>

                    {
                        (props.name === "Woocommerce") && <WooIcon style={{
                            width: "40px",
                            height: "40px"
                        }} />
                    }

                    {
                        (props.name === "DialogFlow") && <DialogIcon style={{
                            width: "40px",
                            height: "40px"
                        }} />
                    }

                </div>
            </div>
            <div style={{
                width: "100%",
                marginLeft: "5px"
            }}>
                <div style={{
                    fontWeight: "700",
                    color: "#08379B",
                    display: "flex",
                    alignItems: "center"
                }}>
                    <div style={{
                        width: "100%"
                    }}>
                        {props.name}
                    </div>
                    <div style={{
                        width: "40px",
                        color: "#fff",
                        textAlign: "center",
                        background: "#6666FF",
                        borderRadius: "5px",
                        fontSize: "12px"
                    }}>
                        PRO
                    </div>
                </div>
                <div style={{
                    fontSize: "0.8em"
                }}>
                    {props.details}
                </div>
            </div>
        </div>
    );
}

export default ItemCard;