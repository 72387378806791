import React, { useState, useEffect, useContext } from 'react';

import SenderId from '../../assets/senderId.png';

import { ReactComponent as SearchIcon } from '../../assets/icons/magnifyingglass.svg';

import Header from './Header';

import ItemCard from './ItemCard';

import EnvApp from '../../components/EnvApp';

import DialogFlow from './DialogFlow';

import Woocommerce from './Woocommerce';

function Integrations() {

    //const { currentIntegration, setCurrentIntegration } = useContext(AppContext);

    const [search, setSearch] = useState('');

    const [currentIntegration, setCurrentIntegration] = useState({
        name: 'Dialogflow',
        service: 'DIALOGFLOW'
    });

    return (
        <EnvApp>
            <div style={{
                display: "grid",
                gridTemplateColumns: "auto",
                gridTemplateRows: "100px auto",
                background: "#fff",
                width: "100%",
                height: "100%",
                overflow: "auto",
                borderRadius: "10px 0px 0px 10px"
            }}>

                <Header />

                <div style={{
                    padding: "20px 50px 20px 50px"
                }}>

                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "280px auto",
                        gridTemplateRows: "auto",
                        height: "100%"
                    }}>
                        <div style={{
                            height: "100%"
                        }}>

                            <div style={{
                                display: "flex",
                                alignItems: "center"
                            }}>

                                <input type="text" value={search} style={{
                                    background: "#F2F7F8",
                                    border: "0px",
                                    height: "50px"
                                }} className="form-control shadow-none" placeholder="Escribe para buscar" />

                                <button className="btn btn-primary shadow-none" style={{
                                    marginLeft: "5px",
                                    height: "50px",
                                    borderRadius:"15px",
                                    background: "rgb(8,55,155)",
                                    background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)"
                                }}>
                                    <SearchIcon style={{
                                        // width: "20px",
                                        //height: "20px",
                                        fill: "#fff"
                                    }} />
                                </button>

                            </div>

                            <ItemCard onClick={
                                (e) => {
                                    console.log(e);
                                    setCurrentIntegration(e)
                                }
                            } name="DialogFlow" active={(currentIntegration.service === "DIALOGFLOW")} details="Mejora tu estrategia de conversación dotando a tus bots..." />

                            <ItemCard onClick={
                                (e) => {
                                    console.log(e);
                                    setCurrentIntegration(e)
                                }
                            } name="Woocommerce" active={(currentIntegration.service === "WOOCOMMERCE")} details="Integra un buscador de productos contado en tu tienda woocomerce..." />

                        </div>

                        {
                            (currentIntegration.name === null && currentIntegration.service === null) && <div style={{
                                padding: "0px 50px 0px 50px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                                textAlign: "center"
                            }}>
                                <div style={{
                                    maxWidth: "500px"
                                }}>
                                    <div>
                                        <img src={SenderId} style={{
                                            width: "200px"
                                        }} />
                                    </div>
                                    <div style={{
                                        fontSize: "30px",
                                        fontWeight: "700"
                                    }}>Seleccione un integracion</div>
                                    <div style={{
                                        marginTop: "10px"
                                    }}>Seleccione una integracion, para poder editar su información, puedes crear una nueva integracion haciendo click en el boton <b>+ Nuevo integracion</b></div>
                                </div>
                            </div>
                        }


                        {
                            (currentIntegration.name != null && currentIntegration.service != null) && <div style={{
                                overflow: "auto"
                            }}>

                                {
                                    (currentIntegration.service === "DIALOGFLOW") && <>
                                        <DialogFlow />
                                    </>
                                }

                                {
                                    (currentIntegration.service === "WOOCOMMERCE") && <>
                                        <Woocommerce />
                                    </>
                                }


                            </div>
                        }

                    </div>

                </div>

            </div>
        </EnvApp>
    );
}

export default Integrations;