import React, { useState, useEffect } from 'react';

import axios from "axios";

import { PHAT_DEV, PHAT_PROD, DEV } from '../../config';

import { useHistory, useParams } from "react-router-dom";

import toast from 'react-hot-toast';

function Qualifier() {

    let { ref } = useParams();

    const history = useHistory();

    const [n, setN] = useState(0);

    const [d, setD] = useState('');

    const [salved, setSalved] = useState(false);

    useEffect(() => {

        // Obtenemos la informacion del chat
        // 

        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/getQualifier/` + ref, {})
            .then((response) => {
                console.log(response.data);

                if (response.data.n > -1) {
                    setSalved(true);
                }

            }, (error) => {
                toast.error(error.response.data);
            });

    }, [])

    return (
        <div style={{
            padding: "20px",
            maxWidth: "400px",
            margin: "0 auto"
        }}>

            <div style={{
                textAlign: "center",
                padding: "10px",
                fontWeight: "700"
            }}>Califica la conversación</div>

            <br />

            <div style={{
                textAlign: "center"
            }}>
                {
                    (salved) && <>
                        <div style={{
                            fontSize: "40px"
                        }}>😊</div>
                        <div>Ya calificaste este chat, gracias por participar.</div>
                    </>
                }
            </div>


            {
                (!salved) && <>
                    <div style={{
                        display: "flex"
                    }}>
                        <div style={{
                            width: "100%",
                            textAlign: "center",
                            cursor: "pointer",
                            opacity: (n === 1) ? "1" : "0.5"
                        }} onClick={
                            () => {
                                setN(1)
                            }
                        }>
                            <div><img style={{
                                width: "40px",
                                height: "40px"
                            }} src="https://cdn.cloudcitycolombia.com/mala.png" /></div>
                            <div style={{
                                fontWeight: (n === 1) ? "700" : "normal"
                            }}>Mala</div>
                        </div>
                        <div style={{
                            width: "100%",
                            textAlign: "center",
                            cursor: "pointer",
                            opacity: (n === 2) ? "1" : "0.5"
                        }} onClick={
                            () => {
                                setN(2)
                            }
                        }>
                            <div><img style={{
                                width: "40px",
                                height: "40px"
                            }} src="https://cdn.cloudcitycolombia.com/aceptable.png" /></div>
                            <div style={{
                                fontWeight: (n === 2) ? "700" : "normal"
                            }}>Regular</div>
                        </div>
                        <div style={{
                            width: "100%",
                            textAlign: "center",
                            cursor: "pointer",
                            opacity: (n === 3) ? "1" : "0.5"
                        }} onClick={
                            () => {
                                setN(3)
                            }
                        }>
                            <div><img style={{
                                width: "40px",
                                height: "40px"
                            }} src="https://cdn.cloudcitycolombia.com/excelente.png" /></div>
                            <div style={{
                                fontWeight: (n === 3) ? "700" : "normal"
                            }}>Bueno</div>
                        </div>
                        <div style={{
                            width: "100%",
                            textAlign: "center",
                            cursor: "pointer",
                            opacity: (n === 4) ? "1" : "0.5"
                        }} onClick={
                            () => {
                                setN(4)
                            }
                        }>
                            <div><img style={{
                                width: "40px",
                                height: "40px"
                            }} src="https://cdn.cloudcitycolombia.com/increible.png" /></div>
                            <div style={{
                                fontWeight: (n === 4) ? "700" : "normal"
                            }}>Excelente</div>
                        </div>
                    </div>

                    <br />

                    <div>
                        <textarea value={d} onChange={
                            (e) => {
                                setD(e.target.value);
                            }
                        } placeholder="Observaciones" style={{
                            width: "100%",
                            height: "100px",
                            padding: "10px",
                            resize: "none"
                        }}>

                        </textarea>
                    </div>

                    <br />

                    <div>
                        <button style={{
                            width: "100%",
                            borderRadius: "10px",
                            padding: "7px"
                        }} onClick={
                            () => {

                                axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/qualifier/${ref}`, {
                                    n,
                                    d
                                })
                                    .then((response) => {
                                        toast.success("Chat calificado")
                                        setSalved(true);
                                    }, (error) => {
                                        toast.error(error.response.data)
                                    });

                            }
                        }>Enviar</button>
                    </div>
                </>
            }




        </div>
    );

}

export default Qualifier;