import React, { useEffect, useState, useCallback, useContext, useRef } from 'react';

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { Toaster } from 'react-hot-toast';

import Login from "./Pages/Login/index";

import Singup from "./Pages/Singup/index";

import Chat from "./Pages/Chat/index";

import Agents from './Pages/Agents/index';

import Bussines from './Pages/Bussines/index';

import Profile from './Pages/Profile/index';

import Integrations from './Pages/Integrations/index';

import SenderIds from './Pages/SenderIds/index';

import Widget from './Pages/Widget/index';

import Qualifier from './Pages/Qualifier/index';

import Metrics from './Pages/Metrics/index';

import Shifts from './Modules/Shifts/index';

import Screen from './Modules/Shifts/Screen';

import Dispenser from './Modules/Shifts/Dispenser';

import ShiftsQualifier from './Modules/Shifts/ShiftsQualifier';

import ScreenBuilder from './Modules/Shifts/ScreenBuilder/index';

import Customers from './Modules/Admin/Customers/index';

import Requests from './Modules/Admin/Requests/index';

import Settings from './Modules/Admin/Settings/index';

import Campaign from './Pages/Campaign/index';

import Contacts from './Pages/Contacts/index';

import Academy from './Pages/Academy/index';

import Template from './Pages/Template/index';

import History from './Pages/History/index';

import Dev from './Pages/Dev/index';





function App() {

  return (
    <BrowserRouter forceRefresh={true}>

      <Toaster />

      <Switch>

        <Route exact path="/" component={Login} />

        <Route exact path="/login" component={Login} />

        <Route exact path="/register" component={Singup} />

        <Route exact path="/chat/:ref?" component={Chat} />

        <Route exact path="/agents" component={Agents} />

        <Route exact path="/profile" component={Profile} />

        <Route exact path="/integrations" component={Integrations} />

        <Route exact path="/senderids" component={SenderIds} />

        <Route exact path="/bussines" component={Bussines} />

        <Route exact path="/metrics" component={Metrics} />

        <Route exact path="/widget/:sender/:iid" component={Widget} />

        <Route exact path="/qualifier/:ref" component={Qualifier} />

        <Route exact path="/shifts" component={Shifts} />

        <Route exact path="/shifts/screen" component={Screen} />

        <Route exact path="/shifts/screen/builder" component={ScreenBuilder} />

        <Route exact path="/shifts/dispenser" component={Dispenser} />

        <Route exact path="/shifts/qualifier" component={ShiftsQualifier} />

        <Route exact path="/admin/customers" component={Customers} />

        <Route exact path="/admin/pending" component={Requests} />

        <Route exact path="/admin/settings" component={Settings} />

        <Route exact path="/campaign" component={Campaign} />

        <Route exact path="/contacts" component={Contacts} />

        <Route exact path="/academy" component={Academy} />

        <Route exact path="/template" component={Template} />

        <Route exact path="/history" component={History} />

        <Route exact path="/devs" component={Dev} />


      </Switch>

    </BrowserRouter>
  );
}

export default App;
