import axios from 'axios';

import { PHAT_DEV, PHAT_PROD, DEV } from '../config';

export const saveContactGroup = (payload) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/groups`, payload)
            .then((response) => {
                resolve(response.data);
            }, (error) => {
                reject(error.response.data)
            });

    })
}

export const updateContactGroup = (ref, payload) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.put(`${(DEV) ? PHAT_DEV : PHAT_PROD}/groups/${ref}`, payload)
            .then((response) => {
                resolve(response.data);
            }, (error) => {
                reject(error.response.data)
            });

    })
}

export const saveContact = (payload) => {
    return new Promise((resolve, reject) => {
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/contacts`, payload)
            .then((response) => {
                resolve(response.data);
            }, (error) => {
                reject(error.response.data)
            });

    })
}

export const updateContact = (ref, payload) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.put(`${(DEV) ? PHAT_DEV : PHAT_PROD}/contacts/${ref}`, payload)
            .then((response) => {
                resolve(response.data);
            }, (error) => {
                reject(error.response.data)
            });

    })
}

export const removeContact = (ref) => {

    return new Promise((resolve, reject) => {
        axios.delete(`${(DEV) ? PHAT_DEV : PHAT_PROD}/contact/${ref}`, {})
            .then((response) => {
                resolve(response.data);
            }, (error) => {
                reject(error.response.data)
            });

    })
}

export const remove = (ref) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.delete(`${(DEV) ? PHAT_DEV : PHAT_PROD}/contactList/${ref}`, {})
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al intentar guardar el agente, intente nuevamente.")
                }
            }, (error) => {
                reject(error.response.data)
            });

    })
}


export const contactsByGroup = (ref) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/contacts/groups/${ref}`, {})
            .then((response) => {
                resolve(response.data);
            }, (error) => {
                reject(error.response.data);
            });

    })
}

export const get = (ref) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/contactList/${ref}`, {})
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al recuperar las listas de contactos, intente nuevamente.");
                }
            }, (error) => {
                reject(error.response.data)
            });

    })
}

export const getAllContactGroup = (iid) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/groups/${iid}`, {})
            .then((response) => {
                resolve(response.data);
            }, (error) => {
                reject(error.response.data)
            });

    })
}


export const importFile = (file, iid, ref) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', file, file.name);
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/import-contacts/${iid}/${ref}`, formData, {
            'Content-Type': `multipart/form-data;`
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    resolve(response.data)
                } else {
                    console.log("Ocurrio un problema al intentar subir el archivo.");
                }
            }, (error) => {
                console.log(error.message);
            });
    })
}