import React, { useState, useEffect, useContext } from 'react';

import { useHistory } from "react-router-dom";

import MaskInput from 'react-maskinput';

import toast from 'react-hot-toast';

import { save, getUsersByRol, getAsesors, remove } from '../../services/Agent';

import { ReactComponent as UserIcon } from '../../assets/icons/person.fill.svg';

import { ReactComponent as User1Icon } from '../../assets/icons/person.circle.svg';

import { ReactComponent as SearchIcon } from '../../assets/icons/magnifyingglass.svg';

import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';

import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.fill.svg';

import { ReactComponent as AgentesIcon } from '../../assets/icons/agentes.svg';

import { ReactComponent as ResultsIcon } from '../../assets/icons/text.magnifyingglass.svg';

import { ReactComponent as SaveIcon } from '../../assets/save.svg';

import { getByBussine } from '../../services/Channel';

import { AppContext } from '../../contexts/AppProvider';

import EnvApp from '../../components/EnvApp';

function Agents() {

    const history = useHistory();

    const { senderIds, setSenderIds } = useContext(AppContext);

    const [ref, setRef] = useState('');

    const [name, setName] = useState('');

    const [alias, setAlias] = useState('');

    const [email, setEmail] = useState('');

    const [phone, setPhone] = useState('');

    const [newPassword, setNewPassword] = useState('');

    const [whatsapp, setWhatsapp] = useState('');

    const [confirmPassword, setConfirmPassword] = useState('');

    const [search, setSearch] = useState('');

    const [TimeStamp, setTimeStamp] = useState({
        day: '',
        start: {
            hour: '',
            min: ''
        },
        end: {
            hour: '',
            min: ''
        }
    });

    const [currentAgent, setCurrentAgent] = useState({
        ref: null,
        nickname: "",
        name: "",
        email: "",
        channels: [],
        phone: "",
        password: ""
    });

    const [agents, setAgents] = useState([]);

    const [availableChannels, setAvailableChannels] = useState([]);

    const [channels, setChannels] = useState([]);

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    useEffect(() => {
        console.log(TimeStamp);
    }, [TimeStamp]);

    useEffect(() => {

        setName(currentAgent.name);

        setAlias(currentAgent.nickname);

        setEmail(currentAgent.email);

        setPhone(currentAgent.phone);

        setChannels(currentAgent.channels)


    }, [currentAgent]);

    const onClear = () => {
        setRef("");
        setName("");
        setAlias("");
        setEmail("");
        setPhone("");
        setNewPassword("");
        setWhatsapp("");
        setConfirmPassword("");
    }

    useEffect(() => {

        getAsesors(window.localStorage.getItem("say-bussine")).then((res) => {
            console.log(res);
            setAgents(res);
        }).catch((err) => {
            console.log(err);
        })

        getByBussine(window.localStorage.getItem("say-bussine")).then((res) => {
            // setChannels(res);

            setAvailableChannels([])

            for (let i = 0; i < res.length; i++) {

                const channel = res[i];

                setAvailableChannels(oldChannels => [...oldChannels, {
                    name: channel.name,
                    active: false
                }]);

            }

        }).catch((err) => {
            console.log(err);
            //toast.error(err.data.message)
        })

    }, []);


    useEffect(() => {
        console.log(channels);
    }, [channels])

    return (

        <EnvApp>

            <div style={{
                display: "grid",
                gridTemplateColumns: "auto",
                gridTemplateRows: "100px auto",
                background: "#fff",
                width: "100%",
                height: "100%",
                overflow: "auto",
                borderRadius: "10px 0px 0px 10px"
            }}>

                <div style={{
                    padding: "20px 50px 20px 50px"
                }}>

                    <div style={{
                        fontSize: "30px",
                        width: "100%",
                        height: "100%",
                        borderBottom: "1px solid #E8EBF0",
                        display: "flex"
                    }}>
                        <div style={{
                            width: "100%"
                        }}>
                            Agentes
                        </div>
                        <div style={{
                            width: "250px",
                            textAlign: "right"
                        }}>
                            <button style={{
                                borderRadius: "15px",
                                padding:"3px",
                               width:"150px",
                               background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)"
                            }} onClick={
                                () => {
                                    setCurrentAgent({
                                        ref: "",
                                        nickname: "",
                                        name: "",
                                        channels: [],
                                        email: "",
                                        phone: "",
                                        password: ""
                                    })
                                }
                            } className="btn btn-primary">
                                <PlusIcon style={{
                                    // width: "20px",
                                    //height: "20px",
                                    fill: "#fff"
                                }} /> Nuevo agente</button>
                        </div>
                    </div>



                </div>

                <div style={{
                    padding: "20px 50px 20px 50px"
                }}>

                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "280px auto",
                        gridTemplateRows: "auto",
                        height: "100%"
                    }}>
                        <div style={{
                            height: "100%"
                        }}>

                            <div style={{
                                display: "flex",
                                alignItems: "center"
                            }}>

                                <input type="text" value={search} style={{
                                    background: "#F2F7F8",
                                    border: "0px",
                                    height: "50px"
                                }} onChange={
                                    (e) => {
                                        setSearch(e.target.value)
                                    }
                                } className="form-control shadow-none" placeholder="Escribe para buscar" />

                                <button className="btn btn-primary shadow-none" style={{
                                    marginLeft: "5px",
                                    height: "50px",
                                    borderRadius: "15px",
                                    background: "rgb(8,55,155)",
                                    background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)"
                                }} onClick={
                                    () => {
                                        /*searchUsers(search, "agent").then((users) => {
                                            setAgents(users);
                                        }).catch((err_) => {
                                            console.log(err_);
                                        })*/
                                        toast.error("Esta funcionalidad aún no está disponible")
                                    }
                                }>
                                    <SearchIcon style={{
                                        //width: "20px",
                                        //height: "20px",
                                        fill: "#fff"
                                    }} />
                                </button>

                            </div>

                            <br />

                            {
                                (agents.length === 0) && <div style={{
                                    textAlign: "center"
                                }}>
                                    <div style={{
                                        fontSize: "18px",
                                        fontWeight: "700"
                                    }}><ResultsIcon style={{
                                        width: "40px",
                                        height: "40px",
                                        fill: "#fff"
                                    }} /></div>
                                    <div>Sin resultados</div>
                                </div>
                            }


                            {
                                agents.map((Agent, AgentIndex) => {
                                    return (<div key={AgentIndex} style={{
                                        marginTop: "10px",
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        background: (currentAgent.ref === Agent.ref) ? "#D9E2F1" : "transparent",
                                        padding: "10px",
                                        borderRadius: "10px"
                                    }} onClick={
                                        () => {
                                            setCurrentAgent(Agent)
                                        }
                                    }>
                                        <div>
                                            <UserIcon style={{
                                                width: "55px",
                                                height: "55px",
                                                borderRadius: "50%",
                                                fill: (currentAgent.ref === Agent.ref) ? '#08379B' : '#000'
                                            }} />
                                        </div>
                                        <div style={{
                                            width: "100%",
                                            marginLeft: "5px"
                                        }}>
                                            <div style={{
                                                fontWeight: "700",
                                                color: "#08379B"
                                            }}>{Agent.name} ({(Agent.nickname != "" && Agent.nickname != null) ? Agent.nickname : "Sin alias"})</div>
                                            <div>
                                                <div>
                                                    <span><span>7.1</span> / 10</span>
                                                </div>
                                                <div style={{
                                                    width: "53px",
                                                    height: "3px",
                                                    background: "#E8EBF0"
                                                }}>
                                                    <div style={{
                                                        width: "80%",
                                                        height: "100%",
                                                        background: "#AFE1DA"
                                                    }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                                })
                            }

                        </div>

                        {
                            (currentAgent.ref === null) && <div style={{
                                padding: "0px 50px 0px 50px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                                textAlign: "center"
                            }}>
                                <div style={{
                                    maxWidth: "500px"
                                }}>
                                    <div>
                                        <AgentesIcon style={{
                                            fill: "#fff"
                                        }} />
                                    </div>
                                    <div style={{
                                        fontSize: "25px",
                                        fontWeight: "500",
                                        marginTop: "-50px"
                                    }}>Selecciona un agente</div>
                                    <div style={{
                                        marginTop: "10px"
                                    }}>Selecciona un agente para poder editar tu información, puedes crear un nuevo agente haciendo clic en el botón<b>+ Nuevo agente.</b></div>
                                </div>
                            </div>
                        }


                        {
                            (currentAgent.ref != null) && <div style={{
                                overflow: "auto"
                            }}>
                                <div style={{
                                    padding: "0px 50px 0px 50px",
                                    display: "flex"
                                }}>

                                    <div style={{
                                        width: "80px"
                                    }}>
                                        <User1Icon style={{
                                            width: "115px",
                                            height: "115px",
                                            borderRadius: "50%",
                                            fill: "rgb(8, 55, 155)",
                                            marginLeft: "-20"
                                        }} />
                                    </div>

                                    <div style={{
                                        width: "100%"
                                    }}>

                                        <div className="form-control shadow-none plain" style={{
                                            fontSize: "30px",
                                            fontWeight: "700",
                                            border: "0px"
                                        }} >{(name != "") ? name : "Sin nombre"}</div>

                                        <div className="form-control shadow-none plain" style={{
                                            fontSize: "20px",
                                            border: "0px"
                                        }} >{(email != "") ? email : "Sin email"}</div>

                                    </div>

                                </div>

                                <div style={{
                                    padding: "0px 50px 0px 50px"
                                }}>

                                    <br />

                                    <div style={{
                                        minHeight: "100px"
                                    }}>

                                        <div style={{
                                            fontSize: "25px",
                                            fontWeight: "700"
                                        }}>Información</div>

                                        <div style={{
                                            marginTop: "10px"
                                        }}>

                                            <div className="row">
                                                <div className="col-6 mt-3" >
                                                    <div  style={{
                                                        fontWeight: "700",
                                                        color: "rgb(8, 55, 155)"
                                                    }}>Alias</div>

                                                    <input type="text" value={alias} onChange={
                                                        (e) => {
                                                            setAlias(e.target.value)
                                                        }
                                                    } class="shadow  bg-body rounded" style={{
                                                        width: "100%",
                                                        padding: "10px",
                                                        fontSize: "20px",
                                                        background: "#F2F7F8",
                                                        border: "0px"
                                                    }} placeholder="Alias" />
                                                </div>
                                                <div className="col-6 mt-3">
                                                    <div style={{
                                                        fontWeight: "700",
                                                        color: "rgb(8, 55, 155)"
                                                    }}>Nombre</div>

                                                    <input type="text" value={name} onChange={
                                                        (e) => {
                                                            setName(e.target.value)
                                                        }
                                                    } class="shadow bg-body rounded" style={{
                                                        width: "100%",
                                                        padding: "10px",
                                                        fontSize: "20px",
                                                        background: "#F2F7F8",
                                                        border: "0px"
                                                    }} placeholder="Nombre completo" />
                                                </div>
                                                <div className="col-6 mt-3">
                                                    <div style={{
                                                        fontWeight: "700",
                                                        color: "rgb(8, 55, 155)"
                                                    }}>Número de teléfono</div>


                                                    <MaskInput alwaysShowMask maskChar="_" value={phone} onChange={
                                                        (e) => {
                                                            setPhone(e.target.value);
                                                        }
                                                    } class="shadow bg-body rounded" mask="+57 (000) 000-00-00" style={{
                                                        width: "100%",
                                                        padding: "10px",
                                                        fontSize: "20px",
                                                        background: "#F2F7F8",
                                                        border: "0px"
                                                    }} size={20} />

                                                    {
                                                        /*
                                                        <input type="text" value={phone} onChange={} className="form-control shadow-none" style={{
                                                        width: "100%",
                                                        padding: "10px",
                                                        fontSize: "20px",
                                                        background: "#F2F7F8",
                                                        border: "0px"
                                                    }} placeholder="Número de telefono" />
                                                        */
                                                    }


                                                </div>
                                                <div className="col-6 mt-3">
                                                    <div style={{
                                                        fontWeight: "700",
                                                        color: "rgb(8, 55, 155)"
                                                    }}>Correo electrónico</div>

                                                    <input type="email" value={email} onChange={
                                                        (e) => {
                                                            setEmail(e.target.value)
                                                        }
                                                    } class="shadow bg-body rounded" style={{
                                                        width: "100%",
                                                        padding: "10px",
                                                        fontSize: "20px",
                                                        background: "#F2F7F8",
                                                        border: "0px"
                                                    }} placeholder="Correo electrónico" />
                                                </div>
                                            </div>

                                        </div>
                                        <br />

                                    </div>


                                    <br />
                                    <br />

                                    <div>
                                        <div style={{
                                            fontSize: "25px",
                                            fontWeight: "700"
                                        }}>Canales</div>

                                        <br />

                                        {
                                            availableChannels.map((channel, chaIndex) =>
                                                <button style={{
                                                    marginLeft: "5px",
                                                    padding: "7px",
                                                    color: (channels.indexOf(channel.name) > -1) ? '#fff' : '#000',
                                                    background: (channels.indexOf(channel.name) > -1) ? '#000' : '#EFEFEF'
                                                }} onClick={() => {

                                                    if (availableChannels[chaIndex].active) {
                                                        // Agregamos

                                                        var copy = [...availableChannels];
                                                        copy[chaIndex].active = false;
                                                        setAvailableChannels(copy);

                                                        setChannels(channels.filter(item => item !== channel.name));

                                                    } else {
                                                        // Eliminamos

                                                        var copy = [...availableChannels];
                                                        copy[chaIndex].active = true;
                                                        setAvailableChannels(copy);

                                                        setChannels(oldChannels => [...oldChannels, channel.name]);

                                                    }

                                                }}>{channel.name}</button>




                                            )
                                        }




                                    </div>

                                    <br />
                                    <br />

                                    <div style={{
                                        minHeight: "100px"
                                    }}>

                                        <div style={{
                                            fontSize: "25px",
                                            fontWeight: "700"
                                        }}>Notificaciones</div>

                                        <div style={{
                                            marginTop: "10px"
                                        }}>

                                            <div style={{
                                                display: "flex"
                                            }}>

                                                <div style={{
                                                    width: "50%",
                                                    marginRight: "10px"
                                                }}>
                                                    <div style={{
                                                        fontWeight: "700",
                                                        color: "rgb(8, 55, 155)"
                                                    }}>Whatsapp</div>

                                                    <MaskInput alwaysShowMask maskChar="_" value={whatsapp} onChange={
                                                        (e) => {
                                                            setWhatsapp(e.target.value);
                                                        }
                                                    } class="shadow  bg-body rounded" mask="+57 (000) 000-00-00" style={{
                                                        width: "100%",
                                                        padding: "10px",
                                                        fontSize: "20px",
                                                        background: "#F2F7F8",
                                                        border: "0px"
                                                    }} size={20} />

                                                </div>

                                            </div>

                                        </div>

                                    </div >

                                    <br />
                                    <br />

                                    <div style={{
                                        minHeight: "100px"
                                    }}>

                                        <div style={{
                                            fontSize: "25px",
                                            fontWeight: "700"
                                        }}>Seguridad</div>

                                        <div style={{
                                            marginTop: "10px"
                                        }}>

                                            <div style={{
                                                display: "flex"
                                            }}>

                                                <div style={{
                                                    width: "50%",
                                                    marginRight: "10px"
                                                }}>
                                                    <div style={{
                                                        fontWeight: "700",
                                                        color: "rgb(8, 55, 155)"
                                                    }}>Contraseña</div>

                                                    <input type="password" value={newPassword} onChange={
                                                        (e) => {
                                                            setNewPassword(e.target.value)
                                                        }
                                                    } class="shadow  bg-body rounded" style={{
                                                        width: "100%",
                                                        padding: "10px",
                                                        fontSize: "20px",
                                                        background: "#F2F7F8",
                                                        border: "0px"
                                                    }} placeholder="Password" />
                                                </div>

                                                <div style={{
                                                    width: "60%"
                                                }}>
                                                    <div style={{
                                                        fontWeight: "700",
                                                        color: "rgb(8, 55, 155)"
                                                    }}>Confirmar contraseña</div>

                                                    <input type="password" value={confirmPassword} onChange={
                                                        (e) => {
                                                            setConfirmPassword(e.target.value)
                                                        }
                                                    } class="shadow  bg-body rounded" style={{
                                                        width: "100%",
                                                        padding: "10px",
                                                        fontSize: "20px",
                                                        background: "#F2F7F8",
                                                        border: "0px"
                                                    }} placeholder="Password" />
                                                </div>

                                            </div>

                                        </div>
                                        <br />

                                        <div>
                                            <button className="btn btn-primary shadow-none" style={{
                                                borderRadius: "15px",
                                                background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)"
                                            }} onClick={
                                                () => {
                                                    // Funcion para crear

                                                    if (newPassword === confirmPassword) {


                                                        if (validateEmail(email)) {
                                                            save({
                                                                ref: currentAgent.ref,
                                                                name: name,
                                                                email: email,
                                                                nickname: alias,
                                                                channels: channels,
                                                                phone: phone,
                                                                password: newPassword,
                                                                bussine: window.localStorage.getItem("say-bussine")
                                                            }).then((res) => {
                                                                console.log(res);
                                                                // Modificamos la lista

                                                                onClear();

                                                                getAsesors(window.localStorage.getItem("say-bussine")).then((res) => {
                                                                    console.log(res);
                                                                    setAgents(res);
                                                                }).catch((err) => {
                                                                    console.log(err);
                                                                })

                                                                setCurrentAgent({
                                                                    ref: null,
                                                                    nickname: "",
                                                                    name: "",
                                                                    channels: [],
                                                                    phone: "",
                                                                    email: "",
                                                                    whatsapp: "",
                                                                    password: "",
                                                                    confirmPassword: ""
                                                                })

                                                                toast.success("El agente se creó correctamente")

                                                            }).catch((err) => {
                                                                toast.error(err)
                                                            })
                                                        } else {
                                                            toast.error("Correo electrónico no válido")
                                                        }



                                                    } else {
                                                        toast.error("Las contraseñas no coinciden")
                                                    }

                                                    //icono guardar cambios
                                                }}>




                                                {
                                                    (currentAgent.ref != "") ? <div><SaveIcon style={{
                                                        width: "15px",
                                                        height: "20px",
                                                        fill: "#fff",
                                                        marginRight: "2px"
                                                    }} />Guardar cambios</div> : <div>Crear nuevo agente</div>
                                                }
                                            </button >
                                        </div >

                                    </div >

                                    <br />
                                    <br />

                                    {
                                        (currentAgent.ref != '') && <div style={{
                                            textAlign: "right"
                                        }}>
                                            <button className="btn btn-danger shadow-none" style={{
                                                padding: "6px", background: "rgb(237,11,11)",
                                                background: "linear-gradient(180deg, rgba(237,11,11,1) 0%, rgba(227,84,98,1) 100%)"

                                            }} onClick={
                                                () => {
                                                    remove(currentAgent.ref).then((res) => {
                                                        console.log(res);

                                                        getAsesors(window.localStorage.getItem("say-bussine")).then((res) => {
                                                            console.log(res);
                                                            setAgents(res);
                                                        }).catch((err) => {
                                                            console.log(err);
                                                        })

                                                        setCurrentAgent({
                                                            ref: null,
                                                            nickname: "",
                                                            name: "",
                                                            channels: [],
                                                            phone: "",
                                                            email: "",
                                                            whatsapp: "",
                                                            password: "",
                                                            confirmPassword: ""
                                                        })

                                                        onClear();

                                                        toast.success("El agente se elimino correctamente");

                                                    }).catch((error) => {
                                                        console.log(error);
                                                    })
                                                }
                                            }>
                                                <DeleteIcon style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    fill: "#fff"
                                                }} /> Eliminar agente</button>
                                        </div>
                                    }



                                    <br />

                                    <br />

                                </div >

                            </div >
                        }


                    </div >

                </div >

            </div >

        </EnvApp >
    );
}

export default Agents;

