import React, { useState, useEffect, useContext } from 'react';

import Bubble from './Bubble';

import BubbleMe from './BubbleMe';

import { WidgetContext } from '../../contexts/WidgetProvider';

function Messages(props) {

    const { Timeline, status } = useContext(WidgetContext);

    useEffect(() => {
        // Obtenemos la lista de mensajes del jid actual

    }, [])

    return (
        <>
            <div style={{
                padding: "10px"
            }}>

                <div style={{
                    background: "#f5f5f5",
                    padding: "10px",
                    maxWidth: "300px",
                    borderRadius: "10px",
                    margin: "10px 0px 10px 0px",
                }}>
                    <p>Ahora estás en espera. Uno de nuestros especialistas en vivo investigará tu caso y responderá en los próximos 10 minutos.</p>

                    <p>Queremos ayudarte lo más rápido posible, así que danos más detalles sobre tu problema.</p>

                    <p>Tenemos tu email, por lo que podremos comunicarnos contigo si abandonas este sitio web.</p>
                </div>
                {
                    Timeline.map((Line, LineIndex) =>
                        <div key={LineIndex}>
                            {
                                (Line.rol === "client") && <Bubble line={Line.message} time="11:05 am." />
                            }
                            {
                                (Line.rol === "agent") && <BubbleMe line={Line.message} time="11:10 am." />
                            }
                        </div>
                    )
                }
                <div>
                    {
                        (status === 1) && <div style={{
                            textAlign: "center"
                        }}>
                            Esta conversación esta cerrada y no puedes seguir contestando
                        </div>
                    }
                </div>

            </div>
        </>
    );
}

export default Messages;