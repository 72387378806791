import React, { useState, useEffect, useContext } from 'react';

import axios from 'axios';

import { PHAT_DEV, PHAT_PROD, DEV } from '../../../config';

import toast from 'react-hot-toast';

import { ReactComponent as SearchIcon } from '../../../assets/icons/magnifyingglass.svg';

import { ReactComponent as UserIcon } from '../../../assets/icons/person.fill.svg';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/trash.fill.svg';

import { ReactComponent as User1Icon } from '../../../assets/icons/person.circle.svg';

import { ReactComponent as CompanyIcon } from '../../../assets/icons/house.svg';

import { ReactComponent as TelephoneIcon } from '../../../assets/icons/phone.fill.svg';

import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg';

import { ReactComponent as CancelIcon } from '../../../assets/icons/xmark.svg';

import { ReactComponent as ApprovedIcon } from '../../../assets/icons/garrapata.svg';

import { ReactComponent as RequestIcon } from '../../../assets/icons/request.svg';

import { ReactComponent as ResultsIcon } from '../../../assets/icons/text.magnifyingglass.svg';

import { AppContext } from '../../../contexts/AppProvider';

import Header from './Header';

import EnvApp from '../../../components/EnvApp';

function Requests() {

    const [ref, setRef] = useState('');

    const [name, setName] = useState('');

    const [email, setEmail] = useState('');

    const [phone, setPhone] = useState('');

    const [createdAt, setCreatedAt] = useState('');

    const [bussine, setBussine] = useState(null);

    const { currentSenderId } = useContext(AppContext);

    const [search, setSearch] = useState('')

    const [list, setList] = useState([])

    const [currentItem, setCurrentItem] = useState('');

    const [page, setPage] = useState('NOTIFY');


    // Crgamos los clientes existentes
    const load = () => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/customers/pending`, {})
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data);
                    setList(response.data);
                } else {
                    toast.error("Ocurrio un problema al intentar recuperar las cuentas de empresa, intente nuevamente.");
                }
            }, (error) => {
                console.log(error);
            });
    }


    useEffect(() => {
        load()
    }, []);

    return (
        <EnvApp>

            <div style={{
                display: "grid",
                gridTemplateColumns: "auto",
                gridTemplateRows: "100px auto",
                background: "#fff",
                width: "100%",
                height: "100%",
                overflow: "auto",
                borderRadius: "10px 0px 0px 10px"
            }}>

                <Header />

                <div style={{
                    padding: "20px 50px 20px 50px"
                }}>

                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "280px auto",
                        gridTemplateRows: "auto",
                        height: "100%"
                    }}>
                        <div style={{
                            height: "100%"
                        }}>

                            <div style={{
                                padding: "10px",
                                cursor: "pointer",
                                borderRadius: "10px",
                                background: (page === 'NOTIFY') ? "#ff0000" : "transparent",
                                color: (page === 'NOTIFY') ? "#fff" : "#000"
                            }} onClick={
                                () => {
                                    setPage('NOTIFY')
                                }
                            }>Notificaciones</div>

                            <div style={{
                                padding: "10px",
                                cursor: "pointer",
                                borderRadius: "10px",
                                background: (page === 'BRANDING') ? "#ff0000" : "transparent",
                                color: (page === 'BRANDING') ? "#fff" : "#000"
                            }} onClick={
                                () => {
                                    setPage('BRANDING')
                                }
                            }>Branding</div>

                            <div style={{
                                padding: "10px",
                                cursor: "pointer",
                                borderRadius: "10px",
                                background: (page === 'ASES') ? "#ff0000" : "transparent",
                                color: (page === 'ASES') ? "#fff" : "#000"
                            }} onClick={
                                () => {
                                    setPage('ASES')
                                }
                            }>Amazon SES</div>

                            <div style={{
                                padding: "10px",
                                cursor: "pointer",
                                borderRadius: "10px",
                                background: (page === 'ASNS') ? "#ff0000" : "transparent",
                                color: (page === 'ASNS') ? "#fff" : "#000"
                            }} onClick={
                                () => {
                                    setPage('ASNS')
                                }
                            }>Amazon SNS</div>

                        </div>

                        <div style={{
                            overflow: "auto"
                        }}>


                            <div style={{
                                padding: "0px 50px 0px 50px"
                            }}>

                                <div>

                                    {
                                        (page === 'NOTIFY') && <>
                                            NOTIFICACIONES
                                        </>
                                    }

                                    {
                                        (page === 'BRANDING') && <>
                                            MARCA
                                        </>
                                    }

                                    {
                                        (page === 'ASES') && <>
                                            Amazon SES
                                        </>
                                    }

                                    {
                                        (page === 'ASNS') && <>
                                            Amazon SNS
                                        </>
                                    }

                                </div>


                            </div>


                        </div>


                    </div>

                </div>

            </div>
        </EnvApp>
    );
}

export default Requests;