import React, { useState, useEffect, useContext, useCallback } from 'react';

// import { connect, getStatus, force, disconect } from '../../services/Bussine';

import { AppContext } from '../../contexts/AppProvider';

import { SocketContext } from '../../contexts/SocketProvider';

import toast from 'react-hot-toast';

import { ReactComponent as ReloadIcon } from '../../assets/reload.svg';

import { ReactComponent as WaLogin } from '../../assets/wa-login.svg';

import axios from 'axios';

import DQr from '../../assets/qr.png';

import { DEV, PHAT_PROD, PHAT_DEV } from '../../config';

import { getQr } from '../../services/WhatsApp';

import "./QrForm.css";

function QrForm(props) {

    const { currentSenderId, iid, senderIds, setSenderIds } = useContext(AppContext);

    const { qr, setQr, open, setOpen, Status, setStatus, emit } = useContext(SocketContext);

    const [Bussines, setBussines] = useState(null);

    const [Base64Qr, setBase64Qr] = useState('');

    const [loadingQrForm, setLoadingQrForm] = useState(true);

    useEffect(() => {
        if (qr != '') {
            setBase64Qr(qr);
            var i = senderIds.findIndex((send) => send.ref === currentSenderId.ref);
            if (i > -1) {
                var cop = [...senderIds];
                cop[i].status.code = "QR";
                cop[i].status.details = qr;
                setSenderIds(cop);
            }
        }
    }, [qr])

    useEffect(() => {
        setBussines(window.localStorage.getItem("say-bussine"))


        console.log({
            iid: window.localStorage.getItem("say-bussine"),
            senderId: currentSenderId.name
        });

        emit('status', {
            iid: window.localStorage.getItem("say-bussine"),
            senderId: currentSenderId.name
        })

    }, [])

    useEffect(() => {
        var i = senderIds.findIndex((e) => e.name === currentSenderId.name);
        if (i > -1) {
            setBase64Qr(senderIds[i].status.details);
        }
    }, [senderIds])

    useEffect(() => {
        if (iid != null) {
            if (loadingQrForm) {
                setLoadingQrForm(false);
            }
        }
    }, [Status])


    useEffect(() => {

        getQr({
            params: {
                sender: currentSenderId.name,
                iid: window.localStorage.getItem("say-bussine")
            }
        }).then((res) => {

            console.log(res);

            // Procemosa el codigo

            // Prgeuntamos si ya pasaron 5 segundos, si pasaron 5 segundos mostramos el boton para pedir un nuevo codigo qr

        }).catch((err) => {
            console.log(err);
        })


    }, [currentSenderId]);


    return (
        <div>

            {
                /*
                LOADER
                */
            }
            {
                (loadingQrForm) && <div>
                    Cargando...
                </div>
            }

            {
                /*
                DESCONECTADO 
                */
            }

            {
                /*
                <div style={{
                    textAlign: "center"
                }}>
                    <WaLogin />
                    <div>Sincronizar dispositivos</div>
                </div>
                */
            }

            {
                (currentSenderId.status.code === 'DISCONNECTED') && <div style={{ /*  */
                    width: "250px",
                    height: "250px",
                    position: "relative",
                    margin: "0 auto"
                }}>
                    <>
                        <div>
                            <div style={{
                                width: "200px",
                                height: "200px",
                                background: "#5FB9B0",
                                color: "#fff",
                                textAlign: "center",
                                position: "absolute",
                                left: "50%",
                                marginLeft: "-100px",
                                top: "50%",
                                marginTop: "-100px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "50%",
                                cursor: "pointer"
                            }} onClick={
                                () => {
                                    if (Bussines != null) {
                                        if (currentSenderId.name != null) {

                                            emit('qr', {
                                                iid: window.localStorage.getItem("say-bussine"),
                                                channel: currentSenderId.ref
                                            })

                                        } else {
                                            toast.error("Seleccione un SenderId, para conectar");
                                        }
                                    } else {
                                        toast.error("Error en la cuenta de la empresa");
                                    }
                                }
                            }>
                                <div>
                                    <div>
                                        <ReloadIcon style={{
                                            width: "30px",
                                            height: "30px",
                                            fill: "#fff"
                                        }} />
                                    </div>
                                    <div style={{
                                        fontSize: "17px"
                                    }}>CLIC PARA RECARGAR CÓDIGO QR</div>
                                </div>
                            </div>
                            <img src={DQr} style={{
                                width: "250px",
                                height: "250px"
                            }} />
                        </div>
                    </>
                </div>

            }

            {
                // Base64Qr != '' && 
                (currentSenderId.status.code === 'QR') && <div style={{
                    width: "250px",
                    height: "250px",
                    position: "relative",
                    margin: "0 auto"
                }}>
                    <img src={Base64Qr} style={{
                        width: "250px",
                        height: "250px"
                    }} />
                </div>
            }




        </div >
    );

}

export default QrForm;