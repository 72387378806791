import React, { useState, useEffect, useContext } from 'react';

import { AppContext } from '../../contexts/AppProvider';

import BotProfile from '../../assets/botProfile.png';

function AgentCard(props) {

    const { currentIntegration, setCurrentIntegration } = useContext(AppContext);

    useEffect(() => {

    }, [])

    return (
        <div style={{
            display: "grid",
            gridTemplateColumns: "auto",
            gridTemplateRows: "200px 100px",
            width: "200px",
            border: "1px solid #ebebeb",
            borderRadius: "10px",
            boxShadow: "rgb(46 44 54 / 2%) 0px 10px 20px 0px"
        }}>

            <div style={{
                background: "#1e2347",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                fontWeight: "700",
                fontSize: "30px",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <div>
                <div>{(props.name != '') ? props.name : "Sin nombre"}</div>
                <div>{props.id}</div>
                </div>
            </div>

            <div style={{
                alignSelf: "center",
                justifySelf: "center"
            }}>
                <a href={'https://dialogflow.cloud.google.com/#/agent/' + props.projectId + '/intents'} target="_blank" >
                    <button style={{
                        borderRadius: "7px",
                        fontSize: "25px"
                    }}>
                        O
                    </button>
                </a>

                <button style={{
                    borderRadius: "7px",
                    fontSize: "25px",
                    marginLeft: "5px"
                }} onClick={
                    () => {
                        props.onEdit();
                    }
                }>
                    <i className="las la-pen"></i>
                </button>

                <button style={{
                    borderRadius: "7px",
                    fontSize: "25px",
                    background: "#bb2d3b",
                    color: "#fff",
                    marginLeft: "20px"
                }} onClick={
                    () => {
                        props.onDelete();
                    }
                }>
                    <i className="lar la-trash-alt"></i>
                </button>
            </div>

        </div>
    );
}

export default AgentCard;