import React, { useState, useEffect, useContext } from 'react';

import { Modal } from 'react-responsive-modal';

import './SenderIdExplorer.css';

import { getSenders } from '../../../services/Bussine';

import { getByBussine } from '../../../services/Channel';

import { ReactComponent as ChekIcon } from '../../../assets/chek.svg';

import { ReactComponent as WhatsappIcon } from '../../../assets/whatsapp.svg';

import { AppContext } from '../../../contexts/AppProvider';



function SenderIdExplorer(props) {

    const { iid, chatSenderId, setChatSenderId } = useContext(AppContext);

    const [open, setOpen] = useState(false);

    const [senders, setSenders] = useState([]);

    const onOpenModal = () => setOpen(true);

    const onCloseModal = () => setOpen(false);

    useEffect(() => {
        
       // if (iid != "") {

            console.log("CARGAMOS LOS SENDERS");
            /*getSenders(iid).then((data) => {
                setSenders(data)
            }).catch((err) => {
                console.log(err);
            })*/


            // Cragamos los canales disponibles para esta cuenta de empresa
            getByBussine(window.localStorage.getItem("say-bussine")).then((res) => {
                console.log("resresresresresresresresresres");
                console.log(res)
                setSenders(res)
            }).catch((error) => {
                console.log("error");
                console.log(error);
            })


      //  }
    }, [])

    return (
        <>
            <button className="btn btn-sm shadow-none" style={{
                background: "#f9fafa",
                color: "#408cb5",
                fontWeight: "700",
                marginLeft: "5px"
            }} onClick={
                (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onOpenModal();
                }
            }>{props.text}</button>

            {
                /*
                : {(chatSenderId != '' ? chatSenderId : 'Sin asignar')}
                */
            }

            <Modal open={open} onClose={onCloseModal} classNames={{
                overlay: 'customOverlay',
                modal: 'customModal',
            }} center>
                <div>
                    Seleccione un canal para responder:
                    <br />
                    {
                        senders.map((sender, senderIndex) =>
                            (sender.channel === "WHATSAPP") && <div key={senderIndex} style={{
                                marginTop: "10px",
                                display: "flex",
                                alignItems: "center"
                            }}>

                                <div>
                                    <WhatsappIcon style={{
                                        width: "40px",
                                        height: "40px",
                                        fill: "#06d755"
                                    }} />
                                </div>
                                <div style={{
                                    width: "100%",
                                    marginLeft: "5px"
                                }}>
                                    <div>{sender.name}</div>
                                    <div style={{
                                        fontSize: "15px"
                                    }}>{sender.channel}</div>
                                </div>
                                <div style={{
                                    width: "25px",
                                }}>
                                    {
                                        (sender.channel === "WEB" || sender.status.code === "CONNECTED") && <button onClick={
                                            (e) => {

                                                e.stopPropagation();
                                                e.preventDefault();

                                                props.onChange(sender);

                                                setOpen(false);

                                            }
                                        } style={{
                                            background: "transparent"
                                        }}>
                                            <ChekIcon style={{
                                                width: "25px",
                                                height: "25px",
                                                marginLeft: "-6px"
                                            }} />
                                        </button>
                                    }
                                    {
                                        (sender.channel != "WEB" && sender.status.code != "CONNECTED") && <div style={{
                                            width: "25px",
                                            height: "25px",
                                            background: "#ff0000",
                                            borderRadius: "50%"
                                        }}>

                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
            </Modal>

        </>
    );
}

export default SenderIdExplorer;

