import { createContext, useState, useEffect, useRef } from "react";

import { getByBussine } from '../services/Channel';

import { setChannel } from '../services/Chat';

import pdfIcon from '../assets/pdf.png';

import fileIcon from '../assets/file.png';

import docIcon from '../assets/doc.png';

import excelIcon from '../assets/excel.png';

export const AppContext = createContext();

const mimes = {
    documents: [
        "application/vnd.oasis.opendocument.presentation",
        "application/vnd.oasis.opendocument.spreadsheet",
        "application/vnd.oasis.opendocument.text",
        "application/vnd.ms-powerpoint",
        "application/pdf",
        "application/msword",
        "text/csv",
        "application/vnd.ms-excel",
        "application/msexcel",
        "application/x-msexcel",
        "application/x-ms-excel",
        "application/x-excel",
        "application/x-dos_ms_excel",
        "application/xls",
        "application/x-xls",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ],
    audios: [
        "audio/mp4",
        "audio/ogg; codecs=opus"
    ],
    images: [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/webp"
    ],
    videos: [
        "video/mp4"
    ]
}

const AppProvider = ({ children }) => {

    const [Photo, setPhoto] = useState('');

    const [Name, setName] = useState('');

    const [Email, setEmail] = useState('');

    const [Phone, setPhone] = useState('');

    const [Bussine, setBussine] = useState({
        ref: '',
        name: ''
    });

    const [IsSending, setIsSending] = useState(false);

    const [Chats, setChats] = useState([]);

    const [assignedChats, setAssignedChats] = useState([]);

    const [iid, setIid] = useState('');

    const [CurrentPage, setCurrentPage] = useState('UNASSIGNED'); // UNASSIGNED, ASSIGNED, ALL

    const [currentSenderId, setCurrentSenderId] = useState(
        {
            ref: null,
            name: null,
            online: false,
            replaced: false
        }
    );

    const [currentIntegration, setCurrentIntegration] = useState(
        {
            ref: null,
            name: null,
            service: null,
            key: null
        }
    );

    const [chatSender, setChatSender] = useState('');

    const [CurrentChat, setCurrentChat] = useState(
        {
            ref: "",
            jid: "",
            iid: "",
            name: "",
            channel: "",
            typeChannel: "",
            status: 0,
            num: 0
        }
    );

    const [chat, setChat] = useState(
        {
            ref: "",
            jid: "",
            name: "",
            channel: "",
            status: 0
        }
    );

    const [senderIds, setSenderIds] = useState([]);

    const [Timeline, setTimeline] = useState([]);

    const [line, setLine] = useState([]);

    const [Statistics, setStatistics] = useState({
        assigned: 0,
        unassigned: 0,
        all: 0
    });

    const [NewMessage, setNewMessage] = useState(null);

    const [NewChat, setNewChat] = useState(null);

    const [listQrs, setListQrs] = useState([]);

    useEffect(() => {
        // Cargamos los canales
        getByBussine(window.localStorage.getItem("say-bussine")).then((res) => {
            console.log("resresresresresresresresresres");
            console.log(res)
            setSenderIds(res)
        }).catch((error) => {
            console.log("error");
            console.log(error);
        })
    }, [])

    useEffect(() => {

        setPhoto(window.localStorage.getItem("say-photo"));

        setName(window.localStorage.getItem("say-name"));

        setEmail(window.localStorage.getItem("say-email"));

        setPhone(window.localStorage.getItem("say-phone"));

        setIid(window.localStorage.getItem("say-bussine"));

    }, [])

    useEffect(async () => {
        if (chatSender != "" && typeof (chatSender) != "undefined") {

            await setChannel(chat.ref, chatSender).catch((err) => {
                console.log(err);
            })

            setCurrentChat({
                jid: chat.jid,
                name: chat.name,
                ref: chat.ref,
                channel: chat.channel,
                sender: chatSender,
                status: chat.status
            })

        }
    }, [chatSender])

    const getIDoc = (mime) => {

        if (mime === "application/pdf") {
            return pdfIcon;
        }

        if (mime === "application/vnd.ms-excel" || mime === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            return excelIcon;
        }

        if (mime === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            return docIcon;
        }

        return fileIcon;
    }


    const getTypeByMime = (mime) => {
        if (mimes.documents.findIndex((document) => document === mime) > -1) {
            return "document"
        }

        if (mimes.audios.findIndex((audio) => audio === mime) > -1) {
            return "audio"
        }

        if (mimes.images.findIndex((image) => image === mime) > -1) {
            return "image"
        }

        if (mimes.videos.findIndex((video) => video === mime) > -1) {
            return "video"
        }
    }

    const isFile = (mime) => {
        if (mimes.documents.findIndex((document) => document === mime) > -1) {
            return true;
        }
    }

    const isImage = (mime) => {
        if (mimes.images.findIndex((image) => image === mime) > -1) {
            return true;
        }
    }

    const isAudio = (mime) => {
        if (mimes.audios.findIndex((audio) => audio === mime) > -1) {
            return true;
        }
    }

    const isVideo = (mime) => {
        if (mimes.videos.findIndex((video) => video === mime) > -1) {
            return true;
        }
    }

    const getW = (mime) => {
        if (getTypeByMime(mime) === "image") {
            return "350px";
        }
        if (getTypeByMime(mime) === "document") {
            return "310px";
        }
        if (getTypeByMime(mime) === "audio") {
            return "350px";
        }
        if (getTypeByMime(mime) === "video") {
            return "400px";
        }
        return "auto";
    }

    return (
        <AppContext.Provider value={
            {
                Photo, setPhoto,
                Name, setName,
                Email, setEmail,
                Bussine, setBussine,
                Phone, setPhone,
                currentSenderId, setCurrentSenderId,
                iid, setIid,
                senderIds, setSenderIds,
                CurrentChat, setCurrentChat,
                line, setLine,
                chat, setChat,
                Timeline, setTimeline,
                chatSender, setChatSender,
                Chats, setChats,
                Statistics, setStatistics,
                NewMessage, setNewMessage,
                assignedChats, setAssignedChats,
                CurrentPage, setCurrentPage,
                listQrs, setListQrs,
                getIDoc,
                getW,
                isFile, isImage, isAudio, isVideo,
                NewChat, setNewChat,
                currentIntegration, setCurrentIntegration,
                IsSending, setIsSending
            }
        }>
            {children}
        </AppContext.Provider>
    );

}

export default AppProvider;