//importaciones
import React, { useState, useEffect, useRef, useContext } from 'react';



function ProductItem(props) {
    //Estados


    return (<div style={{
        display: "flex",
        alignItems: "center",
        marginTop: "7px"
    }} >
        <div>
            <img src={props.photo} style={{
                borderRadius: "10px",
                border: "1px solid #c3c3c3"
            }} width="60px" height="60px" />
        </div>
        <div style={{
            width: "100%",
            marginLeft: "5px"
        }}>
            <div style={{
                fontWeight: "700",
                color: "#3883B0"
            }}>
                {
                    props.name
                }
            </div>
            <div>
               0 x ${props.price}
            </div>
        </div>
        
    </div>
    )
}


export default ProductItem;