import React, { useState, useEffect } from 'react';

import { useHistory } from "react-router-dom";

function TurnCard(props) {

    const history = useHistory();

    useEffect(() => {

    }, []);

    return (
        <div style={{
            display: "flex",
            border: `3px solid rgba(${props.color.r}, ${props.color.g}, ${props.color.b}, ${props.color.a})`,
            fontSize: "65px",
            padding: "10px 20px 10px 20px",
            borderRadius: "10px",
            marginTop: "10px",
            height: "120px",
            color: `rgb(${props.color.r}, ${props.color.g}, ${props.color.b})`,
            background: "repeating-linear-gradient(-55deg, #222, #222 20px, #333 20px, #333 40px)"
        }}>
            <div style={{
                width: "100%",
                fontWeight: "500"
            }}>0</div>
            <div style={{
                width: "100px"
            }}>A0</div>
        </div>
    );
}

export default TurnCard;

