import React, { useState, useEffect, useContext } from 'react';

import axios from 'axios';

import { PHAT_DEV, PHAT_PROD, DEV } from '../../../config';

import toast from 'react-hot-toast';

import { ReactComponent as SearchIcon } from '../../../assets/icons/magnifyingglass.svg';

import { ReactComponent as UserIcon } from '../../../assets/icons/person.fill.svg';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/trash.fill.svg';

import { ReactComponent as CompanyIcon } from '../../../assets/icons/house.svg';

import { ReactComponent as SaveIcon } from '../../../assets/icons/guardar.svg';

import { ReactComponent as ResultsIcon } from '../../../assets/icons/guardar.svg';

import { AppContext } from '../../../contexts/AppProvider';

import Header from './Header';

import EnvApp from '../../../components/EnvApp';

function Customers() {


    const [ref, setRef] = useState('');

    const [name, setName] = useState('');

    const [email, setEmail] = useState('');

    const [phone, setPhone] = useState('');

    const [createdAt, setCreatedAt] = useState('');

    const [bussine, setBussine] = useState(null);


    const { currentSenderId } = useContext(AppContext);

    const [search, setSearch] = useState('')

    const [list, setList] = useState([])

    const [currentItem, setCurrentItem] = useState('');


    // Crgamos los clientes existentes
    const load = () => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/customers`, {})
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data);
                    setList(response.data);
                } else {
                    toast.error("Ocurrio un problema al intentar recuperar las cuentas de empresa, intente nuevamente.");
                }
            }, (error) => {
                console.log(error);
            });
    }

    const clear = () => {
        setRef("");
        setName("");
        setPhone("");
        setEmail("");
        setBussine(null);
        setCreatedAt("");
    }

    useEffect(() => {
        load();
    }, []);

    return (
        <EnvApp>

            <div style={{
                display: "grid",
                gridTemplateColumns: "auto",
                gridTemplateRows: "100px auto",
                background: "#fff",
                width: "100%",
                height: "100%",
                overflow: "auto",
                borderRadius: "10px 0px 0px 10px"
            }}>

                <Header />

                <div style={{
                    padding: "20px 50px 20px 50px"
                }}>

                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "280px auto",
                        gridTemplateRows: "auto",
                        height: "100%"
                    }}>
                        <div style={{
                            height: "100%"
                        }}>

                            <div style={{
                                display: "flex",
                                alignItems: "center"
                            }}>

                                <input type="text" value={search} style={{
                                    background: "#F2F7F8",
                                    border: "0px",
                                    height: "50px"
                                }} onChange={
                                    (e) => {
                                        setSearch(e.target.value)
                                    }
                                } className="form-control shadow-none" placeholder="Escribe para buscar" />

                                <button className="btn btn-primary shadow-none" style={{
                                    marginLeft: "5px",
                                    height: "50px",
                                    borderRadius:"15px",
                                    background: "rgb(8,55,155)",
                                    background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)"
                                }} onClick={
                                    () => {
                                        toast.error("Esta funcionalidad aún no está disponible");
                                    }
                                }>
                                    <SearchIcon style={{
                                        //width: "20px",
                                        // height: "20px",
                                        fill: "#fff"
                                    }} />


                                </button>

                            </div>

                            <br />


                            {
                                (list.length === 0) && <div style={{
                                    textAlign: "center"
                                }}>
                                    <div style={{
                                        fontSize: "18px",
                                        fontWeight: "700"
                                    }}> <ResultsIcon style={{
                                        width: "40px",
                                        height: "40px",
                                        fill: "#fff"
                                    }} /></div>
                                    <div>Sin resultados</div>
                                </div>
                            }


                            {

                                list.map((item, itemIndex) =>
                                    <div key={itemIndex} style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: "10px",
                                        cursor: "pointer",
                                        color: (currentItem === item.ref) ? "#fff" : "#000",
                                        background: (currentItem === item.ref) ? "#d9e2f1" : "#fff",
                                        padding: "10px",
                                        borderRadius: "10px"
                                    }} onClick={
                                        () => {

                                            setCurrentItem(item.ref);

                                            setRef((typeof (item.ref) != "undefined" ? item.ref : ""));

                                            setName((typeof (item.name) != "undefined" ? item.name : ""));

                                            setPhone((typeof (item.phone) != "undefined" ? item.phone : ""));

                                            setEmail((typeof (item.email) != "undefined" ? item.email : ""));

                                            setBussine((typeof (item.bussine) != "undefined" ? item.bussine : null));

                                            setCreatedAt((typeof (item.createdAt) != "undefined" ? item.createdAt : null));

                                        }
                                    }>  <div>
                                            <UserIcon style={{
                                                width: "55px",
                                                height: "55px",
                                                borderRadius: "50%",
                                                fill: (currentItem === item.ref) ? '#08379B' : '#000'
                                            }} />
                                        </div>

                                        <div style={{
                                            marginLeft: "5px"
                                        }}>
                                            <div style={{
                                                fontWeight: "700",
                                                color: "#08379b"
                                            }}>
                                                {item.name}
                                            </div>
                                            <div style={{
                                                color: "#08379b"
                                            }}>
                                                {item.email}
                                            </div>
                                        </div>

                                    </div>
                                )

                            }

                        </div>


                        <div style={{
                            overflow: "auto"
                        }}>


                            <div style={{
                                padding: "0px 50px 0px 50px"
                            }}>

                                <br />


                                <div>

                                    <br />

                                    <h3>Información</h3>

                                    <br />

                                    <div className="row">

                                        <div className="col-6">
                                            <div style={{
                                                marginTop: "7px"
                                            }}>
                                                <div style={{
                                                    fontWeight: "700"
                                                }}>Nombre</div>
                                                <div>
                                                    <input type="text" placeholder="Nombre" style={{
                                                        width: "100%"
                                                    }} value={name} onChange={
                                                        (e) => {
                                                            setName(e.target.value)
                                                        }
                                                    } />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div style={{
                                                marginTop: "7px"
                                            }}>
                                                <div style={{
                                                    fontWeight: "700"
                                                }}>Número teléfonico</div>
                                                <div>
                                                    <input type="text" placeholder="Nombre" style={{
                                                        width: "100%"
                                                    }} value={phone} onChange={
                                                        (e) => {
                                                            setPhone(e.target.value)
                                                        }
                                                    } />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div style={{
                                                marginTop: "7px"
                                            }}>
                                                <div style={{
                                                    fontWeight: "700"
                                                }}>Correo electronico</div>
                                                <div>
                                                    <input type="text" placeholder="Nombre" style={{
                                                        width: "100%"
                                                    }} value={email} onChange={
                                                        (e) => {
                                                            setEmail(e.target.value)
                                                        }
                                                    } />
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    {
                                        (createdAt != '') && <div style={{
                                            marginTop: "7px"
                                        }}>
                                            <div style={{
                                                fontWeight: "700"
                                            }}>Fecha de creación</div>
                                            <div>{createdAt}</div>
                                        </div>
                                    }

                                    <br />

                                    {
                                        (bussine != null) && <>
                                            <h3>Empresa</h3>

                                            <br />

                                            <div style={{
                                                display: "flex",
                                                alignItems: "center"
                                            }}>
                                                <div style={{
                                                    width: "50px",
                                                    height: "50px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    fontSize: "35px",
                                                    color: "#ff0000",
                                                    borderRadius: "10px"
                                                }}>
                                                    <CompanyIcon style={{
                                                        width: "95px",
                                                        height: "85px",
                                                        fill: '#000'
                                                    }} />
                                                </div>
                                                <div style={{
                                                    marginLeft: "5px"
                                                }}>
                                                    <div>
                                                        <div style={{
                                                            fontWeight: "700"
                                                        }}>{bussine.name}</div>
                                                    </div>
                                                    <div>
                                                        <div>{bussine.senders.length}</div>
                                                    </div>
                                                </div>

                                            </div>
                                        </>
                                    }

                                    <br />

                                </div>

                                <br />

                                <div style={{
                                    minHeight: "100px"
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center"
                                    }}>
                                        <button className="btn btn-primary shadow-none"
                                            onClick={
                                                (e) => {
                                                    // save-customer 
                                                    axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/save-customer`, {
                                                        ref,
                                                        name,
                                                        phone,
                                                        email
                                                    })
                                                        .then((response) => {
                                                            if (response.status === 200) {
                                                                console.log(response.data);
                                                            } else {
                                                                console.log("Ocurrio un problema al intentar guardar el agente, intente nuevamente.");
                                                            }
                                                        }, (error) => {
                                                            console.log(error.response.data);
                                                        });
                                                }
                                            } style={{
                                                display: "flex",
                                                alignItems: "center",
                                                borderRadius:"15px",
                                                background: "rgb(8,55,155)",
                                                background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)"
                                            }}> <SaveIcon style={{
                                                width: "15px",
                                                height: "20px",
                                                fill: "#fff",
                                                marginRight: "5px"

                                            }} />
                                            Guardar
                                        </button>

                                        {
                                            (currentItem != '') && <>
                                                <button className="btn btn-danger shadow-none" onClick={
                                                    (e) => {

                                                    }
                                                } style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    marginLeft: "5px",
                                                    borderRadius:"15px",
                                                }}><DeleteIcon style={{
                                                    width: "22px",
                                                    height: "22px",
                                                    fill: "#fff",
                                                    marginTop: "3px"



                                                }} />
                                                    Eliminar
                                                </button>
                                            </>
                                        }

                                    </div>

                                </div>

                                <br />
                                <br />

                            </div>


                        </div>


                    </div>

                </div>

            </div>
        </EnvApp>
    );
}

export default Customers;