import React, { useState, useEffect, useContext } from 'react';

import { useHistory } from "react-router-dom";

import MaskInput from 'react-maskinput';

import toast from 'react-hot-toast';

import { save, getUsersByRol, getAsesors, remove } from '../../services/Agent';

import { saveKnowledge, getKnowledges } from '../../services/Knowledge';

import { ReactComponent as UserIcon } from '../../assets/icons/person.fill.svg';

import { ReactComponent as User1Icon } from '../../assets/icons/person.circle.svg';

import { ReactComponent as SearchIcon } from '../../assets/icons/magnifyingglass.svg';

import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';

import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.fill.svg';

import { ReactComponent as AcademyIcon } from '../../assets/icons/Academy.svg';

import { ReactComponent as ResultsIcon } from '../../assets/icons/text.magnifyingglass.svg';

import { ReactComponent as SaveIcon } from '../../assets/save.svg';

import { getSendersByBussine } from '../../services/Bussine';

import { AppContext } from '../../contexts/AppProvider';

import EnvApp from '../../components/EnvApp';


function Academy() {

    const history = useHistory();

    const { senderIds, setSenderIds } = useContext(AppContext);

    const [ref, setRef] = useState('');

    const [name, setName] = useState('');

    const [url, setUrl] = useState('');

    const [description, setDescription] = useState('');



    const [search, setSearch] = useState('');


    const [currentKnowledges, setCurrentKnowledges] = useState({
        ref: null,
        name: "",
        url: "",
        description: "",

    });

    const [knowledges, setKnowledges] = useState([]);



    useEffect(() => {

        setName(currentKnowledges.name);

        setUrl(currentKnowledges.url);

        setDescription(currentKnowledges.description);

    }, [currentKnowledges]);

    const onClear = () => {
        setRef("");
        setName("");
        setUrl("");
        setDescription("");

    }

    useEffect(() => {


        getKnowledges(window.localStorage.getItem("say-bussine")).then((res) => {
            // setChannels(res);



            setKnowledges(res)
        }).catch((err) => {
            console.log(err);
            //toast.error(err.data.message)
        })

    }, []);


    return (

        <EnvApp>
            <div style={{
                display: "grid",
                gridTemplateColumns: "auto",
                gridTemplateRows: "100px auto",
                background: "#fff",
                width: "100%",
                height: "100%",
                overflow: "auto",
                borderRadius: "10px 0px 0px 10px"
            }}>

                <div style={{
                    padding: "20px 50px 20px 50px"
                }}>

                    <div style={{
                        fontSize: "30px",
                        width: "100%",
                        height: "100%",
                        borderBottom: "1px solid #E8EBF0",
                        display: "flex"
                    }}>

                        <div style={{
                            width: "100%"
                        }}>
                            Base de conocimiento
                        </div>

                        <div style={{
                            width: "250px",
                            textAlign: "right"

                        }}>
                            <button style={{
                                borderRadius: "25px",
                                background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)"
                                                            
                            }} onClick={
                                () => {
                                    setCurrentKnowledges({
                                        ref: "",
                                        name: "",
                                        url: "",
                                        description: "",

                                    })
                                }
                            } className="btn btn-primary">
                                <PlusIcon style={{
                                    // width: "20px",
                                    //height: "20px",
                                    fill: "#fff"
                                }} />Nuevo video</button>
                        </div>


                    </div>

                </div>

                <div style={{
                    padding: "20px 50px 20px 50px"
                }}>

                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "280px auto",
                        gridTemplateRows: "auto",
                        height: "100%"
                    }}>

                        <div style={{
                            height: "100%"
                        }}>


                            <div style={{
                                display: "flex",
                                alignItems: "center"
                            }}>

                                <input type="text" value={search} style={{
                                    background: "#F2F7F8",
                                    border: "0px",
                                    height: "50px"
                                }} onChange={
                                    (e) => {
                                        setSearch(e.target.value)
                                    }
                                } className="form-control shadow-none" placeholder="Escribe para buscar" />

                                <button className="btn btn-primary shadow-none" style={{
                                    marginLeft: "5px",
                                    height: "50px",
                                    borderRadius: "15px",
                                    background: "rgb(8,55,155)",
                                    background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)"
                                }} onClick={
                                    () => {
                                        /*searchUsers(search, "agent").then((users) => {
                                            setAgents(users);
                                        }).catch((err_) => {
                                            console.log(err_);
                                        })*/
                                        toast.error("Esta funcionalidad aún no está disponible")
                                    }
                                }>
                                    <SearchIcon style={{
                                        //width: "20px",
                                        //height: "20px",
                                        fill: "#fff"
                                    }} />
                                </button>

                            </div>

                            <br />

                            {
                                (knowledges.length === 0) && <div style={{
                                    textAlign: "center"
                                }}>
                                    <div style={{
                                        fontSize: "18px",
                                        fontWeight: "700"
                                    }}><ResultsIcon style={{
                                        width: "40px",
                                        height: "40px",
                                        fill: "#fff"
                                    }} />
                                    </div>
                                    <div>Sin resultados</div>
                                </div>
                            }

                            {
                                knowledges.map((knowledge, knowledgeIndex) => {
                                    return (<div key={knowledgeIndex} style={{
                                        marginTop: "10px",
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        background: (currentKnowledges.ref === knowledge.ref) ? "#D9E2F1" : "transparent",
                                        padding: "10px",
                                        borderRadius: "10px"
                                    }} onClick={
                                        () => {
                                            setCurrentKnowledges(knowledge)
                                        }
                                    }>
                                        <div>
                                            <UserIcon style={{
                                                width: "55px",
                                                height: "55px",
                                                borderRadius: "50%",
                                                fill: (currentKnowledges.ref === knowledge.ref) ? '#08379B' : '#000'
                                            }} />
                                        </div>
                                        <div style={{
                                            width: "100%",
                                            marginLeft: "5px"
                                        }}>
                                            <div style={{
                                                fontWeight: "700",
                                                color: "#08379B"
                                            }}>{knowledge.name}</div>
                                            <div>
                                                <div>
                                                    <span><span>7.1</span> / 10</span>
                                                </div>
                                                <div style={{
                                                    width: "53px",
                                                    height: "3px",
                                                    background: "#E8EBF0"
                                                }}>
                                                    <div style={{
                                                        width: "80%",
                                                        height: "100%",
                                                        background: "#AFE1DA"
                                                    }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                                })
                            }



                        </div>

                        <div style={{
                            overflow: "auto"
                        }}>

                            {
                                (currentKnowledges.ref === null) && <div style={{
                                    padding: "0px 50px 0px 50px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100%",
                                    textAlign: "center"
                                }}>
                                    <div style={{
                                        maxWidth: "500px"
                                    }}>
                                        <div>
                                            <AcademyIcon style={{
                                                fill: "#fff"
                                            }} />
                                        </div>
                                        <div style={{
                                            fontSize: "25px",
                                            fontWeight: "500",
                                            marginTop: "-10px"
                                        }}>Base de conocimiento</div>
                                        <div style={{
                                            marginTop: "10px"
                                        }}>Selecciona el botón <b>+ Nuevo video</b>, En este módulo podrás visualizar los videos sobre el manejo de Sayform.</div>
                                    </div>
                                </div>
                            }


                            {
                                (currentKnowledges.ref != null) && <div>

                                    <div style={{
                                        padding: "0px 50px 0px 50px"
                                    }}>


                                        <div style={{
                                            fontSize: "25px",
                                            fontWeight: "700"
                                        }}>Información</div>


                                        <div style={{
                                            display: "flex"
                                        }}>
                                            <div style={{
                                                width: "100%"
                                            }}>
                                                <div>
                                                    <div style={{
                                                        fontWeight: "700",
                                                        color: "rgb(8, 55, 155)"
                                                    }}>Titulo</div>

                                                    <input type="text" value={name} class="shadow bg-body "onChange={
                                                        (e) => {
                                                            setName(e.target.value)
                                                        }
                                                    } className="form-control shadow-none"  style={{
                                                        width: "100%",
                                                        padding: "10px",
                                                        fontSize: "20px",
                                                        background: "#F2F7F8",
                                                        border: "0px"
                                                    }} placeholder="Titulo" />
                                                </div>
                                                <div>
                                                    <div style={{
                                                        fontWeight: "700",
                                                        color: "rgb(8, 55, 155)"
                                                    }}>URL</div>

                                                    <input type="text" value={url} class="shadow p-3 mb-5 bg-body rounded" onChange={
                                                        (e) => {
                                                            setUrl(e.target.value)
                                                        }
                                                    } className="form-control shadow-none" style={{
                                                        width: "100%",
                                                        padding: "10px",
                                                        fontSize: "20px",
                                                        background: "#F2F7F8",
                                                        border: "0px"
                                                    }} placeholder="URL" />
                                                </div>
                                            </div>
                                            <div style={{
                                                width: "450px"
                                            }}>

                                                <div style={{
                                                    padding: "10px"
                                                }}>
                                                    <iframe src={url} frameBorder="0" allowfullscreen="allowfullscreen" style={{
                                                        width: "100%"
                                                    }}></iframe>
                                                </div>

                                            </div>
                                        </div>

                                        <div>
                                            <div style={{
                                                fontWeight: "700",
                                                color: "rgb(8, 55, 155)"
                                            }}>Descripción</div>

                                            <textarea type="text" value={description} onChange={
                                                (e) => {
                                                    setDescription(e.target.value)
                                                }
                                            } style={{
                                                background: "#F2F7f8",
                                                border: "0px",
                                                padding: "10px",
                                                resize: "none",
                                                minHeight: "150px",
                                                fontSize: "20px",
                                            }} placeholder="Mensaje" className="w-100 shadow  bg-body rounded"></textarea>
                                        </div>

                                        <div>

                                            <button className="btn btn-primary shadow-none" style={{
                                                background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)",
                                                borderRadius:"15px"
                                            }} onClick={
                                                () => {
                                                    // Funcion para crear
                                                    saveKnowledge({
                                                        "ref": currentKnowledges.ref,
                                                        "iid": window.localStorage.getItem("say-bussine"),
                                                        "name": name,
                                                        "url": url,
                                                        "description": description
                                                    }).then((response) => {
                                                        console.log(response)
                                                        /* getKnowledge(window.localStorage.getItem("say-bussine")).then((response) => {
                                                             console.log(response)
                                                             setKnowledges(response)
                                                         })*/
                                                    })

                                                    //icono guardar cambios
                                                }}>

                                                <SaveIcon style={{
                                                    width: "15px",
                                                    height: "20px",
                                                    fill: "#fff",
                                                    marginRight: "8px"
                                                }} />



                                                {
                                                    (currentKnowledges.ref != "") ? "Guardar " : "Guardar"
                                                }
                                            </button >

                                            {
                                                (currentKnowledges.ref != '') && <button className="btn btn-danger shadow-none" style={{
                                                    padding: "6px",
                                                    background: "rgb(237,11,11)",
                                                    marginLeft: "5px",
                                                    background: "linear-gradient(180deg, rgba(237,11,11,1) 0%, rgba(227,84,98,1) 100%)"
                                                }} onClick={
                                                    () => {
                                                        remove(currentKnowledges.ref).then((res) => {
                                                            console.log(res);

                                                            getAsesors(window.localStorage.getItem("say-bussine")).then((res) => {
                                                                console.log(res);
                                                                setKnowledges(res);
                                                            }).catch((err) => {
                                                                console.log(err);
                                                            })

                                                            setCurrentKnowledges({
                                                                ref: null,
                                                                name: "",
                                                                url: "",
                                                                description: "",

                                                            })

                                                            onClear();

                                                            toast.success("El agente se elimino correctamente");

                                                        }).catch((error) => {
                                                            console.log(error);
                                                        })
                                                    }
                                                }>
                                                    <DeleteIcon style={{
                                                        width: "20px",
                                                        height: "20px",
                                                        fill: "#fff"
                                                    }} /> Eliminar</button>
                                            }

                                        </div>

                                    </div>

                                </div>
                            }


                        </div>


                    </div>

                </div>

            </div>
        </EnvApp >
    );

}

export default Academy;
