import { createContext, useState, useEffect, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";

import { io } from "socket.io-client";

import toast from 'react-hot-toast';

import { AppContext } from './AppProvider';

import { PHAT_SOCKET_DEV, PHAT_SOCKET_PROD, URL_SOCKET_PROD, URL_SOCKET_DEV, DEV } from '../config';

import { getNumbersChats, newMessage } from '../services/Chat';

export const SocketContext = createContext();

var socket = null;

const SocketProvider = ({ children }) => {

    const history = useHistory();

    const { NewChat, setNewChat, senderIds, setSenderIds, listQrs, CurrentPage, Phones, setPhones, Chats, setChats, setCurrentTerminal, CurrentChat, setTimeline, NewMessage, setNewMessage, Timeline } = useContext(AppContext);

    const [qr, setQr] = useState(''); // el qr actual

    const { jid, page, ref } = useParams();

    const [nChat, setNChat] = useState({
        t: 0,
        na: 0,
        np: 0
    });

    const [nAssigned, setNAssigned] = useState(0);

    const [nPending, setNPending] = useState(0);

    const [nClosed, setNClosed] = useState(0);

    const [numAssigned, setNumAssigned] = useState(0);

    const [numPending, setNumPending] = useState(0);

    const [numClose, setNumClose] = useState(0);

    const [open, setOpen] = useState(false);

    const [newPing, setNewPing] = useState(null);

    const [NewSta, setNewSta] = useState({
        iid: '',
        sender: '',
        code: '',
        details: ''
    });

    const [Status, setStatus] = useState({
        iid: null,
        senderId: null,
        code: 'DISCONECTED',
        details: ''
    });

    const [Presences, setPresences] = useState(null);

    useEffect(() => {
        console.log("NewMessage")
        console.log(NewMessage);
    }, [newMessage])

    useEffect(() => {

    }, [Presences])

    // Cada vez que se cargen los mensajes de un char preguntamos de nuevo por la cantidad
    useEffect(() => {

    }, [Timeline])

    useEffect(() => {

        getNumbersChats(window.localStorage.getItem("say-bussine"), window.localStorage.getItem("say-ref")).then((response) => {
            setNumAssigned(response.assigned);
            setNumPending(response.pending);
            setNumClose(response.closed);
        }).catch((error) => {
            console.log("error");
            console.log(error);
        })

    }, [Chats])


    useEffect(() => {
        if (NewSta.code === 'QR' || NewSta.code === 'CONNECTED' || NewSta.code === 'DISCONECTED' || NewSta.code === 'REPLACED' || NewSta.code === 'LOSSWIFI') {
            var copy = [...senderIds];
            // Modificamos el estado de todos los senders
            for (let i = 0; i < copy.length; i++) {
                // Preguntamos el senderid
                if (copy[i].name === NewSta.sender) {
                    copy[i].status.code = NewSta.code;
                    copy[i].status.details = NewSta.details;
                }
            }
            setSenderIds(copy);
            if (NewSta.code === "CONNECTED") {
                toast.success("El canal " + NewSta.sender + " se conectó");
            } else if (NewSta.code === "REPLACED") {
                toast.error("El canal " + NewSta.sender + " fue abierto en otro dispositivo");
            } else if (NewSta.code === "LOSSWIFI") {
                toast.error("El canal " + NewSta.sender + " perdió la conexión a internet");
            }
            // Actualizamos el codigo qr del sender activo
        } else if (NewSta.code === "LIMITQR") {
            console.log("SE DEJO DE GENERAR CODIGOS QR");
            /*setStatus({
                qr,
                open,
                replaced,
                message,
            })*/
        }
    }, [NewSta])

    useEffect(() => {
        console.log(listQrs);
    }, [listQrs])

    useEffect(() => {

        // Inicializamos el servicio socket.io
        socket = io(`${(DEV) ? URL_SOCKET_DEV : URL_SOCKET_PROD}`/*, {
            // path: (DEV) ? PHAT_SOCKET_DEV : PHAT_SOCKET_PROD,
            query: {},
            secure: true,
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: 99999
        }*/);

        /*socket.emit("token", {
            "token": "ABC"
        });*/

        socket.on('on-qr', (data) => {
            console.log('on-qr');
            console.log(data);
            setQr(data);
        })

        socket.on('on-disconnected', (data) => {
            console.log('on-disconnected');
            console.log(data);
            // senderIds, setSenderIds
            // Buscamos en el canal para actualizar el estado en desconectado
            var i = senderIds.findIndex((send) => send.ref === data.iid);
            if (i > -1) {
                var cop = [...senderIds];
                cop[i].status.code = "DISCONNECTED";
                cop[i].status.details = "...";
                setSenderIds(cop);
            }
        })

        socket.on('on-connected', (data) => {

            console.log('on-connected');
            console.log(data);

            // Buscamos en el canal para actualizar el estado a conectado
            var i = senderIds.findIndex((send) => send.ref === data.iid);

            console.log(data.iid);
            console.log(senderIds);

            if (i > -1) {
                var cop = [...senderIds];
                cop[i].status.code = "CONNECTED";
                cop[i].status.details = "...";
                setSenderIds(cop);
            }
        })

        socket.on('on-message', (data) => setNewMessage(data))

        socket.on('on-message-update', (data) => {
            console.log('on-message-update');
            console.log(data);
        })

        socket.on('on-presence-update', (data) => {
            console.log('on-presence-update');
            console.log(data);
        })

    }, [])

    const emit = (eve, data) => {
        if (socket != null) {
            socket.emit(eve, data)
        }
    }

    return (
        <SocketContext.Provider value={
            {
                qr, setQr,
                open, setOpen,
                Status, setStatus,
                emit,
                nChat, setNChat,
                nAssigned, setNAssigned, nPending, setNPending, nClosed, setNClosed,
                numAssigned, setNumAssigned, numPending, setNumPending, numClose, setNumClose,
                newPing, setNewPing
            }
        }>
            {children}
        </SocketContext.Provider>
    );

}

export default SocketProvider;