import React, { useState, useEffect, useContext } from 'react';

import { useHistory } from "react-router-dom";

import ContentEditable from 'react-contenteditable';

import toast, { Toaster } from 'react-hot-toast';

import SecurityForm from './SecurityForm';

import BussineForm from './BussineForm';

import PreferencesForm from './PreferencesForm';

import { save, getUser, searchUsers, removeUsers, passwordUpdate } from '../../services/Agent';

import { ReactComponent as UserIcon } from '../../assets/user.svg';

import { ReactComponent as ConfigIcon } from '../../assets/config.svg';

import { ReactComponent as SecureIcon } from '../../assets/secure.svg';

import { ReactComponent as BussineIcon } from '../../assets/bussine.svg';

import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';

import { ReactComponent as SaveIcon } from '../../assets/save.svg';

import { AppContext } from '../../contexts/AppProvider';

import { get } from '../../services/Bussine';

import EnvApp from '../../components/EnvApp';

function Profile() {

    const history = useHistory();

    const { Bussine, setBussine, Name, setName, Email, setEmail, Phone, setPhone } = useContext(AppContext);

    const [ref, setRef] = useState(window.localStorage.getItem("say-ref"));

    const [currentPassword, setCurrentPassword] = useState('');

    const [newPassword, setNewPassword] = useState('');

    const [confirmPassword, setConfirmPassword] = useState('');

    const [page, setPage] = useState('preferences');

    useEffect(() => {
        setBussine(window.localStorage.getItem("say-bussine"))
    }, []);


    useEffect(() => {
        /*if (page === "preferences") {
            // Cargamos las preferencias de la cuenta
            getUser(ref).then((res) => {
                console.log(res);

                setName(res.name);

                setEmail(res.email);

                setPhone(res.phone);

                // setBussine(res.ref);
            }).catch((err) => {
                console.log(err);
            })
        }
        if (page === "bussines") {
            // Cargamos la info la empresa de la cuneta
            get(Bussine).then((res) => {
                console.log(res);
                setBussine(res.ref);
            }).catch((err) => {
                console.log(err);
            })
        }
        if (page === "security") {

        }*/
    }, [page]);

    return (

        <EnvApp>

            <div style={{
                display: "grid",
                gridTemplateColumns: "auto",
                gridTemplateRows: "100px auto"
            }}>

                <div style={{
                    padding: "20px 50px 20px 50px"
                }}>

                    <div style={{
                        fontSize: "30px",
                        width: "100%",
                        height: "100%",
                        borderBottom: "1px solid #E8EBF0",
                        display: "flex"
                    }}>
                        <div style={{
                            width: "100%"
                        }}>
                            Cuenta
                        </div>
                        <div style={{
                            width: "250px",
                            textAlign: "right"
                        }}>

                        </div>
                    </div>



                </div>

                <div style={{
                    padding: "20px 50px 20px 50px"
                }}>

                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "300px auto",
                        gridTemplateRows: "auto",
                        height: "100%"
                    }}>
                        <div style={{
                            height: "100%"
                        }}>

                            <div style={{
                                fontWeight: "700",
                                fontSize: "20px",
                                marginTop: "10px",
                                color: "#27509F",
                                cursor: "pointer",
                                background: (page === "bussines") ? "#D9E2F1" : "transparent",
                                padding: "10px",
                                borderRadius: "10px"
                            }} onClick={
                                () => {
                                    setPage("bussines")
                                }
                            }>
                                <BussineIcon style={{
                                    width: "20px",
                                    height: "20px",
                                    fill: "#27509F"
                                }} /> Empresa
                            </div>

                            <div style={{
                                fontWeight: "700",
                                fontSize: "20px",
                                marginTop: "10px",
                                color: "#27509F",
                                cursor: "pointer",
                                background: (page === "preferences") ? "#D9E2F1" : "transparent",
                                padding: "10px",
                                borderRadius: "10px"
                            }} onClick={
                                () => {
                                    setPage("preferences")
                                }
                            }>
                                <ConfigIcon style={{
                                    width: "20px",
                                    height: "20px",
                                    fill: "#27509F"
                                }} /> Preferencias
                            </div>

                            <div style={{
                                fontWeight: "700",
                                fontSize: "20px",
                                marginTop: "10px",
                                color: "#27509F",
                                cursor: "pointer",
                                background: (page === "security") ? "#D9E2F1" : "transparent",
                                padding: "10px",
                                borderRadius: "10px"
                            }} onClick={
                                () => {
                                    setPage("security")
                                }
                            }>
                                <SecureIcon style={{
                                    width: "20px",
                                    height: "20px",
                                    fill: "#27509F"
                                }} /> Seguridad
                            </div>

                        </div>

                        <div style={{
                            padding: "0px 50px 0px 50px",
                            height: "100%"
                        }}>

                            {
                                (page === "security") && <SecurityForm />
                            }

                            {
                                (page === "preferences") && <PreferencesForm />
                            }

                            {
                                (page === "bussines") && <BussineForm />
                            }

                        </div>


                    </div>

                </div>

            </div>

        </EnvApp >
    );
}

export default Profile;

