import React, { useState, useEffect, useContext } from 'react';

import toast from 'react-hot-toast';

import { ReactComponent as SearchIcon } from '../../assets/icons/magnifyingglass.svg';

import EnvApp from '../../components/EnvApp';

import { getToken, generateToken, saveWebhook, getWebhook } from '../../services/Dev';

function Dev() {

    const [search, setSearch] = useState('');

    const [token, setToken] = useState('sin token');

    const [webhook, setWebhook] = useState('');



    useEffect(() => {

        getToken(window.localStorage.getItem("say-bussine")).then((token_) => {
            setToken(token_);
        })

        getWebhook(window.localStorage.getItem("say-bussine")).then((webhook_) => {
            console.log("webhook_");
            console.log(webhook_);
            setWebhook(webhook_);
        })

    }, []);

    const isValidHttpUrl = (string) => {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    return (

        <EnvApp>

            <div style={{
                display: "grid",
                gridTemplateColumns: "auto",
                gridTemplateRows: "100px auto",
                background: "#fff",
                width: "100%",
                height: "100%",
                overflow: "auto",
                borderRadius: "10px 0px 0px 10px"
            }}>

                <div style={{
                    padding: "20px 50px 20px 50px"
                }}>

                    <div style={{
                        fontSize: "30px",
                        width: "100%",
                        height: "100%",
                        borderBottom: "1px solid #E8EBF0",
                        display: "flex"
                    }}>
                        <div style={{
                            width: "100%"
                        }}>
                            Desarrolladores
                        </div>
                        <div style={{
                            width: "250px",
                            textAlign: "right"
                        }}>

                        </div>
                    </div>
                </div>

                <div style={{
                    padding: "20px 50px 20px 50px"
                }}>

                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "280px auto",
                        gridTemplateRows: "auto",
                        height: "100%"
                    }}>
                        <div style={{
                            height: "100%"
                        }}>

                            <div style={{
                                display: "flex",
                                alignItems: "center"
                            }}>

                                <input type="text" value={search} style={{
                                    background: "#F2F7F8",
                                    border: "0px",
                                    height: "50px"
                                }} onChange={
                                    (e) => {
                                        setSearch(e.target.value)
                                    }
                                } className="form-control shadow-none" placeholder="Escribe para buscar" />

                                <button className="btn btn-primary shadow-none" style={{
                                    marginLeft: "5px",
                                    height: "50px",
                                    borderRadius: "15px",
                                    background: "rgb(8,55,155)",
                                    background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)"
                                }} onClick={
                                    () => {
                                        toast.error("Esta funcionalidad aún no está disponible")
                                    }
                                }>
                                    <SearchIcon style={{
                                        fill: "#fff"
                                    }} />
                                </button>

                            </div>
                        </div>

                        <div style={{
                            padding: "0px 50px 0px 50px"
                        }}>

                            <div style={{
                                height: "100%",
                            }}>

                                <div style={{
                                    fontSize: "25px",
                                    fontWeight: "700"
                                }}>
                                    Token
                                </div>
                                <br />
                                <div style={{
                                    fontSize: "25px",
                                    fontWeight: "700"
                                }}>
                                    {token}
                                </div>
                                <br />
                                <div style={{
                                    width: "250px",
                                }}>
                                    <button style={{
                                        borderRadius: "15px",
                                        padding: "3px",
                                        width: "150px",
                                        background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)"
                                    }} onClick={
                                        () => {
                                            generateToken(window.localStorage.getItem("say-bussine")).then((response) => {

                                                getToken(window.localStorage.getItem("say-bussine")).then((token) => {
                                                    setToken(token);
                                                })

                                            })
                                        }
                                    } className="btn btn-primary">

                                        Generar Token
                                    </button>
                                </div>

                                <br />

                                <div>
                                    <div style={{
                                        fontSize: "25px",
                                        fontWeight: "700"
                                    }}>
                                        Webhook
                                    </div>
                                    <br />
                                    <div>
                                        <input className="form-control shadow-none" placeholder="Escribe la url aquí" value={webhook} onChange={
                                            (event) => {
                                                setWebhook(event.target.value)
                                            }
                                        } />

                                    </div>
                                    <br />
                                    <div style={{
                                        width: "250px",
                                    }}>
                                        <button style={{
                                            borderRadius: "15px",
                                            padding: "3px",
                                            width: "150px",
                                            background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)"
                                        }} onClick={
                                            () => {

                                                if (isValidHttpUrl(webhook)) {
                                                    saveWebhook(window.localStorage.getItem("say-bussine"), webhook).then(() => {

                                                        alert("Información actualizada");

                                                        getWebhook(window.localStorage.getItem("say-bussine")).then((webhook_) => {
                                                            setWebhook(webhook_);
                                                        })
                                                    })
                                                } else {
                                                    alert("No es una url valida");
                                                }

                                            }
                                        } className="btn btn-primary">

                                            Guardar
                                        </button>
                                    </div>
                                </div>

                                <br />

                                <div>
                                    <div style={{
                                        fontSize: "25px",
                                        fontWeight: "700"
                                    }}>
                                        Documentación
                                    </div>
                                    <br />
                                    La integración API de Sayform le permite a tu negocio enviar notificaciones de texto con archivos desde diferentes números de WhatsApp.
                                    <br />
                                    <br />
                                    Consulta la documentación de la API aquí: {" "} <a href="https://documenter.getpostman.com/view/13717245/UVJYJeFZ">Documentación Postman</a>
                                    <br />
                                    <br />

                                </div>

                            </div>
                        </div>




                    </div>
                </div>

            </div>
        </EnvApp>
    );
}

export default Dev;