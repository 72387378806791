import React, { useState, useEffect, useContext } from 'react';

import Env from './Env';


import WidgetProvider from '../../contexts/WidgetProvider';

function Widget() {

    return (
        <>
            <WidgetProvider>
                <Env />
            </WidgetProvider>
        </>

    );
}

export default Widget;