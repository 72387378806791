import React, { useState, useEffect, useContext } from 'react';

import 'react-responsive-modal/styles.css';

import './SenderIdCard.css';

import { Modal } from 'react-responsive-modal';

import { AppContext } from '../../contexts/AppProvider';

import { SocketContext } from '../../contexts/SocketProvider';

import { ReactComponent as ChannelIcon } from '../../assets/icons/ipad.and.iphone.svg';

import { ReactComponent as Send } from '../../assets/icons/paperplane1.svg';

import { ReactComponent as QrIcon } from '../../assets/qr.svg';

import axios from 'axios';

import { PHAT_DEV, PHAT_PROD, DEV, URL_SOCKET_DEV, URL_SOCKET_PROD } from '../../config';

import QrForm from './QrForm';

function SenderIdCard(props) {

    const { currentSenderId, setCurrentSenderId, iid } = useContext(AppContext);

    const { qr, setQr, Status, setStatus, emit } = useContext(SocketContext);

    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);

    const onCloseModal = () => setOpen(false);

    useEffect(() => {

    }, [props])

    useEffect(() => {

    }, [])

    return (
        <div style={{
            marginTop: "10px",
            cursor: "pointer",
            background: (currentSenderId.ref === props.SenderId.ref) ? "#D9E2F1" : "transparent",
            padding: "10px",
            borderRadius: "10px"
        }}>
            <div style={{
                display: "flex",
                alignItems: "center"
            }} onClick={
                () => {
                    setCurrentSenderId(props.SenderId)
                }
            }>
                <div>
                    <ChannelIcon style={{
                        width: "55px",
                        height: "55px",
                        borderRadius: "50%"
                    }} />
                </div>
                <div style={{
                    width: "100%",
                    marginLeft: "5px"
                }}>
                    <div style={{
                        fontWeight: "700",
                        color: "#08379B"
                    }}><span style={{
                        color: "#000",
                        fontWeight: "700"
                    }}>{props.SenderId.ref}</span> - {props.SenderId.name}</div>
                    <div style={{
                        fontSize: "0.8em"
                    }}>
                        {props.SenderId.channel}: {(props.SenderId.status.code === "DISCONECTED" ? "DESCONECTADO" : (props.SenderId.status.code === "LIMITQR") ? "LIMITE DE QR" : (props.SenderId.status.code === "CONNECTED") ? "CONECTADO" : (props.SenderId.status.code === "REPLACED") ? "EN USO" : (props.SenderId.status.code === "LOSSWIFI") ? "SIN INTERNET" : ".")}
                    </div>
                </div>
                {
                    (currentSenderId.ref === props.SenderId.ref && props.SenderId.channel === 'WHATSAPP') && <>
                        <div>

                            {
                                (props.SenderId.status.code === "DISCONNECTED") && <button style={{
                                    color: "#fff",
                                    border: "0px",
                                    background: "#0d6efd",
                                    borderRadius: "5px",
                                    height: "30px",
                                    marginRight: "8px"

                                }} onClick={
                                    (e) => {
                                        e.stopPropagation();
                                        e.preventDefault();

                                        //onOpenModal();

                                        props.onLogin()


                                    }
                                }>Conectar</button>
                            }

                            {
                                (props.SenderId.status.code === "CONNECTED") && <div>
                                    <button style={{
                                        color: "#fff",
                                        border: "0px",
                                        background: "#0d6efd",
                                        borderRadius: "5px",
                                        height: "30px",
                                        marginRight: "8px",

                                    }} onClick={
                                        (e) => {
                                            e.stopPropagation();
                                            e.preventDefault();

                                            // Eliminamos la sesionpara el sender

                                            axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/disconnect/${props.SenderId.ref}`, {})
                                                .then((response) => {
                                                    if (response.status === 200) {
                                                        console.log(response.data);
                                                    } else {
                                                        console.log("Ocurrio un problema al intentar guardar el agente, intente nuevamente.")
                                                    }
                                                }, (error) => {
                                                    console.log(error.reponse.data)
                                                });

                                            emit('logout', {
                                                iid: currentSenderId.ref
                                            })

                                        }
                                    }>Desconectar</button>

                                    <button
                                        style={{
                                            marginTop: "5px",
                                            color: "#fff",
                                            border: "0px",
                                            background: "#0d6efd",
                                            borderRadius: "5px",
                                            height: "30px",
                                            marginRight: "8px"

                                        }} onClick={
                                            (e) => {
                                                props.onSend();
                                            }}
                                    >
                                        <Send style={{
                                            width: "20px",
                                            height: "20px",
                                            fill: "#fff"
                                        }} />
                                    </button>
                                </div>


                            }

                            {
                                (props.SenderId.status.code === "QR") && <button style={{
                                    color: "#fff",
                                    border: "0px",
                                    background: "#0d6efd",
                                    borderRadius: "5px",
                                    height: "30px",
                                    marginRight: "8px"

                                }} onClick={
                                    (e) => {
                                        e.stopPropagation();
                                        e.preventDefault();

                                        // onOpenModal();

                                        props.onLogin();

                                    }
                                }>Adquirir QR</button>
                            }

                        </div>
                    </>
                }

                {
                    (currentSenderId.ref != props.SenderId.ref && props.SenderId.channel === 'WHATSAPP') && <>
                        <div>

                            {
                                (props.SenderId.status.code === "DISCONNECTED") && <div style={{
                                    width: "15px",
                                    height: "15px",
                                    background: "red",
                                    borderRadius: "50%"
                                }}>

                                </div>
                            }

                            {
                                (props.SenderId.status.code === "CONNECTED") && <div style={{
                                    width: "15px",
                                    height: "15px",
                                    background: "green",
                                    borderRadius: "50%"
                                }}>

                                </div>
                            }

                            {
                                (props.SenderId.status.code === "QR") && <div style={{
                                    width: "15px",
                                    height: "15px",
                                    background: "black",
                                    borderRadius: "50%"
                                }}>

                                </div>
                            }

                        </div>
                    </>
                }

                <Modal open={open} onClose={onCloseModal} classNames={{
                    overlay: 'customOverlay',
                    modal: 'customModal',
                }} center>
                    <div>
                        <QrForm />
                    </div>
                </Modal>

            </div>

            {
                (currentSenderId.ref === props.SenderId.ref && props.SenderId.channel === 'WHATSAPP' && (props.SenderId.status.code === "LOSSWIFI" || props.SenderId.status.code || "REPLACED" && props.SenderId.status.code || "CONNECTED")) && <button style={{
                    color: "#fff",
                    border: "0px",
                    background: "#ff0000",
                    borderRadius: "5px",
                    height: "30px",
                    marginRight: "8px",
                    width: "100%"

                }} onClick={
                    (e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/disconnect/${props.SenderId.ref}`, {})
                            .then((response) => {
                                if (response.status === 200) {
                                    console.log(response.data);
                                } else {
                                    console.log("Ocurrio un problema al intentar guardar el agente, intente nuevamente.")
                                }
                            }, (error) => {
                                console.log(error.reponse.data)
                            });

                        emit('logout', {
                            iid: currentSenderId.ref
                        })

                    }
                }>Reiniciar</button>
            }

        </div>
    );
}

export default SenderIdCard;