import React, { useState, useEffect, useContext } from 'react';

import axios from 'axios';

import toast from 'react-hot-toast';

import AgentCard from './AgentCard';

import WooNotification from './WooNotification';

import { PHAT_DEV, PHAT_PROD, DEV, URL_SOCKET_DEV, URL_SOCKET_PROD } from '../../config';

import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';

import { ReactComponent as ConnectIcon } from '../../assets/icons/connect.svg';

import { ReactComponent as SaveIcon } from '../../assets/save.svg';

import SayFormPlugin from '../../assets/plugins/SayForm.zip'


function Woocommerce(props) {

    const [ref, setRef] = useState('');

    const [iid, setIid] = useState(window.localStorage.getItem("say-bussine"));

    const [name, setName] = useState('');

    const [url, setUrl] = useState('');

    const [active, setActive] = useState('1');

    const [showList, setShowList] = useState(true);

    const [showForm, setShowForm] = useState(false);

    const [integrations, setIntegrations] = useState([]);

    const [onhold, setOnhold] = useState({
        image: {
            l: '',
            mimetype: ''
        },
        caption: ''
    });

    const [processing, setProcessing] = useState({
        image: {
            l: '',
            mimetype: ''
        },
        caption: ''
    });

    const [pending, setPending] = useState({
        image: {
            l: '',
            mimetype: ''
        },
        caption: ''
    });

    const [cancelled, setCancelled] = useState({
        image: {
            l: '',
            mimetype: ''
        },
        caption: ''
    });

    const [refunded, setRefunded] = useState({
        image: {
            l: '',
            mimetype: ''
        },
        caption: ''
    });

    const [failed, setFailed] = useState({
        image: {
            l: '',
            mimetype: ''
        },
        caption: ''
    });

    const [completed, setCompleted] = useState({
        image: {
            l: '',
            mimetype: ''
        },
        caption: ''
    });

    const clearForm = () => {

        setRef("");

        setName("");

        setUrl("");

        setActive("1");

    }

    const remove = (ref, service) => {
        return new Promise((resolve, reject) => {
            axios.delete(`${(DEV) ? PHAT_DEV : PHAT_PROD}/integration/${ref}/${service}`, {})
                .then((response) => {
                    // response.status === 200 
                    if (response.status === 200) {
                        resolve(response.data);
                    } else {
                        reject("Ocurrio un problema al recuperar las integraciones.");
                    }
                }, (error) => {
                    reject(error);
                });
        })
    }

    const load = () => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/get-integrations/${iid}/woocommerce`, {})
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data);
                    setIntegrations(response.data);
                } else {
                    toast.error("Ocurrió un problema al guardar la integración.");
                }
            }, (error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        load();
    }, [])

    const vaUrl = (value) => {
        return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
    }

    const save = (payload) => {
        // Notificamos al sevidor para guardar la configuracion y activar el uso del dialogflow
        return new Promise((resolve, reject) => {
            axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/save-integration`, payload)
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response.data);
                    } else {
                        reject("Ocurrió un problema al guardar la integración.");
                    }
                }, (error) => {
                    reject(error);
                });
        })
    }

    return (
        <>
            <div style={{
                padding: "20px"
            }}>


                <div style={{
                    display: "grid",
                    gridTemplateColumns: "auto 150px",
                    gridTemplateRows: "auto"
                }}>
                    <div>
                        <div style={{
                            fontSize: "30px",
                            fontWeight: "700",
                            display: "flex"
                        }}>
                            <div style={{
                                marginRight: "10px",
                                color: "#1E2347"
                            }}>Woocommerce</div>
                            <div style={{
                                fontSize: "15px"
                            }}>
                                <div style={{
                                    width: "50px",
                                    color: "#fff",
                                    textAlign: "center",
                                    background: "#6666FF",
                                    borderRadius: "5px"
                                }}>
                                    PRO
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button
                            style={{
                                borderRadius: "15px",
                                background: "rgb(8,55,155)",
                                background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)"
                            }}
                            onClick={
                                () => {
                                    console.log("Mostramos el formulario y ocultamos la lista!!!!");
                                    setShowForm(true);
                                    setShowList(false);
                                }
                            } className="btn btn-primary">
                            <PlusIcon style={{
                                width: "25px",
                                // height: "20px",
                                fill: "#fff"
                            }} />
                            Nueva Tienda</button>
                    </div>
                </div>

                <br />

                <div>Mejora tu estrategia de conversación dotando a tus bots de potencia de procesamiento de lenguaje natural mediante la integración de Dialogflow.</div>

                <br />

                {
                    (showList) && <>
                        <div style={{
                            display: "flex"
                        }}>

                            {
                                (integrations.length === 0) && <div style={{
                                    width: "100%",
                                    textAlign: "center",
                                    fontWeight: "500",
                                    marginTop: "-30px"
                                }}><div> <ConnectIcon style={{
                                    width: "350px",
                                    height: "350px",
                                    fill: "#fff"
                                }} /></div>
                                    <div style={{
                                        marginTop: "-60px"
                                    }}>Sin tiendas virtuales conectadas</div>
                                </div>
                            }

                            {
                                integrations.map((integration, iIndex) =>
                                    <div key={iIndex} style={{
                                        marginTop: "10px",
                                        marginLeft: "10px"
                                    }}>

                                        <AgentCard id={integration.ref} name={integration.name} onDelete={
                                            () => {
                                                // Eliminamos
                                                remove(integration.ref, 'WOOCOMMERCE').then((res) => {

                                                    load();

                                                    clearForm();

                                                    setShowList(true);

                                                    setShowForm(false);

                                                    toast.success("La integracion se elimino correctamente")
                                                }).catch((err) => {
                                                    console.log(err);
                                                })
                                            }
                                        } onEdit={
                                            () => {
                                                setRef(integration.ref);
                                                setName(integration.name);
                                                setUrl(integration.url);
                                                setOnhold(integration.onhold);
                                                setProcessing(integration.processing);
                                                setPending(integration.pending);
                                                setCancelled(integration.cancelled);
                                                setRefunded(integration.refunded);
                                                setFailed(integration.failed);
                                                setCompleted(integration.completed);
                                                setActive(integration.active);
                                                setShowList(false);
                                                setShowForm(true);
                                            }
                                        } /></div>
                                )
                            }
                        </div>

                    </>
                }

                {
                    (showForm) && <>

                        <div>
                            <h3>General</h3>
                            <div>...</div>
                        </div>

                        <br />

                        <div>
                            <div style={{
                                fontWeight: "500"
                            }}>Nombre</div>
                            <br />
                            <input type="text" value={name} onChange={
                                (e) => {
                                    setName(e.target.value);
                                }
                            } placeholder="Nombre de la integración" className="shadow w-100" />
                        </div>

                        <br />

                        <div>
                            <div style={{
                                fontWeight: "500"
                            }}>URL de tu tienda woocommerce</div>
                            <br />
                            <input type="text" value={url} onChange={
                                (e) => {
                                    setUrl(e.target.value);
                                }
                            } placeholder="URL del sitio web" className="shadow w-100" />
                        </div>

                        <br />

                        {
                            /*
                            https://store-example.com/wp-json/wc-filter/v1/filter/?product=Gucci
                            */
                        }


                        <div>
                            <h3>Notificaciones</h3>
                            <div>Notifica a tus clientes sobre el estado de sus órdenes por medio de whatsapp</div>
                        </div>

                        <br />

                        <div className="row" >
                            <div className="col-4 ">
                                <WooNotification title="Notificaciones (On-hold)"  value={onhold} onChange={
                                    (ea) => {
                                        setOnhold(ea);
                                    }
                                } />
                            </div>
                            <div className="col-4">
                                <WooNotification title="Notificaciones (Processing)" value={processing} onChange={
                                    (ea) => {
                                        setProcessing(ea);
                                    }
                                } />
                            </div>

                            <div className="col-4">
                                <WooNotification title="Notificaciones (Pending)" value={pending} onChange={
                                    (ea) => {
                                        setPending(ea);
                                    }
                                } />
                            </div>
                            <div className="col-4">
                                <WooNotification title="Notificaciones (Cancelled)" value={cancelled} onChange={
                                    (ea) => {
                                        setCancelled(ea);
                                    }
                                } />
                            </div>


                            <div className="col-4">
                                <WooNotification title="Notificaciones (Refunded)" value={refunded} onChange={
                                    (ea) => {
                                        setRefunded(ea);
                                    }
                                } />
                            </div>
                            <div className="col-4">
                                <WooNotification title="Notificaciones (Failed)" value={failed} onChange={
                                    (ea) => {
                                        setFailed(ea);
                                    }
                                } />
                            </div>


                            <div className="col-4">
                                <WooNotification title="Notificaciones (Completed)" value={completed} onChange={
                                    (ea) => {
                                        setCompleted(ea);
                                    }
                                } />
                            </div>

                        </div>

                        <br />

                        <div>
                            <h3>Plugin</h3>
                            <div>Descarga, instala el plugin de sayform para poder realizar la conexión con tu tienda virtual</div>
                        </div>

                        <br />

                        <a href={SayFormPlugin}>Descargar</a>

                        <br />

                        <br />


                        <div>

                            <div style={{
                                fontWeight: "500"
                            }}>Activo</div>
                            <br />
                            <select value={active} class="shadow " onChange={
                                (e) => {
                                    setActive(e.target.value)
                                }
                            }>
                                <option value="1">Si</option>
                                <option value="0">No</option>
                            </select>
                        </div>

                        <br />

                        <div>
                            <button className="btn btn-primary"
                                style={{
                                    background: "rgb(8,55,155)",
                                    background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)",
                                    borderRadius:"15px"
                                }}
                                onClick={
                                    () => {
                                        if (vaUrl(url)) {

                                            save({
                                                ref,
                                                iid,
                                                name,
                                                type: "WOOCOMMERCE",
                                                url,
                                                onhold,
                                                processing,
                                                pending,
                                                cancelled,
                                                refunded,
                                                failed,
                                                completed,
                                                active
                                            }).then((response) => {

                                                toast.success("Se guardó correctamente")

                                                clearForm();

                                                load();

                                                setShowList(true);

                                                setShowForm(false);

                                            }).catch((error) => {
                                                console.log(error);
                                            })

                                        } else {
                                            toast.error("La URL no es correcta")
                                        }
                                    }
                                }><SaveIcon style={{
                                    width: "15px",
                                    height: "20px",
                                    fill: "#fff",
                                    marginRight: "5px"
                                }} />
                                Guardar</button>


                        </div>
                    </>
                }


            </div>
        </>
    );
}

export default Woocommerce;