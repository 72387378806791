import axios from 'axios';

import { PHAT_DEV, PHAT_PROD, DEV, URL_SOCKET_DEV, URL_SOCKET_PROD } from '../config';

export const getToken = (ref) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/bussines/token/${ref}`, {})
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al intentar recuperar los clientes, intente nuevamente.");
                }
            }, (error) => {
                reject(error.response);
            });
    })
}

export const generateToken = (ref) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.put(`${(DEV) ? PHAT_DEV : PHAT_PROD}/bussines/token/${ref}`, {})
            .then((response) => {
                if (response.status === 200) {
                    console.log(response);
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al intentar de guardar el SenderId, intente nuevamente.");
                }
            }, (error) => {
                reject(error.response);
            });
    })
}

export const getWebhook = (ref) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/bussines/webhook/${ref}`, {})
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al intentar recuperar los clientes, intente nuevamente.");
                }
            }, (error) => {
                reject(error.response);
            });
    })
}

export const saveWebhook = (ref, url) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.put(`${(DEV) ? PHAT_DEV : PHAT_PROD}/bussines/webhook/${ref}`, {
            url
        })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response);
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al intentar de guardar el SenderId, intente nuevamente.");
                }
            }, (error) => {
                reject(error.response);
            });
    })
}