import React, { useState, useEffect, useContext } from 'react';

import { useHistory } from "react-router-dom";

import { ReactComponent as PlusIcon } from '../../assets/plus.svg';

import { AppContext } from '../../contexts/AppProvider';

import { SocketContext } from '../../contexts/SocketProvider';

function Header() {

    const { iid, setCurrentSenderId } = useContext(AppContext);

    const { emit } = useContext(SocketContext);

    useEffect(() => {
        if (iid != null && iid != "") {
            emit('hello', {
                iid: iid,
                ref: window.localStorage.getItem("say-ref")
            })
        }
    }, [iid])

    return (
        <div style={{
            padding: "20px 50px 20px 50px"
        }}>

            <div style={{
                fontSize: "30px",
                width: "100%",
                height: "100%",
                borderBottom: "1px solid #E8EBF0",
                display: "flex"
            }}>
                <div style={{
                    width: "100%"
                }}>
                    Canales
                </div>
                <div style={{
                    width: "250px",
                    textAlign: "right"

                }}>


                    <button  className="shadow btn btn-primary" style={{
                        background: "rgb(73,227,84)",
                        background: "linear-gradient(0deg, rgba(73,227,84,1) 0%, rgba(11,179,24,1) 100%)",
                        border: "1px solid #0bb318",
                        borderRadius: "15px"


                    }}
                        onClick={
                            () => {
                                // Creamos un nuevo sender id 
                                setCurrentSenderId({
                                    ref: "",
                                    name: "",
                                    online: false,
                                    replaced: false
                                })

                                // setName("");
                            }
                        } >
                        <PlusIcon style={{
                            width: "20px",
                            height: "20px",
                            fill: "#fff"
                        }} /> Nuevo Canal</button>
                </div>
            </div>

        </div>
    );
}

export default Header;