import React, { useState, useEffect, useRef } from 'react';

import { useHistory } from "react-router-dom";

import toast from 'react-hot-toast';

// import { save, getAllContactList, getAsesors, remove } from '../../services/Agent';

import { saveContactGroup, updateContactGroup, getAllContactGroup, saveContact, updateContact, contactsByGroup, removeContact, importFile } from '../../services/Contact';

import { ReactComponent as UserIcon } from '../../assets/icons/person.fill.svg';

import { ReactComponent as User1Icon } from '../../assets/icons/person.circle.svg';

import { ReactComponent as SearchIcon } from '../../assets/icons/magnifyingglass.svg';

import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';

import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.fill.svg';

import { ReactComponent as ContactIcon } from '../../assets/icons/ContactUs.svg';

import { ReactComponent as ResultsIcon } from '../../assets/icons/text.magnifyingglass.svg';

import { ReactComponent as EditIcon } from '../../assets/icons/square.and.pencil.svg';

import { ReactComponent as RemoveIcon } from '../../assets/icons/trash.square.svg';

import { ReactComponent as NewIcon } from '../../assets/icons/plus.circle.fill.svg';

import { ReactComponent as ImportIcon } from '../../assets/icons/tray.and.arrow.down.fill.svg';

import { ReactComponent as ExportIcon } from '../../assets/icons/tray.and.arrow.up.fill.svg';

import { ReactComponent as CorreoIcon } from '../../assets/icons/envelope.svg';

import { ReactComponent as TelIcon } from '../../assets/icons/flipphone.svg';

import { ReactComponent as SaveIcon } from '../../assets/save.svg';

import EnvApp from '../../components/EnvApp';

function Contacts() {

    const history = useHistory();

    const file = useRef(null);

    const [ref, setRef] = useState('');

    const [name, setName] = useState('');

    const [names, setNames] = useState('');

    const [surnames, setSurnames] = useState('');

    const [phone, setPhone] = useState('');

    const [search, setSearch] = useState('');

    const [showNewForm, setShowNewForm] = useState(false);

    const [currentContactList, setCurrentContactList] = useState({
        _id: "",
        ref: "",
        name: ""
    });

    const [contacts, setContacts] = useState([]);

    const [contactList, setContactList] = useState([]);

    useEffect(() => {
        setName(currentContactList.name);
        if (currentContactList._id != "") {
            contactsByGroup(currentContactList._id).then((res) => {
                setContacts(res);
            });
        }
    }, [currentContactList]);

    const onClear = () => {
        setName("");
    }

    const onClearContactForm = () => {
        setRef("");
        setNames("");
        setSurnames("");
        setPhone("");
    }

    useEffect(() => {

        getAllContactGroup(window.localStorage.getItem("say-bussine")).then((res) => {

            console.log(res);

            setContactList(res);

            if (res.length > 0) {
                setCurrentContactList(res[0]);
            }

        }).catch((err) => {
            console.log(err);
        })

    }, []);

    // save-contact-to-group

    const save = (payload) => {
        return new Promise((resolve, reject) => {

        })
    }

    return (

        <EnvApp>

            <div style={{
                display: "grid",
                background: "red",
                gridTemplateColumns: "auto",
                gridTemplateRows: "100px auto",
                background: "#fff",
                width: "100%",
                height: "100vh",
                overflow: "auto",
                borderRadius: "10px 0px 0px 10px"
            }}>

                <div style={{
                    padding: "20px 10px 20px 10px",
                    height: "100%",
                    overflow: "auto"

                }}>

                    <div style={{
                        fontSize: "30px",
                        width: "100%",
                        height: "100%",
                        borderBottom: "1px solid #E8EBF0",
                        display: "flex"
                    }}>
                        <div style={{
                            width: "100%"
                        }}>
                            Grupo de contactos
                        </div>
                        <div style={{
                            width: "250px",
                            textAlign: "right"

                        }}>
                            <button style={{
                                borderRadius: "45px",
                                padding: "6px",
                                width: "130px",
                                background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)"
                            }} onClick={
                                () => {
                                    setCurrentContactList({
                                        _id: "",
                                        ref: "",
                                        name: ""
                                    })
                                }
                            } className="btn btn-primary">
                                <PlusIcon style={{
                                    // width: "20px",
                                    //height: "20px",
                                    fill: "#fff"
                                }} /> Nueva lista </button>
                        </div>
                    </div>



                </div>

                <div style={{
                    padding: "20px 0px 20px 0px",
                    overflow: "auto"
                }}>

                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "280px auto",
                        gridTemplateRows: "auto",
                        height: "100%",
                        overflow: "auto"
                    }}>
                        <div style={{
                            height: "100%",
                            overflow: "auto"
                        }}>

                            <div style={{
                                display: "flex",
                                alignItems: "center"
                            }}>

                                <input type="text" value={search} style={{
                                    background: "#F2F7F8",
                                    border: "0px",
                                    height: "50px"
                                }} onChange={
                                    (e) => {
                                        setSearch(e.target.value)
                                    }
                                } className="form-control shadow-none" placeholder="Escribe para buscar" />

                                <button className="btn btn-primary shadow-none" style={{
                                    marginLeft: "5px",
                                    height: "50px",
                                    borderRadius: "15px",
                                    background: "rgb(8,55,155)",
                                    background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)"
                                }} onClick={
                                    () => {
                                        /*searchUsers(search, "agent").then((users) => {
                                            setAgents(users);
                                        }).catch((err_) => {
                                            console.log(err_);
                                        })*/
                                        toast.error("Esta funcionalidad aún no está disponible")
                                    }
                                }>
                                    <SearchIcon style={{
                                        //width: "20px",
                                        //height: "20px",
                                        fill: "#fff"
                                    }} />
                                </button>

                            </div>

                            <br />

                            <div>

                                <input type="file" ref={file} onChange={
                                    (e) => {

                                        // Enviamos el archivo a la cola

                                        importFile(e.target.files[0], window.localStorage.getItem("say-bussine"), currentContactList._id).then((res) => {

                                            // Obtenemos el listado de contactos

                                            contactsByGroup(currentContactList._id).then((res) => {

                                                console.log("res");
                                                console.log(res);

                                                setContacts(res);

                                            });

                                        }).catch((error) => {
                                            console.log("errorerrorerror");
                                            console.log(error);
                                        })

                                    }
                                } style={{
                                    position: "absolute",
                                    top: "0px",
                                    left: "0px",
                                    top: "-1000px",
                                    left: "-1000px",
                                    zIndex: "999999999",
                                }} accept="text/csv, .csv" />

                                <button style={{
                                    width: "115px",
                                    borderRadius: "45px",
                                    marginLeft: "10px",
                                    background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)"

                                }}
                                    onClick={
                                        () => {

                                            setCurrentContactList({
                                                _id: "",
                                                ref: "",
                                                name: ""
                                            })

                                        }
                                    } className="btn btn-primary">
                                    Importar</button>

                                <button style={{
                                    marginLeft: "15px",
                                    width: "115px",
                                    borderRadius: "45px",
                                    background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)"



                                }} onClick={
                                    () => {
                                        setCurrentContactList({
                                            _id: "",
                                            ref: "",
                                            name: ""
                                        })
                                    }
                                } className="btn btn-primary">
                                    Exportar</button>
                            </div>

                            {
                                (contactList.length === 0) && <div style={{
                                    textAlign: "center"
                                }}>
                                    <div style={{
                                        fontSize: "18px",
                                        fontWeight: "700"
                                    }}><ResultsIcon style={{
                                        width: "40px",
                                        height: "40px",
                                        fill: "#fff"
                                    }} /></div>
                                    <div>Sin resultados</div>
                                </div>
                            }

                            {
                                contactList.map((ContactList, ContactIndex) => {
                                    return (<div key={ContactIndex} style={{
                                        marginTop: "10px",
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        background: (currentContactList.ref === ContactList.ref) ? "#D9E2F1" : "transparent",
                                        padding: "10px",
                                        borderRadius: "10px"
                                    }} onClick={
                                        () => {
                                            setCurrentContactList(ContactList)
                                        }
                                    }>
                                        <div>
                                            <UserIcon style={{
                                                width: "55px",
                                                height: "55px",
                                                borderRadius: "50%",
                                                fill: (currentContactList.ref === ContactList.ref) ? '#08379B' : '#000'
                                            }} />
                                        </div>
                                        <div style={{
                                            width: "100%",
                                            marginLeft: "5px"
                                        }}>
                                            <div style={{
                                                fontWeight: "700",
                                                color: "#08379B"
                                            }}>{ContactList.name}</div>
                                            <div>
                                                {ContactList.ref}
                                            </div>
                                        </div>
                                    </div>)
                                })
                            }

                        </div>



                        <div style={{
                            height: "100%",
                            overflow: "auto"
                        }}>

                            {
                                (currentContactList.ref === null) && <div style={{
                                    padding: "0px 50px 0px 50px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100%",
                                    textAlign: "center"
                                }}>
                                    <div style={{
                                        maxWidth: "500px"
                                    }}>
                                        <div>
                                            <ContactIcon style={{
                                                fill: "#fff"
                                            }} />
                                        </div>
                                        <div style={{
                                            fontSize: "25px",
                                            fontWeight: "500",
                                            marginTop: "-50px"
                                        }}>Crear contacto</div>
                                        <div style={{
                                            marginTop: "10px"
                                        }}>Dando clic en el botón<b> + Nueva lista</b>, podrás crear y editar la infomación de un contacto, además de que podrás importar y exportar la lista de contactos.</div>
                                    </div>
                                </div>
                            }


                            {
                                (currentContactList.ref != null) && <div style={{
                                    overflow: "auto"
                                }}>
                                    <div style={{
                                        padding: "0px 50px 0px 50px",
                                        display: "flex"
                                    }}>

                                        <div style={{
                                            width: "80px"
                                        }}>
                                            <User1Icon style={{
                                                width: "115px",
                                                height: "115px",
                                                borderRadius: "50%",
                                                fill: "rgb(8, 55, 155)",
                                                marginLeft: "-20"
                                            }} />
                                        </div>

                                        <div style={{
                                            width: "100%"
                                        }}>

                                            <div className="form-control shadow-none plain" style={{
                                                fontSize: "30px",
                                                fontWeight: "700",
                                                border: "0px"
                                            }} >{(name != "") ? name : "Sin nombre"}</div>

                                            <div className="form-control shadow-none plain" style={{
                                                fontSize: "20px",
                                                border: "0px"
                                            }} >...</div>

                                        </div>

                                    </div>

                                    <div style={{
                                        padding: "0px 50px 0px 50px"
                                    }}>

                                        {
                                            (!showNewForm) && <>
                                                <br />

                                                <div style={{
                                                    minHeight: "100px"
                                                }}>

                                                    <div style={{
                                                        fontSize: "25px",
                                                        fontWeight: "700"
                                                    }}>Información</div>

                                                    <div style={{
                                                        marginTop: "10px"
                                                    }}>

                                                        <div className="row">

                                                            <div className="col-6 mt-3">
                                                                <div style={{
                                                                    fontWeight: "700",
                                                                    color: "rgb(8, 55, 155)"
                                                                }}>Nombres </div>

                                                                <input type="text" value={name} onChange={
                                                                    (e) => {
                                                                        setName(e.target.value)
                                                                    }
                                                                } class="shadow p-3 mb-5 bg-body rounded" style={{
                                                                    width: "100%",
                                                                    padding: "10px",
                                                                    fontSize: "20px",
                                                                    background: "#F2F7F8",
                                                                    border: "0px"
                                                                }} placeholder="Nombres" />
                                                            </div>

                                                            {
                                                                (currentContactList.ref != "") && <>


                                                                    <div style={{
                                                                        fontSize: "25px",
                                                                        fontWeight: "700",
                                                                        marginTop: "20px"
                                                                    }}>Contactos</div>

                                                                    <button style={{
                                                                        width: "130px",
                                                                        borderRadius: "45px",
                                                                        height: "30px",
                                                                        marginLeft: "10px"

                                                                    }}
                                                                        onClick={
                                                                            () => {
                                                                                setShowNewForm(true)
                                                                            }
                                                                        } >
                                                                        <NewIcon style={{
                                                                            width: "25px",
                                                                            height: "25px",
                                                                            fill: "#fff",
                                                                            marginRight: "10px"
                                                                        }} />
                                                                        Nuevo</button>

                                                                    <button style={{
                                                                        width: "130px",
                                                                        borderRadius: "45px",
                                                                        height: "30px",
                                                                        marginLeft: "10px"

                                                                    }}
                                                                        onClick={
                                                                            () => {
                                                                                // Abrimos el upload
                                                                                file.current.click();
                                                                            }
                                                                        } > <ImportIcon style={{
                                                                            width: "25px",
                                                                            height: "25px",
                                                                            fill: "#fff",
                                                                            marginRight: "10px"
                                                                        }} />
                                                                        Importar</button>

                                                                    <button style={{
                                                                        width: "130px",
                                                                        borderRadius: "45px",
                                                                        height: "30px",
                                                                        marginLeft: "10px",


                                                                    }}
                                                                        onClick={
                                                                            () => {
                                                                                setCurrentContactList({
                                                                                    _id: "",
                                                                                    ref: "",
                                                                                    name: ""
                                                                                })
                                                                            }
                                                                        } > <ExportIcon style={{
                                                                            width: "25px",
                                                                            height: "25px",
                                                                            fill: "#fff",
                                                                            marginRight: "10px"
                                                                        }} />
                                                                        Exportar</button>



                                                                </>
                                                            }


                                                        </div>

                                                        <div class="row" >
                                                            {
                                                                contacts.map((contact, contactIndex) =>
                                                                    <div class="col-6" >
                                                                        <div key={contactIndex} className="shadow bg-body rounded" style={{
                                                                            background: "#fff",
                                                                            display: "grid",
                                                                            gridTemplateColumns: "auto",
                                                                            gridTemplateRows: "auto 50px",
                                                                            borderRadius: "20px",
                                                                            padding: "20px",
                                                                            marginTop: "30px"
                                                                        }}>

                                                                            <div style={{
                                                                                display: "grid",
                                                                                gridTemplateColumns: "100px auto",
                                                                                gridTemplateRows: "auto",
                                                                                alignSelf: "center",
                                                                                marginTop: "30px"

                                                                            }}>
                                                                                <div>
                                                                                    <div style={{ width: "90px", height: "90px", borderRadius: "50%" }}>
                                                                                        <User1Icon style={{
                                                                                            width: "90px",
                                                                                            height: "90px",
                                                                                            borderRadius: "50%",
                                                                                            fill: "rgb(8, 55, 155)"
                                                                                        }} />
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <div style={{ fontWeight: "700", textTransform: "uppercase", fontSize: "20px" }}>{contact.names} {contact.surnames}</div>
                                                                                    <div><TelIcon style={{
                                                                                        width: "25px",
                                                                                        height: "25px",
                                                                                        fill: "#fff",

                                                                                    }} />{contact.phone}</div>
                                                                                    <div><CorreoIcon style={{
                                                                                        width: "25px",
                                                                                        height: "25px",
                                                                                        fill: "#fff",

                                                                                    }} />Sin correo electrónico</div>
                                                                                </div>
                                                                            </div>

                                                                            <div style={{
                                                                                alignSelf: "center",
                                                                                display: "flex"
                                                                            }}>
                                                                                <button class="btn btn-primary" onClick={
                                                                                    (e) => {

                                                                                        setShowNewForm(true);

                                                                                        setRef(contact.ref);

                                                                                        setNames(contact.names);

                                                                                        setSurnames(contact.surnames);

                                                                                        setPhone(contact.phone);

                                                                                    }
                                                                                } style={{ borderRadius: "20px", marginRight: "10px", width: "80px", height: "30px", padding: "1px" }}>Editar</button>
                                                                                <button class="btn btn-danger" onClick={
                                                                                    () => {
                                                                                        removeContact(contact.ref).then((res) => {

                                                                                            console.log(res);

                                                                                            contactsByGroup(currentContactList._id).then((res) => {
                                                                                                setContacts(res);
                                                                                            });

                                                                                        })
                                                                                    }
                                                                                } style={{ borderRadius: "20px", height: "30px", padding: "1px", width: "80px" }}>Eliminar</button>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>



                                                    </div>
                                                    <br />

                                                </div>

                                                <div style={{
                                                    minHeight: "100px"
                                                }}>

                                                    <br />

                                                    <div>
                                                        <button className="btn btn-primary shadow-none" style={{
                                                            background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)",
                                                            borderRadius: "15px"
                                                        }} onClick={
                                                            () => {
                                                                // Funcion para crear

                                                                if (name != "") {

                                                                    if (currentContactList.ref != "") {

                                                                        alert(name);

                                                                        updateContactGroup(currentContactList.ref, {
                                                                            iid: window.localStorage.getItem("say-bussine"),
                                                                            name: name
                                                                        }).then((res) => {
                                                                            console.log("resresresresresresresresresres");
                                                                            console.log(res);
                                                                            // Modificamos la lista

                                                                            onClear();

                                                                            getAllContactGroup(window.localStorage.getItem("say-bussine")).then((res) => {
                                                                                console.log(res);
                                                                                setContactList(res);
                                                                            }).catch((err) => {
                                                                                console.log(err);
                                                                            })
                                                                        }).catch((err) => {
                                                                            toast.error(err)
                                                                        })
                                                                    } else {
                                                                        saveContactGroup({
                                                                            ref: currentContactList.ref,
                                                                            iid: window.localStorage.getItem("say-bussine"),
                                                                            name: name
                                                                        }).then((res) => {
                                                                            console.log("resresresresresresresresresres");
                                                                            console.log(res);
                                                                            // Modificamos la lista

                                                                            onClear();

                                                                            getAllContactGroup(window.localStorage.getItem("say-bussine")).then((res) => {
                                                                                console.log(res);
                                                                                setContactList(res);
                                                                            }).catch((err) => {
                                                                                console.log(err);
                                                                            })

                                                                            /*
    
                                                                            setCurrentContactList({
                                                                                _id: "",
                                                                                ref: "",
                                                                                name: ""
                                                                            })*/

                                                                        }).catch((err) => {
                                                                            toast.error(err)
                                                                        })
                                                                    }



                                                                } else {
                                                                    toast.error("El nombre de la lista no puede estar en blanco")
                                                                }

                                                                //icono guardar cambios
                                                            }}>

                                                            <SaveIcon style={{
                                                                width: "15px",
                                                                height: "20px",
                                                                fill: "#fff",
                                                                marginRight: "8px"
                                                            }} />



                                                            {
                                                                (currentContactList.ref != "") ? "Guardar cambios" : "Guardar"
                                                            }
                                                        </button >
                                                    </div >

                                                </div >

                                                <br />
                                                <br />

                                                {
                                                    (currentContactList.ref != '') && <div style={{
                                                        textAlign: "right"
                                                    }}>
                                                        <button className="btn btn-danger shadow-none" style={{
                                                            padding: "6px", background: "rgb(237,11,11)",
                                                            background: "linear-gradient(180deg, rgba(237,11,11,1) 0%, rgba(227,84,98,1) 100%)"

                                                        }} onClick={
                                                            () => {



                                                                removeContact(currentContactList.ref).then((res) => {
                                                                    console.log(res);

                                                                    getAllContactGroup(window.localStorage.getItem("say-bussine")).then((res) => {
                                                                        console.log(res);
                                                                        setContactList(res);
                                                                    }).catch((err) => {
                                                                        console.log(err);
                                                                    })

                                                                    setCurrentContactList({
                                                                        ref: null,
                                                                        name: ""
                                                                    })

                                                                    onClear();

                                                                    toast.success("El contacto se eliminó correctamente");

                                                                }).catch((error) => {
                                                                    console.log(error);
                                                                })

                                                            }
                                                        }>
                                                            <DeleteIcon style={{
                                                                width: "20px",
                                                                height: "20px",
                                                                fill: "#fff"
                                                            }} /> Eliminar lista</button>
                                                    </div>
                                                }
                                            </>
                                        }

                                        {
                                            (showNewForm) && <>

                                                <h3>Nuevo contacto</h3>

                                                <br />

                                                <div className="row">

                                                    <div className="col-6 mt-3">
                                                        <div style={{
                                                            fontWeight: "700",
                                                            color: "rgb(8, 55, 155)"
                                                        }}>Nombres </div>

                                                        <input type="text" value={names} onChange={
                                                            (e) => {
                                                                setNames(e.target.value)
                                                            }
                                                        } className="form-control shadow-none" style={{
                                                            width: "100%",
                                                            padding: "10px",
                                                            fontSize: "20px",
                                                            background: "#F2F7F8",
                                                            border: "0px"
                                                        }} placeholder="Nombres" />
                                                    </div>

                                                    <div className="col-6 mt-3">
                                                        <div style={{
                                                            fontWeight: "700",
                                                            color: "rgb(8, 55, 155)"
                                                        }}>Apellidos </div>

                                                        <input type="text" value={surnames} onChange={
                                                            (e) => {
                                                                setSurnames(e.target.value)
                                                            }
                                                        } className="form-control shadow-none" style={{
                                                            width: "100%",
                                                            padding: "10px",
                                                            fontSize: "20px",
                                                            background: "#F2F7F8",
                                                            border: "0px"
                                                        }} placeholder="Apellidos" />
                                                    </div>


                                                    <div className="col-6 mt-3">
                                                        <div style={{
                                                            fontWeight: "700",
                                                            color: "rgb(8, 55, 155)"
                                                        }}>Telefono </div>

                                                        <input type="text" value={phone} onChange={
                                                            (e) => {
                                                                setPhone(e.target.value)
                                                            }
                                                        } className="form-control shadow-none" style={{
                                                            width: "100%",
                                                            padding: "10px",
                                                            fontSize: "20px",
                                                            background: "#F2F7F8",
                                                            border: "0px"

                                                        }} placeholder="Número de teléfono" />
                                                    </div>



                                                </div>

                                                <br />

                                                <div>
                                                    <button className="btn btn-primary shadow-none" onClick={
                                                        () => {
                                                            // Funcion para crear

                                                            if (name != "") {

                                                                // Almacenamos el contacto

                                                                if (ref != "") {

                                                                    updateContact(ref, {
                                                                        iid: window.localStorage.getItem("say-bussine"),
                                                                        names,
                                                                        surnames,
                                                                        phone,
                                                                        groups: [currentContactList._id]
                                                                    }).then((res) => {

                                                                        console.log("res");
                                                                        console.log(res);

                                                                        setShowNewForm(false);

                                                                        contactsByGroup(currentContactList._id).then((res) => {
                                                                            setContacts(res);
                                                                        });

                                                                        onClearContactForm();

                                                                    }).catch((err) => {
                                                                        console.log(err);
                                                                    })

                                                                } else {

                                                                    saveContact({
                                                                        iid: window.localStorage.getItem("say-bussine"),
                                                                        names,
                                                                        surnames,
                                                                        phone,
                                                                        groups: [currentContactList._id]
                                                                    }).then((res) => {

                                                                        console.log("res");
                                                                        console.log(res);

                                                                        setShowNewForm(false);

                                                                        contactsByGroup(currentContactList._id).then((res) => {
                                                                            setContacts(res);
                                                                        });

                                                                        onClearContactForm();

                                                                    }).catch((err) => {
                                                                        console.log(err);
                                                                    })

                                                                }



                                                            } else {
                                                                toast.error("El nombre del contacto no puede estar en blanco")
                                                            }

                                                            //icono guardar cambios
                                                        }}>

                                                        <SaveIcon style={{
                                                            width: "15px",
                                                            height: "20px",
                                                            fill: "#fff",
                                                            marginRight: "8px"
                                                        }} />

                                                        {
                                                            (currentContactList.ref != "") ? "Guardar cambios" : "Guardar"
                                                        }
                                                    </button >
                                                    <button className="btn btn-danger shadow-none" style={{
                                                        marginLeft: "5px"
                                                    }} onClick={
                                                        () => {
                                                            onClearContactForm();
                                                            setShowNewForm(false);
                                                        }}>
                                                        Cancelar
                                                    </button >
                                                </div >


                                            </>
                                        }

                                        <br />

                                        <br />

                                    </div >

                                </div >
                            }

                        </div>



                    </div >

                </div >

            </div >

        </EnvApp >
    );
}

export default Contacts;

