import React, { useState, useEffect, useContext } from 'react';

import { SketchPicker } from 'react-color'

function ColorPicker(props) {

    const [pi, setPi] = useState({
        displayPicker: false,
        color: props.color
    });

    useEffect(() => {
        console.log(props.color);
    }, [])

    const handleClick = () => {
        setPi({
            color: pi.color,
            displayPicker: !pi.displayPicker
        })
    };

    const handleClose = () => {
        setPi({
            color: pi.color,
            displayPicker: false
        })
    };

    const handleChange = (color) => {
        setPi({
            color: color.rgb,
            displayPicker: pi.displayPicker
        })
        props.onChange(color.rgb);
    };

    return (
        <>
            <div style={{
                padding: '5px',
                background: '#fff',
                borderRadius: '50%',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer'
            }} onClick={handleClick}>
                <div style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    background: `rgba(${pi.color.r}, ${pi.color.g}, ${pi.color.b}, ${pi.color.a})`
                }} />
            </div>

            {pi.displayPicker ? <div style={{
                position: 'absolute',
                zIndex: '2'
            }}>
                <div style={{
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px'
                }} onClick={handleClose} />
                <SketchPicker color={pi.color} onChange={handleChange} />
            </div> : null}
        </>
    );
}

export default ColorPicker;