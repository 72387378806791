import React, { useState, useEffect, useRef, useContext } from 'react';

import { useHistory, useParams } from "react-router-dom";

import axios from 'axios';

import { PHAT_DEV, PHAT_PROD, DEV, URL_SOCKET_DEV, URL_SOCKET_PROD } from '../../config';

import Control from './components/Control';

import Pos from './components/Pos';

import AgentsExplorer from './components/AgentsExplorer';

import SenderIdExplorer from './components/SenderIdExplorer';

import SocketProvider from '../../contexts/SocketProvider';

import Timeline from './components/Timeline';

import Conn from '../../assets/intro-connection.jpg';

import bg from '../../assets/bg.jpg';

import { ReactComponent as UserIcon } from '../../assets/user.svg';

import SimpleReactLightbox from 'simple-react-lightbox'

import './modal.css';

import { getClosedChatsByNumber, getStatistics, getChatsUnassigned, getMessages, takeChat, closedChat, getCount, getNumbersChats } from '../../services/Chat';

import { AppContext } from '../../contexts/AppProvider';

import Side from './components/Side';

import toast from 'react-hot-toast';

import { send } from '../../services/Channel';

function Chat() {

    const { chat, line, CurrentPage, chatSender, setCurrentPage, setChatSender, setTimeline, setChats, setStatistics } = useContext(AppContext);

    const history = useHistory();

    const { ref } = useParams();

    const [presence, setPresence] = useState('available');

    const [chatHistory, setChatHistory] = useState(false);

    const [isLoadingPrevMessages, setIsLoadingPrevMessages] = useState(null);

    const [showCart, setShowCart] = useState(true);

    const phonenumber = (number) => {

        //var phoneno = /^\(?([0-9]{2})?[-. ]?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        var phoneno = /^\(?([0-9]{12})$/;
        if (number.match(phoneno)) {
            return true;
            //true
        }
        else {
            return false;
            //false
        }
    }

    useEffect(() => {
        if (isLoadingPrevMessages != null) {

            if (isLoadingPrevMessages === false) {
                // Nos movemos el scroll a 0
                var a = document.getElementById("enva");
                if (a != null) {
                    a.scrollTop = 0;
                }
            }

        }
    }, [isLoadingPrevMessages])

    useEffect(() => {
        var a = document.getElementById("enva");
        if (a != null) {
            a.scrollTop = a.scrollHeight;
        }
    }, [line]);

    // const [bName, setBName] = useState((window.localStorage.getItem("say-bName") != '') ? window.localStorage.getItem("say-bName") : 'Sin nombre');

    useEffect(() => {

        setCurrentPage('assigned');

        /*if (Notification.permission === "default") {
            Notification.requestPermission().then(function (permission) {
                if (permission === "denied") {
                    toast.error("¡Para recibir notificaciones tienes que permitir las notificaciones en el navegador!");
                } else {
                    toast.success("¡Felicitaciones, recibirás todas las notificaciones!")
                }
            });
        } else if (Notification.permission === "denied") {
            toast.error("¡Para recibir notificaciones tienes que permitir las notificaciones en el navegador!");
        }

        var a = document.getElementById("enva");
        if (a != null) {
            a.scrollTop = a.scrollHeight;
        }

        // Cragamos los canales disponibles para esta cuenta de empresa
        getByBussine(window.localStorage.getItem("say-bussine")).then((res) => {
            console.log("resresresresresresresresresres");
            console.log(res)

        }).catch((error) => {
            console.log("error");
            console.log(error);
        })*/

        getCount(window.localStorage.getItem("say-bussine"), window.localStorage.getItem("say-ref")).then((res) => {
            console.log("getCountgetCountgetCountgetCountgetCountgetCountgetCount");
            console.log(res);
        }).catch((error) => {
            console.log("error");
            console.log(error);
        })

    }, [])

    const ChatsUnassigned = () => {
        setTimeline([]);
        // Obtenemos los chats
        getChatsUnassigned(window.localStorage.getItem("say-bussine")).then((chats) => {
            console.log("getChatsUnassigned");
            console.log("chats");
            console.log(chats);
            // Damos formato a la respuesta
            setChats(chats);
        }).catch((err) => {
            console.log(err);
        })
    }

    const send_new_message = (payload) => {
        return new Promise((resolve, reject) => {
            // ${(DEV) ? PHAT_DEV : PHAT_PROD}
            axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/send-message`, payload)
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response.data)
                    }
                }, (error) => {
                    reject(error.response.data)
                });
        })
    }

    useEffect(() => {

        // Cargamos las estadisticas
        getStatistics(window.localStorage.getItem("say-ref")).then((res) => {
            setStatistics(res);
        }).catch((err) => {
            console.log(err);
        })

        var a = document.getElementById("enva");
        if (a != null) {
            a.scrollTop = a.scrollHeight;
        }

    }, []);

    const onTake = (sender) => {

        takeChat(chat.jid, chat.ref, window.localStorage.getItem("say-ref"), sender).then((res) => {
            console.log(res);

            history.push('/chat/assigned');

        }).catch((err) => {
            console.log(err);
        })

    }

    return (
        <SimpleReactLightbox>
            <SocketProvider>
                <div style={{
                    width: "100%",
                    height: "100vh",
                    background: "#ff0000",
                    display: "grid",
                    gridTemplateColumns: (CurrentPage === "assigned" && chat.jid != "" && chat.ref != "" && chatHistory) ? "380px auto 400px" : "380px auto", //   && chat.jid != "" && chat.ref != ""
                    gridTemplateRows: "auto",
                    overflow: "auto"
                }}>

                    <Side />

                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "auto",
                        gridTemplateRows: "120px auto 55",
                        overflow: "auto"
                    }}>

                        <audio src="./whatsapp-apple.mp3" style={{
                            position: "absolute",
                            top: "-1000px",
                            left: "-1000px"
                        }} controls>
                            Your browser does not support the audio element.
                        </audio>

                        {
                            (chat.jid != "" && chat.ref != "") && <div style={{
                                width: "auto",
                                display: "grid",
                                gridTemplateColumns: "auto",
                                gridTemplateRows: "65px 55px auto 55px",
                                overflow: "auto",
                                backgroundImage: `url(${bg})`
                            }}>

                                <div style={{
                                    padding: "10px",
                                    background: "#fff"
                                }}>
                                    <div style={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center"
                                    }}>
                                        <div style={{
                                            width: "45px"
                                        }}>
                                            <UserIcon style={{
                                                width: "45px",
                                                height: "45px",
                                                borderRadius: "50%"
                                            }} />
                                        </div>
                                        <div style={{
                                            width: "100%",
                                            marginLeft: "10px"
                                        }}>
                                            <div style={{
                                                color: "#3283B0",
                                                fontWeight: "700"
                                            }}>{chat.name}</div>
                                            <div style={{
                                                color: "#939190"
                                            }}>
                                                {
                                                    (presence === "composing") && <div style={{ color: "#2fb55c" }}>Escribiendo...</div>
                                                }

                                                {
                                                    (presence === "available") && <div>Disponible</div>
                                                }

                                                {
                                                    (presence === "unavailable") && <div>No disponible</div>
                                                }
                                            </div>
                                        </div>
                                        <div style={{
                                            width: "27px",
                                            marginRight: "10px"
                                        }}>
                                        </div>
                                    </div>
                                </div>

                                <div style={{
                                    background: "#fff",
                                    padding: "0px 10px 0px 10px",
                                    display: "flex",
                                    alignItems: "center",
                                    borderBottom: "2px solid #eeeded"
                                }}>
                                    {
                                        (chat.jid !== "") && <>

                                            <div style={{
                                                width: "100%"
                                            }}>

                                                {
                                                    (CurrentPage === "assigned") && <button className="btn btn-sm shadow-none" style={{
                                                        background: "#f9fafa",
                                                        color: "#fc4a4b",
                                                        fontWeight: "700"
                                                    }} onClick={
                                                        () => {
                                                            closedChat(chat.ref).then((res) => {
                                                                console.log("res");
                                                                console.log(res);
                                                                toast.success(res);
                                                                history.push('/chat/assigned');
                                                            }).catch((err) => {
                                                                console.log("err");
                                                                console.log(err);
                                                                toast.success(err);
                                                                history.push('/chat/assigned');
                                                            })
                                                        }
                                                    }>Cerrar el chat</button>
                                                }

                                                {
                                                    (CurrentPage === "assigned") && <AgentsExplorer />
                                                }

                                                {
                                                    /*
                                                        {
                                                            (CurrentPage === "unassigned") && <button onClick={ //chat.channel === "WEB" && 
                                                                () => {
                                                                    // Del current chat obtenemos el sender para que actualize para este
                                                                    // CurrentChat.sender 
                                                                    //onTake(chat.sender)
                                                                }
                                                            }>Tomar conversación web</button>
                                                        }
    
                                                        {
                                                            (chat.channel != "WEB" && CurrentPage === "unassigned") && <SenderIdExplorer text="Tomar conversación" onChange={(data) => onTake(data)} />
                                                        }
    
                                                        <button className="btn btn-sm shadow-none" style={{
                                                    background: "#f9fafa",
                                                    color: "#fc4a4b",
                                                    fontWeight: "700",
                                                    marginLeft: "5px"
                                                }} onClick={
                                                    () => {
    
                                                        // Marcamos el chat como no visto
    
                                                        markUnread({
                                                            jid: chat.jid,
                                                            sender: chat.sender
                                                        }).then((res) => {
                                                            console.log(res);
            
                                                        }).catch((err) => {
                                                            console.log(err);
                                                        })
    
                                                    }
                                                }>Marcar como no visto</button>
                                                    */
                                                }



                                                {
                                                    (CurrentPage === "assigned") && <button className="btn btn-sm shadow-none" style={{
                                                        background: "#f9fafa",
                                                        color: "#fc4a4b",
                                                        fontWeight: "700"
                                                    }} onClick={
                                                        () => {

                                                            setChatHistory(true);

                                                            // Cargamos el historial de este numero de telefono
                                                            getClosedChatsByNumber(chat.jid).then((res) => {
                                                                console.log(res);
                                                            }).catch((error) => {
                                                                console.log("error");
                                                                console.log(error);
                                                            })
                                                        }
                                                    }>Historial</button>
                                                }


                                            </div>

                                            <div style={{
                                                width: "300px",
                                                textAlign: "right"
                                            }}>

                                                {
                                                    (CurrentPage === "assigned" && chat.typeChannel != "WEB") && <SenderIdExplorer text={(chat.channel != "") ? "Responder por: " + chat.channel : "Responder por: no asignado"} onChange={
                                                        (sender) => {
                                                            setChatSender(sender.ref);
                                                        }
                                                    } />
                                                }

                                            </div>

                                        </>
                                    }

                                </div>

                                <div id="enva" style={{
                                    overflow: "auto",
                                    padding: "20px 30px 20px 30px"
                                }}>

                                    <div >
                                        {
                                            (chat.jid !== "") && <>

                                                <br />

                                                <div style={{
                                                    marginTop: "20px"
                                                }}>

                                                    <Timeline />

                                                </div>

                                            </>
                                        }

                                        {
                                            (chat.channel === "WEB" && CurrentPage === "unassigned") && <div className="text-center mt-5">
                                                <button onClick={
                                                    () => {
                                                        // Del current chat obtenemos el sender para que actualize para este
                                                        // chat.sender
                                                        onTake(chat.sender)
                                                    }
                                                }>Tomar conversación web</button>
                                            </div>
                                        }

                                        {
                                            (chat.channel != "WEB" && CurrentPage === "unassigned" && chat.jid !== "") && <div className="text-center mt-5">
                                                <SenderIdExplorer text="Tomar conversación" onChange={(data) => onTake(data)} />
                                            </div>
                                        }

                                    </div>

                                </div>

                                {
                                    (CurrentPage != "UNASSIGNED" && CurrentPage != "unassigned" && CurrentPage != "closed" && CurrentPage != "CLOSED") && <Control id={ref} onSend={
                                        () => {
                                            getMessages(ref).then((res) => {

                                                console.log("chatchatchatchat----------------");
                                                console.log(res);

                                                // setTimeline(chat.Chat_.timeline);

                                            }).catch((err) => {
                                                console.log(err);
                                            })
                                        }
                                    } />
                                }

                            </div>
                        }

                        {
                            (chat.jid === "" && chat.ref === "") && <div style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                background: "#fff"
                            }}>

                                <div style={{
                                    maxWidth: "500px"
                                }}>
                                    <div style={{
                                        textAlign: "center"
                                    }}>
                                        <img src={Conn} style={{
                                            width: "250px",
                                            height: "250px"
                                        }} />
                                    </div>

                                    <div style={{
                                        textAlign: "center",
                                        fontSize: "2em"
                                    }}>
                                        Mantén tu teléfono conectado
                                    </div>
                                    <div style={{
                                        textAlign: "center"
                                    }}>
                                        WhatsApp se conecta a tu teléfono para sincronizar los mensajes. Para reducir el consumo de tus datos, conecta tu teléfono a una red Wi-Fi.
                                    </div>
                                </div>

                            </div>
                        }
                    </div>


                    {
                        (chatHistory) && <>
                            <div style={{
                                background: "#fff",
                                borderLeft: "2px solid #eeeded",
                                display: "grid",
                                gridTemplateColumns: "auto",
                                gridTemplateRows: "65px auto",
                                overflow: "auto"
                            }}>

                                <div style={{
                                    padding: "10px"
                                }}>
                                    <div style={{
                                        color: "#3883B0",
                                        fontWeight: "700"
                                    }}>Historico</div>
                                    <div style={{
                                        color: "#939190"
                                    }}>Consulta el historico de conversaciones.</div>
                                </div>

                                <div>
                                    AQUI LOS CHATS
                                </div>

                            </div>
                        </>
                    }

                    {
                        /*
                        (CurrentPage === "assigned" && chat.jid != "" && chat.ref != "" && showCart) && <>
                            <Pos onClick={
                                async (e) => {

                                    await send_new_message(e).catch((err) => {
                                        toast.error(err.message);
                                    })

                                    toast.success("El link del producto se envio")

                                }
                            } />
                        </>
                        */
                    }

                </div>
            </SocketProvider >
        </SimpleReactLightbox>
    );
}

export default Chat;