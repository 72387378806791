import React, { useState, useEffect, useContext } from 'react';

import { useHistory } from "react-router-dom";

import MaskInput from 'react-maskinput';

import toast from 'react-hot-toast';

import { save, getUsersByRol, getAsesors, remove } from '../../services/Agent';

import { saveTemplate, getTemplates } from '../../services/Template';

import { ReactComponent as ListmensaggeIcon } from '../../assets/icons/list.dash.header.rectangle.svg';

import { ReactComponent as User1Icon } from '../../assets/icons/person.circle.svg';

import { ReactComponent as SearchIcon } from '../../assets/icons/magnifyingglass.svg';

import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';

import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.fill.svg';

import { ReactComponent as ListaIcon } from '../../assets/icons/lista.svg';

import { ReactComponent as ResultsIcon } from '../../assets/icons/text.magnifyingglass.svg';

import { ReactComponent as EnviarIcon } from '../../assets/icons/paperplane.svg';

import { getSendersByBussine } from '../../services/Bussine';

import { AppContext } from '../../contexts/AppProvider';

import EnvApp from '../../components/EnvApp';

function Template() {

    const history = useHistory();

    const { senderIds, setSenderIds } = useContext(AppContext);

    const [ref, setRef] = useState('');

    const [name, setName] = useState('');

    const [message, setMessage] = useState('');


    const [search, setSearch] = useState('');

    const [currentTemplate, setCurrentTemplate] = useState({
        ref: null,
        name: "",
        message: "",
    });

    const [templates, setTemplates] = useState([]);

    useEffect(() => {

        setName(currentTemplate.name);

        setMessage(currentTemplate.message);
    }, [currentTemplate]);

    const onClear = () => {
        setRef("");
        setName("");
        setMessage("");

    }

    useEffect(() => {


        getTemplates(window.localStorage.getItem("say-bussine")).then((response) => {
            console.log("Andreafsgfgdfg")
            console.log(response)
            setTemplates(response)
        })
        /*getSendersByBussine(window.localStorage.getItem("say-bussine")).then((res) => {
            // setChannels(res);

            setAvailableChannels([])

            for (let i = 0; i < res.length; i++) {

                const channel = res[i];

                setAvailableChannels(oldChannels => [...oldChannels, {
                    name: channel.name,
                    active: false
                }]);

            }

        }).catch((err) => {
            console.log(err);
            //toast.error(err.data.message)
        })*/

    }, []);




    return (

        <EnvApp>

            <div style={{
                display: "grid",
                gridTemplateColumns: "auto",
                gridTemplateRows: "100px auto",
                background: "#fff",
                width: "100%",
                height: "100%",
                overflow: "auto",
                borderRadius: "10px 0px 0px 10px"
            }}>

                <div style={{
                    padding: "20px 50px 20px 50px"
                }}>

                    <div style={{
                        fontSize: "30px",
                        width: "100%",
                        height: "100%",
                        borderBottom: "1px solid #E8EBF0",
                        display: "flex"
                    }}>
                        <div style={{
                            width: "100%"
                        }}>
                            Plantilla de mensajes
                        </div>
                        <div style={{
                            width: "250px",
                            textAlign: "right"
                        }}>
                            <button style={{
                                borderRadius:"15px",
                                background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)"
                                                            
                            }}onClick={
                                () => {
                                    setCurrentTemplate({
                                        ref: "",
                                        name: "",
                                        message: "",

                                    })
                                }
                            } className="btn btn-primary">
                                <PlusIcon style={{
                                    // width: "20px",
                                    //height: "20px",
                                    fill: "#fff"
                                }} /> Lista de mensajes</button>
                        </div>
                    </div>



                </div>

                <div style={{
                    padding: "20px 50px 20px 50px"
                }}>

                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "280px auto",
                        gridTemplateRows: "auto",
                        height: "100%"
                    }}>
                        <div style={{
                            height: "100%"
                        }}>

                            <div style={{
                                display: "flex",
                                alignItems: "center"
                            }}>

                                <input type="text" value={search} style={{
                                    background: "#F2F7F8",
                                    border: "0px",
                                    height: "50px"
                                }} onChange={
                                    (e) => {
                                        setSearch(e.target.value)
                                    }
                                } className="form-control shadow-none" placeholder="Escribe para buscar" />

                                <button className="btn btn-primary shadow-none" style={{
                                    marginLeft: "5px",
                                    height: "50px",
                                    borderRadius: "15px",
                                    background: "rgb(8,55,155)",
                                    background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)"
                                }} onClick={
                                    () => {
                                        /*searchUsers(search, "agent").then((users) => {
                                            setAgents(users);
                                        }).catch((err_) => {
                                            console.log(err_);
                                        })*/
                                        toast.error("Esta funcionalidad aún no está disponible")
                                    }
                                }>
                                    <SearchIcon style={{
                                        //width: "20px",
                                        //height: "20px",
                                        fill: "#fff"
                                    }} />
                                </button>

                            </div>

                            <br />

                            {
                                (templates.length === 0) && <div style={{
                                    textAlign: "center"
                                }}>
                                    <div style={{
                                        fontSize: "18px",
                                        fontWeight: "700"
                                    }}><ResultsIcon style={{
                                        width: "40px",
                                        height: "40px",
                                        fill: "#fff"
                                    }} /></div>
                                    <div>Sin resultados</div>
                                </div>
                            }
                            {<button style={{
                                width: "130px",
                                borderRadius: "45px",
                                background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)"



                            }}
                                onClick={
                                    () => {
                                        setCurrentTemplate({
                                            ref: "",
                                            name: "",
                                            message: "",

                                        })
                                    }
                                } className="btn btn-primary">
                                Importar</button>

                            }

                            {<button style={{
                                marginLeft: "20px",
                                width: "130px",
                                borderRadius: "45px",
                                background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)"


                            }} onClick={
                                () => {
                                    setCurrentTemplate({
                                        ref: "",
                                        name: "",
                                        message: "",
                                    })
                                }
                            } className="btn btn-primary">
                                Exportar</button>

                            }

                            {
                                templates.map((Template, TemplateIndex) => {
                                    return (<div key={TemplateIndex} style={{
                                        marginTop: "10px",
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        background: (currentTemplate.ref === Template.ref) ? "#D9E2F1" : "transparent",
                                        padding: "10px",
                                        borderRadius: "10px"
                                    }} onClick={
                                        () => {
                                            setCurrentTemplate(Template)
                                        }
                                    }>
                                        <div>
                                            <ListmensaggeIcon style={{
                                                width: "55px",
                                                height: "55px",
                                                borderRadius: "50%",
                                                fill: (currentTemplate.ref === Template.ref) ? '#08379B' : '#000'
                                            }} />
                                        </div>
                                        <div style={{
                                            width: "100%",
                                            marginLeft: "5px"
                                        }}>
                                            <div style={{
                                                fontWeight: "700",
                                                color: "#08379B"
                                            }}>{Template.name} </div>
                                            <div>
                                                <div>
                                                    <span><span>7.1</span> / 10</span>
                                                </div>
                                                <div style={{
                                                    width: "53px",
                                                    height: "3px",
                                                    background: "#E8EBF0"
                                                }}>
                                                    <div style={{
                                                        width: "80%",
                                                        height: "100%",
                                                        background: "#AFE1DA"
                                                    }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                                })
                            }

                        </div>

                        {
                            (currentTemplate.ref === null) && <div style={{
                                padding: "0px 50px 0px 50px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                                textAlign: "center"
                            }}>
                                <div style={{
                                    maxWidth: "500px"
                                }}>
                                    <div>
                                        <ListaIcon style={{
                                            fill: "#fff"
                                        }} />
                                    </div>
                                    <div style={{
                                        fontSize: "25px",
                                        fontWeight: "500",
                                        marginTop: "-10px"
                                    }}>Mensajes</div>
                                    <div style={{
                                        marginTop: "10px"
                                    }}>Seleccionado el botón <b>+ Lista de mensajes</b>, podrás editar la información del mensaje, además de que brinda la oportunidad de importar y exportar documentos que contenga la lista de mensajes.</div>
                                </div>
                            </div>
                        }


                        {
                            (currentTemplate.ref != null) && <div style={{
                                overflow: "auto"
                            }}>
                                <div style={{
                                    padding: "0px 50px 0px 50px",
                                    display: "flex"
                                }}>


                                </div>

                                <div style={{
                                    padding: "0px 50px 0px 50px"
                                }}>

                                    <br />

                                    <div style={{
                                        minHeight: "100px"
                                    }}>

                                        <div style={{
                                            fontSize: "25px",
                                            fontWeight: "700"
                                        }}>Información</div>

                                        <div style={{
                                            marginTop: "10px"
                                        }}>

                                            <div className="row">
                                                <div>
                                                    <div style={{
                                                        fontWeight: "700",
                                                        color: "rgb(8, 55, 155)"
                                                    }}>Nombre</div>

                                                    <input type="text" value={name}  onChange={
                                                        (e) => {
                                                            setName(e.target.value)
                                                        }
                                                    } className="form-control shadow-none" class="shadow p-2  bg-body rounded"style={{
                                                        width: "100%",
                                                        padding: "10px",
                                                        fontSize: "20px",
                                                        background: "#F2F7F8",
                                                        border: "0px"
                                                    }} placeholder="Nombre" />
                                                </div>


                                                <div >
                                                    <div style={{
                                                        fontWeight: "700",
                                                        color: "rgb(8, 55, 155)",
                                                        marginTop: "10px"
                                                    }}>Mensaje</div>
                                                    <textarea type="text" value={message} onChange={
                                                        (e) => {
                                                            setMessage(e.target.value)
                                                        }
                                                    } style={{
                                                        background: "#F2F7f8",
                                                        border: "0px",
                                                        padding: "10px",
                                                        resize: "none",
                                                        minHeight: "200px",
                                                        fontSize: "20px",
                                                    }} placeholder="Mensaje" className="w-100 shadow p-2  bg-body rounded"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{
                                        minHeight: "100px"
                                    }}>

                                        <div>
                                            <button className="btn btn-primary shadow-none" style={{
                                                background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)",
                                                borderRadius:"15px"
                                            }} onClick={
                                                () => {
                                                    // Funcion para crear


                                                    saveTemplate({
                                                        "ref": currentTemplate.ref,
                                                        "iid": window.localStorage.getItem("say-bussine"),
                                                        "name": name,
                                                        "message": message
                                                    }).then((response) => {
                                                        console.log(response)
                                                        getTemplates(window.localStorage.getItem("say-bussine")).then((response) => {
                                                            console.log(response)
                                                            setTemplates(response)
                                                        })
                                                    })



                                                    //icono guardar cambios
                                                }}>

                                                <EnviarIcon style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    fill: "#fff",
                                                    marginRight: "6px",
                                                    marginLeft: "-5px"
                                                }} />



                                                {
                                                    (currentTemplate.ref != "") ? "Guardar cambios" : "Guardar"
                                                }
                                            </button >
                                        </div >

                                    </div >

                                    <br />
                                    <br />

                                    {
                                        (currentTemplate.ref != '') && <div style={{
                                            textAlign: "right"
                                        }}>
                                            <button className="btn btn-danger shadow-none" style={{
                                                padding: "6px", background: "rgb(237,11,11)",
                                                background: "linear-gradient(180deg, rgba(237,11,11,1) 0%, rgba(227,84,98,1) 100%)"

                                            }} onClick={
                                                () => {
                                                    /* remove(currentAgent.ref).then((res) => {
                                                         console.log(res);
 
                                                         getAsesors(window.localStorage.getItem("say-bussine")).then((res) => {
                                                             console.log(res);
                                                             setAgents(res);
                                                         }).catch((err) => {
                                                             console.log(err);
                                                         })
 
                                                         setCurrentAgent({
                                                             ref: null,
                                                             nickname: "",
                                                             name: "",
                                                             channels: [],
                                                             phone: "",
                                                             email: "",
                                                             whatsapp: "",
                                                             password: "",
                                                             confirmPassword: ""
                                                         })
 
                                                         onClear();
 
                                                         toast.success("El agente se elimino correctamente");
 
                                                     }).catch((error) => {
                                                         console.log(error);
                                                     })*/
                                                }
                                            }>
                                                <DeleteIcon style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    fill: "#fff"
                                                }} /> Eliminar mensaje</button>
                                        </div>
                                    }



                                    <br />

                                    <br />

                                </div >

                            </div >
                        }


                    </div >

                </div >

            </div >

        </EnvApp >
    );
}

export default Template;

