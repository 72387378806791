import axios from 'axios';

import { PHAT_DEV, PHAT_PROD, DEV, URL_SOCKET_DEV, URL_SOCKET_PROD } from '../config';

export const loadIntegrations = (iid, service) => {
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/get-integrations/${iid}/${service}`, {})
            .then((response) => {
                // response.status === 200 
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al recuperar las integraciones.");
                }
            }, (error) => {
                reject(error);
            });
    })
}

export const loadWoocommers = (iid, type) => {
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/integrations/${iid}/${type}`, {})
            .then((response) => {
                // response.status === 200 
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al recuperar las integraciones.");
                }
            }, (error) => {
                reject(error);
            });
    })
}

export const loadDialogFlow = (iid, type) => {
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/integrations/${iid}/${type}`, {})
            .then((response) => {
                // response.status === 200 
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al recuperar las integraciones.");
                }
            }, (error) => {
                reject(error);
            });
    })
}