import React, { useState, useEffect } from 'react';

import { useHistory } from "react-router-dom";

import toast, { Toaster } from 'react-hot-toast';

import { passwordUpdate } from '../../services/Agent';

import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';

import { ReactComponent as SaveIcon } from '../../assets/save.svg';


function SecurityForm() {

    const [ref, setRef] = useState(window.localStorage.getItem("say-ref"));

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    return (
        <div>

            <div className="row">

                <div className="col-6 mt-3" style={{
                    fontWeight: "700",
                    color: "#243340"
                }}>CONTRASEÑA ACTUAL</div>
                <div className="col-6 mt-3">
                    <input type="password" value={currentPassword} onChange={
                        (e) => {
                            setCurrentPassword(e.target.value)
                        }
                    } placeholder="Introduce tu contraseña actual" className="form-control shadow-none" />
                </div>

                <div className="col-6 mt-3" style={{
                    fontWeight: "700",
                    color: "#243340"
                }}>CONTRASEÑA NUEVA</div>
                <div className="col-6 mt-3">
                    <input type="password" value={newPassword} onChange={
                        (e) => {
                            setNewPassword(e.target.value)
                        }
                    } placeholder="Introduce una nueva contraseña" className="form-control shadow-none" />
                </div>

                <div className="col-6 mt-3" style={{
                    fontWeight: "700",
                    color: "#243340"
                }}>CONFIRMAR CONTRASEÑA</div>
                <div className="col-6 mt-3">
                    <input type="password" value={confirmPassword} onChange={
                        (e) => {
                            setConfirmPassword(e.target.value);
                        }
                    } placeholder="Confirma tu nueva contraseña" className="form-control shadow-none" />
                </div>

            </div>

            <br />

            <br />

            <div style={{
                textAlign: "right"
            }}>

                <button className="btn btn-primary shadow-none" style={{
                    padding: "10px",
                    marginRight: "5px"
                }} onClick={
                    () => {

                        if (currentPassword != "" && newPassword != "" && confirmPassword != "") {

                            if (newPassword === confirmPassword) {
                                passwordUpdate({
                                    ref: ref,
                                    currentPassword,
                                    newPassword
                                }).then((res) => {
                                    console.log(res);

                                    toast.success("Contraseña actualizada.")

                                }).catch((err) => {
                                    toast.error(err)
                                })
                            } else {
                                toast.error("Las contraseñas no coinciden.")
                            }

                        } else {
                            toast.error("Complete el formulario para continuar.")
                        }

                    }
                }>
                    <SaveIcon style={{
                        width: "20px",
                        height: "20px",
                        fill: "#fff"
                    }} /> Guardar</button>

            </div>

        </div>
    );
}

export default SecurityForm;

