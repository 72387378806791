import axios from 'axios';

import { PHAT_DEV, PHAT_PROD, DEV, URL_SOCKET_DEV, URL_SOCKET_PROD } from '../config';


export const deleteChat = (ref) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/delete-chat/${ref}`, {})
            .then((response) => {
                resolve(response.data);
            }, (err) => {
                reject(err);
            });
    })
}
export const loadPrevMessagess = (payload) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/load-messages`, payload)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al intentar recuperar los mensajes anteriores.");
                }
            }, (err) => {
                reject(err);
            });
    })
}

export const searchProducts = (endpoint, track) => {
    return new Promise((resolve, reject) => {
        axios.get(`${endpoint}/wp-json/wc-filter/v1/filter/?product=${track}`, {})
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al buscar el producto, intente nuevamente.");
                }
            }, (error) => {
                console.log(error);
            });
    })
}

export const closedChat = (ref) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/chat/closed/` + ref, {
            params: {
                channels: window.localStorage.getItem("say-channels")
            }
        })
            .then((response) => {
                // response.status === 200 
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al iniciar sesión.");
                }
            }, (error) => {
                reject(error.response.data);
            });
    })
}

export const markUnread = (payload) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/mark-unread`, payload)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al intentar marcar como no visto el chat, intente nuevamente.");
                }
            }, (err) => {
                reject(err)
            });
    })
}

export const markRead = (payload) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/mark-read`, payload)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al intentar marcar como no visto el chat, intente nuevamente.");
                }
            }, (err) => {
                reject(err)
            });
    })
}

export const markMessages = (jid) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/markMessages`, {
            jid: jid
        })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data)
                } else {
                    reject("Ocurrio un problema al intentar recuperar los clientes, intente nuevamente.");
                }
            }, (err) => {
                reject(err)
            });
    })
}

export const takeChat = (jid, ref, agent, sender = "") => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/takeChat`, {
            jid,
            ref,
            agent,
            sender
        })
            .then((response) => {
                // response.status === 200 
                if (response.status === 200) {
                    resolve(response.data)
                } else {
                    reject("Ocurrio un problema al intentar recuperar los clientes, intente nuevamente.");
                }
            }, (error) => {
                reject(error);
            });
    })
}


export const newMessage = (name, jid, message) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/newMessage`, {
            name: name,
            jid: jid,
            message: message
        })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data)
                } else {
                    console.log("Ocurrio un problema al intentar enviar el mensaje, intente nuevamente.");
                }
            }, (error) => {
                console.log(error);
            });

    })
}


export const newAudio = (name, jid, url) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/newAudio`, {
            name,
            jid,
            url
        })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data)
                } else {
                    console.log("Ocurrio un problema al intentar enviar el audio, intente nuevamente.");
                }
            }, (error) => {
                console.log(error);
            });

    })
}


export const getChatsUnassigned = (iid) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/getChatsUnassigned/${iid}`, {
            params: {
                channels: window.localStorage.getItem("say-channels")
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data.list)
                } else {
                    reject("Ocurrio un problema al intentar recuperar los chats, intente nuevamente.")
                }
            }, (error) => {
                console.log(error);
            });
    })
}

export const getClosedChatsByNumber = (jid) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/get-close-chats/${window.localStorage.getItem("say-ref")}`, {
            params: {
                jid
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data)
                } else {
                    reject("Ocurrio un problema al intentar recuperar los chats, intente nuevamente.")
                }
            }, (error) => {
                console.log(error);
            });
    })
}

export const getChatsAssigned = (ref, channels) => {
    //convert input to output 

    console.log(channels);

    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/getChatsAssigned/` + ref, {
            params: {
                channels: window.localStorage.getItem("say-channels")
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA");
                    console.log(response.data);
                    resolve(response.data.list)
                } else {
                    reject("Ocurrio un problema al intentar recuperar los chats, intente nuevamente.")
                }
            }, (error) => {
                console.log(error);
            });
    })
}

export const getClosedChats = (ref, channels) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/getClosedChats/` + ref, {
            params: {
                channels: window.localStorage.getItem("say-channels")
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data.list)
                } else {
                    reject("Ocurrio un problema al intentar recuperar los chats, intente nuevamente.")
                }
            }, (error) => {
                console.log(error);
            });
    })
}

export const getClosedChatsBussine = (ref, channels) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/getClosedChatsBussine/` + ref, {
            params: {
                channels: window.localStorage.getItem("say-channels")
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data.list)
                } else {
                    reject("Ocurrio un problema al intentar recuperar los chats, intente nuevamente.")
                }
            }, (error) => {
                console.log(error);
            });
    })
}

export const getAllChat = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/getAllChat/`, {})
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data.list)
                } else {
                    reject("Ocurrio un problema al intentar recuperar los chats, intente nuevamente.")
                }
            }, (error) => {
                console.log(error);
            });
    })
}

export const getCount = (iid, ref) => {
    return new Promise((resolve, reject) => {
        if (ref != null) {
            axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/count`, {
                params: {
                    iid,
                    agent: ref,
                    channels: window.localStorage.getItem("say-channels")
                }
            })
                .then((response) => {
                    resolve(response.data)
                }, (error) => {
                    console.log(error.response.data)
                });
        }
    })
}

export const getMessages = (ref) => {
    return new Promise((resolve, reject) => {
        if (ref != null) {
            axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/getMessages/${ref}`, {})
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response);
                        resolve(response.data)
                    } else {
                        reject("Ocurrio un problema al intentar recuperar los mensajes, intente nuevamente.")
                    }
                }, (error) => {
                    console.log(error);
                });
        }
    })
}

export const getNumbersChats = (iid, ref) => {
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/getNumbersChats/` + ref, {
            params: {
                iid,
                agent: ref,
                channels: window.localStorage.getItem("say-channels")
            }
        })
            .then((response) => {
                resolve(response.data);
            }, (error) => {
                console.log(error);
            });
    })
}

export const getStatistics = (ref) => {
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/getStatistics/` + ref, {})
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data)
                } else {
                    reject("Ocurrio un problema al intentar recuperar los clientes, intente nuevamente.")
                }
            }, (error) => {
                console.log(error);
            });
    })
}

// Funcion que establece el senderid para un chat
export const setChannel = (ref, channel) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/set-channel/`, {
            channel,
            ref
        })
            .then((response) => {
                console.log("response");
                console.log(response);
                if (response.status === 200) {
                    resolve(response.data)
                } else {
                    console.log("Ocurrio un problema al intentar enviar el mensaje, intente nuevamente.");
                }
            }, (error) => {
                console.log(error);
            });

    })
}

// Funcion que establece el senderid para un chat
export const setAgent = (payload) => {
    //convert input to output 
    return new Promise((resolve, reject) => {

        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/chat/setAgent/`, payload)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data)
                } else {
                    console.log("Ocurrio un problema al intentar asignar el agente, intente nuevamente.");
                }
            }, (error) => {
                console.log(error);
            });

    })
}

// Funcion que establece el senderid para un chat
export const sendAudio = (payload) => {
    //convert input to output 
    return new Promise((resolve, reject) => {

        const formData = new FormData();

        formData.append('myBlob', payload, "audio");

        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/upload/`, formData)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data)
                } else {
                    console.log("Ocurrio un problema al intentar asignar el agente, intente nuevamente.");
                }
            }, (error) => {
                console.log(error);
            });

    })
}


export const sendFile = (file) => {
    //convert input to output 
    return new Promise((resolve, reject) => {

        const formData = new FormData();

        formData.append('file', file, file.name);

        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/upload-file`, formData, {
            'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
        })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data)
                } else {
                    console.log("Ocurrio un problema al intentar asignar el agente, intente nuevamente.");
                }
            }, (error) => {
                console.log(error);
            });

    })
}


export const uploadFile = (file) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', file, file.name);
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/upload-file`, formData, {
            'Content-Type': `multipart/form-data;`
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    resolve(response.data)
                } else {
                    console.log("Ocurrio un problema al intentar subir el archivo.");
                }
            }, (error) => {
                console.log(error.message);
            });
    })
}


export const uploadKey = (file) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', file, file.name);
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/upload-key`, formData, {
            'Content-Type': `multipart/form-data;`
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    resolve(response.data)
                } else {
                    console.log("Ocurrio un problema al intentar subir el archivo.");
                }
            }, (error) => {
                console.log(error.message);
            });
    })
}

// Funcion que establece el senderid para un chat
export const addIntegration = (payload) => {
    return new Promise((resolve, reject) => {
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/add-integration`, payload)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data)
                } else {
                    console.log("Ocurrio un problema al intentar guardar integracion, intente nuevamente.");
                }
            }, (error) => {
                console.log(error);
            });

    })
}


export const getIntegrations = (iid) => {
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/integrations/` + iid, {})
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data)
                } else {
                    reject("Ocurrio un problema al intentar recuperar las integraciones, intente nuevamente.")
                }
            }, (error) => {
                console.log(error);
            });
    })
}


export const getChat = (ref) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/chat/${ref}`, {})
            .then((response) => {
                resolve(response.data);
            }, (error) => {
                console.log(error);
            });
    })
}