import { createContext, useState, useEffect, useContext } from "react";

import { io } from "socket.io-client";

import toast from 'react-hot-toast';

import moment from 'moment';

import { PHAT_SOCKET_DEV, PHAT_SOCKET_PROD, DEV, URL_SOCKET_DEV, URL_SOCKET_PROD } from '../config';

export const WidgetContext = createContext();

var socket = null;

const WidgetProvider = ({ children }) => {

    // const { senderIds, setSenderIds, listQrs, CurrentPage, assignedChats, setStatistics, Phones, setPhones, Chats, setChats, setCurrentTerminal, CurrentChat, setTimeline, NewMessage, setNewMessage } = useContext(AppContext);

    const [Timeline, setTimeline] = useState([]);

    const [page, setPage] = useState(''); // login, lobby, inbox, conversations

    const [names, setNames] = useState('');

    const [surnames, setSurnames] = useState('');

    const [email, setEmail] = useState('');

    const [phone, setPhone] = useState('');

    const [NewMessage, setNewMessage] = useState(null);

    const [qualifier, setQualifier] = useState({
        n: 1,
        d: ''
    });

    const [agent, setAgent] = useState({
        ref: '',
        name: ''
    });

    const [theme, setTheme] = useState({
        colors: {
            primary: {
                r: 0,
                g: 0,
                b: 0,
                a: 1
            },
            secondary: {
                r: 0,
                g: 0,
                b: 0,
                a: 1
            }
        }
    });

    const [status, setStatus] = useState(-1);

    useEffect(() => {
        if (page != '') {
            window.localStorage.setItem("widget-page", page);
        }
    }, [page])

    useEffect(() => {
        // Se agrego un nuevo mensaje
        if (NewMessage != null) {
            // widget-ref
            console.log("NewMessage");
            console.log(NewMessage);
            // Aqui preguntar por jid del chat si no es el jid no se representa 
            if (window.localStorage.getItem("widget-email") === NewMessage.jid && window.localStorage.getItem("widget-ref") === NewMessage.ref) {
                delete NewMessage.action;
                setTimeline(Timeline => [...Timeline, NewMessage]);
                setNewMessage(null);
            }
        }
    }, [NewMessage])

    const initSocket = () => {

        // Inicializamos el servicio socket.io
        socket = io(`${(DEV) ? URL_SOCKET_DEV : URL_SOCKET_PROD}`, {
            path: (DEV) ? PHAT_SOCKET_DEV : PHAT_SOCKET_PROD,
            query: {},
            secure: true,
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: 99999
        });

        socket.on('close', (data) => {
            console.log("close");
        })

        socket.on('newStatus', (data) => {
            if (data.ref === window.localStorage.getItem("widget-ref")) {
                setStatus(data.status)
            }
        })

        socket.on('newNotification', (data) => {
            if (data.type === 'success') {
                toast.success(data.message)
            }
            if (data.type === 'error') {
                toast.error(data.message)
            }
        })

        socket.on('newWidgetMessage', (data) => setNewMessage(data));

        socket.on('newMessage', (data) => setNewMessage(data));

        socket.on('connected', (data) => {
            console.log("connected");
        })

        socket.on('notification', ({ error, message }) => {
            if (error) {
                toast.error(message);
            } else {
                toast.success(message);
            }
        })

        return true;

    }
    const emit = (eve, data) => {
        socket.emit(eve, data)
    }

    return (
        < WidgetContext.Provider value={
            {
                emit,
                Timeline,
                setTimeline,
                qualifier, setQualifier,
                status, setStatus,
                initSocket,
                theme, setTheme,
                page, setPage,
                names, setNames, surnames, setSurnames, email, setEmail, phone, setPhone,
                agent, setAgent
            }
        }>
            {children}
        </ WidgetContext.Provider>
    );

}

export default WidgetProvider;