import React, { useState, useEffect, useContext } from 'react';

import axios from 'axios';

import { DEV, PHAT_DEV, PHAT_PROD } from '../../config';

import { WidgetContext } from '../../contexts/WidgetProvider';

import toast from 'react-hot-toast';

import { useParams } from "react-router-dom";

import Lobby from './Lobby';

import Inbox from './Inbox';

import Login from './Login';

import WidgetProvider from '../../contexts/WidgetProvider';

import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';

function Env() {

    const { setTheme, page, setPage, setNames, setSurnames, setEmail, setPhone, setQualifier } = useContext(WidgetContext);

    let { iid, sender } = useParams();

    const [isError, setIsError] = useState(false);

    const [error, setError] = useState('');

    const getTheme = (iid, sender) => {
        return new Promise((resolve, reject) => {
            axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/get-web-theme`, {
                params: {
                    iid,
                    sender
                }
            })
                .then((response) => {
                    resolve(response.data);
                }, (error) => {
                    reject(error.response.data.message);
                });
        })
    }

    useEffect(() => {

        if (window.localStorage.getItem("widget-names") != null && window.localStorage.getItem("widget-surnames") != null && window.localStorage.getItem("widget-email") != null && window.localStorage.getItem("widget-phone") != null) {

            setNames(window.localStorage.getItem("widget-names"))

            setSurnames(window.localStorage.getItem("widget-surnames"))

            setEmail(window.localStorage.getItem("widget-email"))

            setPhone(window.localStorage.getItem("widget-phone"))

            if (window.localStorage.getItem("widget-page") != null && window.localStorage.getItem("widget-page") != '') {
                setPage(window.localStorage.getItem("widget-page"));
            } else {
                setPage('lobby');
            }
        } else {
            setPage('login');
        }

        getTheme(iid, sender).then((res) => {
            setTheme({
                colors: {
                    primary: {
                        r: res.primaryColor.r,
                        g: res.primaryColor.g,
                        b: res.primaryColor.b,
                        a: 1
                    },
                    secondary: {
                        r: res.secondaryColor.r,
                        g: res.secondaryColor.g,
                        b: res.secondaryColor.b,
                        a: 1
                    }
                }
            })
        }).catch((error) => {
            toast.error(error);
            setIsError(true);
            setError(error);
        })
    }, [])

    return (
        <>
            {
                (isError) && <>
                    <div style={{
                        padding: "20px",
                        textAlign: "center"
                    }}>

                        <div style={{
                            fontWeight: "700",
                            fontSize: "30px"
                        }}> <ErrorIcon style={{
                            width: "400px",
                            height: "400px",
                            fill: "#fff"
                        }} /></div>

                        <div>
                            {error}
                        </div>

                    </div>
                </>

            }

            {
                (!isError) && <>
                    {
                        (page === 'login') && <Login />
                    }

                    {
                        (page === 'lobby') && <Lobby onSetPage={
                            (page) => {
                                setPage(page);
                            }
                        } />
                    }

                    {
                        (page === 'inbox') && <Inbox />
                    }

                </>
            }
        </>

    );
}

export default Env;