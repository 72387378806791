import React, { useState, useEffect, useRef } from 'react';

import { useHistory } from "react-router-dom";

import { SketchPicker } from 'react-color'

function ColorPicker(props) {

    const [pi, setPi] = useState({
        displayPicker: false,
        color: props.color
    });

    const [modalLeft, setModalLeft] = useState("-1000px");

    const [modalTop, setModalTop] = useState("-1000px");

    const rebutton = useRef(null);

    const remodal = useRef(null);

    useEffect(() => {
        if (pi != null) {
            if (pi.displayPicker) {
                setModalLeft(rebutton.current.offsetLeft + 60 + "px")
                setModalTop(rebutton.current.offsetTop + "px")
            }
        }
    }, [pi])

    const handleClick = () => {
        setPi({
            color: pi.color,
            displayPicker: !pi.displayPicker
        })
    };

    const handleClose = () => {
        setPi({
            color: pi.color,
            displayPicker: false
        })
    };

    const handleChange = (color) => {
        setPi({
            color: color.rgb,
            displayPicker: pi.displayPicker
        })
        props.onChange(color.rgb);
    };

    return (
        <>

            <div ref={rebutton} style={{
                width: "50px",
                height: "50px",
                background: `rgba(${pi.color.r}, ${pi.color.g}, ${pi.color.b}, ${pi.color.a})`,
                borderRadius: "50%",
                marginTop: "10px",
                alignSelf: "center",
                justifySelf: "center",
                border: "2px solid #fff",
                cursor: "pointer"
            }} onClick={handleClick}></div>

            {pi.displayPicker ? <div ref={remodal} style={{
                position: 'absolute',
                zIndex: '2',
                left: modalLeft,
                top: modalTop
            }}>
                <div style={{
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px'
                }} onClick={handleClose} />
                <SketchPicker color={pi.color} onChange={handleChange} />
            </div> : null}

        </>

    );
}

export default ColorPicker;

