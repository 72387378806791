import axios from 'axios';

import { PHAT_DEV, PHAT_PROD, DEV, URL_SOCKET_DEV, URL_SOCKET_PROD } from '../config';

export const getByBussine = (bussine) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/channel/${bussine}`, {})
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data.list);
                } else {
                    reject("Ocurrio un problema al intentar recuperar el SenderId para " + bussine + ", intente nuevamente.");
                }
            }, (error) => {
                reject(error.response);
            });
    })
}



export const getHistoryChat = (channel, number) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/history-chat`, {
            params: {
                channel,
                number
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema");
                }
            }, (error) => {
                reject(error.response);
            });
    })
}


export const removeHistoryChat = (ref) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.delete(`${(DEV) ? PHAT_DEV : PHAT_PROD}/history/${ref}`, {})
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrió un problema al intentar eliminar el chat, intente nuevamente.");
                }
            }, (error) => {
                reject(error.response.data);
            });
    })
}

// ESTA!
export const save = (payload) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/channel`, payload)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al intentar de guardar el SenderId, intente nuevamente.");
                }
            }, (error) => {
                reject(error.response);
            });
    })
}

export const remove = (ref) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.delete(`${(DEV) ? PHAT_DEV : PHAT_PROD}/channel/${ref}`, {})
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al intentar eliminar el senderId, intente nuevamente.");
                }
            }, (error) => {
                reject(error.response.data);
            });
    })
}

export const send = (payload) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/send-test`, payload)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al intentar de guardar el SenderId, intente nuevamente.");
                }
            }, (error) => {
                reject(error.response);
            });
    })
}