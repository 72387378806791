import React, { useState, useEffect } from 'react';

import { useHistory } from "react-router-dom";

import EnvApp from '../../components/EnvApp';

function Shifts() {

    const history = useHistory();

    const [operation, setOperation] = useState(null)

    useEffect(() => {

    }, []);

    return (

        <EnvApp>

            <div style={{
                display: "grid",
                gridTemplateColumns: "auto",
                gridTemplateRows: "100px auto",
                background: "#fff",
                width: "100%",
                height: "100%",
                overflow: "auto",
                borderRadius: "10px 0px 0px 10px"
            }}>

                <div style={{
                    padding: "20px 50px 20px 50px"
                }}>

                    <div style={{
                        fontSize: "30px",
                        width: "100%",
                        height: "100%",
                        borderBottom: "1px solid #E8EBF0",
                        display: "flex"
                    }}>
                        <div style={{
                            width: "100%"
                        }}>
                            Modulo de turnos
                        </div>

                    </div>

                </div>

                <div style={{
                    padding: "20px 50px 20px 50px"
                }}>

                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "400px auto",
                        gridTemplateRows: "auto",
                        height: "100%"
                    }}>
                        <div style={{
                            height: "100%",
                            padding: "20px"
                        }}>

                            <div onClick={
                                () => {
                                    setOperation('general')
                                }
                            } style={{
                                padding: "10px",
                                margionTop: "10px",
                                cursor: "pointer",
                                height: "50px",
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "10px",
                                color: (operation === 'general') ? '#08379b' : '#000',
                                background: (operation === 'general') ? '#d9e2f1' : '#fff'
                            }}>General</div>

                            <div onClick={
                                () => {
                                    setOperation('services')
                                }
                            } style={{
                                padding: "10px",
                                margionTop: "10px",
                                cursor: "pointer",
                                height: "50px",
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "10px",
                                color: (operation === 'services') ? '#08379b' : '#000',
                                background: (operation === 'services') ? '#d9e2f1' : '#fff'
                            }}>Servicios</div>

                            <div onClick={
                                () => {
                                    setOperation('formalities')
                                }
                            } style={{
                                padding: "10px",
                                margionTop: "10px",
                                cursor: "pointer",
                                height: "50px",
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "10px",
                                color: (operation === 'formalities') ? '#08379b' : '#000',
                                background: (operation === 'formalities') ? '#d9e2f1' : '#fff'
                            }}>Tramites</div>

                        </div>

                        {
                            (operation != null) && <>
                                <div style={{
                                    overflow: "auto",
                                    display: "grid",
                                    gridTemplateColumns: "300px auto",
                                    gridTemplateRows: "auto"
                                }}>
                                    <div>
                                        {
                                            (operation != null) && <>
                                                {
                                                    (operation === 'services') && <>
                                                        SERVICIOS
                                                    </>
                                                }
                                                {
                                                    (operation === 'formalities') && <>
                                                        TRAMITES
                                                    </>
                                                }
                                            </>
                                        }
                                        {
                                            (operation === null) && <>
                                                Seleccione una operación para continuar
                                            </>
                                        }
                                    </div>
                                    <div style={{
                                        overflow: "auto"
                                    }}>
                                        FORMULARIO
                                    </div>
                                </div>
                            </>
                        }

                        {
                            (operation === null) && <>
                                Seleccione una operación para continuar
                            </>
                        }



                    </div>

                </div>

            </div>

        </EnvApp >
    );
}

export default Shifts;

