import React, { useState, useEffect, useContext } from 'react';

import useDropdown from 'react-dropdown-hook';

import webNotification from "simple-web-notification";

import { useHistory, NavLink, useLocation } from "react-router-dom";

import { ReactComponent as UserIcon } from '../assets/user.svg';

import { ReactComponent as ChannelsIcon } from '../assets/icons/server.rack.svg';

import { ReactComponent as PersonIcon } from '../assets/icons/person.fill.svg';

import { ReactComponent as IntegrationsIcon } from '../assets/icons/powerplug.svg';

import { ReactComponent as MetricsIcon } from '../assets/icons/chart.line.uptrend.xyaxis.svg';

import { ReactComponent as SettingsIcon } from '../assets/icons/gearshape.svg';

import { ReactComponent as CustomersIcon } from '../assets/icons/person.3.svg';

import { ReactComponent as PendingIcon } from '../assets/icons/list.bullet.rectangle.portrait.svg';

import { ReactComponent as CloseIcon } from '../assets/icons/power.circle.svg';

import { ReactComponent as ContactsIcon } from '../assets/icons/person.text.rectangle.svg';

import { ReactComponent as CampaignIcon } from '../assets/icons/megaphone.svg';

import { ReactComponent as AcademyIcon } from '../assets/icons/graduationcap.svg';

import { ReactComponent as ListIcon } from '../assets/icons/list.bullet.rectangle.portrait (3).svg';

import { ReactComponent as StoriesIcon } from '../assets/icons/clock.arrow.2.circlepath.svg';

import { ReactComponent as DevsIcon } from '../assets/icons/devs.svg';

import { AppContext } from '../contexts/AppProvider';

import Imglogo from '../assets/Logop.png';

const EnvApp = (props) => {

    const [wrapperRef, dropdownOpen, toggleDropdown, closeDropdown] = useDropdown();

    const { Photo, setPhoto, Name, setName, Email, setEmail, Bussine } = useContext(AppContext);

    const history = useHistory();

    const { pathname } = useLocation();

    const [role, setRole] = useState(null);

    useEffect(() => {
        console.log(pathname);

        setRole(window.localStorage.getItem('rol'));

    }, [])

    // 60
    return (
        <>
            <div style={{
                display: "grid",
                gridTemplateColumns: "77px auto",
                gridTemplateRows: "auto",
                width: "100%",
                height: "100vh",
                overflow:"auto"

            }}>

                <div style={{
                    width: "100%",
                    height: "100vh",
                    background: "rgb(8,55,155)",
                    background: "linear-gradient(180deg, rgba(8,55,155,1) 53%, rgba(13,110,253,1) 100%)",
                    display: "grid",
                    gridTemplateRows: "65px auto 55px",
                    gridTemplateColumns: "auto",
                    overflow: "auto"
                }}>

                    <div style={{
                        padding: "10px"
                    }}>
                        <img src={Imglogo} style={{
                            height: "50px"
                        }} onClick={
                            () => {
                                webNotification.showNotification('Example Notification', {
                                    body: 'Notification Text...',
                                    icon: 'my-icon.ico',
                                    onClick: function onNotificationClicked() {
                                        alert('Notification clicked.');
                                    },
                                    autoClose: 4000 //auto close the notification after 4 seconds (you can manually close it via hide function)
                                }, function onShow(error, hide) {
                                    if (error) {
                                        window.alert('Unable to show notification: ' + error.message);
                                    } else {
                                        console.log('Notification Shown.');

                                        setTimeout(function hideNotification() {
                                            console.log('Hiding notification....');
                                            hide(); //manually close the notification (you can skip this if you use the autoClose option)
                                        }, 5000);
                                    }
                                });
                            }
                        } />
                    </div>

                    <div>


                        {
                            (role === 'agent') && <>
                                <NavLink
                                    to="/chat/assigned" isActive={() => ['/chat/assigned', '/chat/unassigned', '/chat/closed'].includes(pathname)} activeClassName="active" style={{
                                        paddingTop: "10px",
                                        paddingBottom: "10px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "10px",
                                        cursor: "pointer",
                                        marginTop: "20px",
                                        textDecoration: "none",
                                        color: "#50545f"
                                    }}
                                >
                                    <div>
                                        <i className="las la-comments icon" style={{
                                            fontSize: "30px"
                                        }}></i>
                                    </div>
                                </NavLink>
                            </>
                        }

                        {
                            (role === 'agent') && <>
                                <NavLink
                                    to="/shifts" isActive={() => ['/shifts'].includes(pathname)} activeClassName="active" style={{
                                        paddingTop: "10px",
                                        paddingBottom: "10px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "10px",
                                        cursor: "pointer",
                                        marginTop: "20px",
                                        textDecoration: "none",
                                        color: "#50545f"
                                    }}
                                >
                                    <div style={{
                                        position: "relative"
                                    }}>
                                        <div style={{
                                            width: "40px",
                                            color: "rgb(255, 255, 255)",
                                            textAlign: "center",
                                            background: "rgb(102, 102, 255)",
                                            borderRadius: "5px",
                                            fontSize: "12px",
                                            position: "absolute",
                                            top: "-10px",
                                            left: "10px"
                                        }}>
                                            SOON
                                        </div>
                                        <i className="las la-headset icon" style={{
                                            fontSize: "30px"
                                        }}></i>
                                    </div>
                                </NavLink>
                            </>
                        }

                        {
                            (role != 'agent' && role != 'master') && <>
                                <NavLink
                                    to="/senderids"
                                    activeClassName="active" style={{
                                        paddingTop: "10px",
                                        paddingBottom: "5px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "10px",
                                        cursor: "pointer",
                                        marginTop: "20px",
                                        textDecoration: "none",
                                        color: "#50545f"

                                    }}
                                >
                                    <div>
                                        <ChannelsIcon style={{
                                            width: "50px",
                                            height: "35px",
                                            fill: (['/senderids'].includes(pathname)) ? '#1e2347' : '#fff',
                                            marginRight: "-9"
                                        }} />
                                    </div>
                                </NavLink>
                            </>
                        }

                        {
                            (role != 'agent' && role != 'master') && <>
                                <NavLink
                                    to="/agents"
                                    activeClassName="active" style={{
                                        paddingTop: "10px",
                                        paddingBottom: "5px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "10px",
                                        cursor: "pointer",
                                        marginTop: "10px",
                                        textDecoration: "none",
                                        color: "#50545f"
                                    }}
                                >
                                    <div>
                                        <PersonIcon style={{
                                            width: "50px",
                                            height: "35px",
                                            fill: (['/agents'].includes(pathname)) ? '#1e2347' : '#fff',
                                        }} />
                                    </div>
                                </NavLink>
                            </>
                        }


                        {
                            (role != 'agent' && role != 'master') && <>
                                <NavLink
                                    to="/integrations"
                                    activeClassName="active" style={{
                                        paddingTop: "10px",
                                        paddingBottom: "5px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "10px",
                                        cursor: "pointer",
                                        marginTop: "10px",
                                        textDecoration: "none",
                                        color: "#50545f"
                                    }}
                                >
                                    <div>
                                        <IntegrationsIcon style={{
                                            width: "50px",
                                            height: "35px",
                                            fill: (['/integrations'].includes(pathname)) ? '#1e2347' : '#fff',

                                        }} />

                                    </div>
                                </NavLink>
                            </>
                        }


                        {
                            (role != 'agent' && role != 'master') && <>
                                <NavLink
                                    to="/metrics"
                                    activeClassName="active" style={{
                                        paddingTop: "10px",
                                        paddingBottom: "5px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "10px",
                                        cursor: "pointer",
                                        marginTop: "10px",
                                        textDecoration: "none",
                                        color: "#50545f"
                                    }}
                                >
                                    <div>
                                        <MetricsIcon style={{
                                            width: "50px",
                                            height: "35px",
                                            fill: (['/metrics'].includes(pathname)) ? '#1e2347' : '#fff',
                                        }} />
                                    </div>
                                </NavLink>
                            </>
                        }
                        {<NavLink
                            to="/contacts"
                            activeClassName="active" style={{
                                paddingTop: "10px",
                                paddingBottom: "5px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "10px",
                                cursor: "pointer",
                                marginTop: "10px",
                                textDecoration: "none",
                                color: "#50545f"
                            }}
                        >
                            <div>
                                <ContactsIcon style={{
                                    width: "50px",
                                    height: "35px",
                                    fill: (['/contacts'].includes(pathname)) ? '#1e2347' : '#fff',

                                }} />

                            </div>
                        </NavLink>
                        }
                        {<NavLink
                            to="/campaign"
                            activeClassName="active" style={{
                                paddingTop: "10px",
                                paddingBottom: "5px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "10px",
                                cursor: "pointer",
                                marginTop: "10px",
                                textDecoration: "none",
                                color: "#50545f"
                            }}
                        >
                            <div>
                                <CampaignIcon style={{
                                    width: "50px",
                                    height: "35px",
                                    fill: (['/campaign'].includes(pathname)) ? '#1e2347' : '#fff',

                                }} />

                            </div>
                        </NavLink>
                        }

                        {<NavLink
                            to="/academy"
                            activeClassName="active" style={{
                                paddingTop: "10px",
                                paddingBottom: "5px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "10px",
                                cursor: "pointer",
                                marginTop: "10px",
                                textDecoration: "none",
                                color: "#50545f"
                            }}
                        >
                            <div>
                                <AcademyIcon style={{
                                    width: "50px",
                                    height: "35px",
                                    fill: (['/academy'].includes(pathname)) ? '#1e2347' : '#fff',

                                }} />

                            </div>
                        </NavLink>}

                        {<NavLink
                            to="/template"
                            activeClassName="active" style={{
                                paddingTop: "10px",
                                paddingBottom: "5px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "10px",
                                cursor: "pointer",
                                marginTop: "10px",
                                textDecoration: "none",
                                color: "#50545f"
                            }}
                        >
                            <div>
                                <ListIcon style={{
                                    width: "50px",
                                    height: "35px",
                                    fill: (['/template'].includes(pathname)) ? '#1e2347' : '#fff',

                                }} />

                            </div>
                        </NavLink>}

                        {<NavLink
                            to="/history"
                            activeClassName="active" style={{
                                paddingTop: "10px",
                                paddingBottom: "5px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "10px",
                                cursor: "pointer",
                                marginTop: "10px",
                                textDecoration: "none",
                                color: "#50545f"
                            }}
                        >
                            <div>
                                <StoriesIcon style={{
                                    width: "30px",
                                    height: "35px",
                                    fill: (['/history'].includes(pathname)) ? '#1e2347' : '#fff',

                                }} />

                            </div>
                        </NavLink>}

                        {
                            (role != 'agent' && role != 'master') && <>
                                <NavLink
                                    to="/settings"
                                    activeClassName="active" style={{
                                        paddingTop: "10px",
                                        paddingBottom: "5px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "10px",
                                        cursor: "pointer",
                                        marginTop: "10px",
                                        textDecoration: "none",
                                        color: "#50545f"
                                    }}
                                >
                                    <div>
                                        <SettingsIcon style={{
                                            width: "50px",
                                            height: "35px",
                                            fill: (['/settings'].includes(pathname)) ? '#1e2347' : '#fff',
                                        }} />
                                    </div>
                                </NavLink>
                            </>
                        }

                        {
                            (role === 'master') && <>
                                <NavLink
                                    to="/admin/customers"
                                    activeClassName="active" style={{
                                        paddingTop: "10px",
                                        paddingBottom: "5px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "10px",
                                        cursor: "pointer",
                                        marginTop: "10px",
                                        textDecoration: "none",
                                        color: "#fff"
                                    }}
                                >
                                    <div>
                                        <CustomersIcon style={{
                                            width: "50px",
                                            height: "35px",
                                            fill: (['/admin/customers'].includes(pathname)) ? '#1e2347' : '#fff',
                                        }} />
                                    </div>
                                </NavLink>
                            </>
                        }


                        {
                            (role === 'master') && <>
                                <NavLink
                                    to="/admin/pending"
                                    activeClassName="active" style={{
                                        paddingTop: "10px",
                                        paddingBottom: "5px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "10px",
                                        cursor: "pointer",
                                        marginTop: "10px",
                                        textDecoration: "none",
                                        color: "#fff"
                                    }}
                                >
                                    <div>
                                        <PendingIcon style={{
                                            width: "50px",
                                            height: "35px",
                                            fill: (['/admin/pending'].includes(pathname)) ? '#1e2347' : '#fff',
                                        }} />
                                    </div>
                                </NavLink>
                            </>
                        }



                        {
                            (role === 'master') && <>
                                <NavLink
                                    to="/admin/settings"
                                    activeClassName="active" style={{
                                        paddingTop: "10px",
                                        paddingBottom: "5px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "10px",
                                        cursor: "pointer",
                                        marginTop: "10px",
                                        textDecoration: "none",
                                        color: "#fff"
                                    }}
                                >
                                    <div>
                                        <PendingIcon style={{
                                            width: "50px",
                                            height: "35px",
                                            fill: (['/admin/settings'].includes(pathname)) ? '#1e2347' : '#fff',
                                        }} />
                                    </div>
                                </NavLink>
                            </>
                        }

                        {
                            (role != 'agent' && role != 'master') && <>
                                <NavLink
                                    to="/devs"
                                    activeClassName="active" style={{
                                        paddingTop: "10px",
                                        paddingBottom: "5px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "10px",
                                        cursor: "pointer",
                                        marginTop: "10px",
                                        textDecoration: "none",
                                        color: "#50545f"
                                    }}
                                >
                                    <div>
                                        <DevsIcon style={{
                                            width: "30px",
                                            height: "30px",
                                            fill: (['/devs'].includes(pathname)) ? '#1e2347' : '#fff',
                                        }} />
                                    </div>
                                </NavLink>
                            </>
                        }


                    </div>

                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        paddingBottom: "15px"
                    }} onClick={
                        () => {
                            window.localStorage.clear();
                            history.push('/login')
                        }
                    }>
                        <div style={{
                            width: "55px",
                            height: "55px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <CloseIcon style={{
                                width: "60px",
                                height: "45px",
                                fill: '#fff'
                            }} />

                        </div>
                    </div>

                </div>

                <div style={{
                    height: "100vh",
                    background: "#1e2347",
                    overflow:"auto"
                }}>
                    {props.children}
                </div>

            </div>
        </>
    )
}

export default EnvApp;
