import { useContext } from "react";

import moment from 'moment';

import tiempo from 'tiempo';

import { ReactComponent as DowIcon } from '../../assets/download.svg';

import { ReactComponent as UserIcon } from '../../assets/user.svg';

import { AppContext } from '../../contexts/AppProvider';

tiempo.config({ locale: 'es' });

function BubbleMe(props) {

    // getIDoc

    const { getIDoc, getW, isFile, isImage } = useContext(AppContext);

    return (
        <>
            <div style={{
                display: "flex",
                justifyContent: "flex-start"
            }}>
                <div
                    style={{
                        background: "#e7f7ff",
                        borderRadius: "10px",
                        padding: "10px",
                        marginTop: "5px",
                        display: "flex",
                        marginLeft: "7px",
                        width: getW(props.line.mimetype)
                    }}
                >
                    <div style={{
                        color: "#303030"
                    }}>
                        <div style={{
                            color: "#3283B0",
                            fontWeight: "700"
                        }}>{props.line.name}</div>
                        <div>
                            {
                                (isFile(props.line.mimetype)) && <div>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        background: "#f7f7f7",
                                        padding: "5px",
                                        borderRadius: "5px"
                                    }}>
                                        <div style={{
                                            width: "30px"
                                        }}><img src={getIDoc(props.line.mimetype)} style={{
                                            width: "30px"
                                        }} /></div>
                                        <div style={{
                                            marginLeft: "10px",
                                            marginRight: "10px",
                                            width: "200px"
                                        }}>{props.line.caption.substr(0, 15)}...</div>
                                        <div style={{
                                            width: "30px"
                                        }}>
                                            <a href={props.line.message}>
                                                <DowIcon style={{
                                                    width: "30px",
                                                    height: "30px"
                                                }} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                (isImage(props.line.mimetype)) && <div>
                                    <img src={props.line.message} style={{
                                        width: "100%"
                                    }} />
                                </div>
                            }

                            {
                                (props.line.mimetype === "application/text") && <div>
                                    {
                                        (props.line.mention.id != '') && <div style={{
                                            background: "#f7f7f7",
                                            padding: "10px",
                                            borderRadius: "5px",
                                            borderLeft: "5px solid #00817a",
                                            color: "#6d6c6a"
                                        }}>{props.line.caption}</div>
                                    }
                                    <div style={{
                                        marginTop: "5px"
                                    }}>{props.line.message}</div>
                                </div>
                            }

                        </div>
                        <div style={{
                            fontSize: "11px",
                            display: "flex",
                            alignSelf: "flex-end",
                            color: "#8c8c8c"
                        }}>{moment().format("YYYY-MM-DD hh:mm:ss")}</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BubbleMe;