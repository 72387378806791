import React, { useState, useEffect, useContext } from 'react';

import axios from 'axios';

import { DEV, PHAT_DEV, PHAT_PROD } from '../../config';

import { WidgetContext } from '../../contexts/WidgetProvider';

import toast from 'react-hot-toast';

import EnvMessages from './EnvMessages';

import Control from './Control';

function Inbox(props) {

    const { theme, setPage, setTimeline, setQualifier, agent, setAgent, status, setStatus } = useContext(WidgetContext);

    useEffect(() => {
        // Cargamos el chat

        if (window.localStorage.getItem('widget-ref') != null) {
            try {
                axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/get-web-conversation/${window.localStorage.getItem('widget-ref')}`, {})
                    .then((response) => {
                        if (response.status === 200) {

                            if (response.data != null) {

                                console.log(response.data);

                                if (response.data.status === 1 && response.data.qualifier.n > -1) {
                                    // Ya fue cerrado y calificado!

                                    window.localStorage.setItem("widget-page", "lobby");

                                    window.localStorage.removeItem("widget-ref");

                                } else {
                                    setAgent(response.data.agent)

                                    setStatus(response.data.status)

                                    setQualifier(response.data.qualifier);

                                    setTimeline(response.data.timeline);
                                }

                            } else {

                                window.localStorage.clear();

                                // window.location.reload();

                            }

                        } else {
                            alert("Ocurrió un problema al intentar recuperar los mensajes del chat.");
                        }
                    }, (error) => {
                        console.log("!!!!!!!!!!!!!!!!!!!!!!!");
                        console.log(error.response.data);
                        window.localStorage.clear();
                        setPage('login')
                    })
            } catch (error) {
                alert("asjahsjash");
            }

        }

    }, [])

    return (
        <>
            <div style={{
                width: "100%",
                height: "100vh",
                display: "grid",
                gridTemplateColumns: "auto",
                gridTemplateRows: "90px auto 55px",
                background: "#fff"
            }}>

                <div style={{
                    display: "grid",
                    gridTemplateColumns: "50px auto",
                    gridTemplateRows: "auto",
                    background: `rgba(${theme.colors.primary.r}, ${theme.colors.primary.g}, ${theme.colors.primary.b}, ${theme.colors.primary.a})`,
                }}>
                    <div style={{
                        color: "#fff",
                        fontSize: "30px",
                        placeSelf: "center"
                    }} onClick={
                        (e) => {
                            setPage('lobby')
                        }
                    }>
                        <img src="" />
                    </div>

                    <div style={{
                        padding: "20px",
                        color: "#fff"
                    }}>
                        <div style={{
                            textTransform: "capitalize"
                        }}>{(agent.ref != '') ? (agent.nickname != '') ? agent.nickname : 'Agente 1' : 'Sin agente asignado'}</div>
                        <div>
                            {
                                (status === 0) && <>
                                    {'Asignado al asesor: '}
                                    {(agent.nickname != '') ? agent.nickname : 'Agente 1'}
                                </>
                            }
                            {
                                (status === -2) && <>
                                    {'🤖 Conversando con el bot'}
                                </>
                            }
                            {
                                (status === 1) && <>
                                    {'Conversación cerrada'}
                                </>
                            }
                            {
                                (status === -1) && <>
                                    {'⏱ En espera de un asesor'}
                                </>
                            }
                        </div>
                    </div>
                </div>

                <EnvMessages />

                <Control />

            </div>
        </>
    );
}

export default Inbox;