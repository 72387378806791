import axios from 'axios';

import { PHAT_DEV, PHAT_PROD, DEV, URL_SOCKET_DEV, URL_SOCKET_PROD } from '../config';



export const saveTemplate = (payload) => {
    return new Promise((resolve, reject) => {
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/message-template`, payload)
        
            .then((response) => {
            
                resolve(response.data);

            }, (error) => {
                reject(error);
            });
    })
}

export const deleteTemplate = (ref) => {
    return new Promise((resolve, reject) => {
        axios.delete(`${(DEV) ? PHAT_DEV : PHAT_PROD}/message-template/${ref}`, {})
    
            .then((response) => {
            
                resolve(response.data);

            }, (error) => {
                reject(error);
            });
    })
}

export const getTemplates = (iid) => {
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/message-template/${iid}`, {})
        
            .then((response) => {
            
                resolve(response.data);

            }, (error) => {
                reject(error);
            });
    })
}
