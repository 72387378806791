import React, { useState, useEffect } from 'react';

import { useHistory } from "react-router-dom";

function ShiftCard(props) {

    const history = useHistory();

    useEffect(() => {

    }, []);

    return (
        <div style={{
            display: "flex",
            border: "2px solid #fff",
            fontSize: "65px",
            padding: "10px 20px 10px 20px",
            borderRadius: "10px",
            marginTop: "10px"
        }}>
            <div style={{
                width: "100%",
                fontWeight: "500"
            }}>{props.n}</div>
            <div style={{
                width: "100px"
            }}>{props.t}</div>
        </div>
    );
}

export default ShiftCard;

