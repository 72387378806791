import axios from 'axios';

import { PHAT_DEV, PHAT_PROD, DEV, URL_SOCKET_DEV, URL_SOCKET_PROD } from '../config';

export const save = (payload) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/campaigns`, payload)
            .then((response) => {
                resolve(response.data);
            }, (error) => {
                reject(error.response.data);
            });

    })
}


export const getAllCampaigns = (iid) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? URL_SOCKET_DEV : URL_SOCKET_PROD}/campaigns/${iid}`, {})
            .then((response) => {
                resolve(response.data);
            }, (error) => {
                reject(error.response);
            });
    })
}

export const getRecipients = (campaign) => {
    //convert input to output /recipients/:
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? URL_SOCKET_DEV : URL_SOCKET_PROD}/recipients/${campaign}`, {})
            .then((response) => {
                resolve(response.data);
            }, (error) => {
                reject(error.response);
            });
    })
}

export const remove = (payload) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.delete(`${(DEV) ? PHAT_DEV : PHAT_PROD}/remove-campaign`, payload)
            .then((response) => {
                resolve(response.data);
            }, (error) => {
                reject(error.response.data);
            });

    })
}