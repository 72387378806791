import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';

import { useHistory } from "react-router-dom";

import toast, { Toaster } from 'react-hot-toast';

import Cropper from 'react-easy-crop';

import { preferencesUpdate } from '../../services/Agent';

import { ReactComponent as SaveIcon } from '../../assets/save.svg';

import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';

import { ReactComponent as UserIcon } from '../../assets/user.svg';

import { getCroppedImg, getRotatedImage } from './canvasUtils';

import { profilePicturesUpdate } from '../../services/Agent';

import { AppContext } from '../../contexts/AppProvider';

function PreferencesForm() {

    const FileRef = useRef(null)

    const { Photo, setPhoto, Name, setName, Email, setEmail, Phone, setPhone } = useContext(AppContext);

    const [ref, setRef] = useState(window.localStorage.getItem("say-ref"));

    const [imageSrc, setImageSrc] = useState(null)

    const [profilePicture, setProfilePicture] = useState(null)

    const [photoEdit, setPhotoEdit] = useState(false)

    const [crop, setCrop] = useState({ x: 0, y: 0 })

    const [zoom, setZoom] = useState(1)

    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const [croppedImage, setCroppedImage] = useState(null)

    const [uploading, setUploading] = useState(false)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }

    const onFileChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            let imageDataUrl = await readFile(file)
            setImageSrc(imageDataUrl)
            setPhotoEdit(true)
        }
    }

    const showCroppedImage = useCallback(async () => {
        try {

            const croppedImage = await getCroppedImg(
                imageSrc,
                croppedAreaPixels
            )

            setCroppedImage(croppedImage);

            setUploading(true);

            setPhotoEdit(false);

            profilePicturesUpdate({
                file: croppedImage,
                ref: window.localStorage.getItem("say-ref")
            }).then(({ photo }) => {

                setPhoto(photo);

                window.location.reload(true);

                setUploading(false);

            }).catch((err) => {
                console.log(err);
            })

        } catch (e) {
            console.error(e)
        }
    }, [imageSrc, croppedAreaPixels])

    return (
        <div>

            <div className="row">

                <div style={{
                    display: "flex",
                    alignItems: "center"
                }}>
                    <div style={{
                        width: "100px",
                        height: "100px"
                    }}>

                        {
                            (Photo != "" && Photo != "undefined") && <img src={Photo} style={{
                                width: "100px",
                                height: "100px",
                                borderRadius: "50%"
                            }} />
                        }

                        {
                            (Photo === "" || Photo === "undefined") && <UserIcon style={{
                                width: "100px",
                                height: "100px",
                                borderRadius: "50%"
                            }} />
                        }


                    </div>
                    <div style={{
                        marginLeft: "5px"
                    }}>
                        <div style={{
                            fontSize: "30px",
                            fontWeight: "700"
                        }}>
                            {Name}
                        </div>
                        <div>
                            <button className="btn btn-primary shadow-none" onClick={
                                (e) => {
                                    FileRef.current.click();
                                }
                            }>Cambiar</button>
                            <button className="btn btn-danger shadow-none" style={{
                                marginLeft: "5px"
                            }} onClick={
                                () => {
                                    alert("Limpiamos la foto de perfil!");
                                }
                            }>Remover</button>
                        </div>
                    </div>
                </div>


                <input type="file" style={{
                    marginTop: "-1000px",
                    left: "-1000px",
                    position: "absolute"
                }} ref={FileRef} onChange={onFileChange} accept="image/*" />

                {
                    (uploading) && <div>Subiendo foto...</div>
                }

                {
                    (photoEdit && !uploading) && <div className="col-12 mt-3">
                        <div style={{
                            position: 'relative',
                            width: '100%'
                        }}>

                            <div style={{
                                position: 'relative',
                                width: '100%',
                                background: '#333',
                                height: 300
                            }}>

                                <Cropper
                                    image={imageSrc}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={1}
                                    cropShape="round"
                                    showGrid={false}
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                />

                            </div>

                            <br />

                            <button className="btn btn-primary shadow-none" style={{
                                marginLeft: "5px"
                            }} onClick={
                                showCroppedImage
                            }>Guardar nueva foto de perfil</button>
                            <button className="btn btn-danger shadow-none" style={{
                                marginLeft: "5px"
                            }} onClick={
                                () => {
                                    setImageSrc(null);
                                    setPhotoEdit(false);
                                }
                            }> Cancelar </button>

                        </div>
                    </div>
                }


                {
                    (!photoEdit) && <>
                        <div className="col-6 mt-5" style={{
                            fontWeight: "700",
                            color: "#243340"
                        }}>DIRECCIÓN DE EMAIL</div>
                        <div className="col-6 mt-5">
                            <input type="text" value={Email} onChange={
                                (e) => {
                                    setEmail(e.target.value)
                                }
                            } className="form-control shadow-none" />
                        </div>

                        <div className="col-6 mt-3" style={{
                            fontWeight: "700",
                            color: "#243340"
                        }}>NOMBRE</div>
                        <div className="col-6 mt-3">
                            <input type="text" value={Name} onChange={
                                (e) => {
                                    setName(e.target.value)
                                }
                            } className="form-control shadow-none" />
                        </div>

                        <div className="col-6 mt-3">
                            <div style={{
                                fontWeight: "700",
                                color: "#243340"
                            }}>NÚMERO DE TELÉFONO</div>
                            <div>Por favor, añade tu número de teléfono en formato internacional.</div>
                        </div>
                        <div className="col-6 mt-3">
                            <input type="text" value={Phone} onChange={
                                (e) => {
                                    setPhone(e.target.value)
                                }
                            } className="form-control shadow-none" />
                        </div>
                        <div className="col-12 mt-5">
                            <div style={{
                                textAlign: "right"
                            }}>

                                <button className="btn btn-primary shadow-none" style={{
                                    padding: "10px",
                                    marginRight: "5px"
                                }} onClick={
                                    () => {

                                        preferencesUpdate({
                                            ref: ref,
                                            name: Name,
                                            email: Email,
                                            phone: Phone
                                        }).then((res) => {
                                            console.log(res);
                                            toast.success("Cambios guardados")
                                        }).catch((err) => {
                                            toast.error(err)
                                        })

                                    }
                                }>
                                    <SaveIcon style={{
                                        width: "20px",
                                        height: "20px",
                                        fill: "#fff"
                                    }} /> Guardar</button>

                                <button className="btn btn-danger shadow-none" style={{
                                    padding: "10px"
                                }} onClick={
                                    () => {
                                        alert("Paramos la cuenta");
                                    }
                                }>
                                    <DeleteIcon style={{
                                        width: "20px",
                                        height: "20px",
                                        fill: "#fff"
                                    }} /> Eliminar cuenta</button>
                            </div>
                        </div>
                    </>
                }

            </div>

        </div>
    );
}

export default PreferencesForm;

