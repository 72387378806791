import { getByDisplayValue } from '@testing-library/dom';
import React, { useState, useEffect } from 'react';

import { useHistory } from "react-router-dom";

import QualifierBlock from '../../components/QualifierBlock';

function ShiftsQualifier() {

    const history = useHistory();

    useEffect(() => {

    }, []);

    return (
        <div style={{
            width: "100%",
            height: "100vh",
            overflow: "auto",
            display: "flex",
            background: "#fff",
            alignItems: "center",
            justifyContent: "center"
        }}>

            <div style={{
                width: "400px"
            }}>

                <div style={{
                    textAlign: "center"
                }}>
                    <img src="https://pascualbravo.edu.co/wp-content/uploads/2020/01/escudo-pascual-bravo_Mesa-de-trabajo-1.jpg" width="300px" />
                </div>

                <QualifierBlock n={-1} d={''} />

            </div>

        </div>
    );
}

export default ShiftsQualifier;

