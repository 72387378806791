import React, { useState, useEffect, useContext } from 'react';

import toast, { Toaster } from 'react-hot-toast';

import { useHistory } from "react-router-dom";

import { io } from "socket.io-client";

import { ReactComponent as SaveIcon } from '../../assets/save.svg';

import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';

import { update, remove } from '../../services/Bussine';

import { asociar } from '../../services/WhatsApp';

import { AppContext } from '../../contexts/AppProvider';

var socket = null;

function BussineForm() {

    const history = useHistory();

    const { Bussine, setBussine } = useContext(AppContext);

    const [token, setToken] = useState('');

    const [ref, setRef] = useState('');

    const [phone, setPhone] = useState('');

    const [Name, setName] = useState('');

    // updateInstance 

    useEffect(() => {
        if (Bussine.ref != "") {
            // Modificamos
            setRef(Bussine.ref);
            setName(Bussine.name);
        }
    }, [Bussine]);

    useEffect(() => {
        if (ref != "") {
            socket = io("http://localhost:7000", {
                query: {
                    token: token
                }
            });

            socket.on('updateInstance', (data) => {
                console.log("updateInstance");
                console.log(data);
            })
        }
    }, [ref]);

    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }

    return (
        <div>

            <div className="row">

                <div className="col-6 mt-5" style={{
                    fontWeight: "700",
                    color: "#243340"
                }}>NOMBRE</div>
                <div className="col-6 mt-5">
                    <input type="text" value={Name} onChange={
                        (e) => {
                            setName(e.target.value)
                        }
                    } className="form-control shadow-none" />
                </div>

                <div className="col-12 mt-5">
                    <div style={{
                        textAlign: "right"
                    }}>

                        <button className="btn btn-primary shadow-none" style={{
                            padding: "10px",
                            marginRight: "5px"
                        }} onClick={
                            () => {

                                update(ref, {
                                    name: Name
                                }).then((res) => {

                                    console.log(res);

                                    setBussine(res);

                                    toast.success("Los cambios se guardarón");

                                }).catch((err) => {
                                    toast.error(err);
                                })

                            }
                        }>
                            <SaveIcon style={{
                                width: "20px",
                                height: "20px",
                                fill: "#fff"
                            }} /> Guardar</button>

                        <button className="btn btn-primary shadow-none" style={{
                            padding: "10px",
                            marginRight: "5px"
                        }} onClick={
                            () => {

                                asociar(ref).then((res) => {
                                    toast.success("Los cambios se guardarón");
                                }).catch((err) => {
                                    toast.error(err);
                                })

                            }
                        }>  Asociar instancia</button>

                        <button className="btn btn-danger shadow-none" style={{
                            padding: "10px"
                        }} onClick={
                            () => {

                                remove(ref).then((res) => {
                                    console.log(res);

                                    window.localStorage.clear();
                                    history().push('/');

                                }).catch((err) => {
                                    console.log(err);
                                })

                            }
                        }>
                            <DeleteIcon style={{
                                width: "20px",
                                height: "20px",
                                fill: "#fff"
                            }} /> Eliminar cuenta de empresa</button>
                    </div>
                </div>

            </div>

        </div >
    );
}

export default BussineForm;

