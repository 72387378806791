import React, { useState, useEffect, useContext } from 'react';

import { ReactComponent as PlusIcon } from '../../assets/plus.svg';

import { AppContext } from '../../contexts/AppProvider';

function Header() {

    const { setCurrentIntegration } = useContext(AppContext);

    return (
        <div style={{
            padding: "20px 50px 20px 50px"
        }}>

            <div style={{
                fontSize: "30px",
                width: "100%",
                height: "100%",
                borderBottom: "1px solid #E8EBF0",
                display: "flex"
            }}>
                <div style={{
                    width: "100%"
                }}>
                    Integraciones
                </div>
            </div>

        </div>
    );
}

export default Header;