import React, { useState, useEffect } from 'react';

import toast, { Toaster } from 'react-hot-toast';

import { useHistory } from "react-router-dom";

import { css, cx } from '@emotion/css';

import { Link } from 'react-router-dom';

import { register } from '../../services/Auth';

import bg from '../../assets/fonflogin.jpg';

import Imglogin from '../../assets/fonflogi.png';

import Imglogo from '../../assets/Logop.png';





import { ReactComponent as LogoIcon } from '../../assets/logo.svg';

function Singup() {

    const history = useHistory();

    const [Email, setEmail] = useState('');

    const [Password, setPassword] = useState('');

    const [Name, setName] = useState('');

    const [Bussine, setBussine] = useState('');

    useEffect(() => {
        document.title = 'SayForm - Crear cuenta';
    }, [])

    return (
        <div style={{
            display: "grid",
            gridTemplateColumns: "auto 500px",
            gridTemplateRows: "auto",
            width: "100%",
            height: "100vh",
            overflow: "auto"
        }}>

            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                background: "url(" + bg + ")no-repeat center center fixed"
            }}>
                 <div style={{
                    maxWidth: "600px",
                    padding:"20px"
                }}>
                    <img src={Imglogin} style={{
                        width: "100%"
                    }} />
                </div>

            </div>

            <div style={{
                height: "100%",
                overflow: "auto"
            }}>
                <div style={{
                    padding: "20px 50px 20px 50px"
                }}>

                    <div style={{
                        textAlign: "center"
                    }}>
                        <div>
                            
                        <img src={Imglogo} style={{
                                width: "100px"
                            }} />
                        </div>

                        <div style={{
                            fontSize: "35px",
                            fontWeight: "700",
                            color: "#1E3142"
                        }}>Sayform</div>
                        <div style={{
                            fontSize: "20px",
                            fontWeight: "600",
                            color: "#1278BF"
                        }}>Cree su nueva cuenta</div>

                    </div>

                    <br />

                    <div>

                        <div>
                            <div className="mb-2" style={{
                                fontWeight: "700"
                            }}>Nombre empresa</div>
                            <input type="email" name="text" className="form-control shadow-none" style={{
                                background: "#F3F4F5",
                                border: "0px"
                            }} value={Bussine} onChange={
                                (e) => {
                                    setBussine(e.target.value);
                                }
                            } placeholder="Nombre empresa" />
                        </div>

                        <br />

                        <div>
                            <div className="mb-2" style={{
                                fontWeight: "700"
                            }}>Su nombre completo</div>
                            <input type="email" name="text" className="form-control shadow-none" style={{
                                background: "#F3F4F5",
                                border: "0px"
                            }} value={Name} onChange={
                                (e) => {
                                    setName(e.target.value);
                                }
                            } placeholder="Su nombre completo" />
                        </div>

                        <br />

                        <div>
                            <div className="mb-2" style={{
                                fontWeight: "700"
                            }}>Correo electronico</div>
                            <input type="email" name="email" className="form-control shadow-none" style={{
                                background: "#F3F4F5",
                                border: "0px"
                            }} value={Email} onChange={
                                (e) => {
                                    setEmail(e.target.value);
                                }
                            } placeholder="example@gmail.com" />
                        </div>

                        <br />

                        <div>
                            <div className="mb-2" style={{
                                fontWeight: "700"
                            }}>Contraseña</div>
                            <input type="password" name="password" className="form-control shadow-none" style={{
                                background: "#F3F4F5",
                                border: "0px"
                            }} value={Password} onChange={
                                (e) => {
                                    setPassword(e.target.value);
                                }
                            } placeholder="Contraseña" />
                        </div>

                        <br />

                        <br />

                        <div>
                            <button className="btn btn-primary" style={{
                                width: "100%",
                                padding: "10px",
                                background: "rgb(8,55,155)",
                                background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)",
                                borderRadius:"15px"
                            }} onClick={
                                (e) => {

                                    if (Email != "" && Name != "" && Password != "") {
                                        register({
                                            bussine: Bussine,
                                            name: Name,
                                            email: Email,
                                            password: Password
                                        }).then((res) => {

                                            toast.success('¡Tu cuenta fue creada correctamente! Inicia sesión.');

                                            history.push("/login");

                                        }).catch((err) => {
                                            if (err.response) {
                                                toast.error(err.response.data.message)
                                            }
                                        })
                                    } else {
                                        toast.error("Complete el formulario para continuar")
                                    }

                                }
                            }>Crear cuenta</button>

                            <br />

                            <div style={{
                                paddingTop: "10px",
                                textAlign: "center"
                            }}>
                                <Link to="/login">Ya tengo una cuenta</Link>
                            </div>

                        </div>

                    </div>

                </div>
            </div>

        </div>
    );
}

export default Singup;