import React, { useState, useEffect, useContext } from 'react';

import toast from 'react-hot-toast';

import "./index.css";

import { save, getByBussine, remove, send } from '../../services/Channel';

import { ReactComponent as SearchIcon } from '../../assets/icons/magnifyingglass.svg';

import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.fill.svg';

import { ReactComponent as ResultsIcon } from '../../assets/icons/text.magnifyingglass.svg';

import { ReactComponent as SenIcon } from '../../assets/icons/Multichannel.svg';

import { ReactComponent as SaveIcon } from '../../assets/save.svg';

import { AppContext } from '../../contexts/AppProvider';

import SocketProvider from '../../contexts/SocketProvider';

import SenderIdCard from './SenderIdCard';

import ColorPicker from './ColorPicker';

import QrForm from './QrForm';

import Header from './Header';

import EnvApp from '../../components/EnvApp';

import { loadIntegrations, loadWoocommers, loadDialogFlow } from '../../services/Integration';

function SenderIds() {

    const [ref, setRef] = useState('');

    const { iid, Bussine, currentSenderId, setCurrentSenderId, senderIds, setSenderIds } = useContext(AppContext);

    const [channels_, setChannels_] = useState([
        {
            ref: 'WHATSAPP',
            label: 'Whatsapp'
        },
        {
            ref: 'WEB',
            label: 'Web'
        },
        {
            ref: 'TELEGRAM',
            label: 'Telegram'
        },
        {
            ref: 'SMS',
            label: 'Mensajes de texto'
        }
    ]);

    /// ListStatus, setListStatus

    const [primaryColor, setPrimaryColor] = useState({
        r: 0,
        g: 0,
        b: 0,
        a: 1
    });

    const [secondaryColor, setSecondaryColor] = useState({
        r: 54,
        g: 49,
        b: 49,
        a: 1
    });

    const [t1StartTime, setT1StartTime] = useState('00:00');

    const [t1EndTime, setT1EndTime] = useState('23:59');

    const [t2StartTime, setT2StartTime] = useState('00:00');

    const [t2EndTime, setT2EndTime] = useState('23:59');

    const [search, setSearch] = useState('');

    const [name, setName] = useState('');

    const [awsAccessKey, setAwsAccessKey] = useState('');

    const [awsSecretAccess, setAwsSecretAccess] = useState('');

    const [awsRegion, setAwsRegion] = useState('us-east-1');

    const [provider, setProvider] = useState('SNS');

    const [virtualAgent, setVirtualAgent] = useState('none');

    const [woocommerce, setWoocommerce] = useState('none');

    const [ratingLink, setRatingLink] = useState(1);

    const [channel, setChannel] = useState(channels_[0].ref);

    const [integrations, setIntegrations] = useState([]);

    const [woocommerces, setWoocommerces] = useState([]);

    const [dialogFlows, setDialogFlows] = useState([]);

    const [isLogin, setIsLogin] = useState(false);


    const phonenumber = (number) => {

        //var phoneno = /^\(?([0-9]{2})?[-. ]?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        var phoneno = /^\(?([0-9]{12})$/;
        if (number.match(phoneno)) {
            return true;
            //true
        }
        else {
            return false;
            //false
        }
    }

    useEffect(() => {
        if (currentSenderId.name != null && currentSenderId.channel != null) {
            setName(currentSenderId.name);
            setChannel(currentSenderId.channel);
            setVirtualAgent(currentSenderId.virtualAgent);
            setWoocommerce(currentSenderId.woocommerce);

            setAwsRegion(currentSenderId.awsRegion);
            setAwsSecretAccess(currentSenderId.awsSecretAccess);
            setAwsAccessKey(currentSenderId.awsAccessKey);
            setProvider(currentSenderId.provider);

            setRatingLink(currentSenderId.ratingLink);

            setT1StartTime(currentSenderId.t1StartTime);
            setT1EndTime(currentSenderId.t1EndTime);

            setT2StartTime(currentSenderId.t2StartTime);
            setT2EndTime(currentSenderId.t2EndTime);


            setPrimaryColor(currentSenderId.primaryColor);
            setSecondaryColor(currentSenderId.secondaryColor);
        }
    }, [currentSenderId]);

    useEffect(() => {
        if (Bussine.ref != "") {
            setRef(Bussine.ref);
        }
    }, [Bussine]);

    useEffect(() => {

        setRef(window.localStorage.getItem("say-ref"));

        // Obtenemos todos los Sender Ids para este negocio!

        getByBussine(window.localStorage.getItem("say-bussine")).then((res) => {

            console.log("res");
            console.log(res);

            setSenderIds(res);
        }).catch((err) => {
            console.log(err);
            //toast.error(err.data.message)
        })


        loadDialogFlow(window.localStorage.getItem("say-bussine"), "dialogflow").then((res) => {
            setDialogFlows(res);
        }).catch((error) => {

        })

        /*

        // Obtenemos los woocommerces disponibles
        loadWoocommers(window.localStorage.getItem("say-bussine"), "woocommerce").then((res) => {
            setWoocommerces(res);
        }).catch((error) => {

        })*/


    }, []);

    useEffect(() => {
        if (ref != "") {

        }
    }, [ref]);

    return (
        <SocketProvider>
            <EnvApp>
                <div style={{
                    display: "grid",
                    gridTemplateColumns: "auto",
                    gridTemplateRows: "100px auto",
                    background: "#fff",
                    width: "100%",
                    height: "100%",
                    overflow: "auto",
                    borderRadius: "10px 0px 0px 10px"
                }}>

                    <Header />

                    <div style={{
                        padding: "20px 50px 20px 50px"
                    }}>

                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "280px auto",
                            gridTemplateRows: "auto",
                            height: "100%"
                        }}>
                            <div style={{
                                height: "100%"
                            }}>

                                <div style={{
                                    display: "flex",
                                    alignItems: "center"
                                }}>

                                    <input type="text" value={search} style={{
                                        background: "#F2F7F8",
                                        border: "0px",
                                        height: "50px"
                                    }} onChange={
                                        (e) => {
                                            setSearch(e.target.value)
                                        }
                                    } className="form-control shadow-none" placeholder="Escribe para buscar" />

                                    <button className="btn btn-primary shadow-none" style={{
                                        marginLeft: "5px",
                                        height: "50px",
                                        borderRadius: "15px",
                                        background: "rgb(8,55,155)",
                                        background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)"
                                    }} onClick={
                                        () => {
                                            /*searchUsers(search, "agent").then((users) => {
                                                setAgents(users);
                                            }).catch((err_) => {
                                                console.log(err_);
                                            })*/
                                            toast.error("Esta funcionalidad aún no está disponible")
                                        }
                                    }>
                                        <SearchIcon style={{

                                            //width: "20px",
                                            //height: "20px",
                                            fill: "#fff"
                                        }} />
                                    </button>

                                </div>

                                <br />

                                {
                                    (senderIds.length === 0) && <div style={{
                                        textAlign: "center"
                                    }}>
                                        <div style={{
                                            fontSize: "18px",
                                            fontWeight: "700"
                                        }}>
                                            <ResultsIcon style={{
                                                width: "40px",
                                                height: "40px",
                                                fill: "#fff"
                                            }} /></div>
                                        <div>Sin resultados</div>
                                    </div>
                                }


                                {
                                    senderIds.map((SenderId, SenderIdIndex) =>
                                        <>
                                            <SenderIdCard onLogin={
                                                () => {
                                                    setIsLogin(true);
                                                }
                                            }
                                                onSend={
                                                    () => {

                                                        const number1 = prompt('digite su numero de teléfono de la siguiente manera: \n 573214567890');
                                                        phonenumber(number1);
                                                        console.log(number1);

                                                        //si es true 
                                                        if (phonenumber(number1) == true) {
                                                            const prueba = number1.substr(0, 2);
                                                            console.log(prueba);
                                                            //si empieza con 57 va a enviar info al endpoint
                                                            if (prueba == "57") {
                                                                //alert(SenderId.ref);
                                                                send({
                                                                    "number": number1,
                                                                    "channel": SenderId.ref,
                                                                    "message": "Este es un mensaje de prueba enviado desde sayform"
                                                                });
                                                            } else {
                                                                //sino alert que el telefono esta mal
                                                                alert(`${number1} no es un número de teléfono válido`)
                                                            }
                                                            //si es falso va a alert que telefono esta mal
                                                        } else {
                                                            alert(`${number1} no es un número de teléfono válido`)
                                                        }
                                                    }} key={SenderIdIndex} SenderId={SenderId} />
                                        </>
                                    )
                                }

                            </div>

                            {
                                (currentSenderId.ref === null) && <div style={{
                                    padding: "0px 50px 0px 50px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100%",
                                    textAlign: "center"
                                }}>
                                    <div style={{
                                        maxWidth: "500px"
                                    }}>

                                        <div>
                                            <SenIcon />
                                        </div>
                                        <div style={{
                                            fontSize: "25px",
                                            fontWeight: "500"
                                        }}>Selecciona un Canal</div>
                                        <div style={{
                                            marginTop: "10px"
                                        }}>Selecciona un Canal, para poder editar tu información puedes crear un nuevo Canal haciendo clic en el botón <b>+ Nuevo Canal.</b></div>
                                    </div>
                                </div>
                            }

                            {
                                (currentSenderId.ref != null) && <div style={{
                                    overflow: "auto"
                                }}>


                                    <div style={{
                                        padding: "0px 50px 0px 50px"
                                    }}>

                                        {
                                            (isLogin) && <>
                                                <br />

                                                <QrForm />
                                            </>
                                        }

                                        {
                                            (!isLogin) && <>
                                                <br />

                                                <div style={{
                                                    minHeight: "100px"
                                                }}>
                                                    <div style={{
                                                        fontSize: "30px",
                                                        fontWeight: "700"
                                                    }}>Información</div>

                                                    <div style={{
                                                        marginTop: "10px"
                                                    }}>
                                                        <div className="row">
                                                            <div className="col-4 mt-3">
                                                                <div style={{
                                                                    fontWeight: "700",
                                                                    color: "rgb(8, 55, 155)"
                                                                }}>Nombre</div>

                                                                <input type="text" value={name} class="shadow p-7 bg-body rounded" onChange={
                                                                    (e) => {
                                                                        setName(e.target.value)
                                                                    }
                                                                } style={{
                                                                    width: "100%",
                                                                    padding: "10px",
                                                                    fontSize: "20px",
                                                                    background: "#F2F7F8",
                                                                    border: "0px"
                                                                }} placeholder="Nombre del Sender Id" />
                                                            </div>
                                                            <div className="col-4 mt-3">
                                                                <div style={{
                                                                    fontWeight: "700",
                                                                    marginTop: "10px",
                                                                    color: "rgb(8, 55, 155)",

                                                                }}>Canal</div>

                                                                < select value={channel} style={{
                                                                    border: 0
                                                                }} onChange={
                                                                    (e) => {

                                                                        setChannel(e.target.value);

                                                                        if (e.target.value === "WEB") {

                                                                        } else {

                                                                        }

                                                                    }
                                                                } className="form-control shadow p-2  bg-body rounded" >
                                                                    {
                                                                        channels_.map((Channel_, ChannelIndex) => {
                                                                            return (<option key={ChannelIndex} value={Channel_.ref}>{Channel_.label}</option>)
                                                                        })
                                                                    }
                                                                </select>

                                                            </div>

                                                            {
                                                                (channel === 'WEB' || (channel === 'WHATSAPP')) && <>
                                                                    <div className="col-4 mt-3">
                                                                        <div style={{
                                                                            fontWeight: "700",
                                                                            marginTop: "8px",
                                                                            color: "rgb(8, 55, 155)",

                                                                        }}>Agente Virtual</div>

                                                                        <select value={virtualAgent}
                                                                            style={{
                                                                                border: 0
                                                                            }}
                                                                            onChange={
                                                                                (e) => {
                                                                                    setVirtualAgent(e.target.value);
                                                                                }
                                                                            } className="form-control shadow p-2  bg-body rounded" style={{
                                                                                border: 0
                                                                            }}>
                                                                            <option value="none">Sin agente</option>
                                                                            {
                                                                                dialogFlows.map((dialogFlow, dialogIndex) => {
                                                                                    return (<option key={dialogIndex} value={dialogFlow.ref}>{dialogFlow.name}</option>)
                                                                                })
                                                                            }
                                                                        </select>

                                                                    </div>
                                                                </>
                                                            }

                                                            {
                                                                (channel === 'SMS') && <>
                                                                    <div className="col-4 mt-3" >

                                                                        <div style={{
                                                                            fontWeight: "700",
                                                                            marginTop: "8px",
                                                                            color: "rgb(8, 55, 155)"
                                                                        }}>Proveedor</div>

                                                                        <select value={provider} class="shadow p-7  bg-body rounded" onChange={
                                                                            (e) => {
                                                                                setProvider(e.target.value);
                                                                            }
                                                                        } className="form-control" style={{

                                                                        }}>
                                                                            <option value="SNS">Amazon SNS</option>
                                                                            <option value="CELLVOZ">CellVoz</option>
                                                                        </select>

                                                                    </div>
                                                                </>
                                                            }


                                                            {
                                                                (channel === 'SMS') && <>
                                                                    <div className="col-4 mt-3">

                                                                        <div style={{
                                                                            fontWeight: "700",
                                                                            marginTop: "8px",
                                                                            color: "rgb(8, 55, 155)"
                                                                        }}>ACCESS KEY</div>

                                                                        <input type="text" value={awsAccessKey} onChange={
                                                                            (e) => {
                                                                                setAwsAccessKey(e.target.value)
                                                                            }
                                                                        } style={{
                                                                            width: "100%"
                                                                        }} name="appt" required />

                                                                    </div>
                                                                </>
                                                            }


                                                            {
                                                                (channel === 'SMS') && <>
                                                                    <div className="col-4 mt-3">

                                                                        <div style={{
                                                                            fontWeight: "700",
                                                                            marginTop: "8px",
                                                                            color: "rgb(8, 55, 155)"
                                                                        }}>SECRET ACCESS</div>

                                                                        <input type="text" value={awsSecretAccess} onChange={
                                                                            (e) => {
                                                                                setAwsSecretAccess(e.target.value)
                                                                            }
                                                                        } style={{
                                                                            width: "100%"
                                                                        }} name="appt" required />

                                                                    </div>
                                                                </>
                                                            }


                                                            {
                                                                (channel === 'SMS') && <>
                                                                    <div className="col-4 mt-3">

                                                                        <div style={{
                                                                            fontWeight: "700",
                                                                            marginTop: "8px",
                                                                            color: "rgb(8, 55, 155)"
                                                                        }}>REGION</div>

                                                                        <input type="text" value={awsRegion} class="shadow p-7  bg-body rounded" onChange={
                                                                            (e) => {
                                                                                setAwsRegion(e.target.value)
                                                                            }
                                                                        } style={{
                                                                            width: "100%"
                                                                        }} name="appt" required />

                                                                    </div>
                                                                </>
                                                            }

                                                            {
                                                                (channel === 'WEB' || (channel === 'WHATSAPP')) && <>
                                                                    <div className="col-4 mt-3">
                                                                        <div style={{
                                                                            fontWeight: "700",
                                                                            color: "rgb(8, 55, 155)"
                                                                        }}>Woocommerce</div>

                                                                        <select value={woocommerce} class="shadow p-7  bg-body rounded" onChange={
                                                                            (e) => {
                                                                                setWoocommerce(e.target.value);
                                                                            }
                                                                        } className="form-control shadow p-2  bg-body rounded" style={{

                                                                            border: 0

                                                                        }}>
                                                                            <option value="none" >Sin woocommerce</option>
                                                                            {
                                                                                woocommerces.map((woocommerce, WooIndex) => {
                                                                                    return (<option key={WooIndex} value={woocommerce.ref}>{woocommerce.name}</option>)
                                                                                })
                                                                            }
                                                                        </select>

                                                                    </div>
                                                                </>
                                                            }

                                                        </div>

                                                    </div>

                                                    <br />

                                                    {
                                                        (channel === 'WEB' || (channel === 'WHATSAPP')) && <>
                                                            <div style={{
                                                                fontSize: "30px",
                                                                fontWeight: "700"
                                                            }}>Calificación</div>

                                                            <div style={{
                                                                marginTop: "10px"
                                                            }}>
                                                                <div className="row">
                                                                    <div className="col-4 mt-3">
                                                                        <div style={{
                                                                            fontWeight: "700",
                                                                            marginTop: "10px",
                                                                            color: "rgb(8, 55, 155)",

                                                                        }}>Enviar link de calificación</div>

                                                                        <select value={ratingLink} style={{
                                                                            border: 0
                                                                        }} onChange={
                                                                            (e) => {
                                                                                setRatingLink(e.target.value)
                                                                            }
                                                                        } className="form-control shadow p-7  bg-body rounded">
                                                                            <option value="1">Si</option>
                                                                            <option value="0">No</option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <br />
                                                        </>
                                                    }

                                                    <div style={{
                                                        marginTop: "10px"
                                                    }}>

                                                        <div style={{
                                                            fontSize: "30px",
                                                            fontWeight: "700"
                                                        }}>Horario de atención</div>

                                                        <div className="row">

                                                            <div className="col-6 mt-3">
                                                                <div style={{
                                                                    fontSize: "18px",
                                                                    fontWeight: "700"
                                                                }}>Primer turno</div>
                                                                <div className="row">
                                                                    <div className="col-6 mt-3">
                                                                        <div>
                                                                            <div style={{
                                                                                fontWeight: "700",
                                                                                color: "rgb(8, 55, 155)"
                                                                            }}>Desde</div>
                                                                            <input type="time" value={t1StartTime} onChange={
                                                                                (e) => {
                                                                                    setT1StartTime(e.target.value)
                                                                                }
                                                                            } style={{
                                                                                width: "100%"
                                                                            }} name="appt" min="09:00" max="18:00" required />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mt-3">
                                                                        <div>
                                                                            <div style={{
                                                                                fontWeight: "700",
                                                                                color: "rgb(8, 55, 155)"
                                                                            }}>Hasta</div>
                                                                            <input type="time" value={t1EndTime} onChange={
                                                                                (e) => {
                                                                                    setT1EndTime(e.target.value)
                                                                                }
                                                                            } id="appt" style={{
                                                                                width: "100%"
                                                                            }} name="appt" min="09:00" max="18:00" required />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-6 mt-3">
                                                                <div style={{
                                                                    fontSize: "18px",
                                                                    fontWeight: "700"
                                                                }}>Segundo turno</div>
                                                                <div className="row">
                                                                    <div className="col-6 mt-3">
                                                                        <div>
                                                                            <div style={{
                                                                                fontWeight: "700",
                                                                                color: "rgb(8, 55, 155)"
                                                                            }}>Desde</div>
                                                                            <input type="time" value={t2StartTime} onChange={
                                                                                (e) => {
                                                                                    setT2StartTime(e.target.value)
                                                                                }
                                                                            } style={{
                                                                                width: "100%"
                                                                            }} name="appt" min="09:00" max="18:00" required />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 mt-3">
                                                                        <div>
                                                                            <div style={{
                                                                                fontWeight: "700",
                                                                                color: "rgb(8, 55, 155)"
                                                                            }}>Hasta</div>
                                                                            <input type="time" value={t2EndTime} onChange={
                                                                                (e) => {
                                                                                    setT2EndTime(e.target.value)
                                                                                }
                                                                            } id="appt" style={{
                                                                                width: "100%"
                                                                            }} name="appt" min="09:00" max="18:00" required />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>


                                                    <div style={{
                                                        marginTop: "10px"
                                                    }}>
                                                        <div className="row">
                                                            {
                                                                (channel === 'WEB') && <>
                                                                    <div className="col-3 mt-3">
                                                                        <div style={{
                                                                            display: "flex"
                                                                        }}>
                                                                            <div>
                                                                                <div style={{
                                                                                    fontWeight: "700",
                                                                                    color: "rgb(8, 55, 155)"
                                                                                }}>Color principal</div>
                                                                                <div>Seleccione el color del widget</div>
                                                                                <div>
                                                                                    <ColorPicker color={primaryColor} onChange={(color) => {
                                                                                        setPrimaryColor(color)
                                                                                    }} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3 mt-3">
                                                                        <div style={{
                                                                            marginLeft: "25px"
                                                                        }}>
                                                                            <div style={{
                                                                                fontWeight: "700",
                                                                                color: "rgb(8, 55, 155)"
                                                                            }}>Color secundario</div>
                                                                            <div>Seleccione el color de los botones</div>
                                                                            <div>
                                                                                <ColorPicker color={secondaryColor} onChange={(color) => {
                                                                                    setSecondaryColor(color)
                                                                                }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>

                                                    {
                                                        (currentSenderId.ref != "" && (channel === 'WEB' || channel === 'WHATSAPP')) && <>
                                                            <br />
                                                            <div style={{
                                                                marginTop: "10px"
                                                            }}>

                                                                <h3 style={{
                                                                    fontWeight: "700"
                                                                }}>Intégralo en tu sitio web</h3>
                                                                <div style={{
                                                                    textAlign: "justify",
                                                                    color: "#4D5656"
                                                                }}>Copia y pega el siguiente código en el pie de página de tu sitio web antes de la etiqueta 'body'. Para sitios web WordPress, puedes instalar y activar el siguiente <a href="https://es.wordpress.org/plugins/insert-headers-and-footers/">plugin</a>.</div>
                                                                <br />
                                                                <div><h4>Código del widget</h4></div>


                                                                <div>
                                                                    Copia y pega el siguiente código en el pie de página de tu sitio web antes de la etiqueta <span style={{
                                                                        fontWeight: "700"
                                                                    }}>{'</body>'}</span>. Para sitios web WordPress, puedes instalar y activar el siguiente <a href="https://es.wordpress.org/plugins/insert-headers-and-footers/">plugin</a>.
                                                                </div>

                                                                <br />

                                                                <div style={{
                                                                    background: "#0A2740",
                                                                    borderRadius: "10px",
                                                                    color: "#fff",
                                                                    padding: "10px"
                                                                }}>

                                                                    {
                                                                        (currentSenderId.channel === 'WEB') && <>
                                                                            <code>
                                                                                <pre style={{
                                                                                    color: "#fff"
                                                                                }}>{`
<script src="https://code.jquery.com/jquery-1.12.4.js"></script>
<script src="https://cdn.cloudcitycolombia.com/v3/bundled.js"></script>
<script>	
$(document).ready(function(){
initaWidget('#000', '${currentSenderId.name}', '${window.localStorage.getItem('say-bussine')}')
})
</script>
`}</pre></code></>
                                                                    }

                                                                    {
                                                                        (currentSenderId.channel === 'WHATSAPP') && <>
                                                                            <code>
                                                                                <pre style={{
                                                                                    color: "#fff"
                                                                                }}>{`
<script src="https://code.jquery.com/jquery-1.12.4.js"></script>
<script src="https://cdn.cloudcitycolombia.com/v3/bundled.js"></script>
<script>	
$(document).ready(function(){
initWhatsApp('AQUI EL NÚMERO DE TELÉFONO')
})
</script>
`}</pre></code></>
                                                                    }


                                                                </div>

                                                            </div>
                                                        </>
                                                    }


                                                    {
                                                        (channel === 'WEB') && <>
                                                            <div style={{
                                                                background: "#f2f2f2",
                                                                borderRadius: "10px",
                                                                position: "fixed",
                                                                bottom: "10px",
                                                                right: "10px",
                                                                maxWidth: "350px"
                                                            }}>
                                                                <div style={{
                                                                    background: `rgba(${primaryColor.r}, ${primaryColor.g}, ${primaryColor.b}, ${primaryColor.a})`,
                                                                    padding: "20px",
                                                                    color: "#fff",
                                                                    borderRadius: "10px 10px 0px 0px"
                                                                }}>
                                                                    <div style={{
                                                                        fontSize: "30px",
                                                                        fontWeight: "700",
                                                                    }}>Hola Rafael Jose</div>
                                                                    <div>¡Pregúntanos lo que sea, estamos aquí para ayudarte!</div>
                                                                </div>
                                                                <div>
                                                                    <div style={{
                                                                        background: "#fff",
                                                                        padding: "20px",
                                                                        margin: "10px",
                                                                        borderRadius: "10px"
                                                                    }}>
                                                                        <div style={{
                                                                            fontWeight: "700"
                                                                        }}>
                                                                            Continúa la conversación
                                                                        </div>
                                                                        <button className="btn" style={{
                                                                            width: "100%",
                                                                            marginTop: "10px",
                                                                            background: `rgba(${secondaryColor.r}, ${secondaryColor.g}, ${secondaryColor.b}, ${secondaryColor.a})`,
                                                                            border: "0px",
                                                                            color: "#fff"
                                                                        }}>Ver todas las conversaciones</button>
                                                                    </div>
                                                                    <br />
                                                                </div>
                                                                <div></div>
                                                            </div>
                                                        </>
                                                    }

                                                </div>

                                                <br />

                                                {
                                                    (currentSenderId.name != "" && channel === 'WEB') && <>
                                                        <h3 style={{
                                                            fontWeight: "700"
                                                        }}>Prueba tu Widget</h3>
                                                        <br />
                                                        <div style={{
                                                            marginBottom: "5px"
                                                        }}>
                                                            Dale click en el link y podrás probar el Widget
                                                        </div>
                                                        <a href={`https://app.sayform.com/widget/${currentSenderId.name}/${window.localStorage.getItem("say-bussine")}`} target="_blank" >{`https://app.sayform.com/widget/${currentSenderId.name}/${window.localStorage.getItem("say-bussine")}`}</a>
                                                    </>

                                                }

                                                <br />

                                                <br />

                                                <div style={{
                                                    minHeight: "100px"
                                                }}>
                                                    <div>
                                                        <button className="shadow btn btn-primary" style={{
                                                            background: "rgb(8,55,155)",
                                                            background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)",
                                                            borderRadius: "15px"
                                                        }} onClick={
                                                            () => {
                                                                // Funcion para crear

                                                                if (name != "") {

                                                                    save({
                                                                        ref: currentSenderId.ref,
                                                                        virtualAgent,
                                                                        woocommerce,
                                                                        ratingLink,
                                                                        name,
                                                                        channel,

                                                                        t1StartTime,
                                                                        t1EndTime,
                                                                        t2StartTime,
                                                                        t2EndTime,

                                                                        primaryColor,
                                                                        secondaryColor,
                                                                        provider,
                                                                        awsRegion,
                                                                        awsSecretAccess,
                                                                        awsAccessKey,
                                                                        bussine: window.localStorage.getItem("say-bussine")
                                                                    }).then((res) => {
                                                                        // Modificamos la lista

                                                                        setCurrentSenderId({
                                                                            ref: "",
                                                                            name: "",
                                                                            t1StartTime: "",
                                                                            t1EndTime: "",
                                                                            t2StartTime: "",
                                                                            t2EndTime: "",
                                                                            online: false,
                                                                            replaced: false
                                                                        })

                                                                        getByBussine(window.localStorage.getItem("say-bussine")).then((res) => {
                                                                            setSenderIds(res);
                                                                        }).catch((err) => {
                                                                            console.log(err);
                                                                            //toast.error(err.data.message)
                                                                        })

                                                                        setName("");

                                                                        toast.success("El canal se creó correctamente");

                                                                    }).catch((err) => {
                                                                        toast.error(err)
                                                                    })
                                                                } else {
                                                                    toast.error("El nombre del SenderId, tiene que ser diferente a vacío")
                                                                }
                                                            }
                                                            //icono guardar
                                                        }>

                                                            {

                                                                (currentSenderId.ref != "") ? <><SaveIcon style={{
                                                                    width: "15px",
                                                                    height: "20px",
                                                                    fill: "#fff",
                                                                    marginRight: "8px"
                                                                }} />Guardar cambios</> : <>Crear nuevo Canal</>
                                                            }
                                                        </button>
                                                        {
                                                            (currentSenderId.ref != '') && <>
                                                                <button className="btn btn-danger shadow-none" style={{
                                                                    marginLeft: "5px",
                                                                    background: "rgb(237,11,11)",
                                                                    background: "linear-gradient(180deg, rgba(237,11,11,1) 0%, rgba(227,84,98,1) 100%)",
                                                                    borderRadius: "15px"

                                                                }} onClick={
                                                                    () => {

                                                                        /*
                                                                        ,
                                                                            bussine: window.localStorage.getItem("say-bussine"),
                                                                            name: currentSenderId.name
                                                                        */

                                                                        remove(currentSenderId.ref).then((res) => {

                                                                            setCurrentSenderId({
                                                                                ref: "",
                                                                                name: "",
                                                                                t1StartTime: "",
                                                                                t1EndTime: "",
                                                                                t2StartTime: "",
                                                                                t2EndTime: "",
                                                                                online: false,
                                                                                replaced: false
                                                                            })

                                                                            getByBussine(window.localStorage.getItem("say-bussine")).then((res) => {
                                                                                setSenderIds(res);
                                                                            }).catch((err) => {
                                                                                console.log(err);
                                                                                //toast.error(err.data.message)
                                                                            })

                                                                            setName("");


                                                                            toast.success("Canal eliminado");

                                                                        }).catch((err) => {
                                                                            toast.error(err);
                                                                        })

                                                                    }
                                                                }>
                                                                    <DeleteIcon style={{
                                                                        width: "20px",
                                                                        height: "20px",
                                                                        fill: "#fff",
                                                                        marginRight: "-2px",
                                                                        marginLeft: "-2px"

                                                                    }} /> Eliminar Canal</button>
                                                            </>
                                                        }

                                                    </div>

                                                </div>

                                                <br />
                                                <br />

                                            </>
                                        }

                                    </div>


                                </div>
                            }


                        </div>

                    </div>

                </div>
            </EnvApp>
        </SocketProvider >
    );
}

export default SenderIds;