import React, { useState, useEffect, useContext } from 'react';

import { CSVLink, CSVDownload } from "react-csv";

import DateDiff from 'date-diff';

import SenderId from '../../assets/senderId.png';

import None from '../../assets/none.png';

import Mala from '../../assets/mala.png';

import Regular from '../../assets/regular.png';

import Bueno from '../../assets/bueno.png';

import Excelente from '../../assets/excelente.png';

import { PHAT_DEV, PHAT_PROD, DEV } from '../../config';

import axios from 'axios';

import { ReactComponent as SearchIcon } from '../../assets/icons/magnifyingglass.svg';

import { ReactComponent as UserIcon } from '../../assets/icons/person.fill.svg';

import { ReactComponent as ListIcon } from '../../assets/icons/list.bullet.rectangle.portrait.svg';

import { ReactComponent as MetricIcon } from '../../assets/icons/metrics.svg';

import { ReactComponent as ResultsIcon } from '../../assets/icons/text.magnifyingglass.svg';

import { ReactComponent as ImportIcon } from '../../assets/icons/tray.and.arrow.up.svg';


import Header from './Header';

import { AppContext } from '../../contexts/AppProvider';

import { getUsersByRol, getAsesors } from '../../services/Agent';

import { getConsolidated } from '../../services/Bussine';



import EnvApp from '../../components/EnvApp';
import moment from 'moment';
import toast from 'react-hot-toast';

function Metrics() {

    //const { currentIntegration, setCurrentIntegration } = useContext(AppContext);

    const [search, setSearch] = useState('');

    const [general, setGeneral] = useState(false);

    const [dateGeneral, setDateGeneral] = useState(moment().format('YYYY-MM-DD'));

    const [dateEndGeneral, setDateEndGeneral] = useState(moment().format('YYYY-MM-DD'));

    const [currentIntegration, setCurrentIntegration] = useState({
        name: '',
        service: ''
    });

    const [agent, setAgent] = useState([]);

    const [agents, setAgents] = useState([]);

    const [consecutiveAgent, setConsecutiveAgent] = useState([]);

    const [consecutiveBot, setConsecutiveBot] = useState([]);

    const [chats, setChats] = useState([]);



    const [dateStartConsecutive, setDateStartConsecutive] = useState(moment().format('YYYY-MM-DD'));

    const [dateEndConsecutive, setDateEndConsecutive] = useState(moment().format('YYYY-MM-DD'));

    const [dateStartAverage, setDateStartAverage] = useState(moment().format('YYYY-MM-DD'));

    const [dateEndAverage, setDateEndAverage] = useState(moment().format('YYYY-MM-DD'));

    const [total, setTotal] = useState(0);



    const [average, setAverage] = useState([
        {
            name: 'Sin calificar',
            punctuation: 0,
            percentage: 0
        },
        {
            name: 'Mala',
            punctuation: 0,
            percentage: 0
        },
        {
            name: 'Regular',
            punctuation: 0,
            percentage: 0
        },
        {
            name: 'Bueno',
            punctuation: 0,
            percentage: 0
        },
        {
            name: 'Excelente',
            punctuation: 0,
            percentage: 0
        }

    ]);

    const [csvData, setCsvData] = useState([
        ["Fecha", "Número de conversaciones", "Sin calificar", "Malo", "Regular", "Bueno", "Excelente"]
    ]);

    const [csvDataConsolidated, setCsvDataConsolidated] = useState([
        ["Número", "Servicio", "Promotor", "Fecha inicio", "Fecha llamada", "Fecha cierre", "Duración en cola", "Duración de atención"]
    ]);

    const [currentAgent, setCurrentAgent] = useState({
        ref: null,
        name: "",
        email: "",
        phone: "",
        password: ""
    });

    useEffect(() => {
        // Cargamos los asesores
        getAsesors(window.localStorage.getItem("say-bussine")).then((res) => {
            console.log(res);
            setAgents(res);
        }).catch((err) => {
            console.log(err);
        })

    }, [])

    useEffect(() => {
        var ba = 0;
        for (var i = 0; i < average.length; i++) {
            ba += average[i].punctuation
        }
        setTotal(ba)
    }, [average])


    const diffDate = (date1, date2) => {
        var diff = new DateDiff(new Date(date1), new Date(date2));
        return diff.minutes();
    }


    return (
        <EnvApp>
            <div style={{
                display: "grid",
                gridTemplateColumns: "auto",
                gridTemplateRows: "100px auto",
                background: "#fff",
                width: "100%",
                height: "100%",
                overflow: "auto",
                borderRadius: "10px 0px 0px 10px"
            }}>

                <Header />

                <div style={{
                    padding: "20px 50px 20px 50px"
                }}>

                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "280px auto",
                        gridTemplateRows: "auto",
                        height: "100%"
                    }}>
                        <div style={{
                            height: "100%"
                        }}>

                            <div style={{
                                display: "flex",
                                alignItems: "center"
                            }}>

                                <input type="text" value={search} style={{
                                    background: "#F2F7F8",
                                    border: "0px",
                                    height: "50px"
                                }} className="form-control shadow-none" placeholder="Escribe para buscar" />

                                <button className="btn btn-primary shadow-none" style={{
                                    marginLeft: "5px",
                                    height: "50px",
                                    borderRadius: "15px",
                                    background: "rgb(8,55,155)",
                                    background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)"
                                }}>
                                    <SearchIcon style={{
                                        // width: "20px",
                                        // height: "20px",
                                        fill: "#fff"
                                    }} />
                                </button>

                            </div>

                            <br />

                            {
                                (agents.length === 0) && <div style={{
                                    textAlign: "center"
                                }}>
                                    <div style={{
                                        fontSize: "18px",
                                        fontWeight: "700"
                                    }}></div>

                                </div>
                            }

                            <div onClick={
                                () => {
                                    setGeneral(true);
                                    setCurrentAgent({
                                        ref: null,
                                        name: "",
                                        email: "",
                                        phone: "",
                                        password: ""
                                    })
                                }
                            } style={{
                                marginTop: "10px",
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                background: (general) ? "#D9E2F1" : "transparent",
                                padding: "10px",
                                borderRadius: "10px"
                            }}>
                                <div>
                                    <ListIcon style={{
                                        width: "55px",
                                        height: "55px",
                                        borderRadius: "50%",
                                        fill: (general) ? '#08379B' : '#000'
                                    }} />
                                </div>
                                <div style={{
                                    width: "100%",
                                    marginLeft: "5px"
                                }}><div
                                    style={{
                                        fontWeight: "500"
                                    }}>
                                        Reportes generales</div>
                                    <div>  ...</div>

                                </div>


                            </div>

                            {
                                (agents.length === 0) && <div>
                                    <div style={{
                                        fontSize: "18px",
                                        fontWeight: "700",
                                        alignItems: "center",
                                        marginLeft: "80px"
                                    }}> <ResultsIcon style={{
                                        width: "40px",
                                        height: "40px",
                                        marginLeft: "40px",
                                        fill: "#fff"
                                    }} /></div>
                                    <div style={{
                                        fontSize: "18px",
                                        alignItems: "center",
                                        marginLeft: "80px"
                                    }}>Sin resultados</div>

                                </div>
                            }


                            {
                                agents.map((Agent, AgentIndex) =>
                                    <>
                                        <div key={AgentIndex} style={{
                                            marginTop: "10px",
                                            display: "flex",
                                            alignItems: "center",
                                            cursor: "pointer",
                                            background: (currentAgent.ref === Agent.ref) ? "#D9E2F1" : "transparent",
                                            padding: "10px",
                                            borderRadius: "10px"
                                        }} onClick={
                                            () => {
                                                setCurrentAgent(Agent)
                                                setGeneral(false);
                                            }
                                        }>
                                            <div>
                                                <UserIcon style={{
                                                    width: "55px",
                                                    height: "55px",
                                                    borderRadius: "50%",
                                                    fill: (currentAgent.ref === Agent.ref) ? '#08379B' : '#000'
                                                }} />
                                            </div>
                                            <div style={{
                                                width: "100%",
                                                marginLeft: "5px"
                                            }}>
                                                <div style={{
                                                    fontWeight: "700",
                                                    color: "#08379B"
                                                }}>{Agent.name}</div>
                                                <div>
                                                    <div>
                                                        <span><span>7.1</span> / 10</span>
                                                    </div>
                                                    <div style={{
                                                        width: "53px",
                                                        height: "3px",
                                                        background: "#E8EBF0"
                                                    }}>
                                                        <div style={{
                                                            width: "80%",
                                                            height: "100%",
                                                            background: "#AFE1DA"
                                                        }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }

                        </div>

                        {
                            (currentIntegration.name === null && currentIntegration.service === null) && <div style={{
                                padding: "0px 50px 0px 50px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                                textAlign: "center"
                            }}>
                                <div style={{
                                    maxWidth: "500px"
                                }}>
                                    <div>
                                        <img src={SenderId} style={{
                                            width: "200px"
                                        }} />
                                    </div>
                                    <div style={{
                                        fontSize: "30px",
                                        fontWeight: "700"
                                    }}>Seleccione un asesor</div>
                                    <div style={{
                                        marginTop: "10px"
                                    }}>Seleccione una asesor, para poder consultar sus métricas</div>
                                </div>
                            </div>
                        }


                        <div style={{
                            padding: "0px 20px 20px 20px"
                        }}>
                            {
                                (currentAgent.ref === null && !general) && <div style={{
                                    textAlign: "center"
                                }}>
                                    <div style={{
                                        fontWeight: "700",


                                    }}>  <MetricIcon style={{
                                        maxWidth: "700px",
                                        fill: "#fff"
                                    }} /></div>
                                    <div>
                                        <div style={{
                                            fontSize: "25px",
                                            fontWeight: "500",

                                        }}> Selecciona un agente</div>
                                        <div> Selecciona un agente para consultar tus métricas</div>
                                    </div>
                                </div>
                            }

                            {
                                (general) && <>
                                    <h2>Métricas generales</h2>
                                    <div>...</div>

                                    <br />

                                    <div>

                                        <div style={{
                                            display: "grid",
                                            gridTemplateColumns: "auto 800px",
                                            gridTemplateRows: "auto"
                                        }}>
                                            <div style={{
                                                padding: "5px",
                                                fontSize: "20px",
                                                fontWeight: "700",
                                                placeSelf: "center"
                                            }}></div>
                                            <div style={{
                                                width: "200px",
                                                display: "grid",
                                                gridTemplateColumns: "300px 170px 170px 100px 100px",
                                                gridTemplateRows: "auto"
                                            }}>
                                                <div style={{
                                                    padding: "5px",
                                                    marginRight: "10px"
                                                }}>
                                                    <select value={agent} onChange={
                                                        (e) => {
                                                            setAgent(e.target.value);
                                                        }
                                                    } class="form-select form-select-lg mb-3">
                                                        <option value="">Todos los agentes</option>
                                                        {
                                                            agents.map((agent) => <option value={agent.ref}>{agent.name}</option>)
                                                        }

                                                    </select>
                                                </div>

                                                <div style={{
                                                    padding: "5px",
                                                    marginRight: "10px"
                                                }}>
                                                    <input type="date" value={dateGeneral} onChange={
                                                        (e) => {
                                                            setDateGeneral(e.target.value);
                                                        }
                                                    } className="w-100 shadow rounded" />
                                                </div>

                                                <div style={{
                                                    padding: "5px"
                                                }}>

                                                    <input type="date" value={dateEndGeneral} onChange={
                                                        (e) => {
                                                            setDateEndGeneral(e.target.value);
                                                        }
                                                    } className="w-100 shadow rounded" />
                                                </div>

                                                <div style={{
                                                    placeSelf: "center",
                                                    padding: "5px"
                                                }}>
                                                    <button className="btn btn-primary"
                                                        style={{
                                                            background: "rgb(8,55,155)",
                                                            background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)",
                                                            borderRadius: "15px",
                                                            height: "50px",
                                                            width: "50px"


                                                        }}
                                                        onClick={
                                                            () => {
                                                                // dateGeneral, dateEndGeneral

                                                                getConsolidated(dateGeneral, dateEndGeneral, agent).then((res) => {
                                                                    console.log("a----------------");
                                                                    console.log(res);

                                                                    for (let i = 0; i < res.length; i++) {
                                                                        const re = res[i];

                                                                        setCsvDataConsolidated(prev => [...prev, [
                                                                            re.jid.replace("@s.whatsapp.net", ""),
                                                                            "WhatsApp",
                                                                            re.agent,
                                                                            moment(new Date(re.createdAt)).format("DD-MM-YYYY HH:mm:ss"),
                                                                            moment(new Date(re.takeTime)).format("DD-MM-YYYY HH:mm:ss"),
                                                                            `${diffDate(re.takeTime, re.createdAt)} min`,
                                                                            `${diffDate(re.closingTime, re.takeTime)} min`
                                                                        ]]);

                                                                    }


                                                                    setChats(res)

                                                                })


                                                            }

                                                        }><SearchIcon style={{
                                                            width: "150%",
                                                            height: "150px",
                                                            fill: "#fff",
                                                            marginLeft: "-6.20px",
                                                            marginTop: "-55px"
                                                        }} />
                                                    </button>
                                                </div>

                                                <div style={{
                                                    placeSelf: "center",
                                                    padding: "5px"
                                                }}>
                                                    <CSVLink data={csvDataConsolidated} filename={"Generales"} className="btn btn-primary" style={{
                                                        background: "rgb(8,55,155)",
                                                        background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)",
                                                        marginRight: "5px",
                                                        padding: "9px",
                                                        marginTop: "-5px",
                                                        borderRadius: "15px",
                                                        height: "50px",
                                                        width: "50px"

                                                    }}>Ex</CSVLink>
                                                </div>

                                            </div>
                                        </div>

                                        <div style={{
                                            marginTop: "20px"
                                        }}>
                                            <div className="table-responsive" class="shadow p-3 mb-5 bg-body rounded">
                                                <table style={{
                                                    width: "100%",
                                                    border: "1px solid #c3c3c3"
                                                }}>
                                                    <thead>
                                                        <th style={{
                                                            padding: "10px",
                                                            border: "1px solid #c3c3c3"
                                                        }}>Número</th>
                                                        <th style={{
                                                            padding: "10px",
                                                            border: "1px solid #c3c3c3"
                                                        }}>Servicio</th>
                                                        <th style={{
                                                            padding: "10px",
                                                            border: "1px solid #c3c3c3"
                                                        }}>Promotor</th>
                                                        <th style={{
                                                            padding: "10px",
                                                            border: "1px solid #c3c3c3"
                                                        }}>Fecha inicio</th>
                                                        <th style={{
                                                            padding: "10px",
                                                            border: "1px solid #c3c3c3"
                                                        }}>Fecha llamada</th>
                                                        <th style={{
                                                            padding: "10px",
                                                            border: "1px solid #c3c3c3"
                                                        }}>Fecha cierre</th>
                                                        <th style={{
                                                            padding: "10px",
                                                            border: "1px solid #c3c3c3"
                                                        }}>Duración en cola</th>
                                                        <th style={{
                                                            padding: "10px",
                                                            border: "1px solid #c3c3c3"
                                                        }}>Duración de atención</th>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            chats.map((ca, caIndex) =>
                                                                <tr key={caIndex}>
                                                                    <td style={{
                                                                        padding: "10px",
                                                                        fontWeight: "700",
                                                                        border: "1px solid #c3c3c3"
                                                                    }}>
                                                                        {ca.jid.replace("@s.whatsapp.net", "")}
                                                                    </td>
                                                                    <td style={{
                                                                        padding: "10px",
                                                                        border: "1px solid #c3c3c3"
                                                                    }}>
                                                                        WhatsApp
                                                                    </td>
                                                                    <td style={{
                                                                        padding: "10px",
                                                                        border: "1px solid #c3c3c3"
                                                                    }}>
                                                                        {ca.agent}
                                                                    </td>
                                                                    <td style={{
                                                                        padding: "10px",
                                                                        border: "1px solid #c3c3c3"
                                                                    }}>
                                                                        {moment(new Date(ca.createdAt)).format("DD-MM-YYYY HH:mm:ss")}
                                                                    </td>
                                                                    <td style={{
                                                                        padding: "10px",
                                                                        border: "1px solid #c3c3c3"
                                                                    }}>
                                                                        {moment(new Date(ca.takeTime)).format("DD-MM-YYYY HH:mm:ss")}
                                                                    </td>
                                                                    <td style={{
                                                                        padding: "10px",
                                                                        border: "1px solid #c3c3c3"
                                                                    }}>
                                                                        {moment(new Date(ca.closingTime)).format("DD-MM-YYYY HH:mm:ss")}
                                                                    </td>
                                                                    <td style={{
                                                                        padding: "10px",
                                                                        border: "1px solid #c3c3c3"
                                                                    }}>
                                                                        {(diffDate(ca.takeTime, ca.createdAt) >= 0) ? diffDate(ca.takeTime, ca.createdAt) : diffDate(ca.takeTime, ca.createdAt) * -1} min
                                                                    </td>
                                                                    <td style={{
                                                                        padding: "10px",
                                                                        border: "1px solid #c3c3c3"
                                                                    }}>
                                                                        {(diffDate(ca.closingTime, ca.takeTime) >= 0) ? diffDate(ca.closingTime, ca.takeTime) : diffDate(ca.closingTime, ca.takeTime) * -1} min
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }


                                                    </tbody>
                                                </table>
                                            </div>


                                        </div>
                                    </div>

                                </>
                            }

                            {
                                (currentAgent.ref != null) && <>

                                    <h2>Métricas de {currentAgent.name}</h2>

                                    <div>...</div>

                                    <br />

                                    <div>
                                        <div style={{
                                            display: "grid",
                                            gridTemplateColumns: "auto 440px",
                                            gridTemplateRows: "auto"
                                        }}>
                                            <div style={{
                                                padding: "5px",
                                                fontSize: "20px",
                                                fontWeight: "700",
                                                placeSelf: "center"
                                            }}>Promedio de calificaciones</div>
                                            <div style={{
                                                width: "200px",
                                                display: "grid",
                                                gridTemplateColumns: "170px 170px 100px",
                                                gridTemplateRows: "auto"
                                            }}>
                                                <div style={{
                                                    padding: "5px",
                                                    marginRight: "10px"
                                                }}>
                                                    <input type="date" value={dateStartAverage} onChange={
                                                        (e) => {
                                                            setDateStartAverage(e.target.value);
                                                        }
                                                    } className="w-100 shadow rounded" />
                                                </div>
                                                <div style={{
                                                    padding: "5px"
                                                }}>
                                                    <input type="date" value={dateEndAverage} onChange={
                                                        (e) => {
                                                            setDateEndAverage(e.target.value);
                                                        }
                                                    } className="w-100 shadow rounded" />
                                                </div>
                                                <div style={{
                                                    placeSelf: "center",
                                                    padding: "5px"
                                                }}>
                                                    <button className="btn btn-primary"
                                                        style={{
                                                            background: "rgb(8,55,155)",
                                                            background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)",
                                                            borderRadius: "15px",
                                                            height: "50px",
                                                            width: "50px"


                                                        }}
                                                        onClick={
                                                            () => {

                                                                var ds = dateStartAverage;

                                                                var de = dateEndAverage;

                                                                var key = "general";

                                                                if (currentAgent.ref != null) {
                                                                    key = currentAgent.ref;
                                                                }



                                                                // Hacemos la consulta
                                                                axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/metrics/average/${key}`, {
                                                                    params: {
                                                                        ds,
                                                                        de
                                                                    }
                                                                })
                                                                    .then((response) => {
                                                                        if (response.status === 200) {
                                                                            console.log(response.data);
                                                                            setAverage(response.data);
                                                                        } else {
                                                                            toast.error("Ocurrió un problema al intentar recuperar las métricas.");
                                                                        }
                                                                    }, (error) => {
                                                                        toast.error(error.response);
                                                                    });

                                                            }

                                                        }><SearchIcon style={{
                                                            width: "150%",
                                                            height: "150px",
                                                            fill: "#fff",
                                                            marginLeft: "-6.20px",
                                                            marginTop: "-55px"
                                                        }} />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{
                                            marginTop: "20px"
                                        }}>
                                            <div className="table-responsive" class="shadow p-3 mb-5 bg-body rounded">
                                                <table style={{
                                                    width: "100%"
                                                }}>
                                                    <tr>
                                                        <th style={{
                                                            width: "200px",
                                                            padding: "10px",
                                                            border: "1px solid #c3c3c3"
                                                        }}>Rendimiento</th>
                                                        <th style={{
                                                            padding: "10px",
                                                            border: "1px solid #c3c3c3"
                                                        }}>Puntuación</th>
                                                        <th style={{
                                                            padding: "10px",
                                                            border: "1px solid #c3c3c3"
                                                        }}>Porcentaje</th>
                                                    </tr>
                                                    {
                                                        average.map((ave, aveIndex) =>
                                                            <tr key={aveIndex}>
                                                                <td style={{
                                                                    width: "200px",
                                                                    padding: "10px",
                                                                    fontWeight: "700",
                                                                    border: "1px solid #c3c3c3"
                                                                }}>
                                                                    {
                                                                        (aveIndex === 0) && <img src={None} style={{
                                                                            width: "30px",
                                                                            marginRight: "10px"
                                                                        }} />
                                                                    }

                                                                    {
                                                                        (aveIndex === 1) && <img src={Mala} style={{
                                                                            width: "30px",
                                                                            marginRight: "10px"
                                                                        }} />
                                                                    }

                                                                    {
                                                                        (aveIndex === 2) && <img src={Regular} style={{
                                                                            width: "30px",
                                                                            marginRight: "10px"
                                                                        }} />
                                                                    }

                                                                    {
                                                                        (aveIndex === 3) && <img src={Bueno} style={{
                                                                            width: "30px",
                                                                            marginRight: "10px"
                                                                        }} />
                                                                    }

                                                                    {
                                                                        (aveIndex === 4) && <img src={Excelente} style={{
                                                                            width: "30px",
                                                                            marginRight: "10px"
                                                                        }} />
                                                                    }


                                                                    {ave.name}</td>
                                                                <td style={{
                                                                    padding: "10px",
                                                                    border: "1px solid #c3c3c3"
                                                                }}>{ave.punctuation}</td>
                                                                <td style={{
                                                                    padding: "10px",
                                                                    border: "1px solid #c3c3c3"
                                                                }}>{ave.percentage}%</td>
                                                            </tr>
                                                        )
                                                    }

                                                    <tr>
                                                        <th style={{
                                                            padding: "10px",
                                                            border: "1px solid #c3c3c3",
                                                            textAlign: "center"
                                                        }}>
                                                            Total
                                                        </th>
                                                        <td style={{
                                                            padding: "10px",
                                                            border: "1px solid #c3c3c3"
                                                        }}>{total}</td>
                                                        <td style={{
                                                            padding: "10px",
                                                            border: "1px solid #c3c3c3"
                                                        }}> </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <br />

                                    <div>
                                        <div style={{
                                            display: "grid",
                                            gridTemplateColumns: "auto 510px",
                                            gridTemplateRows: "auto"
                                        }}>
                                            <div style={{
                                                padding: "5px",
                                                fontSize: "20px",
                                                fontWeight: "700",
                                                placeSelf: "center"
                                            }}>Consecutivo de chats atendidos</div>

                                            <div style={{
                                                width: "200px",
                                                display: "grid",
                                                gridTemplateColumns: "170px 170px 200px 100px",
                                                gridTemplateRows: "auto"
                                            }}>
                                                <div style={{
                                                    padding: "5px"
                                                }}>
                                                    <input type="date" value={dateStartConsecutive} onChange={
                                                        (e) => {
                                                            setDateStartConsecutive(e.target.value);
                                                        }
                                                    } className="w-100 shadow rounded" />
                                                </div>
                                                <div style={{
                                                    padding: "5px"
                                                }}>
                                                    <input type="date" value={dateEndConsecutive} onChange={
                                                        (e) => {
                                                            setDateEndConsecutive(e.target.value);
                                                        }
                                                    } className="w-100 shadow rounded" />
                                                </div>
                                                <div style={{
                                                    placeSelf: "center",
                                                    padding: "5px"

                                                }}>

                                                    <CSVLink data={csvData} filename={currentAgent.name + " " + dateStartConsecutive + " - " + dateEndConsecutive} className="btn btn-primary" style={{
                                                        background: "rgb(8,55,155)",
                                                        background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)",
                                                        marginRight: "5px",
                                                        padding: "9px",
                                                        marginTop: "-5px",
                                                        borderRadius: "15px",
                                                        height: "50px",
                                                        width: "50px"

                                                    }}><ImportIcon style={{
                                                        maxWidth: "700px",
                                                        fill: "#fff",
                                                        width: "150%",
                                                        marginLeft: "-7.50px",
                                                        marginTop: "-1px"
                                                    }} /></CSVLink>

                                                    <button className="btn btn-primary"
                                                        style={{
                                                            background: "rgb(8,55,155)",
                                                            background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)",
                                                            borderRadius: "15px",
                                                            height: "50px",
                                                            width: "50px"

                                                        }}
                                                        onClick={
                                                            () => {

                                                                var ds = dateStartConsecutive;

                                                                var de = dateEndConsecutive;

                                                                if (currentAgent.ref != null) {
                                                                    // Hacemos la consulta
                                                                    axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/metrics/consecutiveAgent/${currentAgent.ref}`, {
                                                                        params: {
                                                                            ds,
                                                                            de
                                                                        }
                                                                    })
                                                                        .then((response) => {
                                                                            if (response.status === 200) {

                                                                                // Unimos el vector

                                                                                console.log("response.data");
                                                                                console.log(response.data);

                                                                                for (let i = 0; i < response.data.length; i++) {

                                                                                    const da = response.data[i];

                                                                                    setCsvData(prev => [...prev, [
                                                                                        da.d,
                                                                                        da.n,
                                                                                        da.none,
                                                                                        da.bad,
                                                                                        da.regular,
                                                                                        da.good,
                                                                                        da.excellent
                                                                                    ]]);

                                                                                }

                                                                                setConsecutiveAgent(response.data)

                                                                            } else {
                                                                                toast.error("Ocurrió un problema al intentar recuperar las métricas.")
                                                                            }
                                                                        }, (error) => {
                                                                            toast.error(error.response)
                                                                        });
                                                                } else {
                                                                    toast.error("Seleccione un agente primero")
                                                                }
                                                            }
                                                        }><SearchIcon style={{
                                                            width: "150%",
                                                            height: "150px",
                                                            fill: "#fff",
                                                            marginLeft: "-5.20px",
                                                            marginTop: "-55px"
                                                        }} /></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{
                                            marginTop: "20px"
                                        }}>
                                            <div className="table-responsive" class="shadow p-3 mb-5 bg-body rounded">
                                                <table style={{
                                                    width: "100%",
                                                    border: "1px solid #c3c3c3"
                                                }}>
                                                    <thead>
                                                        <td style={{
                                                            padding: "10px",
                                                            border: "1px solid #c3c3c3"
                                                        }}>Fecha</td>
                                                        <td style={{
                                                            padding: "10px",
                                                            border: "1px solid #c3c3c3"
                                                        }}>Número de conversaciones</td>
                                                        <td style={{
                                                            padding: "10px",
                                                            fontSize: "12px",
                                                            border: "1px solid #c3c3c3",
                                                            textAlign: "center"
                                                        }}>
                                                            <img src={None} style={{ width: "30px" }} />
                                                            <div>Sin calificar</div>
                                                        </td>
                                                        <td style={{
                                                            padding: "10px",
                                                            fontSize: "12px",
                                                            border: "1px solid #c3c3c3",
                                                            textAlign: "center"
                                                        }}>
                                                            <img src={Mala} style={{ width: "30px" }} />
                                                            <div>Malo</div>
                                                        </td>
                                                        <td style={{
                                                            padding: "10px",
                                                            fontSize: "12px",
                                                            border: "1px solid #c3c3c3",
                                                            textAlign: "center"
                                                        }}>
                                                            <img src={Regular} style={{ width: "30px" }} />
                                                            <div>Regular</div>
                                                        </td>
                                                        <td style={{
                                                            padding: "10px",
                                                            fontSize: "12px",
                                                            border: "1px solid #c3c3c3",
                                                            textAlign: "center"
                                                        }}>
                                                            <img src={Bueno} style={{ width: "30px" }} />
                                                            <div>Bueno</div>
                                                        </td>
                                                        <td style={{
                                                            padding: "10px",
                                                            fontSize: "12px",
                                                            border: "1px solid #c3c3c3",
                                                            textAlign: "center"
                                                        }}>
                                                            <img src={Excelente} style={{ width: "30px" }} />
                                                            <div>Excelente</div>
                                                        </td>


                                                    </thead>
                                                    <tbody>

                                                        {
                                                            consecutiveAgent.map((ca, caIndex) =>
                                                                <tr key={caIndex}>
                                                                    <td style={{
                                                                        padding: "10px",
                                                                        fontWeight: "700",
                                                                        border: "1px solid #c3c3c3"
                                                                    }}>
                                                                        {ca.d}
                                                                    </td>
                                                                    <td style={{
                                                                        padding: "10px",
                                                                        border: "1px solid #c3c3c3"
                                                                    }}>{ca.n}</td>
                                                                    <td style={{
                                                                        padding: "10px",
                                                                        border: "1px solid #c3c3c3"
                                                                    }}>{ca.none}</td>
                                                                    <td style={{
                                                                        padding: "10px",
                                                                        border: "1px solid #c3c3c3"
                                                                    }}>{ca.bad}</td>
                                                                    <td style={{
                                                                        padding: "10px",
                                                                        border: "1px solid #c3c3c3"
                                                                    }}>{ca.regular}</td>
                                                                    <td style={{
                                                                        padding: "10px",
                                                                        border: "1px solid #c3c3c3"
                                                                    }}>{ca.good}</td>
                                                                    <td style={{
                                                                        padding: "10px",
                                                                        border: "1px solid #c3c3c3"
                                                                    }}>{ca.excellent}</td>
                                                                </tr>
                                                            )
                                                        }


                                                    </tbody>
                                                </table>
                                            </div>

                                            <br />

                                            <div>
                                                <div style={{
                                                    display: "grid",
                                                    gridTemplateColumns: "auto 510px",
                                                    gridTemplateRows: "auto"
                                                }}>
                                                    <div style={{
                                                        padding: "5px",
                                                        fontSize: "20px",
                                                        fontWeight: "700",
                                                        placeSelf: "center"
                                                    }}>Consecutivo de chats atendidos por el bot</div>

                                                    <div style={{
                                                        width: "200px",
                                                        display: "grid",
                                                        gridTemplateColumns: "170px 170px 200px 100px",
                                                        gridTemplateRows: "auto"
                                                    }}>
                                                        <div style={{
                                                            padding: "5px"
                                                        }}>
                                                            <input type="date" value={dateStartConsecutive} onChange={
                                                                (e) => {
                                                                    setDateStartConsecutive(e.target.value);
                                                                }
                                                            } className="w-100 shadow rounded" />
                                                        </div>
                                                        <div style={{
                                                            padding: "5px"
                                                        }}>
                                                            <input type="date" value={dateEndConsecutive} onChange={
                                                                (e) => {
                                                                    setDateEndConsecutive(e.target.value);
                                                                }
                                                            } className="w-100 shadow rounded" />
                                                        </div>
                                                        <div style={{
                                                            placeSelf: "center",
                                                            padding: "5px"
                                                        }}>

                                                            <CSVLink data={csvData} filename={currentAgent.name + " " + dateStartConsecutive + " - " + dateEndConsecutive} className="btn btn-primary" style={{
                                                                background: "rgb(8,55,155)",
                                                                background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)",
                                                                marginRight: "5px",
                                                                padding: "9px",
                                                                borderRadius: "15px",
                                                                height: "50px",
                                                                width: "50px",
                                                                marginTop: "-5px"
                                                            }}><ImportIcon style={{
                                                                width: "150%",

                                                                fill: "#fff",
                                                                marginLeft: "-7.20px",
                                                                marginTop: "-2px"
                                                            }} /></CSVLink>

                                                            <button className="btn btn-primary"
                                                                style={{
                                                                    background: "rgb(8,55,155)",
                                                                    background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)",
                                                                    borderRadius: "15px",
                                                                    height: "50px",
                                                                    width: "50px",
                                                                    marginTop: "-10px"
                                                                }}
                                                                onClick={
                                                                    () => {

                                                                        var ds = dateStartConsecutive;

                                                                        var de = dateEndConsecutive;


                                                                        // Hacemos la consulta
                                                                        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/metrics/consecutiveBot/${window.localStorage.getItem("say-bussine")}`, {
                                                                            params: {
                                                                                ds,
                                                                                de
                                                                            }
                                                                        })
                                                                            .then((response) => {
                                                                                if (response.status === 200) {

                                                                                    // Unimos el vector

                                                                                    console.log("response.data");
                                                                                    console.log(response.data);

                                                                                    /*for (let i = 0; i < response.data.length; i++) {
    
                                                                                        const da = response.data[i];
    
                                                                                        setCsvData(prev => [...prev, [
                                                                                            da.d,
                                                                                            da.n,
                                                                                            da.none,
                                                                                            da.bad,
                                                                                            da.regular,
                                                                                            da.good,
                                                                                            da.excellent
                                                                                        ]]);
    
                                                                                    }
    
                                                                                    setConsecutiveBot(response.data)*/

                                                                                } else {
                                                                                    toast.error("Ocurrió un problema al intentar recuperar las métricas.")
                                                                                }
                                                                            }, (error) => {
                                                                                toast.error(error.response)
                                                                            });

                                                                    }
                                                                }><SearchIcon style={{
                                                                    width: "150%",
                                                                    height: "150px",
                                                                    fill: "#fff",
                                                                    marginLeft: "-5.20px",
                                                                    marginTop: "-55px"
                                                                }} />Buscar</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    marginTop: "20px"
                                                }}>
                                                    <div className="table-responsive" class="shadow p-3 mb-5 bg-body rounded">
                                                        <table style={{
                                                            width: "100%",
                                                            border: "1px solid #c3c3c3"
                                                        }}>
                                                            <thead>
                                                                <td style={{
                                                                    padding: "10px",
                                                                    border: "1px solid #c3c3c3"
                                                                }}>Fecha</td>
                                                                <td style={{
                                                                    padding: "10px",
                                                                    border: "1px solid #c3c3c3"
                                                                }}>Número de conversaciones</td>

                                                            </thead>
                                                            <tbody>

                                                                {
                                                                    consecutiveAgent.map((ca, caIndex) =>
                                                                        <tr key={caIndex}>
                                                                            <td style={{
                                                                                padding: "10px",
                                                                                fontWeight: "700",
                                                                                border: "1px solid #c3c3c3"
                                                                            }}>
                                                                                {ca.d}
                                                                            </td>
                                                                            <td style={{
                                                                                padding: "10px",
                                                                                border: "1px solid #c3c3c3"
                                                                            }}>{ca.n}</td>
                                                                            <td style={{
                                                                                padding: "10px",
                                                                                border: "1px solid #c3c3c3"
                                                                            }}>{ca.none}</td>
                                                                            <td style={{
                                                                                padding: "10px",
                                                                                border: "1px solid #c3c3c3"
                                                                            }}>{ca.bad}</td>
                                                                            <td style={{
                                                                                padding: "10px",
                                                                                border: "1px solid #c3c3c3"
                                                                            }}>{ca.regular}</td>
                                                                            <td style={{
                                                                                padding: "10px",
                                                                                border: "1px solid #c3c3c3"
                                                                            }}>{ca.good}</td>
                                                                            <td style={{
                                                                                padding: "10px",
                                                                                border: "1px solid #c3c3c3"
                                                                            }}>{ca.excellent}</td>
                                                                        </tr>
                                                                    )
                                                                }


                                                            </tbody>
                                                        </table>
                                                    </div>


                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </>


                            }

                        </div>


                    </div>

                </div>

            </div>
        </EnvApp >
    );
}

export default Metrics;