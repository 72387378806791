import React, { useState, useEffect, useContext } from 'react';

import { AppContext } from '../../../contexts/AppProvider';

import { ReactComponent as Send } from '../../../assets/icons/paperplane1.svg';

import SenderIdExplorer from './SenderIdExplorer';

import { send } from '../../../services/Channel';


function SenderSentinel(props) {

    const { senderIds, setSenderIds, setChatSender } = useContext(AppContext);

    const [t, setT] = useState([]);

    const [nSenders, setNSenders] = useState([]);

    const [oSenders, setOSenders] = useState([]);


    const phonenumber = (number) => {

        var phoneno = /^\(?([0-9]{12})$/;
        if (number.match(phoneno)) {
            return true;
            //true
        }
        else {
            return false;
            //false
        }
    }

    useEffect(() => {
        console.log("Los sender cambiaron!!!!");

        var temosenders = []

        var temnsenders = []

        for (let i = 0; i < senderIds.length; i++) {
            const sender = senderIds[i];

            if (sender.channel === "WHATSAPP") {
                temnsenders.push(sender.name);
            }

            if (sender.status.code === "CONNECTED") {
                var a = temosenders.findIndex((oSender) => oSender === sender.name);
                if (a === -1) {
                    temosenders.push(sender.name)
                }
            }

        }

        setOSenders(temosenders);

        setNSenders(temnsenders);

    }, [senderIds])

    return (
        <div style={{
            display: "grid",
            gridTemplateColumns: "80% 20%"
        }}>
            <div
                style={{
                    padding: "10px",
                    fontWeight: "700",
                    background: (oSenders.length === nSenders.length) ? "#e7f8e6" : "#ffede9",
                    color: (oSenders.length === nSenders.length) ? "#64cc6d" : "#fb4142"
                }}
            >
                {nSenders.length} canales de whatsapp {oSenders.length} en linea
            </div>

                <SenderIdExplorer text={<Send style={{
                        width: "30px",
                        height: "30px",
                        fill: "#000"
                    }} />} onChange={
                    (sender) => {
                        //(sender.ref);
                        const number1 = prompt ('digite su numero de teléfono de la siguiente manera: \n 573214567890');
                            phonenumber(number1);
                            console.log(number1);

                            //si es true 
                            if(phonenumber(number1) == true){
                                const prueba = number1.substr(0,2);
                                console.log(prueba);
                                //si empieza con 57 va a enviar info al endpoint
                                if(prueba == "57"){
                                    //alert(SenderId.ref);
                                    send({
                                        "number": number1,
                                        "channel": sender.ref,
                                        "message": "Este es un mensaje de prueba enviado desde sayform"
                                    });
                                }else{
                                    //sino alert que el telefono esta mal
                                    alert(`${number1} no es un número de teléfono válido`)
                                }
                                //si es falso va a alert que telefono esta mal
                            }else{
                                alert(`${number1} no es un número de teléfono válido`)
                            }
                    }
                } />
        </div>
    );
}

export default SenderSentinel;

