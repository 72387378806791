import React, { useState, useEffect, useRef, useContext } from 'react';

import Bubble from './Bubble';

import BubbleMe from './BubbleMe';

import { getMessages, getNumbersChats } from '../../../services/Chat';

import { AppContext } from '../../../contexts/AppProvider';

import { SocketContext } from '../../../contexts/SocketProvider';

import Loading from '../../../assets/loading.gif';

function Timeline() {

    const { chat, setLine, line, NewMessage, IsSending } = useContext(AppContext);

    const { setNumAssigned, setNumPending, setNumClose } = useContext(SocketContext);

    useEffect(() => {
        if (NewMessage != null) {
            // Aqui listamos los mensajes deel chat actual si el ji d es el mismo que

            if (chat.jid === NewMessage.key.remoteJid) {
                getMessages(chat.ref).then((response) => {
                    // Recorremos cada uno de los mensajes del chat

                    // Verificamos si el jid es el del chat que esta aierto

                    /*var index = line.findIndex((li)=>li.id === )
                    var copy = [...line];
                    setLine(copy);*/

                    setLine(response.list);

                }).catch((error) => {
                    console.log("error");
                    console.log(error);
                })
            }

            getNumbersChats(window.localStorage.getItem("say-bussine"), window.localStorage.getItem("say-ref")).then((response) => {
                setNumAssigned(response.assigned);
                setNumPending(response.pending);
                setNumClose(response.closed);
            }).catch((error) => {
                console.log("error");
                console.log(error);
            })

        }
    }, [NewMessage])

    return (
        <>

            {
                (IsSending) && <div style={{
                    position: "fixed",
                    borderRadius: "10px",
                    padding: "7px 10px 7px 7px",
                    color: "#fff",
                    background: "red",
                    top: "150px"
                }}>
                    <img src={Loading} style={{
                        width: "20px"
                    }} /> Enviando
                </div>
            }

            {
                (chat.jid !== "") && <>

                    <div>
                        {
                            line.map((Line, LineIndex) => {
                                return (
                                    <>
                                        <div key={LineIndex}>

                                            {
                                                (!Line.fromMe) && <Bubble Line={Line} />
                                            }

                                            {
                                                (Line.fromMe) && <BubbleMe Line={Line} />
                                            }

                                        </div>
                                    </>
                                );
                            })
                        }
                    </div>

                </>
            }
        </>
    );
}

export default Timeline;