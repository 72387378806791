import axios from 'axios';

import { PHAT_DEV, PHAT_PROD, DEV, URL_SOCKET_DEV, URL_SOCKET_PROD } from '../config';

export const connect = (ref) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/connect/` + ref, {})
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data)
                } else {
                    reject("Ocurrio un problema al intentar conectar, intente nuevamente.");
                }
            }, (err) => {
                reject(err)
            });
    })
}

export const asociar = (ref) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/asociar/` + ref, {})
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data)
                } else {
                    reject("Ocurrio un problema al intentar asociar, intente nuevamente.");
                }
            }, (err) => {
                reject(err)
            });
    })
}

export const getStatus = (ref) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/status/` + ref, {})
            .then((response) => {
                resolve(response.data);
            }, (error) => {
                reject(error.message);
            });
    })
}

export const force = (ref) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/force/` + ref, {})
            .then((response) => {
                resolve(response.data);
            }, (error) => {
                reject(error.message);
            });
    })
}

export const disconect = (ref) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/disconect/` + ref, {})
            .then((response) => {
                resolve(response.data);
            }, (error) => {
                reject(error.message);
            });
    })
}



























export const getQr = (payload) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? URL_SOCKET_DEV : URL_SOCKET_PROD}/get-qr/`, payload)
            .then((response) => {
                resolve(response.data);
            }, (error) => {
                reject(error.message);
            });
    })
}