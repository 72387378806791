import React, { useState, useEffect, useContext, useRef } from 'react';

import {
    useParams
} from "react-router-dom";

import axios from "axios";

import md5 from 'md5';

import { WidgetContext } from '../../contexts/WidgetProvider';

import toast from 'react-hot-toast';

import { DEV, PHAT_DEV, PHAT_PROD } from '../../config';

function Control() {

    let { iid, sender } = useParams();

    const { emit, status, initSocket, page, setPage } = useContext(WidgetContext);

    const [message, setMessage] = useState('');

    const [jid, setJid] = useState(window.localStorage.getItem('widget-email')); // md5() + '@sayform.com'

    const [ref, setRef] = useState(window.localStorage.getItem('widget-ref'));

    const file = useRef(null);

    useEffect(() => {

        try {
            emit('hello', {
                iid,
                jid
            })
        } catch (error) {
            // Error porque no se inicio el socket
            try {
                if (initSocket()) {
                    // Saludamos de nuevo
                    emit('hello', {
                        iid,
                        jid
                    })
                }
            } catch (error) {
                toast.error("Ocurrio un error al conectar con el canal, intente nuevamente")
            }
        }

    }, [])

    const uploadFile = (file) => {
        //convert input to output 
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('file', file, file.name);
            axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/upload`, formData, {
                'Content-Type': `multipart/form-data;`
            })
                .then((response) => {
                    console.log(response);
                    if (response.status === 200) {
                        resolve(response.data)
                    } else {
                        console.log("Ocurrio un problema al intentar subir el archivo.");
                    }
                }, (error) => {
                    console.log(error.message);
                });
        })
    }

    const send_new_message = (payload) => {
        return new Promise((resolve, reject) => {
            axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/send-web-message`, payload)
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response.data)
                    } else {
                        reject("Ocurrió un problema al intentar enviar el mensaje.")
                    }
                }, (error) => {
                    reject(error.response.data)
                });
        })
    }

    return (
        <>

            {
                (status != 1) && <>
                    <input type="file" ref={file} onChange={
                        (e) => {
                            uploadFile(e.target.files[0]).then(async (res) => {
                                if (res.file != '') {
                                    // Enviamos el mensaje al servidor socket
                                    await send_new_message({
                                        ref,
                                        iid,
                                        jid,
                                        fromMe: true,
                                        sender,
                                        name: window.localStorage.getItem("widget-names") + " " + window.localStorage.getItem("widget-surnames"),
                                        rol: 'client',
                                        status: '',
                                        channel: 'WEB',
                                        message: {
                                            id: '',
                                            caption: '',
                                            message: res.file,
                                            mimetype: res.mimetype,
                                            mention: {
                                                id: '',
                                                message: '',
                                                caption: '',
                                                mimetype: ''
                                            }
                                        }
                                    }).catch((err) => {
                                        toast.error(err.message)
                                        window.localStorage.clear();
                                        setPage('login')
                                    })
                                }
                                file.current.value = '';
                            }).catch((err) => {
                                console.log(err);
                            })
                        }
                    } style={{
                        position: "absolute",
                        top: "-10000px",
                        left: "-10000px",
                        zIndex: "999999999",
                    }} accept="application/vnd.oasis.opendocument.presentation,
            application/vnd.oasis.opendocument.spreadsheet,
            application/vnd.oasis.opendocument.text,
            application/vnd.ms-powerpoint,
            application/pdf,
            application/msword,
            text/csv,
            application/vnd.ms-excel,
            application/msexcel,
            application/x-msexcel,
            application/x-ms-excel,
            application/x-excel,
            application/x-dos_ms_excel,
            application/xls,
            application/x-xls,
            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
            application/vnd.openxmlformats-officedocument.wordprocessingml.document,
            image/jpeg,
            image/png,
            image/webp" />

                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "auto 50px",
                        gridTemplateRows: "auto",
                        width: "100%"
                    }}>
                        <div>
                            <input type="text" placeholder="Enviar un mensaje..." value={message} style={{
                                outline: "none",
                                width: "100%",
                                height: "100%"
                            }} onChange={
                                (e) => {
                                    setMessage(e.target.value);
                                }
                            } onKeyPress={
                                async (event) => {

                                    if (event.key === 'Enter') {
                                        // Enviamos el nuevo mensaje

                                        // alert(chatSenderId);

                                        if (iid != '' && jid != '') {

                                            if (event.target.value != '') {
                                                var message = event.target.value;

                                                // Enviamos el mensaje a la ami, cuando el mensaje llega y se envia, la ami notifica al servidor de sayform para insertar el mensaje enviado por el asesor
                                                setMessage("");

                                                await send_new_message({
                                                    ref,
                                                    iid,
                                                    jid,
                                                    fromMe: true,
                                                    sender,
                                                    name: window.localStorage.getItem("widget-names") + " " + window.localStorage.getItem("widget-surnames"),
                                                    rol: 'client',
                                                    status: '',
                                                    channel: 'WEB',
                                                    message: {
                                                        id: '',
                                                        caption: '',
                                                        message,
                                                        mimetype: 'application/text',
                                                        mention: {
                                                            id: '',
                                                            message: '',
                                                            caption: '',
                                                            mimetype: '',
                                                        }
                                                    }
                                                }).catch((err) => {
                                                    console.log(err);
                                                    toast.error(err.message)

                                                    window.localStorage.clear();

                                                    setPage('login')

                                                })
                                            } else {
                                                toast.error("No puedes enviar un espacio vacio.")
                                            }

                                        }

                                        setMessage('')

                                    }
                                }
                            } />
                        </div>
                        <div>
                            <button style={{
                                width: "100%",
                                height: "100%"
                            }} onClick={
                                () => {
                                    file.current.click();
                                }
                            }><i className="las la-paperclip" style={{
                                fontSize: "30px"
                            }}></i></button>
                        </div>
                    </div>
                </>
            }

        </>
    );
}

export default Control;