import React, { useState, useEffect, useContext } from 'react';

import axios from 'axios';

import toast from 'react-hot-toast';

import { DEV, PHAT_DEV, PHAT_PROD } from '../config';

const QualifierBlock = (props) => {

    const [n, setN] = useState(1);

    const [d, setD] = useState("");

    useEffect(() => {

        setN(props.n);

        setD(props.d);

    }, [])

    return (
        <>
            <div style={{
                marginTop: "20px",
                marginBottom: "20px",
                borderRadius: "10px",
                padding: "20px"
            }}>

                <div style={{
                    textAlign: "center",
                    padding: "10px"
                }}>
                    {
                        (props.n === -1) && <>
                            Califica la conversación
                        </>
                    }

                    {
                        (props.n > -1) && <>
                            Ya calificaste esta conversación
                        </>
                    }

                </div>

                <div style={{
                    display: "flex"
                }}>
                    <div style={{
                        width: "100%",
                        textAlign: "center",
                        cursor: (props.n === -1) ? "pointer" : "default",
                        opacity: (n === 1) ? "1" : "0.5"
                    }} onClick={
                        () => {
                            if (props.n === -1) {
                                setN(1)
                            }
                        }
                    }>
                        <div><img style={{
                            width: "40px",
                            height: "40px"
                        }} src="https://cdn.cloudcitycolombia.com/mala.png" /></div>
                        <div style={{
                            fontWeight: (n === 1) ? "700" : "normal"
                        }}>Mala</div>
                    </div>
                    <div style={{
                        width: "100%",
                        textAlign: "center",
                        cursor: (props.n === -1) ? "pointer" : "default",
                        opacity: (n === 2) ? "1" : "0.5"
                    }} onClick={
                        () => {
                            if (props.n === -1) {
                                setN(2)
                            }
                        }
                    }>
                        <div><img style={{
                            width: "40px",
                            height: "40px"
                        }} src="https://cdn.cloudcitycolombia.com/aceptable.png" /></div>
                        <div style={{
                            fontWeight: (n === 2) ? "700" : "normal"
                        }}>Regular</div>
                    </div>
                    <div style={{
                        width: "100%",
                        textAlign: "center",
                        cursor: (props.n === -1) ? "pointer" : "default",
                        opacity: (n === 3) ? "1" : "0.5"
                    }} onClick={
                        () => {
                            if (props.n === -1) {
                                setN(3)
                            }
                        }
                    }>
                        <div><img style={{
                            width: "40px",
                            height: "40px"
                        }} src="https://cdn.cloudcitycolombia.com/excelente.png" /></div>
                        <div style={{
                            fontWeight: (n === 3) ? "700" : "normal"
                        }}>Bueno</div>
                    </div>
                    <div style={{
                        width: "100%",
                        textAlign: "center",
                        cursor: (props.n === -1) ? "pointer" : "default",
                        opacity: (n === 4) ? "1" : "0.5"
                    }} onClick={
                        () => {
                            if (props.n === -1) {
                                setN(4)
                            }
                        }
                    }>
                        <div><img style={{
                            width: "40px",
                            height: "40px"
                        }} src="https://cdn.cloudcitycolombia.com/increible.png" /></div>
                        <div style={{
                            fontWeight: (n === 4) ? "700" : "normal"
                        }}>Excelente</div>
                    </div>
                </div>

                <br />

                <div>

                    {
                        (props.n > -1) && <>
                            <div style={{
                                padding: "10px"
                            }}>
                                {d}
                            </div>
                        </>
                    }

                    {
                        (props.n === -1) && <>
                            <textarea value={d} onChange={
                                (e) => {
                                    setD(e.target.value);
                                }
                            } placeholder="Observaciones" style={{
                                width: "100%",
                                height: "100px",
                                padding: "10px",
                                resize: "none"
                            }}>

                            </textarea>
                        </>
                    }


                </div>

                {
                    (props.n === -1) && <>
                        <br />

                        <div>
                            <button style={{
                                width: "100%",
                                borderRadius: "10px",
                                padding: "7px"
                            }} onClick={
                                () => {
                                    axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/qualifier/${props.id}`, {
                                        n,
                                        d
                                    })
                                        .then((response) => {
                                            props.onSalve({
                                                n,
                                                d
                                            })
                                        }, (error) => {
                                            toast.error(error.response.data)
                                        });
                                }
                            }>Enviar calificación</button>
                        </div>
                    </>
                }



            </div>
        </>
    )
}

export default QualifierBlock;
