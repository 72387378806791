import axios from 'axios';

import { PHAT_DEV, PHAT_PROD, DEV, URL_SOCKET_DEV, URL_SOCKET_PROD } from '../config';

export const getContactGroups = (iid) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/get-contact-groups/` + iid, {})
            .then((response) => {
                resolve(response.data);
            }, (error) => {
                reject(error.response.data)
            });

    })
}


export const getConsolidated = (ds, de, agent) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/consolidated/`, {
            params: {
                ds,
                de,
                agent
            }
        })
            .then((response) => {
                resolve(response.data);
            }, (error) => {
                reject(error.response.data)
            });

    })
}

export const getSenders = (iid) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/bussine/senders/` + iid, {})
            .then((response) => {

                console.log("SENDERS");
                console.log(response);

                if (response.status === 200) {
                    resolve(response.data.list);
                } else {
                    reject("Ocurrio un problema al intentar recuperar la lista de senders, intente nuevamente.")
                }
            }, (error) => {
                reject(error.response.data)
            });

    })
}

export const update = (ref, payload) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.put(`${(DEV) ? PHAT_DEV : PHAT_PROD}/bussine/` + ref, payload)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al intentar actualizar la información, intente nuevamente.");
                }
            }, (error) => {
                reject(error.response.data);
            });
    })
}

export const remove = (ref) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.delete(`${(DEV) ? PHAT_DEV : PHAT_PROD}/bussine/` + ref, {})
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al intentar eliminar la empresa, intente nuevamente.");
                }
            }, (error) => {
                reject(error.response.data);
            });
    })
}


// :ref/:bussine

export const getSenderId = (ref, bussine) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        // alert(`${(DEV) ? PHAT_DEV : PHAT_PROD}/bussine/${ref}/${bussine}`);
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/bussine/${ref}/${bussine}`, {})
            .then((response) => {
                if (response.status === 200) {
                    console.log(response);
                    resolve(response.data.list);
                } else {
                    reject("Ocurrio un problema al intentar recuperar el SenderId para " + ref + ", intente nuevamente.");
                }
            }, (error) => {
                reject(error.response);
            });
    })
}

// ESTA SI

export const getStatusSenderIdsByBussine = (bussine) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? URL_SOCKET_DEV : URL_SOCKET_PROD}/senderId/${bussine}`, {})
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data.list);
                } else {
                    reject("Ocurrio un problema al intentar recuperar los estados, intente nuevamente.");
                }
            }, (error) => {
                reject(error.response);
            });
    })
}

export const save = (payload) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/bussine/save-agent`, payload)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al intentar guardar el agente, intente nuevamente.")
                }
            }, (error) => {
                reject(error.response.data)
            });

    })
}
