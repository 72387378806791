export const PHAT_DEV = "http://localhost:30000";

// Para el socket app

export const URL_SOCKET_DEV = "http://localhost:30000";

export const PHAT_SOCKET_DEV = "/socket.io";


// Para el socket web

export const URL_SOCKET_WEB_DEV = "http://localhost:30000";


// PRODUCCION --------------------------------

export const PHAT_PROD = "https://waalpha.sayform.com";


// El el socket app

export const URL_SOCKET_PROD = "https://waalpha.sayform.com";

export const PHAT_SOCKET_PROD = "/tools/socket.io";

// Para el socket web/
export const URL_SOCKET_WEB_PROD = "https://waalpha.sayform.com";

export const DEV = false; // true = conectar en local false= conectar con servidor remoto

