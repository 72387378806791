import React, { useState, useEffect, useContext } from 'react';

import { useHistory } from "react-router-dom";

import toast from 'react-hot-toast';

import { getTemplates } from '../../services/Template';

import { ReactComponent as SearchIcon } from '../../assets/icons/magnifyingglass.svg';

import { AppContext } from '../../contexts/AppProvider';

import EnvApp from '../../components/EnvApp';

import { getByBussine, getHistoryChat, removeHistoryChat } from '../../services/Channel';

import { getClosedChatsBussine } from '../../services/Chat';

function History() {

    const history = useHistory();

    const { senderIds, setSenderIds } = useContext(AppContext);

    const [number, setNumber] = useState('');

    const [channel, setChannel] = useState('');

    const [ref, setRef] = useState('');

    const [name, setName] = useState('');

    const [message, setMessage] = useState('');

    const [search, setSearch] = useState('');

    const [chats, setChats] = useState([]);

    const [currentTemplate, setCurrentTemplate] = useState({
        ref: null,
        name: "",
        message: "",
    });

    const [templates, setTemplates] = useState([]);

    useEffect(() => {

        setName(currentTemplate.name);

        setMessage(currentTemplate.message);
    }, [currentTemplate]);

    useEffect(() => {
        // Obtenemos todos los chats cerrados, cuando el select se cargue de datos, utilizamos el indice de el
        // para consultar los chats cerrados tomando en cuenta el valor seleccionado por el select

        getClosedChatsBussine(window.localStorage.getItem("say-bussine")).then((chats) => {
            console.log("Estos son los chats disponibles");
            console.log(chats);
        }).catch((err) => {
            console.log(err)
        })

    }, []);

    const onClear = () => {
        setRef("");
        setName("");
        setMessage("");

    }

    useEffect(() => {


        getTemplates(window.localStorage.getItem("say-bussine")).then((response) => {
            console.log("Andreafsgfgdfg")
            console.log(response)
            setTemplates(response)
        })

        getByBussine(window.localStorage.getItem("say-bussine")).then((res) => {

            console.log("res");
            console.log(res);

            setSenderIds(res);
        }).catch((err) => {
            console.log(err);
            //toast.error(err.data.message)
        })
        /*getSendersByBussine(window.localStorage.getItem("say-bussine")).then((res) => {
            // setChannels(res);

            setAvailableChannels([])

            for (let i = 0; i < res.length; i++) {

                const channel = res[i];

                setAvailableChannels(oldChannels => [...oldChannels, {
                    name: channel.name,
                    active: false
                }]);

            }

        }).catch((err) => {
            console.log(err);
            //toast.error(err.data.message)
        })*/

    }, []);




    return (

        <EnvApp>

            <div style={{
                display: "grid",
                gridTemplateColumns: "auto",
                gridTemplateRows: "100px auto",
                background: "#fff",
                width: "100%",
                height: "100%",
                overflow: "auto",
                borderRadius: "10px 0px 0px 10px"
            }}>

                <div style={{
                    padding: "20px 50px 20px 50px"
                }}>

                    <div style={{
                        fontSize: "30px",
                        width: "100%",
                        height: "100%",
                        borderBottom: "1px solid #E8EBF0",
                        display: "flex"
                    }}>
                        <div style={{
                            width: "100%"
                        }}>
                            Historial
                        </div>

                    </div>

                </div>

                <div style={{
                    padding: "20px 50px 20px 50px"
                }}>

                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "280px auto",
                        gridTemplateRows: "auto",
                        height: "100%"
                    }}>
                        <div style={{
                            height: "100%"
                        }}>

                            <div style={{
                                display: "flex",
                                alignItems: "center"
                            }}>

                                <input type="text" value={search} style={{
                                    background: "#F2F7F8",
                                    border: "0px",
                                    height: "50px"
                                }} onChange={
                                    (e) => {
                                        setSearch(e.target.value)
                                    }
                                } className="form-control shadow-none" placeholder="Escribe para buscar" />

                                <button className="btn btn-primary shadow-none" style={{
                                    marginLeft: "5px",
                                    height: "50px",
                                    borderRadius: "15px",
                                    background: "rgb(8,55,155)",
                                    background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)"
                                }} onClick={
                                    () => {
                                        /*searchUsers(search, "agent").then((users) => {
                                            setAgents(users);
                                        }).catch((err_) => {
                                            console.log(err_);
                                        })*/
                                        toast.error("Esta funcionalidad aún no está disponible")
                                    }
                                }>
                                    <SearchIcon style={{
                                        //width: "20px",
                                        //height: "20px",
                                        fill: "#fff"
                                    }} />
                                </button>

                            </div>

                            <br />

                            {
                                templates.map((Template, TemplateIndex) => {
                                    return (<div key={TemplateIndex} style={{
                                        marginTop: "10px",
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        background: (currentTemplate.ref === Template.ref) ? "#D9E2F1" : "transparent",
                                        padding: "10px",
                                        borderRadius: "10px"
                                    }} onClick={
                                        () => {
                                            setCurrentTemplate(Template)
                                        }
                                    }>

                                        <div style={{
                                            width: "100%",
                                            marginLeft: "5px"
                                        }}>
                                            <div style={{
                                                fontWeight: "700",
                                                color: "#08379B"
                                            }}>{Template.name} </div>
                                            <div>
                                                <div>
                                                    <span><span>7.1</span> / 10</span>
                                                </div>
                                                <div style={{
                                                    width: "53px",
                                                    height: "3px",
                                                    background: "#E8EBF0"
                                                }}>
                                                    <div style={{
                                                        width: "80%",
                                                        height: "100%",
                                                        background: "#AFE1DA"
                                                    }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                                })
                            }

                        </div>
                        <div>

                            <div style={{
                                maxWidth: "900px",
                                margin: "0 auto"
                            }}>

                                <div style={{
                                    display: "flex"
                                }}>

                                    <div style={{
                                        width: "59000px"
                                    }}>
                                        <select value={channel} onChange={(e) => {
                                            setChannel(e.target.value)
                                        }} class="form-select form-select-lg mb-3" aria-label=".form-select-lg example">

                                            {
                                                senderIds.map((channel, chaIndex) => <option value={channel.ref}>{channel.name}</option>)
                                            }
                                        </select>
                                    </div>

                                    <div style={{
                                        width: "100%"
                                    }}></div>

                                    <div style={{
                                        width: "350px",
                                        marginRight: "180px"
                                    }}>
                                        <input style={{
                                            width: "225px"

                                        }} value={number} onChange={
                                            (e) => {
                                                setNumber(e.target.value)
                                            }
                                        } class="form-control form-control-lg" type="number" placeholder="Número" />
                                    </div>

                                    <div style={{
                                        width: "250px"
                                    }}>
                                        <button style={{
                                            marginLeft: "5px",
                                            height: "50px",
                                            borderRadius: "15px",
                                            background: "rgb(8,55,155)",
                                            background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)",
                                            marginRight: "180px"
                                        }} onClick={
                                            () => {

                                                getHistoryChat(channel, number).then((response) => {
                                                    console.log(response)
                                                    setChats(response)
                                                })
                                            }
                                        } className="btn btn-primary">
                                            <SearchIcon style={{
                                                //width: "20px",
                                                //height: "20px",
                                                fill: "#fff"
                                            }} />

                                        </button>

                                    </div>

                                    {/*
                                            senderIds.map((channel, chaIndex) => <option value={channel.ref}>{channel.name}</option>)
 */}
                                </div>

                                <div class="table-responsive">
                                    <table class="table align-middle">
                                        <thead>
                                            <tr>
                                                <th>JID</th>
                                                <th>Fecha</th>
                                                <th>Estado</th>
                                                <th>Canal</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                chats.map((chat) => <tr>
                                                    <td>{chat.jid}</td>
                                                    <td>{chat.createdAt}</td>
                                                    <td>{chat.status == -1?"No asignado":chat.status == 1?"Cerrado":"Esta con el bot"}
                                                    
                                                    </td>
                                                    <td>{chat.typeChannel}</td>
                                                    <td style={{
                                                        textAlign: "right"

                                                    }}>

                                                        <button type="button" onClick={
                                                            (e) => {
                                                                removeHistoryChat(chat.ref).then((response)=>{
                                                                    console.log(response)
                                                                })

                                                                alert("Eliminamos el chat");
                                                            }
                                                        } class="btn btn-primary btn-sm" style={{
                                                            background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)",
                                                            borderRadius: "15px"
                                                        }}>Eliminar</button>
                                                    </td>
                                                </tr>)

                                            }


                                        </tbody>
                                    </table>
                                </div>

                            </div>


                        </div>




                    </div >

                </div >

            </div >

        </EnvApp >
    );
}

export default History;

