
import React, { useState, useContext, useEffect } from 'react';

import { useHistory, useParams } from "react-router-dom";

import axios from 'axios';

import { PHAT_DEV, PHAT_PROD, DEV, URL_SOCKET_DEV, URL_SOCKET_PROD } from '../../../config';

import useDropdown from 'react-dropdown-hook';

import { AppContext } from '../../../contexts/AppProvider';

import { SocketContext } from '../../../contexts/SocketProvider';

import useDocumentTitle from '../../../Hooks/useDocumentTitle';

import { getNumbersChats, getMessages, getChatsAssigned, getClosedChats, getChatsUnassigned } from '../../../services/Chat';

import { getByBussine } from '../../../services/Channel';

import UserItem from './UserItem';

import Loading from '../../../assets/loading.gif';

import { ReactComponent as UserIcon } from '../../../assets/user.svg';

import { ReactComponent as MoreIcon } from '../../../assets/more.svg';

import SenderSentinel from './SenderSentinel';

function Side(props) {

    const { senderIds, setSenderIds, setLine, line, setCurrentPage, NewMessage, setChats, chat, setChat, Timeline, setTimeline, CurrentPage, iid, CurrentChat, Chats } = useContext(AppContext);

    const [page, setPage] = useState('available');

    const [loading, setLoading] = useState(false);

    const { ref } = useParams();

    const history = useHistory();

    const [wrapperRef, dropdownOpen, toggleDropdown, closeDropdown] = useDropdown();

    const { setNAssigned, setNPending, emit, nChat, setNumAssigned, setNumPending, setNumClose, numAssigned, numPending, numClose } = useContext(SocketContext);


    useEffect(() => {
        // Cragamos los canales disponibles para esta cuenta de empresa
        getByBussine(window.localStorage.getItem("say-bussine")).then((res) => {
            console.log("resresresresresresresresresres");
            console.log(res)
            setSenderIds(res)
        }).catch((error) => {
            console.log("error");
            console.log(error);
        })
    }, [])

    useEffect(() => {
        if (NewMessage != null) {
            // Aqui listamos los mensajes del chat actual si el ji d es el mismo que
            if (CurrentPage === "assigned") {
                getChatsAssigned(window.localStorage.getItem("say-ref")).then((chats) => {
                    setChats(chats);
                }).catch((err) => {
                    console.log(err);
                })
            } else if (CurrentPage === "unassigned") {
                getChatsUnassigned(window.localStorage.getItem("say-bussine")).then((chats) => {
                    setChats(chats);
                }).catch((err) => {
                    console.log(err);
                })
            } else if (CurrentPage === "closed") {
                getClosedChats(window.localStorage.getItem("say-ref")).then((chats) => {
                    setChats(chats);
                }).catch((err) => {
                    console.log(err)
                })
            }
        }
    }, [NewMessage])

    useEffect(() => {
        /*if (iid != null && iid != "") {
            emit('hello', {
                iid,
                ref: window.localStorage.getItem("say-ref")
            })
        }*/
    }, [iid])

    useEffect(() => {

        /*getByBussine(window.localStorage.getItem("say-bussine")).then((res) => {
             console.log(res);
             setSenderIds(res);
         }).catch((err) => {
             console.log(err);
             //toast.error(err.data.message)
         })
 
         // Sumamos los numeros
         */

    }, [])

    useEffect(() => {
        // Dependiendo del tipo se cargan los chats

        if (CurrentPage === "assigned") {
            setLoading(true);
            getChatsAssigned(window.localStorage.getItem("say-ref")).then((chats) => {
                console.log("chats");
                console.log(chats);
                setChats(chats);
                setLoading(false);
            }).catch((err) => {
                console.log(err);
            })
        } else if (CurrentPage === "unassigned") {
            setLoading(true);
            getChatsUnassigned(window.localStorage.getItem("say-bussine")).then((chats) => {
                setChats(chats);
                setLoading(false);
            }).catch((err) => {
                console.log(err);
            })
        } else if (CurrentPage === "closed") {
            setLoading(true);
            getClosedChats(window.localStorage.getItem("say-ref")).then((chats) => {
                setChats(chats);
                setLoading(false);
            }).catch((err) => {
                console.log(err)
            })
        }

    }, [CurrentPage])

    useEffect(() => {
        // Cargamos los mensajes de este chat?
        getMessages(chat.ref).then((response) => {
            setLine(response.list)
        }).catch((error) => {
            console.log("error");
            console.log(error);
        })
    }, [chat])

    useDocumentTitle('Sayform: ' + nChat.t + ' chats pendientes');

    return (
        <div style={{
            width: "100%",
            background: "#fff",
            display: "grid",
            gridTemplateColumns: "auto",
            gridTemplateRows: "65px 55px 60px auto 50px",
            overflow: "auto",
            borderRight: "2px solid #eeeded",
            borderBottom: "2px solid #eeeded",
        }} >

            {
                /*
                AQUI PEGAS
                */
            }


            <div style={{
                padding: "10px"
            }}>
                <div style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center"
                }}>

                    <div style={{
                        width: "45px"
                    }}>
                        <UserIcon style={{
                            width: "45px",
                            height: "45px",
                            borderRadius: "50%"
                        }} />
                    </div>

                    <div style={{
                        width: "100%",
                        marginLeft: "10px",

                    }}>
                        <div style={{
                            color: "#3883B0",
                            fontWeight: "700"
                        }}>{window.localStorage.getItem("say-name")}</div>
                        <div style={{
                            color: "#939190"
                        }}>Agente</div>
                    </div>

                    <div style={{
                        width: "35px"
                    }}>

                        <div ref={wrapperRef}>

                            <button style={{
                                border: "0px",
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                background: "transparent"
                            }}><MoreIcon onClick={toggleDropdown} style={{
                                width: "27px",
                                height: "27px",
                                borderRadius: "50%",
                                fill: "#939190"
                            }} /></button>

                            {dropdownOpen &&
                                <>
                                    <div style={{
                                        background: "#fff",
                                        position: "absolute",
                                        width: "177px",
                                        marginLeft: "-140px",
                                        marginTop: "15px",
                                        borderRadius: "7px",
                                        boxShadow: "0 2px 5px 0 rgba(0,0,0,.08),0 2px 10px 0 rgba(0,0,0,.08)"
                                    }}>
                                        <div style={{
                                            padding: "5px 10px 5px 10px",
                                            height: "40px",
                                            display: "flex",
                                            alignItems: "center",
                                            cursor: "pointer"
                                        }} onClick={
                                            (e) => {
                                                window.localStorage.clear();
                                                history.push("/login");
                                            }
                                        }>Cerrar sesión</div>
                                    </div>
                                </>
                            }

                        </div>

                    </div>

                </div>
            </div>


            <div style={{
                background: "#fff",
                padding: "10px",
                height: "55px",
                borderBottom: "2px solid #eeeded",
            }}>

                <div style={{
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "20px",
                    paddingLeft: "5px"
                }}>
                    <div style={{
                        width: "30px",
                        height: "30px"
                    }}>
                        <i className="las la-search" style={{
                            fontSize: "30px",
                            color: "#939190"
                        }}></i>
                    </div>

                    <div style={{
                        width: "100%"
                    }}>
                        <input type="text" className="form-control shadow-none searcher" style={{
                            width: "100%",
                            height: "35px",
                            border: "0px",
                            background: "#fff",
                            borderRadius: "20px",
                            fontSize: "14px"
                        }} placeholder="Buscar un chat" />
                    </div>
                </div>

            </div>

            <div style={{
                display: "grid",
                gridTemplateColumns: "auto auto auto",
                gridTemplateRows: "auto",
                padding: "10px"
            }}>

                <div style={{
                    alignSelf: "center"
                }}>
                    <button style={{
                        border: "0px",
                        borderRadius: "10px",
                        width: "100%",
                        background: (CurrentPage === "assigned") ? "#000" : "#e7f8e6",
                        color: (CurrentPage === "assigned") ? "#fff" : "#64cc6d",
                        fontWeight: "700",
                        padding: "6px"
                    }} onClick={
                        () => {
                            setCurrentPage('assigned');
                        }
                    }>{numAssigned} Asignados</button>
                </div>

                <div style={{
                    alignSelf: "center",
                    margin: "5px"
                }} onClick={
                    () => {
                        setCurrentPage('unassigned');
                    }
                }>
                    <button style={{
                        border: "0px",
                        borderRadius: "10px",
                        width: "100%",
                        background: (CurrentPage === "unassigned") ? "#000" : "#e9e8fa",
                        color: (CurrentPage === "unassigned") ? "#fff" : "#6c65d5",
                        fontWeight: "700",
                        padding: "6px"
                    }}>{numPending} Pendientes</button>

                </div>

                <div style={{
                    alignSelf: "center",
                    margin: "5px"
                }}>
                    <button style={{
                        border: "0px",
                        borderRadius: "10px",
                        width: "100%",
                        background: (CurrentPage === "closed") ? "#000" : "#ffede9",
                        color: (CurrentPage === "closed") ? "#fff" : "#fb4142",
                        fontWeight: "700",
                        padding: "6px"
                    }} onClick={
                        () => {
                            setCurrentPage('closed');
                        }
                    }>{numClose} Cerrados</button>
                </div>

            </div>



            <div style={{
                overflow: "auto"
            }}
            >

                <div style={{
                    textAlign: "center"
                }}>

                    {
                        (loading) && <div style={{
                            marginTop: "10px",
                            fontWeight: "700"
                        }}>
                            <img src={Loading} style={{
                                width: "50px"
                            }} />
                        </div>
                    }

                    {
                        (Chats.length === 0 && !loading) && <div style={{
                            marginTop: "10px",
                            fontWeight: "700"
                        }}>
                            Sin chat disponibles
                        </div>
                    }
                </div>

                {
                    Chats.map((Chat_, ChatIndex) => {
                        return (<>
                            <div key={ChatIndex} onClick={
                                async () => {

                                    setChat(Chat_);
                                    // Limpiamos el numero de mensajes dentro de esre chat
                                    var index = Chats.findIndex((co) => co.ref === Chat_.ref);
                                    if (index > -1) {
                                        var copy = [...Chats];
                                        copy[index].num = 0;
                                        setChats(copy);
                                    }
                                }
                            }>
                                <UserItem id={Chat_.ref} status={Chat_.status} jid={Chat_.jid} typeChannel={Chat_.typeChannel} channel={Chat_.channel} sender={Chat_.sender} active={false} name={Chat_.name} presence={""} n={Chat_.num} />
                                {
                                    /*
                                    (Chat_.ref === chat.ref) ? true : false
                                    */
                                }
                            </div>
                        </>);
                    })
                }

            </div>

            <SenderSentinel />

        </div>
    );
}

export default Side;

