import React, { useState, useEffect } from 'react';

import { useHistory } from "react-router-dom";

import ShiftCard from './ShiftCard';

function Screen() {

    const history = useHistory();

    useEffect(() => {

    }, []);

    return (
        <div style={{
            width: "100%",
            height: "100vh",
            background: "#000",
            display: "grid",
            gridTemplateColumns: "auto 400px",
            gridTemplateRows: "auto",
            color: "#fff",
            padding: "20px"
        }}>

            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>

                <div style={{
                    width: "1024px",
                    height: "750px",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <div style={{
                        width: "100%",
                        display: "grid",
                        gridTemplateColumns: "auto 200px",
                        gridTemplateRows: "auto",
                        marginBottom: "10px"
                    }}>
                        <div style={{
                            alignSelf: "center"
                        }}>
                            <img src="https://cloudcitycolombia.com/imagenes/pascual/logo-one.jpg" style={{
                                height: "90%"
                            }} />
                        </div>
                        <div style={{
                            alignSelf: "center"
                        }}>
                            <img src="https://cloudcitycolombia.com/imagenes/pascual/logo-two.jpg" style={{
                                width: "200px"
                            }} />
                        </div>
                    </div>
                    <div>
                        <img src="https://cloudcitycolombia.com/imagenes/pascual/infoone.jpg" style={{
                            width: "100%"
                        }} />
                    </div>
                    <div style={{
                        width: "100%",
                        display: "grid",
                        gridTemplateColumns: "auto 200px",
                        gridTemplateRows: "auto",
                        marginTop: "10px"
                    }}>
                        <div style={{
                            alignSelf: "center",
                            textAlign: "center",
                            fontSize: "20px"
                        }}>
                            <div style={{
                                fontWeight: "700"
                            }}>Horario de atención</div>
                            <div><b>Oficinas:</b> Lunes a viernes: 8:00 a.m. a 5:00 p.m.</div>
                            <div><b>CIS:</b> Lunes a viernes: 8:00 a.m. a 7:00 p.m.</div>
                            <div>Sábados: 8:00 a.m. a 1:00 p.m.</div>
                        </div>
                        <div style={{
                            alignSelf: "center"
                        }}>
                            <img src="https://cloudcitycolombia.com/imagenes/pascual/logo-hree.jpg" style={{
                                width: "200px"
                            }} />
                        </div>
                    </div>
                </div>

            </div>

            <div>

                <div style={{
                    border: "1px solid #fff",
                    fontSize: "40px",
                    padding: "10px",
                    borderRadius: "10px",
                    textAlign: "center"
                }}>MODULO - TURNO</div>

                <div style={{
                    marginTop: "30px"
                }}>

                    <ShiftCard n={5} t={'B68'} />

                    <ShiftCard n={5} t={'C96'} />

                    <ShiftCard n={2} t={'B68'} />

                    <ShiftCard n={4} t={'N77'} />

                    <ShiftCard n={3} t={'n76'} />

                    <ShiftCard n={4} t={'a86'} />

                </div>

            </div>

        </div>
    );
}

export default Screen;

