import { useContext, useEffect, useState, useCallback } from "react";

import { ReactComponent as UserIcon } from '../../../assets/user.svg';

import { ReactComponent as WhatsappIcon } from '../../../assets/whatsapp.svg';

import { ReactComponent as WebIcon } from '../../../assets/web.svg';

import { ReactComponent as MessengerIcon } from '../../../assets/messenger.svg';

import { ReactComponent as InstagramIcon } from '../../../assets/instagram.svg';

import { AppContext } from '../../../contexts/AppProvider';

function UserItem(props) {

    const { senderIds } = useContext(AppContext);

    const [channel, setChannel] = useState('');

    useEffect(() => {

        console.log("senderIdssenderIdssenderIdssenderIdssenderIdssenderIds");
        console.log(senderIds);

        var index = senderIds.findIndex((senI) => senI.ref === props.channel);

        if (index > -1) {
            setChannel(senderIds[index].name)
        } else {
            setChannel(props.channel + ' - Sin espesificar')
        }

    }, [senderIds])

    const tojid = (jid) => {
        return jid.replace("@s.whatsapp.net", "")
    }

    return (
        <div style={{
            borderBottom: "2px solid #eeeded",
            borderLeft: (props.active) ? "5px solid #f36062" : "5px solid #fff",
            background: (props.active) ? "#fff9f5" : "#fff",
            cursor: "pointer"
        }}>

            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px"
            }}>

                <div style={{
                    width: "50px",
                    height: "50px",
                    position: "relative"
                }}>

                    <UserIcon style={{
                        width: "50px",
                        height: "50px",
                        border: (props.npending > 0) ? "3px solid #06d755" : "3px solid #c3c3c3",
                        borderRadius: "50%"
                    }} />

                    <div style={{
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        bottom: "0px",
                        right: "0px",
                        padding: "0px"
                    }}>
                        <div style={{
                            width: "100%",
                            textAlign: "center"
                        }}>

                            {
                                (props.typeChannel === 'WEB') && <WebIcon style={{
                                    width: "25px",
                                    height: "25px",
                                    fill: "#fff",
                                    borderRadius: "50%"
                                }} />
                            }

                            {
                                (props.typeChannel === 'WHATSAPP') && <WhatsappIcon style={{
                                    width: "25px",
                                    height: "25px",
                                    fill: "#fff",
                                    borderRadius: "50%"
                                }} />
                            }

                            {
                                (props.typeChannel === 'MESSENGER') && <MessengerIcon style={{
                                    width: "25px",
                                    height: "25px",
                                    fill: "#fff",
                                    borderRadius: "50%"
                                }} />
                            }

                            {
                                (props.typeChannel === 'INSTAGRAM') && <InstagramIcon style={{
                                    width: "25px",
                                    height: "25px",
                                    fill: "#fff",
                                    borderRadius: "50%"
                                }} />
                            }

                        </div>
                    </div>

                </div>
                <div style={{
                    width: "100%",
                    marginLeft: "5px",
                    padding: "0px 0px 7px 0px"
                }}>

                    <div>

                        <div style={{
                            color: "#3283b0",
                            fontWeight: "700"
                        }}>
                            <div>{(props.name != "") ? props.name : "+" + tojid(props.jid)}</div>
                            <div>{channel}</div>

                        </div>

                        {
                            (props.presence === "composing") && <div style={{ color: "#2fb55c" }}><b>{props.sender}</b> - Escribiendo...</div>
                        }

                        {
                            (props.presence === "available") && <div><b>{props.sender}</b> - Disponible</div>
                        }

                        {
                            (props.presence === "unavailable") && <div><b>{props.sender}</b> - No disponible</div>
                        }

                    </div>

                </div>
                <div>
                    {
                        (props.n > 0) && <div
                            style={{
                                background: "#06d755",
                                width: "25px",
                                height: "25px",
                                borderRadius: "50%",
                                color: "#fff",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontWeight: "bold",
                                fontSize: "0.6em"
                            }}
                        >{(props.n <= 99) ? props.n : "+99"}</div>
                    }
                </div>
            </div>
        </div>
    );
}

export default UserItem;

