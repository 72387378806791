import axios from 'axios';

import { PHAT_DEV, PHAT_PROD, DEV, URL_SOCKET_DEV, URL_SOCKET_PROD } from '../config';

export const saveBussine = (payload) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/admin`, payload)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al intentar guardar el agente, intente nuevamente.")
                }
            }, (error) => {
                reject(error.response.data)
            });

    })
}


export const getBussines = () => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/bussine`, {})
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data);
                    resolve(response.data.list);
                } else {
                    reject("Ocurrio un problema al intentar recuperar los clientes, intente nuevamente.");
                }
            }, (error) => {
                reject(error.response);
            });
    })
}

export const assignSenderId = (payload) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/admin/assignSenderId`, payload)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data.message);
                } else {
                    reject("Ocurrio un problema al intentar asignar el SenderId, intente nuevamente.")
                }
            }, (error) => {
                reject(error.response.data.message)
            });
    })
}