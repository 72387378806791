import React, { useState, useEffect, useRef } from 'react';

import toast, { Toaster } from 'react-hot-toast';

import { Modal } from 'react-responsive-modal';

import "./index.css";

import { assignSenderId } from '../../services/Admin';

import { ReactComponent as InstanceIcon } from '../../assets/instance.svg';

import { ReactComponent as ChekIcon } from '../../assets/chek.svg';

import AgentImg from '../../assets/Agents.png';

import EnvApp from '../../components/EnvApp';

function InstanceExplorer(props) {

    const [instances, setInstances] = useState([
        {
            name: 'Andromeda',
            endPoint: 'https://an1.sayform.com'
        },
        {
            name: 'Andromeda',
            endPoint: 'http://localhost:5000'
        }
    ]);

    const [open, setOpen] = React.useState(false);

    const modalRef = useRef(null);

    useEffect(() => {
        // Cargamos las instancias disponibles
        console.log("Cargamos las instancias disponibles");

    }, []);

    return (
        <>
            <button style={{
                border: "0px",
                background: "transparent"
            }} onClick={() => setOpen(true)}>
                <InstanceIcon style={{
                    width: "25px",
                    height: "25px"
                }} />
            </button>

            <Modal
                ref={modalRef}
                open={open}
                onClose={() => setOpen(false)}
                initialFocusRef={modalRef}
                classNames={{
                    overlay: 'customOverlay',
                    modal: 'customModal',
                }}
            >
                <h2>Instancias</h2>
                <div>
                    {
                        instances.map((instance, InstanceIndex) =>
                            <div key={InstanceIndex} style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "10px"
                            }}>
                                <div>
                                    <InstanceIcon style={{
                                        width: "40px",
                                        height: "40px"
                                    }} />
                                </div>
                                <div style={{
                                    width: "100%",
                                    marginLeft: "7px"
                                }}>
                                    <div style={{
                                        fontWeight: "700"
                                    }}>{instance.name}</div>
                                    <div>{instance.endPoint}</div>
                                </div>
                                <div>
                                    <button style={{
                                        border: "0px",
                                        background: "transparent"
                                    }} onClick={
                                        (e) => {

                                            e.preventDefault();
                                            e.stopPropagation();

                                            var senderId = prompt("Introduzca el SenderId", "");

                                            if (senderId != null) {

                                                assignSenderId({
                                                    bussine: props.bussine,
                                                    senderId,
                                                    endPoint: instance.endPoint
                                                }).then((message) => {
                                                    toast.success(message)
                                                }).catch((message) => {
                                                    toast.error(message)
                                                })

                                            }

                                        }
                                    }>
                                        <ChekIcon style={{
                                            width: "30px",
                                            height: "30px"
                                        }} />
                                    </button>
                                </div>
                            </div>
                        )
                    }
                </div>
            </Modal>
        </>
    );
}

export default InstanceExplorer;