import axios from 'axios';

import { PHAT_DEV, PHAT_PROD, DEV } from '../config';

export const save = (payload) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/user/save-agent`, payload)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al intentar guardar el agente, intente nuevamente.")
                }
            }, (error) => {
                reject(error.response.data)
            });

    })
}

export const remove = (ref) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.delete(`${(DEV) ? PHAT_DEV : PHAT_PROD}/agent/${ref}`, {})
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al intentar guardar el agente, intente nuevamente.")
                }
            }, (error) => {
                reject(error.response.data)
            });

    })
}

export const getUser = (ref) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/user/` + ref, {

        })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al intentar recuperar el usuario, intente nuevamente.")
                }
            }, (error) => {
                reject(error.response.data)
            });

    })
}


export const getAsesors = (bussine) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/getAgents/` + bussine, {

        })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al intentar recuperar el usuario, intente nuevamente.")
                }
            }, (error) => {
                reject(error.response.data)
            });

    })
}

export const getUsersByRol = (rol) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/user/list/` + rol, {

        })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data.list);
                } else {
                    reject("Ocurrio un problema al intentar recuperar el usuario, intente nuevamente.")
                }
            }, (error) => {
                reject(error.response.data)
            });

    })
}

export const searchUsers = (name, rol) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/user/search/`, {
            params: {
                name: name,
                rol: rol
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data.list);
                } else {
                    reject("Ocurrio un problema al intentar recuperar los usuarios, intente nuevamente.")
                }
            }, (error) => {
                reject(error.response.data)
            });

    })
}

export const removeUsers = (ref) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/user/remove/` + ref, {})
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al intentar eliminar el usuarios, intente nuevamente.")
                }
            }, (error) => {
                reject(error.response.data)
            });

    })
}

export const passwordUpdate = (payload) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/user/password-update`, payload)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al intentar actualizar el password, intente nuevamente.")
                }
            }, (error) => {
                reject(error.response.data)
            });

    })
}


export const preferencesUpdate = (payload) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/user/preferences-update`, payload)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al intentar actualizar las preferencias, intente nuevamente.")
                }
            }, (error) => {
                reject(error.response.data)
            });

    })
}

export const profilePicturesUpdate = (payload) => {
    return new Promise((resolve, reject) => {
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/upload-photo-profile`, payload)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data);
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al intentar actualizar la foto de perfil, intente nuevamente.")
                }
            }, (error) => {
                reject(error.response.data)
            });
    })
}

export const getAgents = (idd) => {
    //convert input to output 
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/user/getAgents/${idd}`, {})
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject("Ocurrio un problema al recuperar los agnetes, intente nuevamente.");
                }
            }, (error) => {
                reject(error.response.data)
            });

    })
}
