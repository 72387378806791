import React, { useState, useEffect, useContext, useRef } from 'react';

import { useHistory } from "react-router-dom";

import toast from 'react-hot-toast';

import Tooltip from "react-simple-tooltip";

import { PHAT_DEV, PHAT_PROD, DEV } from '../../config';

import { SRLWrapper } from "simple-react-lightbox";

import SimpleReactLightbox from 'simple-react-lightbox'

import Select, { StylesConfig } from 'react-select';

import { ReactComponent as MenIcon } from '../../assets/icons/envelope.svg';

import { save, getAllCampaigns, remove } from '../../services/Campaign';

import { ReactComponent as SearchIcon } from '../../assets/icons/magnifyingglass.svg';

import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';

import { ReactComponent as CampaingIcon } from '../../assets/icons/Campaings.svg';

import { ReactComponent as ResultsIcon } from '../../assets/icons/text.magnifyingglass.svg';

import { ReactComponent as EnviarIcon } from '../../assets/icons/paperplane.svg';

import { getAllContactGroup } from '../../services/Contact';

import { getByBussine } from '../../services/Channel';

import { AppContext } from '../../contexts/AppProvider';

import { uploadFile } from '../../services/Chat';

import { getRecipients } from '../../services/Campaign';

import EnvApp from '../../components/EnvApp';

function Campaign() {

    const history = useHistory();

    const file = useRef(null);

    const { senderIds, setSenderIds } = useContext(AppContext);

    const [ref, setRef] = useState('');

    const [name, setName] = useState('');

    const [channel, setChannel] = useState('');

    const [status, setStatus] = useState(0);

    const [image, setImage] = useState('');

    const [groups, setGroups] = useState([]);

    const [message, setMessage] = useState('');

    const [search, setSearch] = useState('');

    const [TimeStamp, setTimeStamp] = useState({
        day: '',
        start: {
            hour: '',
            min: ''
        },
        end: {
            hour: '',
            min: ''
        }
    });

    const [currentCampaign, setCurrentCampaign] = useState({
        ref: null,
        name: '',
        channel: '',
        groups: [],
        message: ''
    });

    const [campaigns, setCampaigns] = useState([]);

    const [recipients, setRecipients] = useState([]);

    const [contactGroups, setContactGroups] = useState([]);

    const [availableContactGroups, setAvailableContactGroups] = useState([]);

    const [availableChannels, setAvailableChannels] = useState([]);

    const [n_text, setN_text] = useState(0);

    const [n_pages, setN_pages] = useState(0);

    useEffect(() => {

        setName(currentCampaign.name);

        setChannel(currentCampaign.channel);

        setStatus(currentCampaign.status);

        setGroups(currentCampaign.groups);

        setMessage(currentCampaign.message);

        /*

        setContactGroups([]);

        for (let i = 0; i < currentCampaign.groups.length; i++) {
            const group = currentCampaign.groups[i];

            setContactGroups(oldContactGroups => [...oldContactGroups, {
                label: group.name,
                value: group._id
            }]);

        }*/

        // Obtenemos los trabajos de esta campaña
        // 

        getRecipients(currentCampaign.ref).then((response) => {
            console.log("response");
            console.log(response);
            setRecipients(response)
        })



    }, [currentCampaign]);

    const onClear = () => {
        setName("");
        setChannel("");
        setGroups("");
        setMessage("");
    }

    useEffect(() => {

        getAllContactGroup(window.localStorage.getItem("say-bussine")).then((res) => {
            setAvailableContactGroups([]);
            for (let i = 0; i < res.length; i++) {
                const re = res[i];
                setAvailableContactGroups(oldContactGroups => [...oldContactGroups, {
                    value: re._id,
                    label: re.name
                }]);
            }
        }).catch((err) => {
            console.log(err);
        })

        // campaigns
        getAllCampaigns(window.localStorage.getItem("say-bussine")).then((res) => {

            setCampaigns(res);

            // Si no existe un -1 entonces ho a habido errores

            // Si el n de 1 es menor que la long de los jobs entonces esta en proceso

            /*
            var a = []
            for (let i = 0; i < res.length; i++) {
                const { name, channel, date, limitExc, message, output, recipient, ref, status, groups, jobs } = res[i];

                var nfailed = jobs.filter((job) => job.status === -1).length;

                var nsuccess = jobs.filter((job) => job.status === 1).length;

                var npending = jobs.filter((job) => job.status === 0).length;

                a.push({
                    name,
                    channel,
                    date,
                    limitExc,
                    message,
                    output,
                    recipient,
                    ref,
                    status,
                    jobs,
                    groups,
                    nfailed,
                    nsuccess,
                    npending
                })

            }

            setCampaigns(a);*/

        }).catch((err) => {
            console.log(err);
        })

        getByBussine(window.localStorage.getItem("say-bussine")).then((res) => {
            // setChannels(res);
            setAvailableChannels([])
            for (let i = 0; i < res.length; i++) {
                const channel = res[i];
                if (channel.channel === "WHATSAPP" || channel.channel === "SMS") {
                    setAvailableChannels(oldChannels => [...oldChannels, {
                        ref: channel.ref,
                        name: channel.name,
                        active: false
                    }]);
                }
            }
        }).catch((err) => {
            console.log(err);
            //toast.error(err.data.message)
        })

    }, []);

    return (
        <SimpleReactLightbox>
            <EnvApp>

                <div style={{
                    display: "grid",
                    gridTemplateColumns: "auto",
                    gridTemplateRows: "100px auto",
                    background: "#fff",
                    width: "100%",
                    height: "100%",
                    overflow: "auto",
                    borderRadius: "10px 0px 0px 10px"
                }}>

                    <div style={{
                        padding: "20px 50px 20px 50px"
                    }}>

                        <div style={{
                            fontSize: "30px",
                            width: "100%",
                            height: "100%",
                            borderBottom: "1px solid #E8EBF0",
                            display: "flex"
                        }}>
                            <div style={{
                                width: "100%"
                            }}>
                                Campañas
                            </div>
                            <div style={{
                                width: "250px",
                                textAlign: "right",
                            }}>
                                <button style={{
                                    borderRadius: "15px",
                                    background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)"
                                }} onClick={
                                    () => {

                                        setCurrentCampaign({
                                            ref: '',
                                            name: '',
                                            channel: '',
                                            groups: '',
                                            message: ''
                                        })

                                        onClear()

                                        setContactGroups([]);

                                    }
                                } className="btn btn-primary">
                                    <PlusIcon style={{
                                        // width: "20px",
                                        //height: "20px",
                                        fill: "#fff"
                                    }} /> Crear campaña</button>
                            </div>
                        </div>



                    </div>

                    <div style={{
                        padding: "20px 50px 20px 50px"
                    }}>

                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "280px auto",
                            gridTemplateRows: "auto",
                            height: "100%"
                        }}>
                            <div style={{
                                height: "100%"
                            }}>

                                <div style={{
                                    display: "flex",
                                    alignItems: "center"
                                }}>

                                    <input type="text" value={search} style={{
                                        background: "#F2F7F8",
                                        border: "0px",
                                        height: "50px"
                                    }} onChange={
                                        (e) => {
                                            setSearch(e.target.value)
                                        }
                                    } className="form-control shadow-none" placeholder="Escribe para buscar" />

                                    <button className="btn btn-primary shadow-none" style={{
                                        marginLeft: "5px",
                                        height: "50px",
                                        borderRadius: "15px",
                                        background: "rgb(8,55,155)",
                                        background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)"
                                    }} onClick={
                                        () => {
                                            /*searchUsers(search, "agent").then((users) => {
                                                setAgents(users);
                                            }).catch((err_) => {
                                                console.log(err_);
                                            })*/
                                            toast.error("Esta funcionalidad aún no está disponible")
                                        }
                                    }>
                                        <SearchIcon style={{
                                            //width: "20px",
                                            //height: "20px",
                                            fill: "#fff"
                                        }} />
                                    </button>

                                </div>

                                <br />

                                {
                                    (campaigns.length === 0) && <div style={{
                                        textAlign: "center"
                                    }}>
                                        <div style={{
                                            fontSize: "18px",
                                            fontWeight: "700"
                                        }}><ResultsIcon style={{
                                            width: "40px",
                                            height: "40px",
                                            fill: "#fff"
                                        }} /></div>
                                        <div>Sin resultados</div>
                                    </div>
                                }


                                {
                                    campaigns.map((Campaign, CampaignIndex) => {
                                        return (<div key={CampaignIndex} style={{
                                            marginTop: "10px",
                                            display: "flex",
                                            alignItems: "center",
                                            cursor: "pointer",
                                            background: (currentCampaign.ref === Campaign.ref) ? "#D9E2F1" : "transparent",
                                            padding: "10px",
                                            borderRadius: "10px"
                                        }} onClick={
                                            () => {
                                                console.log("CampaignCampaignCampaignCampaignCampaign");
                                                console.log(Campaign);

                                                // Limpiamos la lista?


                                                setCurrentCampaign(Campaign);
                                            }
                                        }>
                                            <div>
                                                <MenIcon style={{
                                                    width: "55px",
                                                    height: "55px",
                                                    borderRadius: "50%",
                                                    fill: (currentCampaign.ref === Campaign.ref) ? '#08379B' : '#000'
                                                }} />
                                            </div>
                                            <div style={{
                                                width: "100%",
                                                marginLeft: "5px"
                                            }}>
                                                <div style={{
                                                    fontWeight: "700",
                                                    color: "#08379B"
                                                }}>{Campaign.name}</div>



                                                <div>
                                                    <div style={{
                                                        display: "flex"
                                                    }}>


                                                        <Tooltip style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            marginRight: "7px"
                                                        }} content="Mensajes enviados">
                                                            <div style={{
                                                                width: "10px",
                                                                height: "10px",
                                                                borderRadius: "50%",
                                                                background: "#0064fa",
                                                                backgroundImage: "linear-gradient(30deg,#28c76f,rgba(40,199,111,.5))"
                                                            }}></div>
                                                            <div style={{
                                                                marginLeft: "5px"
                                                            }}>
                                                                {Campaign.nsuccess} sms
                                                            </div>
                                                        </Tooltip>

                                                        <Tooltip style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            marginRight: "7px"
                                                        }} content="Mensajes fallidos">
                                                            <div style={{
                                                                width: "10px",
                                                                height: "10px",
                                                                borderRadius: "50%",
                                                                background: "#640064",
                                                                backgroundImage: "linear-gradient(30deg,#ea5455,rgba(234,84,85,.5))"
                                                            }}></div>
                                                            <div style={{
                                                                marginLeft: "5px"
                                                            }}>
                                                                {Campaign.nfailed} sms
                                                            </div>
                                                        </Tooltip>

                                                        <Tooltip style={{
                                                            display: "flex",
                                                            alignItems: "center"
                                                        }} content="Mensajes pendientes">
                                                            <div style={{
                                                                width: "10px",
                                                                height: "10px",
                                                                borderRadius: "50%",
                                                                background: "gray"
                                                            }}></div>
                                                            <div style={{
                                                                marginLeft: "5px"
                                                            }}>
                                                                {Campaign.npending} sms
                                                            </div>
                                                        </Tooltip>
                                                    </div>
                                                    <div style={{
                                                        width: "53px",
                                                        height: "3px",
                                                        background: "#E8EBF0"
                                                    }}>
                                                        <div style={{
                                                            width: "80%",
                                                            height: "100%",
                                                            background: "#AFE1DA"
                                                        }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                    })
                                }

                            </div>

                            {
                                (currentCampaign.ref === null) && <div style={{
                                    padding: "0px 50px 0px 50px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100%",
                                    textAlign: "center"
                                }}>
                                    <div style={{
                                        maxWidth: "500px"
                                    }}>
                                        <div>
                                            <CampaingIcon style={{
                                                fill: "#fff",

                                            }} />
                                        </div>
                                        <div style={{
                                            fontSize: "25px",
                                            fontWeight: "500",
                                            marginTop: "-30px"
                                        }}>Crear campaña</div>
                                        <div style={{
                                            marginTop: "10px"
                                        }}>Dando clic en el botón <b>+ Crear campaña</b>, podrás crear una nueva campaña y tendrás la oportunidad de asignarles un canal ,un grupo de contactos y su respectivo mensaje.</div>
                                    </div>
                                </div>
                            }


                            {
                                (currentCampaign.ref != null) && <div style={{
                                    overflow: "auto"
                                }}>
                                    <div style={{
                                        padding: "0px 50px 0px 50px",
                                        display: "flex"
                                    }}>


                                    </div>

                                    <div style={{
                                        padding: "0px 50px 0px 50px"
                                    }}>

                                        <br />

                                        {
                                            (currentCampaign.ref != "") && <div>

                                                <div className="row">
                                                    <div className="col-3">
                                                        <div style={{
                                                            background: "#640064",
                                                            backgroundImage: "linear-gradient(30deg,#7367f0,rgba(115,103,240,.5))",
                                                            padding: "10px",
                                                            color: "#fff",
                                                            borderRadius: "10px"
                                                        }}>
                                                            <div style={{
                                                                fontSize: "25px",
                                                                fontWeight: "700"
                                                            }}>{currentCampaign.nTotal}</div>
                                                            <div>Destinatarios</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div style={{
                                                            background: "#0064fa",
                                                            backgroundImage: "linear-gradient(30deg,#28c76f,rgba(40,199,111,.5))",
                                                            padding: "10px",
                                                            color: "#fff",
                                                            borderRadius: "10px"
                                                        }}>
                                                            <div style={{
                                                                fontSize: "25px",
                                                                fontWeight: "700"
                                                            }}>{currentCampaign.nSuccess}</div>
                                                            <div>Entregados</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div style={{
                                                            background: "#640064",
                                                            backgroundImage: "linear-gradient(30deg,#ea5455,rgba(234,84,85,.5))",
                                                            padding: "10px",
                                                            color: "#fff",
                                                            borderRadius: "10px"
                                                        }}>
                                                            <div style={{
                                                                fontSize: "25px",
                                                                fontWeight: "700"
                                                            }}>{currentCampaign.nFailed}</div>
                                                            <div>Fallidos</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div style={{
                                                            background: "gray",
                                                            padding: "10px",
                                                            color: "#fff",
                                                            borderRadius: "10px"
                                                        }}>
                                                            <div style={{
                                                                fontSize: "25px",
                                                                fontWeight: "700"
                                                            }}>{currentCampaign.nPending}</div>
                                                            <div>Pendientes</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <br />

                                                <div>
                                                    <div><span style={{
                                                        fontSize: "17px",
                                                        fontWeight: "700"
                                                    }}>Nombre de la campaña:</span> {name}</div>
                                                    <div><span style={{
                                                        fontSize: "17px",
                                                        fontWeight: "700"
                                                    }}>Canal:</span> {channel.name}</div>
                                                    <div><span style={{
                                                        fontSize: "17px",
                                                        fontWeight: "700"
                                                    }}>Estado:</span> {(status === 1) ? "Terminado" : (status === 0) ? "Pendiente" : "Fallido"}</div>
                                                </div>

                                                <div>

                                                    <br />
                                                    <div style={{
                                                        fontWeight: "700",
                                                        fontSize: "20px"
                                                    }}>Recipientes</div>
                                                    <br />

                                                    <div className="row">
                                                        {

                                                            recipients.map((job, jobIndex) => <div key={jobIndex} className="col-6" style={{
                                                                marginTop: "20px"
                                                            }}>
                                                                <Tooltip style={{
                                                                    width: "100%",
                                                                    display: "flex",
                                                                    alignItems: "center"
                                                                }} content={(job.output != "") ? job.output : "Sin salida"}>
                                                                    <div className="shadow" style={{
                                                                        width: "100%",
                                                                        background: "#fff",
                                                                        borderRadius: "10px",
                                                                        padding: "10px",
                                                                        cursor: "pointer"
                                                                    }}>
                                                                        <div><b>Para:</b> {job.recipient}</div>
                                                                        <div><b>Estatus:</b> {(job.status === 1) ? "Enviado" : (job.status === -1) ? "No enviado" : (job.status === 0) ? "Pendiente" : ""}</div>
                                                                        <div><b>Fecha:</b> {job.date}</div>
                                                                    </div>
                                                                </Tooltip>
                                                            </div>)

                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        }

                                        {
                                            (currentCampaign.ref === "") && <div>
                                                <div style={{
                                                    minHeight: "100px"
                                                }}>

                                                    <div style={{
                                                        fontSize: "25px",
                                                        fontWeight: "700"
                                                    }}>Nueva campaña</div>

                                                    <div style={{
                                                        marginTop: "10px"
                                                    }}>

                                                        <div className="row">
                                                            <div className="col-5 mt-3">
                                                                <div style={{
                                                                    fontWeight: "700",
                                                                    color: "rgb(8, 55, 155)"
                                                                }}>Nombre</div>

                                                                <input type="text" value={name} onChange={
                                                                    (e) => {
                                                                        setName(e.target.value)
                                                                    }
                                                                } class="shadow bg-body rounded" style={{
                                                                    width: "100%",
                                                                    padding: "10px",
                                                                    fontSize: "20px",
                                                                    background: "#F2F7F8",
                                                                    border: "0px"
                                                                }} placeholder="Nombre de la campaña" />


                                                            </div>

                                                            <div className="col-7 mt-3">
                                                                <div style={{
                                                                    fontWeight: "700",
                                                                    color: "rgb(8, 55, 155)",
                                                                    marginTop: "10px"
                                                                }}>Canal</div>

                                                                <select class="form-select" value={channel} class="shadow p-2  bg-body rounded" onChange={
                                                                    (e) => {
                                                                        setChannel(e.target.value);
                                                                    }
                                                                } aria-label="Default select example" style={{
                                                                    color: "#707d84", fontSize: "19px"
                                                                }}>
                                                                    <option value="">Selecciona una opción</option>
                                                                    {
                                                                        availableChannels.map((Channel, ChannelIndex) =>
                                                                            <option key={ChannelIndex} value={Channel.ref}>{Channel.name}</option>
                                                                        )
                                                                    }
                                                                </select>
                                                            </div>

                                                            <div className="col-6 mt-3">
                                                                <div style={{
                                                                    fontWeight: "700",
                                                                    color: "rgb(8, 55, 155)",
                                                                    marginTop: "-1px"
                                                                }}>Tiempo mínimo entre mensajes</div>

                                                                <input type="number" class="shadow p-2  bg-body rounded" style={{
                                                                    width: "100%",
                                                                    padding: "10px",
                                                                    fontSize: "20px",
                                                                    background: "#F2F7F8",
                                                                    border: "0px"
                                                                }} placeholder="Tiempo mínimo entre mensajes" />
                                                            </div>

                                                            <div className="col-6 mt-3">
                                                                <div style={{
                                                                    fontWeight: "700",
                                                                    color: "rgb(8, 55, 155)"
                                                                }}>Tiempo máximo entre mensajes</div>

                                                                <input type="number" className="shadow p-2  bg-body rounded" style={{
                                                                    width: "100%",
                                                                    padding: "10px",
                                                                    fontSize: "20px",
                                                                    background: "#F2F7F8",
                                                                    border: "0px"
                                                                }} placeholder="Tiempo máximo entre  mensajes" />
                                                            </div>

                                                            <div >
                                                                <div style={{
                                                                    fontWeight: "700",
                                                                    color: "rgb(8, 55, 155)",
                                                                    marginTop: "10px"
                                                                }}>Grupo de Contactos</div>

                                                                <Select placeholder="Grupo de Contactos" className="shadow  bg-body rounded" onChange={
                                                                    (esd) => {

                                                                        console.log("esd");
                                                                        console.log(esd);

                                                                        var a = []

                                                                        for (let i = 0; i < esd.length; i++) {
                                                                            const elm = esd[i];

                                                                            a.push({
                                                                                label: elm.label,
                                                                                value: elm.value
                                                                            });

                                                                        }

                                                                        setContactGroups(a);

                                                                    }
                                                                }
                                                                    closeMenuOnSelect={false}
                                                                    value={contactGroups}
                                                                    isMulti
                                                                    options={availableContactGroups}
                                                                /*styles={colourStyles}*/
                                                                />

                                                            </div>

                                                            <div>
                                                                <div>Imagen</div>


                                                                <input type="file" ref={file} onChange={
                                                                    (e) => {

                                                                        uploadFile(e.target.files[0]).then(async (res) => {

                                                                            console.log(res);

                                                                            // res.file

                                                                            // http://localhost:30000/static/5fdf3a1e-fe8c-4e42-83a6-690ecaec7c67.jpg

                                                                            setImage(`${(DEV) ? PHAT_DEV : PHAT_PROD}/static/${res.file}`)

                                                                            file.current.value = '';
                                                                        }).catch((err) => {
                                                                            console.log(err);
                                                                        })

                                                                    }
                                                                } style={{
                                                                    position: "absolute",
                                                                    top: "0px",
                                                                    left: "0px",
                                                                    top: "-1000px",
                                                                    left: "-1000px",
                                                                    zIndex: "999999999",
                                                                }} accept="image/jpeg, image/png" />

                                                                <div>
                                                                    <button className="btn btn-primary shadow-none" style={{
                                                                        background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)",
                                                                        borderRadius: "15px"
                                                                    }} onClick={
                                                                        () => {
                                                                            file.current.click();
                                                                        }}>
                                                                        <EnviarIcon style={{
                                                                            width: "20px",
                                                                            height: "20px",
                                                                            fill: "#fff",
                                                                            marginRight: "6px",
                                                                            marginLeft: "-5px"
                                                                        }} />
                                                                        Subir imagen
                                                                    </button >
                                                                </div>

                                                                <SRLWrapper options={{
                                                                    buttons: {
                                                                        showAutoplayButton: false,
                                                                        showCloseButton: false,
                                                                        showDownloadButton: false,
                                                                        showFullscreenButton: false,
                                                                        showNextButton: false,
                                                                        showPrevButton: false,
                                                                        showThumbnailsButton: false
                                                                    },
                                                                    thumbnails: {
                                                                        showThumbnails: false
                                                                    }
                                                                }}>
                                                                    {
                                                                        (image != "") && <>
                                                                            <br />
                                                                            <div>
                                                                                <div>
                                                                                    <img src={image} style={{
                                                                                        width: "150px",
                                                                                        height: "150px",
                                                                                        borderRadius: "10px",
                                                                                        cursor: "pointer"
                                                                                    }} />
                                                                                </div>
                                                                                <button className="btn btn-primary shadow-none" style={{
                                                                                    width: "150px",
                                                                                    marginTop: "5px",
                                                                                    background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)",
                                                                                    borderRadius: "15px"
                                                                                }} onClick={
                                                                                    (e) => {
                                                                                        setImage('');
                                                                                    }
                                                                                }>Eliminar</button>
                                                                            </div>
                                                                        </>
                                                                    }

                                                                </SRLWrapper>

                                                            </div>

                                                            <div >
                                                                <div style={{
                                                                    fontWeight: "700",
                                                                    color: "rgb(8, 55, 155)",
                                                                    marginTop: "10px"
                                                                }}>Mensaje</div>
                                                                <textarea type="text" value={message} className="shadow p-2  bg-body rounded" onChange={
                                                                    (e) => {

                                                                        var texto = e.target.value;
                                                                        var nt = texto.length;

                                                                        setN_text(nt);

                                                                        if (nt > 0) {
                                                                            if (nt <= 160) {
                                                                                setN_pages(1)
                                                                            } else {
                                                                                setN_pages(nt / 160);
                                                                            }

                                                                        }



                                                                        setMessage(e.target.value);

                                                                    }
                                                                } style={{
                                                                    background: "#F2F7f8",
                                                                    border: "0px",
                                                                    padding: "10px",
                                                                    resize: "none",
                                                                    minHeight: "200px",
                                                                    fontSize: "20px",
                                                                }} placeholder="Mensaje" className="w-100 shadow bg-body rounded"></textarea>

                                                                <div style={{
                                                                    display: "flex"
                                                                }}>
                                                                    <div style={{
                                                                        width: "50%"
                                                                    }}>
                                                                        Cantidad de caracteres: {n_text}
                                                                    </div>
                                                                    <div style={{
                                                                        width: "50%",
                                                                        textAlign: "right"
                                                                    }}>
                                                                        Cantidad de paginas: {n_pages}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{
                                                    minHeight: "100px",
                                                    marginTop: "10px"
                                                }}>

                                                    {
                                                        (currentCampaign.ref === "") && <div>
                                                            <button className="btn btn-primary shadow-none" style={{
                                                                background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)",
                                                                borderRadius: "15px"
                                                            }} onClick={
                                                                () => {

                                                                    // save-campaign 
                                                                    if (channel != "") {

                                                                        if (name != "") {

                                                                            var groups = [];

                                                                            for (let i = 0; i < contactGroups.length; i++) {
                                                                                const group = contactGroups[i];
                                                                                groups.push(group.value);
                                                                            }

                                                                            save({
                                                                                ref: currentCampaign.ref,
                                                                                iid: window.localStorage.getItem("say-bussine"),
                                                                                image,
                                                                                name,
                                                                                channel,
                                                                                groups,
                                                                                message
                                                                            }).then((res) => {

                                                                                console.log("res");
                                                                                console.log(res);

                                                                                getAllCampaigns(window.localStorage.getItem("say-bussine")).then((res) => {
                                                                                    console.log(res);
                                                                                    setCampaigns(res);
                                                                                }).catch((err) => {
                                                                                    console.log(err);
                                                                                })

                                                                                setCurrentCampaign({
                                                                                    ref: null,
                                                                                    name: '',
                                                                                    channel: '',
                                                                                    groups: '',
                                                                                    message: ''
                                                                                })

                                                                            }).catch((error) => {
                                                                                toast.error(error.data);
                                                                            })

                                                                        } else {
                                                                            toast.error("Completa el formulario");
                                                                        }

                                                                    } else {
                                                                        toast.error("Selecciona un canal de envió");
                                                                    }

                                                                }}>

                                                                <EnviarIcon style={{
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    fill: "#fff",
                                                                    marginRight: "6px",
                                                                    marginLeft: "-5px"
                                                                }} />
                                                                Enviar
                                                            </button >
                                                        </div >
                                                    }



                                                </div >
                                            </div>
                                        }


                                        <br />
                                        <br />

                                        {
                                            /*
                                            (currentCampaign.ref != '') && <div style={{
                                                textAlign: "right"
                                            }}>
                                                <button className="btn btn-danger shadow-none" style={{
                                                    padding: "6px", background: "rgb(237,11,11)",
                                                    background: "linear-gradient(180deg, rgba(237,11,11,1) 0%, rgba(227,84,98,1) 100%)"
    
                                                }} onClick={
                                                    () => {
                                                        remove(currentCampaign.ref).then((res) => {
                                                            console.log(res);
    
                                                            getAllCampaigns(window.localStorage.getItem("say-bussine")).then((res) => {
                                                                console.log(res);
                                                                setCampaigns(res);
                                                            }).catch((err) => {
                                                                console.log(err);
                                                            })
    
    
                                                            setCurrentCampaign({
                                                                ref: null,
                                                                name: '',
                                                                channel: '',
                                                                groups: '',
                                                                message: ''
                                                            })
    
                                                            onClear();
    
                                                            toast.success("La campaña se elimino correctamente");
    
                                                        }).catch((error) => {
                                                            console.log(error);
                                                        })
                                                    }
                                                }>
                                                    <DeleteIcon style={{
                                                        width: "20px",
                                                        height: "20px",
                                                        fill: "#fff"
                                                    }} /> Eliminar campaña</button>
                                            </div>
                                            */
                                        }



                                        <br />

                                        <br />

                                    </div >

                                </div >
                            }


                        </div >

                    </div >

                </div >

            </EnvApp >
        </SimpleReactLightbox>
    );
}

export default Campaign;

