import axios from 'axios';

import { PHAT_DEV, PHAT_PROD, DEV, URL_SOCKET_DEV, URL_SOCKET_PROD } from '../config';



export const saveKnowledge = (payload) => {
    return new Promise((resolve, reject) => {
        axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/add-knowledge`, payload)
       
            .then((response) => {
            
                resolve(response.data);

            }, (error) => {
                reject(error);
            });
    })
}

export const deleteKnowledge = (ref) => {
    return new Promise((resolve, reject) => {
        axios.delete(`${(DEV) ? PHAT_DEV : PHAT_PROD}/knowledge/${ref}`, {})
       
            .then((response) => {
            
                resolve(response.data);

            }, (error) => {
                reject(error);
            });
    })
}

export const getKnowledges = (iid) => {
    return new Promise((resolve, reject) => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/knowledge/${iid}`, {})
        
            .then((response) => {
            
                resolve(response.data);

            }, (error) => {
                reject(error);
            });
    })
}