import React, { useState, useEffect, useRef, useContext } from 'react';

import { useHistory, useParams } from "react-router-dom";

import axios from 'axios';

import { PHAT_DEV, PHAT_PROD, DEV, URL_SOCKET_DEV, URL_SOCKET_PROD } from '../../../config';

import SocketProvider from '../../../contexts/SocketProvider';

import ProductCard from './ProductCard';

import ProductItem from './ProductItem';

import Conn from '../../../assets/intro-connection.jpg';

import { ReactComponent as UserIcon } from '../../../assets/user.svg';

import { getStatistics, searchProducts, markUnread, closedChat, takeChat, loadPrevMessagess, getChatsUnassigned, getChatsAssigned, getClosedChats, getMessages } from '../../../services/Chat';

import { AppContext } from '../../../contexts/AppProvider';

import Side from './Side';

import toast from 'react-hot-toast';

import audio from '../../../assets/whatsapp-apple.mp3';

function Chat(props) {

    const { chatSender, CurrentChat } = useContext(AppContext);

    const history = useHistory();

    const { ref } = useParams();

    const [startResult, setStartResult] = useState([]);

    const [Items, setItems] = useState([]);

    const [searching, setSearching] = useState(false);

    const [total, setTotal] = useState(0);
    useEffect(()=>{
        for (let i = 0; i < Items.length; i++) {
            const product = Items[i];
            setTotal(total + parseInt(product.price))
        }
    }, [Items])

    return (
        <div style={{
            background: "#fff",
            borderLeft: "2px solid #eeeded",
            display: "grid",
            gridTemplateColumns: "auto",
            gridTemplateRows: "65px 55px auto 30px 250px 30px",
            overflow: "auto"
        }}>

            <div style={{
                padding: "10px"
            }}>
                <div style={{
                    color: "#3883B0",
                    fontWeight: "700"
                }}>SayForm Pos</div>
                <div style={{
                    color: "#939190"
                }}>Ubica productos, envialos a tus clientes.</div>
            </div>

            <div style={{
                alignSelf: "center",
                borderBottom: "2px solid #eeeded"
            }}>
                <div style={{
                }}>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "20px",
                        paddingLeft: "5px"
                    }}>
                        <div style={{
                            width: "30px",
                            height: "30px"
                        }}>
                            <i className="las la-search" style={{
                                fontSize: "30px",
                                color: "#939190"
                            }}></i>
                        </div>

                        <div style={{
                            width: "100%"
                        }}>
                            <input type="text" onChange={
                                (e) => {
                                    setSearching(true)
                                    searchProducts(window.localStorage.getItem("say-url"), e.target.value).then((res) => {
                                        setStartResult(res)
                                        setSearching(false)
                                    })
                                }
                            } onKeyUp={
                                (e) => {

                                }
                            } className="form-control shadow-none searcher" style={{
                                width: "100%",
                                height: "35px",
                                border: "0px",
                                background: "#fff",
                                borderRadius: "20px",
                                fontSize: "14px"
                            }} placeholder="Buscar un producto" />
                        </div>
                    </div>
                </div>
            </div>

            <div style={{
                overflow: "auto",
                padding: "10px",
                borderBottom: "2px solid #eeeded"
            }}>

                {
                    (searching) && <>
                        <div>Buscando...</div>
                    </>
                }

                {
                    (startResult.length === 0 && !searching) && <>
                        <div>Sin resultados</div>
                    </>
                }

                {
                    startResult.map((Product, i) => {

                        return (<ProductCard onSend={async (guid) => {


                            props.onClick({
                                ref: CurrentChat.ref,
                                iid: window.localStorage.getItem("say-bussine"),
                                jid: CurrentChat.jid,
                                fromMe: true,
                                sender: CurrentChat.sender,
                                name: window.localStorage.getItem("say-name"),
                                rol: 'agent',
                                status: '',
                                channel: CurrentChat.channel,
                                message: {
                                    id: '',
                                    caption: '',
                                    message: guid,
                                    mimetype: 'application/text',
                                    mention: {
                                        id: '',
                                        message: '',
                                        caption: '',
                                        mimetype: '',
                                    }
                                }
                            })

                        }} guid={Product.guid} name={Product.post_title} photo={Product.image} onClick={() => {
                            //setName(product.name)
                            var price = prompt("Que precio tiene el producto")
price = price.replace(".","")
price = price.replace(",","")


if(Number.isInteger(parseInt(price))){
    setItems((prev) => [...prev, {
        guid: Product.guid,
        name: Product.post_title,
        image: Product.image,
        price
    }])
}else{
    alert("Monto no valido")
}
console.log(price)
                           

                        }} />)

                    })
                }

            </div>
            <div style={{

                fontWeight: "500",
                fontSize: "15px",
                textAlign: "center",


            }}> CARRITO
            </div>
            <div style={{
                overflow: "auto"

            }}>


                <div>

                    {
                        Items.map((Product, i) => {

                            return (<ProductItem onSend={async (guid) => {


                                props.onClick({
                                    ref: CurrentChat.ref,
                                    iid: window.localStorage.getItem("say-bussine"),
                                    jid: CurrentChat.jid,
                                    fromMe: true,
                                    sender: CurrentChat.sender,
                                    name: window.localStorage.getItem("say-name"),
                                    rol: 'agent',
                                    status: '',
                                    channel: CurrentChat.channel,
                                    message: {
                                        id: '',
                                        caption: '',
                                        message: guid,
                                        mimetype: 'application/text',
                                        mention: {
                                            id: '',
                                            message: '',
                                            caption: '',
                                            mimetype: '',
                                        }
                                    }
                                })

                            }} guid={Product.guid} name={Product.name} photo={Product.image} price={Product.price} />)

                        })
                    }
                </div>


            </div>
            <div>
                <div style={{

                    fontWeight: "500",
                    fontSize: "15px"
                }}> Total: ${total} </div>
            </div>

        </div>
    );
}

export default Chat;