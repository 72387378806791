import React, { useState, useEffect } from 'react';

import { useHistory } from "react-router-dom";

function Dispenser(props) {

    const history = useHistory();

    const [services, setServices] = useState([
        {
            name: 'Admisiones',
            color: '#f0c7b3'
        },
        {
            name: 'Financiero',
            color: '#d86d96'
        },
        {
            name: 'Talento Humano',
            color: '#a3bdc6'
        },
        {
            name: 'Otros',
            color: '#3893ff'
        },
        {
            name: 'Preferencial',
            color: '#36d3ff'
        }
    ])

    useEffect(() => {

    }, []);

    return (
        <div style={{
            width: "100%",
            height: "100vh",
            overflow: "auto"
        }}>

            <div style={{
                maxWidth: "700px",
                margin: "0 auto",
                textAlign: "center",
                padding: "30px"
            }}>

                <div id="logo">
                    <img src="https://pascualbravo.edu.co/wp-content/uploads/2019/12/cropped-Institucion_Pascual_Bravo_Logo.png" style={{
                        width: "100%"
                    }} />
                </div>

                <div>
                    <div style={{
                        fontSize: "30px"
                    }}>BIENVENIDO AL CIS</div>

                    <div style={{
                        borderBottom: "2px solid #000",
                        paddingBottom: "10px",
                        marginBottom: "10px",
                        fontSize: "25px",
                        marginTop: "20px"
                    }}>Por favor tome su turno</div>

                    <div>

                        {
                            services.map((service, serviceIndex) => {

                                return (<button style={{
                                    background: service.color,
                                    fontSize: "50px",
                                    color: "#fff",
                                    width: "100%",
                                    padding: "20px",
                                    marginTop: "10px",
                                    textTransform: "uppercase"
                                }}>
                                    {service.name}
                                </button>)

                            })
                        }

                    </div>

                </div>

            </div>


        </div>
    );
}

export default Dispenser;

