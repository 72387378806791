import React, { useState, useEffect, useContext } from 'react';

import axios from 'axios';

import { PHAT_DEV, PHAT_PROD, DEV, URL_SOCKET_DEV, URL_SOCKET_PROD } from '../../config';

import { AppContext } from '../../contexts/AppProvider';

import { loadIntegrations } from '../../services/Integration';

import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';

import { ReactComponent as CancelIcon } from '../../assets/icons/xmark.svg';

import { ReactComponent as ChatbotIcon } from '../../assets/icons/chatbot.svg';

import { ReactComponent as SaveIcon } from '../../assets/save.svg';

import AgentCard from './AgentCard';

import toast from 'react-hot-toast';

function DialogFlow(props) {

    const { currentIntegration, setCurrentIntegration } = useContext(AppContext);

    const [iid, setIid] = useState(window.localStorage.getItem("say-bussine"));

    const [ref, setRef] = useState('');

    const [name, setName] = useState('');

    const [active, setActive] = useState('1');

    const [projectId, setProjectId] = useState('');

    const [privateKey, setPrivateKey] = useState('');

    const [clientEmail, setClientEmail] = useState('');

    const [showList, setShowList] = useState(true);

    const [showForm, setShowForm] = useState(false);

    const [integrations, setIntegrations] = useState([]);

    useEffect(() => {
        load();
    }, [])

    const remove = (ref, service) => {
        return new Promise((resolve, reject) => {
            axios.delete(`${(DEV) ? PHAT_DEV : PHAT_PROD}/integration/${ref}/${service}`, {})
                .then((response) => {
                    // response.status === 200 
                    if (response.status === 200) {
                        resolve(response.data);
                    } else {
                        reject("Ocurrió un problema al recuperar las integraciones.");
                    }
                }, (error) => {
                    reject(error);
                });
        })
    }

    const load = () => {
        axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/get-integrations/${iid}/dialogflow`, {})
            .then((response) => {
                if (response.status === 200) {
                    console.log("response.data");
                    console.log(response.data);
                    setIntegrations(response.data);
                } else {
                    toast.error("Ocurrio un problema al guardar la integración.");
                }
            }, (error) => {
                console.log(error);
            });
    }

    const clearForm = () => {
        setRef("");

        setName("");

        setProjectId("");

        setPrivateKey("");

        setClientEmail("");

        setActive("1");
    }

    const save = (payload) => {
        // Notificamos al sevidor para guardar la configuracion y activar el uso del dialogflow
        return new Promise((resolve, reject) => {
            axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/save-integration`, payload)
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response.data);
                    } else {
                        reject("Ocurrió un problema al guardar la integración.");
                    }
                }, (error) => {
                    reject(error);
                });
        })
    }

    return (
        <div>
            <div style={{
                padding: "0px 50px 0px 50px"
            }}>

                <br />

                <div style={{
                    minHeight: "100px"
                }}>

                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "auto 150px",
                        gridTemplateRows: "auto"
                    }}>
                        <div>
                            <div style={{
                                fontSize: "30px",
                                fontWeight: "700",
                                display: "flex"
                            }}>
                                <div style={{
                                    marginRight: "10px",
                                    color: "#1E2347"
                                }}>Dialogflow</div>
                                <div style={{
                                    fontSize: "15px"
                                }}>
                                    <div style={{
                                        width: "50px",
                                        color: "#fff",
                                        textAlign: "center",
                                        background: "#6666FF",
                                        borderRadius: "5px"
                                    }}>
                                        PRO
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            {
                                (showList === true) && <>
                                    <button
                                        style={{
                                            borderRadius:"45px",
                                            background: "rgb(8,55,155)",
                                            background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)"
                                        }}
                                        onClick={
                                            () => {
                                                console.log("Mostramos el formulario y ocultamos la lista!!!!");
                                                setShowForm(true);
                                            }
                                        } className="btn btn-primary">
                                        <PlusIcon style={{
                                            width: "20px",
                                            height: "25px",
                                            fill: "#fff"
                                        }} />
                                        Nuevo Agente
                                    </button>
                                </>
                            }
                        </div>
                    </div>

                    <br />

                    <div>Mejora tu estrategia de conversación dotando a tus bots de potencia de procesamiento de lenguaje natural mediante la integración de Dialogflow.</div>

                    <br />

                    {
                        (showList === true) && <>
                            <div style={{
                                marginTop: "10px"
                            }}>

                                {
                                    (!showForm) && <>
                                        <div style={{
                                            marginTop: "25px",
                                            display: "flex",
                                            marginBottom: "25px"
                                        }}>

                                            {
                                                (integrations.length === 0) && <div style={{
                                                    width: "100%",
                                                    textAlign: "center",
                                                    fontWeight: "500",
                                                    fontSize: "20px"
                                                }}>
                                                    <div>
                                                        <ChatbotIcon style={{
                                                            width: "200px",
                                                            height: "200px",
                                                            fill: "#fff"
                                                        }} /></div>

                                                    <div> Sin agentes virtuales conectados</div>
                                                </div>
                                            }

                                            {
                                                integrations.map((integration, integrationIndex) =>
                                                    <div key={integrationIndex} style={{
                                                        marginTop: "10px",
                                                        marginLeft: "10px"
                                                    }}><AgentCard id={integration.ref} name={integration.name} projectId={integration.projectId} onDelete={
                                                        () => {
                                                            // Eliminamos 
                                                            remove(integration.ref, 'DIALOGFLOW').then((res) => {

                                                                load();

                                                                clearForm()

                                                                setShowList(true);

                                                                setShowForm(false);

                                                                toast.success("La integración se eliminó correctamente")

                                                            }).catch((err) => {
                                                                console.log(err);
                                                            })
                                                        }
                                                    } onEdit={
                                                        () => {

                                                            setRef(integration.ref);

                                                            setName(integration.name);

                                                            setProjectId(integration.projectId);

                                                            setPrivateKey(integration.privateKey);

                                                            setClientEmail(integration.clientEmail);

                                                            setShowList(true);

                                                            setShowForm(true);

                                                        }
                                                    } /></div>
                                                )
                                            }
                                        </div>
                                    </>
                                }

                                {
                                    (showForm) && <div>

                                        <div className="row">
                                            <div className="col-12 mt-2" >
                                                <input type="text" value={name} onChange={
                                                    (e) => {
                                                        setName(e.target.value)
                                                    }
                                                }  placeholder="Nombre" className="shadow w-100"   />
                                            </div>
                                            <div className="col-6 mt-4">
                                                <input type="text" value={projectId} onChange={
                                                    (e) => {
                                                        setProjectId(e.target.value)
                                                    }
                                                } placeholder="Project id" className="shadow w-100" />
                                            </div>
                                            <div className="col-6 mt-4">
                                                <input type="text" value={clientEmail} onChange={
                                                    (e) => {
                                                        setClientEmail(e.target.value)
                                                    }
                                                } placeholder="Client email" className="shadow w-100" />
                                            </div>
                                            <div className="col-12 mt-4">
                                                <textarea type="text" style={{
                                                    background: "#F2F7f8",
                                                    border: "0px",
                                                    padding: "10px",
                                                    resize: "none",
                                                    minHeight: "200px"
                                                }} value={privateKey} onChange={
                                                    (e) => {
                                                        setPrivateKey(e.target.value)
                                                    }
                                                } placeholder="Private key" className="shadow w-100"></textarea>
                                            </div>
                                        </div>

                                        <br />

                                        <div>
                                            <div  style={{
                                                fontWeight: "500"
                                            }}>Activo</div>
                                            <br />
                                            <select value={active} className="shadow" onChange={
                                                (e) => {
                                                    setActive(e.target.value);
                                                }
                                            }>
                                                <option value="1">Si</option>
                                                <option value="0">No</option>
                                            </select>
                                        </div>

                                        <br />

                                        <div style={{
                                            minHeight: "100px"
                                        }}>
                                            <div>

                                                <button className="btn btn-primary shadow-none" style={{
                                                    marginLeft: "2px", marginRight: "2px",
                                                    background: "rgb(8,55,155)",
                                                    background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)",
                                                    borderRadius:"15px"


                                                }} onClick={
                                                    () => {
                                                        if (clientEmail != '' && projectId != '' && privateKey != '') {

                                                            save({
                                                                ref,
                                                                iid,
                                                                type: "DIALOGFLOW",
                                                                name,
                                                                client_email: clientEmail,
                                                                project_id: projectId,
                                                                private_key: privateKey,
                                                                active
                                                            }).then((response) => {

                                                                toast.success("La integración se agregó correctamente")

                                                                clearForm();

                                                                load();

                                                                setShowList(true);

                                                                setShowForm(false);

                                                            }).catch((error) => {
                                                                console.log(error);
                                                            })

                                                        } else {
                                                            toast.error("Complete el formulario")
                                                        }
                                                    }
                                                } ><SaveIcon style={{
                                                    width: "15px",
                                                    height: "20px",
                                                    fill: "#fff",
                                                    marginRight: "2px"
                                                }} />
                                                    Guardar
                                                </button>
                                                <button className="btn btn-danger shadow-none"
                                                style={{
                                                    background: "rgb(237,11,11)",
                                                    background: "linear-gradient(180deg, rgba(237,11,11,1) 0%, rgba(227,84,98,1) 100%)",
                                                    borderRadius:"15px"
                                                }}
                                                onClick={
                                                    () => {
                                                        setShowForm(false);
                                                        setShowList(true);
                                                    }
                                                } ><CancelIcon style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    fill: "#fff",
                                                    marginRight: "-2px"
                                                }} />
                                                    Cancelar
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                }

                            </div>
                        </>
                    }

                </div>

            </div>

        </div>
    );
}

export default DialogFlow;