import React, { useState, useEffect } from 'react';

import toast, { Toaster } from 'react-hot-toast';

import { useHistory } from "react-router-dom";

import { Link } from 'react-router-dom';

import { login } from '../../services/Auth';

import bg from '../../assets/fonflogin.jpg';

import Imglogo from '../../assets/Logop.png';

import Imglogin from '../../assets/fonflogi.png';

function Login() {

    const history = useHistory();

    const [Email, setEmail] = useState('');

    const [Password, setPassword] = useState('');

    useEffect(() => {
        document.title = 'SayForm - Inciar sesión';
    }, [])

    return (
        <div style={{
            display: "grid",
            gridTemplateColumns: "500px auto",
            gridTemplateRows: "auto",
            width: "100%",
            height: "100vh",
            overflow: "auto"
        }}>
            <div style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "auto"
            }}>
                <div style={{
                    padding: "20px",
                    minWidth: "400px"
                }}>
                    <div style={{
                        textAlign: "center"
                    }}>
                        <div>
                            <img src={Imglogo} style={{
                                width: "100px"
                            }} />
                        </div>

                        <div style={{
                            fontSize: "35px",
                            fontWeight: "600",
                            color: "#1E3142",
                        }}>Sayform</div>
                        <div style={{
                            fontSize: "18px",
                            fontWeight: "600",
                            color: "#1278BF",
                        }}>Inicie sesión en su cuenta</div>

                    </div>

                    <br />

                    <div>

                        <div>
                            <div className="mb-2" style={{
                                fontWeight: "500"
                            }}>Correo electrónico</div>
                            <input type="email" name="email" className="form-control shadow-none" style={{
                                background: "#F3F4F5",
                                border: "0px"
                            }} value={Email} onChange={
                                (e) => {
                                    setEmail(e.target.value);
                                }
                            } placeholder="example@gmail.com" />
                        </div>

                        <br />

                        <div>
                            <div className="mb-2" style={{
                                fontWeight: "500"
                            }}>Contraseña</div>
                            <input type="password" name="password" className="form-control shadow-none" style={{
                                background: "#F3F4F5",
                                border: "0px"
                            }} value={Password} onChange={
                                (e) => {
                                    setPassword(e.target.value);
                                }
                            } placeholder="Contraseña" />
                        </div>

                        <br />

                        <div>
                            <button className="btn btn-primary" style={{
                                width: "100%",
                                padding: "10px",
                                background: "rgb(8,55,155)",
                                background: "linear-gradient(180deg, rgba(8,55,155,1) 31%, rgba(13,110,253,1) 100%)",
                                borderRadius:"15px"
                            }} onClick={
                                (e) => {

                                    if (Email != "" && Password != "") {
                                        login({
                                            email: Email,
                                            password: Password
                                        }).then((res) => {

                                            toast.success('Identificación correcta!.')

                                            if (res.rol != "master") {

                                                localStorage.clear();

                                                localStorage.setItem("say-token", res.token);
                                                localStorage.setItem("say-name", res.name);
                                                localStorage.setItem("say-nickname", res.nickname);

                                                localStorage.setItem("say-phone", res.phone);
                                                localStorage.setItem("say-bussine", res.bussine.ref);
                                                localStorage.setItem("say-bName", res.bussine.name);
                                                localStorage.setItem("say-email", res.email);
                                                localStorage.setItem("say-channels", res.channels);
                                                localStorage.setItem("say-ref", res.ref);
                                                localStorage.setItem("say-photo", res.photo);
                                                localStorage.setItem("say-rol", res.rol);

                                                if (res.rol === "agent") {
                                                    history.push("/chat/assigned");
                                                } else {
                                                    history.push("/agents");
                                                }

                                            } else {
                                                localStorage.setItem("say-ref", res.ref);
                                                localStorage.setItem("say-name", res.name);
                                                localStorage.setItem("say-rol", res.rol);
                                                history.push("/admin/pending");
                                            }

                                        }).catch((err) => {
                                            if (err.response) {
                                                toast.error(err.response.data.message)
                                            }
                                        })
                                    } else {
                                        toast.error("Complete el formulario para continuar")
                                    }

                                }
                            }>Iniciar sesión</button>

                            <br />

                            <div style={{
                                paddingTop: "10px",
                                textAlign: "center"
                            }}>
                                <Link to="/register">Crear nueva cuenta</Link>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                background: "url(" + bg + ")no-repeat center center fixed"

            }}>

                <div style={{
                    maxWidth: "600px",
                    padding:"20px"
                }}>
                    <img src={Imglogin} style={{
                        width: "100%"
                    }} />
                </div>
            </div>
        </div>
    );
}

export default Login;