import React, { useState, useEffect, useContext } from 'react';

import axios from 'axios';

import toast from 'react-hot-toast';

import {
    useParams
} from "react-router-dom";

import Messages from './Messages';

import { WidgetContext } from '../../contexts/WidgetProvider';

import QualifierBlock from '../../components/QualifierBlock';

import { DEV, PHAT_DEV, PHAT_PROD } from '../../config';

function Widget() {

    const { Timeline, setTimeline, qualifier, setQualifier, status, setStatus, setPage } = useContext(WidgetContext);

    let { iid, sender } = useParams();

    useEffect(() => {
        // Cargamos el chat

        if (window.localStorage.getItem('ref') != null) {
            axios.get(`${(DEV) ? PHAT_DEV : PHAT_PROD}/get-web-conversation/${window.localStorage.getItem('ref')}`, {})
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data != null) {
                            setQualifier(response.data.qualifier);
                            setTimeline(response.data.timeline);
                            setStatus(response.data.status)
                        } else {
                            window.localStorage.clear();
                            window.location.reload();
                        }
                    } else {
                        alert("Ocurrió un problema al intentar recuperar los mensajes del chat.");
                    }
                }, (error) => {
                    console.log(error.response.data);
                });
        }

    }, [])

    useEffect(() => {
        var a = document.getElementById("enva");
        if (a != null) {
            a.scrollTop = a.scrollHeight; // (0, a.offsetHeight);
        }
    }, [Timeline]);


    return (
        <>
            <div id="enva" style={{
                overflow: "auto"
            }}>

                <Messages />

                {
                    (status === 1) && <>
                        <QualifierBlock id={window.localStorage.getItem('widget-ref')} n={qualifier.n} d={qualifier.d} onSalve={
                            ({ n, d }) => {

                                toast.success("Chat calificado")

                                setQualifier({
                                    n,
                                    d
                                })


                                setPage('lobby');

                                window.localStorage.setItem("widget-page", "lobby");

                                window.localStorage.removeItem("widget-ref");



                            }
                        } />
                    </>
                }

            </div>
        </>

    );
}

export default Widget;