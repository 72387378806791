import React, { useState, useEffect } from 'react';

import { useHistory } from "react-router-dom";

import TurnCard from './TurnCard';

import ColorPicker from './ColorPicker';

import Up from './Up';

function ScreenBuilder() {

    const history = useHistory();


    const [colorPrimary, setColorPrimary] = useState({
        r: 0,
        g: 0,
        b: 0,
        a: 1
    });

    const [colorSecondary, setColorSecondary] = useState({
        r: 255,
        g: 255,
        b: 255,
        a: 1
    });

    useEffect(() => {

    }, []);

    return (
        <div style={{
            width: "100%",
            height: "100vh",
            background: `rgba(${colorPrimary.r}, ${colorPrimary.g}, ${colorPrimary.b}, ${colorPrimary.a})`,
            display: "grid",
            gridTemplateColumns: "100%",
            gridTemplateRows: "80px calc(100% - 80px)",
            color: "#fff"
        }}>
            <div style={{
                display: "grid",
                gridTemplateColumns: "60px 65px 200px auto 55px"
            }}>
                <div style={{
                    width: "60px",
                    paddingLeft: "10px",
                    alignSelf: "center",
                    fontSize: "35px"
                }}>
                    <button style={{
                        background: "transparent",
                        color: "#fff"
                    }}>
                        <i class="las la-angle-left"></i>
                    </button>
                </div>
                <div style={{
                    marginLeft: "10px",
                    alignSelf: "center"
                }}>
                    <div>
                        <img src="https://cloudcitycolombia.com/imagenes/pascual/logox.jpg" style={{
                            width: "60px",
                            borderRadius: "50%"
                        }} />
                    </div>
                </div>
                <div style={{
                    marginLeft: "20px",
                    width: "200px",
                    alignSelf: "center"
                }}>
                    <div style={{
                        fontSize: "20px"
                    }}>Editor de pantalla</div>
                    <div style={{
                        fontSize: "20px"
                    }}><i class="las la-cloud-upload-alt"></i> ...</div>
                </div>
                <div style={{
                    width: "100%",
                    alignSelf: "center"
                }}></div>
                <div style={{
                    width: "50px",
                    alignSelf: "center"
                }}>
                    <button style={{
                        background: "transparent",
                        color: "#fff",
                        fontSize: "40px"
                    }}><i class="las la-save"></i></button>
                </div>
            </div>
            <div>
                <div style={{
                    width: "100%",
                    height: "100%",
                    display: "grid",
                    gridTemplateColumns: "80px auto",
                    gridTemplateRows: "auto",
                    color: "#fff"
                }}>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>

                        <div>
                            <ColorPicker color={
                                {
                                    r: 0,
                                    g: 0,
                                    b: 0,
                                    a: 1
                                }
                            } onChange={
                                (color) => {
                                    setColorPrimary(color);
                                }
                            } />

                            <ColorPicker color={
                                {
                                    r: 255,
                                    g: 255,
                                    b: 255,
                                    a: 1
                                }
                            } onChange={
                                (color) => {
                                    setColorSecondary(color);
                                }
                            } />
                        </div>

                    </div>

                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "auto 400px",
                        gridTemplateRows: "auto",
                        color: "#fff",
                        padding: "20px"
                    }}>

                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>

                            <div style={{
                                width: "1024px",
                                height: "750px",
                                borderRadius: "10px",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <div style={{
                                    width: "100%",
                                    display: "grid",
                                    gridTemplateColumns: "auto 200px",
                                    gridTemplateRows: "auto",
                                    marginBottom: "10px"
                                }}>
                                    <div style={{
                                        alignSelf: "center",
                                        border: "3px dashed #fff",
                                        height: "140px",
                                        marginRight: "10px",
                                        borderRadius: "10px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}>
                                        <Up />
                                    </div>
                                    <div style={{
                                        alignSelf: "center",
                                        border: "3px dashed #fff",
                                        borderRadius: "10px",
                                        height: "140px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}>
                                        <Up />
                                    </div>
                                </div>
                                <div style={{
                                    borderRadius: "10px",
                                    width: "100%",
                                    minHeight: "572px",
                                    background: "repeating-linear-gradient(-55deg, #222, #222 20px, #333 20px, #333 40px)"
                                }}>
                                </div>
                                <div style={{
                                    width: "100%",
                                    display: "grid",
                                    gridTemplateColumns: "auto 200px",
                                    gridTemplateRows: "auto",
                                    marginTop: "10px"
                                }}>
                                    <div style={{
                                        alignSelf: "center",
                                        textAlign: "center",
                                        fontSize: "20px",
                                        border: "3px dashed #fff",
                                        height: "140px",
                                        marginRight: "10px",
                                        borderRadius: "10px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}>
                                        <Up />
                                    </div>
                                    <div style={{
                                        alignSelf: "center",
                                        border: "3px dashed #fff",
                                        height: "140px",
                                        borderRadius: "10px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}>
                                        <Up />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div>

                            <div style={{
                                border: `3px solid rgba(${colorSecondary.r}, ${colorSecondary.g}, ${colorSecondary.b}, ${colorSecondary.a})`,
                                fontSize: "40px",
                                padding: "10px",
                                borderRadius: "10px",
                                textAlign: "center"
                            }}>TURNOS</div>

                            <div style={{
                                marginTop: "30px"
                            }}>

                                <TurnCard color={colorSecondary} />

                                <TurnCard color={colorSecondary} />

                                <TurnCard color={colorSecondary} />

                                <TurnCard color={colorSecondary} />

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
}

export default ScreenBuilder;

