import React, { useState, useEffect, useRef } from 'react';

import { useHistory } from "react-router-dom";

import toast, { Toaster } from 'react-hot-toast';

import InstanceExplorer from './InstanceExplorer';

import "./index.css";

import { saveBussine, getBussines } from '../../services/Admin';

import { ReactComponent as UserIcon } from '../../assets/user.svg';

import { ReactComponent as SearchIcon } from '../../assets/search.svg';

import { ReactComponent as PlusIcon } from '../../assets/plus.svg';

import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';


import AgentImg from '../../assets/Agents.png';

import EnvApp from '../../components/EnvApp';

function Clients() {

    const history = useHistory();

    const [ref, setRef] = useState('');

    const [name, setName] = useState('');

    const [search, setSearch] = useState('');

    const [currentBussine, setCurrentBussine] = useState({
        ref: null,
        name: ""
    });

    const [bussines, setBussines] = useState([]);

    useEffect(() => {

        setName(currentBussine.name);

    }, [currentBussine]);

    useEffect(() => {

        getBussines().then((bussines_) => {
            setBussines(bussines_);
        }).catch((err) => {
            console.log(err);
        })

    }, []);

    return (

        <EnvApp>

            <div style={{
                display: "grid",
                gridTemplateColumns: "auto",
                gridTemplateRows: "100px auto"
            }}>

                <div style={{
                    padding: "20px 50px 20px 50px"
                }}>

                    <div style={{
                        fontSize: "30px",
                        width: "100%",
                        height: "100%",
                        borderBottom: "1px solid #E8EBF0",
                        display: "flex"
                    }}>
                        <div style={{
                            width: "100%"
                        }}>
                            Clientes
                        </div>
                        <div style={{
                            width: "250px",
                            textAlign: "right"
                        }}>
                            <button onClick={
                                () => {
                                    setCurrentBussine({
                                        ref: "",
                                        name: ""
                                    })
                                }
                            } className="btn btn-primary">
                                <PlusIcon style={{
                                    width: "20px",
                                    height: "20px",
                                    fill: "#fff"
                                }} /> Nuevo cliente</button>
                        </div>
                    </div>



                </div>

                <div style={{
                    padding: "20px 50px 20px 50px"
                }}>

                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "400px auto",
                        gridTemplateRows: "auto",
                        height: "100%"
                    }}>
                        <div style={{
                            height: "100%"
                        }}>

                            <div style={{
                                display: "flex",
                                alignItems: "center"
                            }}>

                                <input type="text" value={search} style={{
                                    background: "#F2F7F8",
                                    border: "0px",
                                    height: "50px"
                                }} onChange={
                                    (e) => {
                                        setSearch(e.target.value)
                                    }
                                } className="form-control shadow-none" placeholder="Escribe para buscar" />

                                <button className="btn btn-primary shadow-none" style={{
                                    marginLeft: "5px",
                                    height: "50px"
                                }} onClick={
                                    () => {
                                        /*searchUsers(search, "agent").then((users) => {
                                            setAgents(users);
                                        }).catch((err_) => {
                                            console.log(err_);
                                        })*/
                                        toast.error("Esta funcionalidad aún no está disponible")
                                    }
                                }>
                                    <SearchIcon style={{
                                        width: "20px",
                                        height: "20px",
                                        fill: "#fff"
                                    }} />
                                </button>

                            </div>

                            <br />

                            {
                                (bussines.length === 0) && <div>
                                    Sin resultados
                                </div>
                            }

                            {
                                bussines.map((Bussine, BussineIndex) => {
                                    return (<div key={BussineIndex} style={{
                                        marginTop: "10px",
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        background: (currentBussine.ref === Bussine.ref) ? "#D9E2F1" : "transparent",
                                        padding: "10px",
                                        borderRadius: "10px"
                                    }} onClick={
                                        () => {
                                            setCurrentBussine(Bussine)
                                        }
                                    }>
                                        <div>
                                            <UserIcon style={{
                                                width: "55px",
                                                height: "55px",
                                                borderRadius: "50%"
                                            }} />
                                        </div>
                                        <div style={{
                                            width: "100%",
                                            marginLeft: "5px"
                                        }}>
                                            <div style={{
                                                fontWeight: "700",
                                                color: "#08379B"
                                            }}>{Bussine.name}</div>
                                            <div>
                                                <div>
                                                    <span><span>7.1</span> / 10</span>
                                                </div>
                                                <div style={{
                                                    width: "53px",
                                                    height: "3px",
                                                    background: "#E8EBF0"
                                                }}>
                                                    <div style={{
                                                        width: "80%",
                                                        height: "100%",
                                                        background: "#AFE1DA"
                                                    }}></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <InstanceExplorer bussine={Bussine.ref} />
                                        </div>
                                    </div>)
                                })
                            }

                        </div>

                        {
                            (currentBussine.ref === null) && <div style={{
                                padding: "0px 50px 0px 50px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                                textAlign: "center"
                            }}>
                                <div style={{
                                    maxWidth: "500px"
                                }}>
                                    <div>
                                        <img src={AgentImg} style={{
                                            width: "200px"
                                        }} />
                                    </div>
                                    <div style={{
                                        fontSize: "30px",
                                        fontWeight: "700"
                                    }}>Seleccione un cliente</div>
                                    <div style={{
                                        marginTop: "10px"
                                    }}>Seleccione un cliente, para poder editar su información, puedes crear un nuevo cliente haciendo clic en el botón <b>+ Nuevo cliente</b></div>
                                </div>
                            </div>
                        }


                        {
                            (currentBussine.ref != null) && <div style={{
                                overflow: "auto"
                            }}>
                                <div style={{
                                    padding: "0px 50px 0px 50px",
                                    display: "flex"
                                }}>

                                    <div style={{
                                        width: "115px"
                                    }}>
                                        <UserIcon style={{
                                            width: "115px",
                                            height: "115px",
                                            borderRadius: "50%"
                                        }} />
                                    </div>

                                    <div style={{
                                        width: "100%"
                                    }}>

                                        <div className="form-control shadow-none plain" style={{
                                            fontSize: "30px",
                                            fontWeight: "700",
                                            border: "0px"
                                        }} >{(name != "") ? name : "Sin nombre"}</div>

                                    </div>

                                </div>

                                <div style={{
                                    padding: "0px 50px 0px 50px"
                                }}>

                                    <br />

                                    <div style={{
                                        minHeight: "100px"
                                    }}>

                                        <div style={{
                                            fontSize: "30px",
                                            fontWeight: "700"
                                        }}>Información</div>

                                        <div style={{
                                            marginTop: "10px"
                                        }}>

                                            <div className="row">
                                                <div className="col-12 mt-3">
                                                    <div style={{
                                                        fontWeight: "700",
                                                        color: "rgb(8, 55, 155)"
                                                    }}>Nombre</div>

                                                    <input type="text" value={name} onChange={
                                                        (e) => {
                                                            setName(e.target.value)
                                                        }
                                                    } className="form-control shadow-none" style={{
                                                        width: "100%",
                                                        padding: "10px",
                                                        fontSize: "20px",
                                                        background: "#F2F7F8",
                                                        border: "0px"
                                                    }} placeholder="Nombre completo" />
                                                </div>
                                            </div>

                                        </div>
                                        <br />

                                    </div>

                                    <br />
                                    <br />

                                    <div style={{
                                        minHeight: "100px"
                                    }}>

                                        <div>
                                            <button className="btn btn-primary shadow-none" onClick={
                                                () => {
                                                    // Funcion para crear

                                                    saveBussine({
                                                        bussine: currentBussine.ref,
                                                        name: name
                                                    }).then((res) => {
                                                        console.log(res);
                                                        // Modificamos la lista
                                                        var copy = [...bussines];
                                                        if (currentBussine.ref != "") {
                                                            for (let i = 0; i < copy.length; i++) {
                                                                const bussine_ = copy[i];
                                                                if (bussine_.ref === currentBussine.ref) {
                                                                    copy[i].name = name;
                                                                }
                                                            }
                                                        } else {
                                                            copy.push(res);
                                                        }
                                                        setBussines(copy);
                                                        toast.success("El cliente se creó correctamente")
                                                    }).catch((err) => {
                                                        toast.error(err)
                                                    })
                                                }
                                            }>
                                                {
                                                    (currentBussine.ref != "") ? "Guardar cambios" : "Crear nuevo cliente"
                                                }
                                            </button>
                                        </div>

                                    </div>

                                    <br />
                                    <br />


                                    {
                                        (currentBussine.ref != '') && <div style={{
                                            textAlign: "right"
                                        }}>
                                            <button className="btn btn-danger shadow-none" style={{
                                                padding: "10px"
                                            }} onClick={
                                                () => {

                                                    alert("Eliminamos el cliente");

                                                }
                                            }>
                                                <DeleteIcon style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    fill: "#fff"
                                                }} /> Eliminar cliente</button>
                                        </div>
                                    }



                                    <br />

                                    <br />

                                </div>

                            </div>
                        }


                    </div>

                </div>

            </div>

        </EnvApp >
    );
}

export default Clients;