import React, { useState, useEffect } from 'react';

import { useHistory } from "react-router-dom";

function Up(props) {

    const history = useHistory();

    useEffect(() => {

    }, []);

    return (
        <div style={{
            width: "100px",
            height: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "50px",
            cursor: "pointer"
        }}>
            <i class="las la-upload"></i>
        </div>
    );
}

export default Up;

