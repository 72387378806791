import React, { useState, useEffect, useContext } from 'react';

import { WidgetContext } from '../../contexts/WidgetProvider';

import { useParams } from "react-router-dom";

import axios from 'axios';

import { DEV, PHAT_DEV, PHAT_PROD } from '../../config';

import toast from 'react-hot-toast';

function Lobby(props) {

    let { iid, sender } = useParams();

    const { theme, names, setPage } = useContext(WidgetContext);

    useEffect(() => {
        // Obtenemos la lista de mensajes del jid actual

    }, [])

    return (
        <>
            <div style={{
                width: "100%",
                height: "100vh",
                display: "grid",
                gridTemplateColumns: "auto",
                gridTemplateRows: "140px auto 55px"
            }}>
                <div style={{
                    background: `rgba(${theme.colors.primary.r}, ${theme.colors.primary.g}, ${theme.colors.primary.b}, ${theme.colors.primary.a})`,
                    padding: "20px",
                    color: "#fff"
                }}>
                    <div style={{
                        fontSize: "35px",
                        fontWeight: "700"
                    }}>Hola {names}</div>
                    <div>¡Pregúntanos lo que sea, estamos aquí para ayudarte!</div>
                </div>
                <div>

                    <div style={{
                        background: "#fff",
                        margin: "10px",
                        padding: "20px",
                        borderRadius: "10px"
                    }}>
                        <div style={{
                            fontWeight: "700"
                        }}>Inicia una nueva conversación</div>
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "100px auto",
                            gridTemplateRows: "auto",
                            height: "80px"
                        }}>
                            <div>
                                <div style={{
                                    position: "relative",
                                }}>
                                    <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" width="50px" height="50px" style={{
                                        borderRadius: "50%",
                                        position: "absolute",
                                        top: "14px",
                                        left: "5px"
                                    }} />
                                    <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" width="50px" height="50px" style={{
                                        borderRadius: "50%",
                                        position: "absolute",
                                        top: "14px",
                                        left: "35px"
                                    }} />
                                </div>
                            </div>
                            <div style={{
                                alignSelf: "center"
                            }}>
                                <div style={{
                                    fontSize: "0.9em"
                                }}>Nuestro tiempo de respuesta habitual</div>
                                <div style={{
                                    fontWeight: "700",
                                    fontSize: "0.8em"
                                }}>Menos de 5 minutos</div>
                            </div>
                        </div>
                        <div>
                            Completa el formulario para iniciar la conversación con nuestro bot, nuestro bot aclarará las preguntas frecuentes.
                        </div>
                        <div>
                            <button className="btn btn-primary shadow-none" style={{
                                width: "100%",
                                marginTop: "10px",
                                background: `rgba(${theme.colors.secondary.r}, ${theme.colors.secondary.g}, ${theme.colors.secondary.b}, ${theme.colors.secondary.a})`,
                                border: "0px"
                            }} onClick={
                                async () => {
                                    axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/newConversation`, {
                                        iid,
                                        name: window.localStorage.getItem("widget-names") + " " + window.localStorage.getItem("widget-surnames"),
                                        jid: (window.localStorage.getItem('widget-email') != "") ? window.localStorage.getItem('widget-email') : "gost@sayform.com",
                                        sender,
                                        channel: 'WEB'
                                    })
                                        .then((response) => {
                                            if (response.status === 200) {

                                                window.localStorage.setItem("widget-ref", response.data.ref);

                                                setPage('inbox');
                                                
                                            } else {
                                                console.log("Ocurrió un problema al intentar crear la conversación.");
                                            }
                                        }, (error) => {
                                            console.log(error);
                                        });
                                }
                            }>Envíanos un mensaje</button>
                        </div>
                    </div>
                </div>
                <div style={{
                    textAlign: "center",
                    fontWeight: "700"
                }}>
                    Power by Cloud City Colombia
                </div>
            </div>
        </>
    );
}

export default Lobby;