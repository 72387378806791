import { useContext, useEffect } from "react";

import tiempo from 'tiempo';

import { ReactComponent as DowIcon } from '../../../assets/download.svg';

import { AppContext } from '../../../contexts/AppProvider';

tiempo.config({ locale: 'es' });

function Repply(props) {

    const { getIDoc, isFile, isImage } = useContext(AppContext);

    return (
        <>
            <div style={{
                background: "#f7f7f7",
                padding: "10px",
                borderRadius: "5px",
                borderLeft: "5px solid #00817a",
                color: "#6d6c6a"
            }}>

                {
                    (props.mention.mimetype === "application/text") && <div>
                        {props.mention.message}
                    </div>
                }

                {
                    (isImage(props.mention.mimetype)) && <div>
                        <img src={props.mention.message} style={{
                            width: "200px"
                        }} />
                    </div>
                }
                {
                    (isFile(props.mention.mimetype)) && <div>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            background: "#f7f7f7",
                            padding: "5px",
                            borderRadius: "5px"
                        }}>
                            <div style={{
                                width: "30px"
                            }}><img src={getIDoc(props.mention.mimetype)} style={{
                                width: "30px"
                            }} /></div>
                            <div style={{
                                marginLeft: "10px",
                                marginRight: "10px",
                                width: "200px"
                            }}>{props.mention.caption.substr(0, 15)}...</div>
                            <div style={{
                                width: "30px"
                            }}>
                                <a href={props.mention.message}>
                                    <DowIcon style={{
                                        width: "30px",
                                        height: "30px"
                                    }} />
                                </a>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </>
    );
}

export default Repply;

