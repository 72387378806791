import React, { useState, useEffect, useRef } from 'react';

import toast from 'react-hot-toast';

import notImage from '../../assets/notImage.png';

import { uploadFile } from '../../services/Chat';

function WooNotification(props) {

    const [image, setImage] = useState({
        l: '',
        mimetype: ''
    });

    const [caption, setCaption] = useState(props.value.caption);

    const file = useRef(null);

    useEffect(() => {
        props.onChange({
            image,
            caption
        });
    }, [image, caption])

    useEffect(() => {
        setImage(props.value.image);
    }, [])

    return (
        <>
            <input type="file" ref={file} onChange={
                (e) => {
                    uploadFile(e.target.files[0]).then(async (res) => {
                        if (res.file != '') {
                            // Enviamos el mensaje al servidor socket
                            setImage({
                                l: res.file,
                                mimetype: res.mimetype
                            })

                        }
                        file.current.value = '';
                    }).catch((err) => {
                        console.log(err);
                    })
                }
            } style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                top: "-1000px",
                left: "-1000px",
                zIndex: "999999999",
            }} accept=" image/jpeg,image/png" />
            <div style={{
                marginTop: "20px"
            }}>
                <div style={{
                    fontWeight: "500",
                    marginRight: "10px",
                    color: "#1E2347"
                }}>
                    <div>{props.title}</div>

                    <div style={{
                        marginTop: "10px"
                    }}>
                        <img src={(image.l != null) ? image.l : notImage} style={{
                            width: "100%",
                            cursor: "pointer",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px"
                        }} />
                        {
                            (image.l === "") && <button style={{
                                width: "100%"
                            }} onClick={
                                (e) => {
                                    file.current.click();
                                }
                            }>Subir Imagen</button>
                        }
                        {
                            (image.l != "") && <button style={{
                                width: "100%"
                            }} onClick={
                                (e) => {
                                    setImage({
                                        l: '',
                                        mimetype: ''
                                    })
                                }
                            }>Eliminar Imagen</button>
                        }
                    </div>
                    <textarea style={{
                        background: "#f2f7f8",
                        height: "150px",
                        padding: "10px",
                        resize: "none"
                    }} onChange={
                        (e) => {

                            setCaption(e.target.value);

                        }
                    } placeholder={props.title} className="w-100">{caption}</textarea>
                </div>
                <div style={{
                    color: "#9b9b9b"
                }}>Para recordar usa las siguientes variables @name, @surname, @address.</div>
            </div>
        </>
    );
}

export default WooNotification;