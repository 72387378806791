import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';

import moment from 'moment';

import axios from 'axios';

import { PHAT_DEV, PHAT_PROD, DEV, URL_SOCKET_DEV, URL_SOCKET_PROD } from '../../../config';

import toast from 'react-hot-toast';

import AudioReactRecorder, { RecordState } from 'audio-react-recorder';

import { ReactComponent as FileIcon } from '../../../assets/file.svg';

import { ReactComponent as AudioIcon } from '../../../assets/audio.svg';

import { ReactComponent as CloseWaIcon } from '../../../assets/closeWa.svg';

import { ReactComponent as AprovWaIcon } from '../../../assets/aprovWa.svg';

import { sendAudio, getMessages, sendFile, uploadFile } from '../../../services/Chat';

import { AppContext } from '../../../contexts/AppProvider';

import { SocketContext } from '../../../contexts/SocketProvider';

var counter = 0;

var inter = null;

function Control(props) {

    const [InputMessage, setInputMessage] = useState('');

    const [IsRecord, setIsRecord] = useState(false);

    const { iid, chatSenderId, chatSender, chat, setTimeline, IsSending, setIsSending } = useContext(AppContext);

    const { newPing } = useContext(SocketContext);

    const { emit } = useContext(SocketContext);

    const [TimeRecord, setTimeRecord] = useState('00:00');

    const [recordState, setRecordState] = useState(null);

    const [audioData, setAudioData] = useState(null);

    const file = useRef(null);

    const inputControl = useRef(null);

    useEffect(() => {
        if (IsRecord) {
            inputControl.current.focus();
        }
    }, [IsRecord])

    useEffect(() => {

        getMessages(props.id).then(({ chat, prev }) => {
            setTimeline(chat.Chat_.timeline);
        }).catch((err) => {
            console.log(err);
        })

    }, [newPing])

    useEffect(() => {
        if (IsRecord) {
            abc()
            inter = setInterval(abc, 1000);
        } else {
            clearInterval(inter);
        }
    }, [IsRecord])

    const abc = () => {
        var a = moment().hour(0).minute(0).second(counter++);
        if (a.format("HH") === "00") {
            setTimeRecord(a.format('mm:ss'));
        } else {
            setTimeRecord(a.format('HH:mm:ss'));
        }
    }

    const send_new_message = (payload) => {
        return new Promise((resolve, reject) => {
            // ${(DEV) ? PHAT_DEV : PHAT_PROD}
            axios.post(`${(DEV) ? PHAT_DEV : PHAT_PROD}/send-message`, payload)
                .then((response) => {
                    resolve(response.data)
                }, (error) => {
                    reject(error.response.data)
                });
        })
    }

    return (
        <div>
            {
                /*
                <div>askajska</div>
                */
            }
            <div style={{
                background: "#ededed",
                display: "flex",
                alignItems: "center",
                padding: "0px 10px 0px 10px"
            }}>



                <input type="file" ref={file} onChange={
                    (e) => {

                        uploadFile(e.target.files[0]).then(async (res) => {
                            console.log(res);
                            if (res.file != '') {

                                // Enviamos el mensaje al servidor socket

                                /*await send_new_message({
                                    ref: chat.ref,
                                    iid: window.localStorage.getItem("say-bussine"),
                                    jid: chat.jid,
                                    fromMe: true,
                                    sender: chat.sender,
                                    name: window.localStorage.getItem("say-nickname"),
                                    rol: 'agent',
                                    status: '',
                                    channel: chat.channel,
                                    message: {
                                        id: '',
                                        caption: '',
                                        message: res.file,
                                        mimetype: res.mimetype,
                                        mention: {
                                            id: '',
                                            message: '',
                                            caption: '',
                                            mimetype: '',
                                        }
                                    }
                                }).catch((err) => {
                                    toast.error(err.message);
                                })*/

                            }
                            file.current.value = '';
                        }).catch((err) => {
                            console.log(err);
                        })

                    }
                } style={{
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    top: "-1000px",
                    left: "-1000px",
                    zIndex: "999999999",
                }} accept="application/vnd.oasis.opendocument.presentation,
            application/vnd.oasis.opendocument.spreadsheet,
            application/vnd.oasis.opendocument.text,
            application/vnd.ms-powerpoint,
            application/pdf,
            application/msword,
            text/csv,
            application/vnd.ms-excel,
            application/msexcel,
            application/x-msexcel,
            application/x-ms-excel,
            application/x-excel,
            application/x-dos_ms_excel,
            application/xls,
            application/x-xls,
            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
            application/vnd.openxmlformats-officedocument.wordprocessingml.document,
            image/jpeg,
            image/png,
            image/webp" />

                <AudioReactRecorder style={{
                    position: "absolute",
                    top: "0px",
                    left: "0px"
                }} state={recordState} canvasWidth={0} canvasHeight={0} onStop={(data) => {
                    console.log(data);

                    sendAudio(data.blob).then((res) => {
                        console.log(res);

                        if (res.l != '') {
                            // Enviamos el audio por whatsapp
                            emit('newAudio', {
                                iid: iid,
                                jid: chat.jid,
                                senderId: chatSenderId,
                                url: res.l
                            });
                        }

                    }).catch((err) => {
                        console.log(err);
                    })

                    setAudioData(data);
                }} />

                {
                    (!IsRecord) && <div style={{
                        width: "50px",
                        textAlign: "center"
                    }}>

                        <FileIcon onClick={
                            () => {
                                file.current.click();
                            }
                        } style={{
                            width: "25px",
                            height: "25px",
                            cursor: "pointer"
                        }} />

                    </div>
                }


                <div style={{
                    width: "100%"
                }}>

                    <input ref={inputControl} className="form-control shadow-none" value={InputMessage} placeholder={(IsSending) ? "Enviando el mensaje" : "Escribe un mensaje aquí"} style={{
                        border: "0px",
                        borderRadius: "20px",
                        padding: "10px"
                    }} onChange={
                        (event) => {
                            setInputMessage(event.target.value)
                        }
                    } onKeyPress={
                        async (event) => {
                            if (event.key === 'Enter') {
                                // Enviamos el nuevo mensaje

                                if (!IsSending) {
                                    if (chat.channel != '' && chat.jid != '') {

                                        if (event.target.value != "") {

                                            setInputMessage("");

                                            // Enviamos el mensaje usando consultas http

                                            setIsSending(true);

                                            await send_new_message({
                                                ref: '',
                                                iid: window.localStorage.getItem("say-bussine"),
                                                jid: chat.jid,
                                                chat: chat.ref,
                                                id: '',
                                                name: window.localStorage.getItem("say-name"),
                                                fileName: '',
                                                fromMe: chat.fromMe,
                                                type: 'conversation',
                                                caption: '',
                                                message: event.target.value,
                                                mimetype: 'application/text',
                                                channel: chat.channel,
                                                typeChannel: chat.typeChannel,
                                                mention: {
                                                    id: '',
                                                    message: '',
                                                    caption: '',
                                                    mimetype: ''
                                                },
                                                t: 0
                                            }
                                            ).catch((err) => {
                                                toast.error(err.message);
                                            }).then(() => {

                                                setIsSending(false);

                                                props.onSend();
                                            })

                                        } else {
                                            toast.error("No puedes enviar espacios en blanco")
                                        }

                                    } else {
                                        toast.error("Seleccione un canal válido para enviar el mensaje");
                                    }
                                }
                            }
                        }
                    } />

                </div>

                {
                    (IsRecord) && <div style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px"
                    }}>
                        <div style={{
                            width: "50px",
                            textAlign: "left"
                        }}>
                            <CloseWaIcon onClick={
                                () => {
                                    setIsRecord(false);
                                    counter = 0;
                                }
                            } style={{
                                width: "35px",
                                height: "35px",
                                fill: "#ff0000",
                                cursor: "pointer"
                            }} />
                        </div>
                        <div style={{
                            fontSize: "20px",
                            marginLeft: "7px",
                            marginRight: "7px"
                        }}>{TimeRecord}</div>
                        <div style={{
                            width: "50px",
                            textAlign: "right"
                        }}>
                            <AprovWaIcon onClick={
                                () => {
                                    setIsRecord(false);
                                    counter = 0;
                                    toast.success("Enviamos el audio...");
                                    setRecordState(RecordState.STOP)
                                }
                            } style={{
                                width: "35px",
                                height: "35px",
                                fill: "#06d755",
                                cursor: "pointer"
                            }} />
                        </div>
                    </div>
                }


                {
                    (!IsRecord) && <div style={{
                        width: "50px",
                        textAlign: "center"
                    }} onClick={
                        () => {
                            setIsRecord(true);
                            // Iniciamos la grabacion 
                            setRecordState(RecordState.START);
                        }
                    }>

                        <AudioIcon style={{
                            width: "25px",
                            height: "25px",
                            fill: "#919191",
                            cursor: "pointer"
                        }} />

                    </div>
                }


            </div>
        </div>
    );
}

export default Control;

