import React, { useState, useEffect, useContext } from 'react';

import toast, { Toaster } from 'react-hot-toast';

import { Modal } from 'react-responsive-modal';

import './AgentsExplorer.css';

import { getAgents } from '../../../services/Agent';

import { ReactComponent as ChekIcon } from '../../../assets/chek.svg';

import { ReactComponent as UserIcon } from '../../../assets/user.svg';

import { AppContext } from '../../../contexts/AppProvider';

import { setAgent, getStatistics } from '../../../services/Chat';

function AgentsExplorer(props) {

    const { iid, CurrentChat, setCurrentChat, Chats, setChats, setStatistics } = useContext(AppContext);

    const [open, setOpen] = useState(false);

    const [agents, setAgents] = useState([]);

    const onOpenModal = () => setOpen(true);

    const onCloseModal = () => setOpen(false);

    useEffect(() => {
        if (open) {
            if (iid != "") {
                // Cargamos los agentes para esta empresa
                getAgents(iid).then((data) => {
                    var a = [];
                    for (let i = 0; i < data.list.length; i++) {
                        const agen = data.list[i];
                        if (agen.ref != window.localStorage.getItem("say-ref")) {
                            a.push(agen)
                        }
                    }
                    setAgents(a);
                }).catch((err) => {
                    console.log(err);
                })
            }
        }
    }, [open])

    useEffect(() => {

    }, [])

    return (
        <>
            <button className="btn btn-sm shadow-none" style={{
                background: "#f9fafa",
                fontWeight: "700",
                marginLeft: "5px"
            }} onClick={
                (e) => {

                    e.stopPropagation();
                    e.preventDefault();

                    onOpenModal();

                }
            }>Asignar agente</button>

            <Modal open={open} onClose={onCloseModal} classNames={{
                overlay: 'customOverlay',
                modal: 'customModal'
            }} center>
                <div>
                    Seleccione un agente:
                    <br />
                    {
                        agents.map((agent, agentIndex) =>
                            <div key={agentIndex} style={{
                                marginTop: "10px",
                                display: "flex",
                                alignItems: "center"
                            }}>

                                <div>
                                    <UserIcon style={{
                                        width: "40px",
                                        height: "40px",
                                        fill: "#06d755"
                                    }} />
                                </div>
                                <div style={{
                                    width: "100%",
                                    marginLeft: "5px"
                                }}>
                                    <div>{agent.name}</div>
                                    <div style={{
                                        fontSize: "15px"
                                    }}>...</div>
                                </div>
                                <div>
                                    <button onClick={
                                        (e) => {

                                            e.stopPropagation();
                                            e.preventDefault();

                                            setAgent({
                                                ref: CurrentChat.ref,
                                                agent: agent.ref
                                            }).then((res) => {
                                                console.log(res);

                                                toast.success("Agente asignado")

                                                onCloseModal();

                                                // Eliminamos el chat de la lista de chats
                                                var index = -1;
                                                var copy = [...Chats];
                                                for (let i = 0; i < copy.length; i++) {
                                                    const co = copy[i];
                                                    if (co.ref === CurrentChat.ref) {
                                                        index = i;
                                                    }
                                                }
                                                if (index > -1) {
                                                    copy.splice(index, 1);
                                                }
                                                setChats(copy);

                                                // Deseleccionamos el chat
                                                setCurrentChat({
                                                    ref: "",
                                                    jid: "",
                                                    name: "",
                                                    status: 0
                                                })

                                                // Cargamos las estadisticas
                                                // Cargamos las estadisticas
                                                getStatistics(window.localStorage.getItem("say-ref")).then((res) => {
                                                    setStatistics(res)
                                                }).catch((err) => {
                                                    console.log(err);
                                                })

                                            }).catch((err) => {
                                                console.log(err);
                                            })

                                        }
                                    } style={{
                                        background: "transparent"
                                    }}>
                                        <ChekIcon style={{
                                            width: "25px",
                                            height: "25px"
                                        }} />
                                    </button>
                                </div>
                            </div>
                        )
                    }
                </div>
            </Modal>

        </>
    );
}

export default AgentsExplorer;

