import { useContext, useEffect, useState, useCallback } from "react";

import { PHAT_DEV, PHAT_PROD, DEV, URL_SOCKET_DEV, URL_SOCKET_PROD } from '../../../config';

import moment from 'moment';

import tiempo from 'tiempo';

import { SRLWrapper } from "simple-react-lightbox";

import { format, render, cancel, register } from 'timeago.js';

import { ReactComponent as DowIcon } from '../../../assets/download.svg';

import { ReactComponent as UserIcon } from '../../../assets/user.svg';

import { AppContext } from '../../../contexts/AppProvider';

import ImageViewer from "react-simple-image-viewer";

import Repply from './Repply';

tiempo.config({ locale: 'es' });

function Bubble(props) {

    const { getIDoc, getW, isFile, isImage, isAudio, isVideo } = useContext(AppContext);

    const [currentImage, setCurrentImage] = useState(0);

    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const [images, setImages] = useState([]);

    const options = {
        buttons: {
            showAutoplayButton: false,
            showCloseButton: false,
            showDownloadButton: false,
            showFullscreenButton: false,
            showNextButton: false,
            showPrevButton: false,
            showThumbnailsButton: false
        },
        thumbnails: {
            showThumbnails: false
        }
    }

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    useEffect(() => {
        console.log("props.Line");
        console.log(props.Line);
    }, [])

    return (
        <>
            <SRLWrapper options={options}>
                <div style={{
                    display: "flex",
                    justifyContent: "flex-start"
                }}>

                    <div>
                        <UserIcon style={{
                            width: "55px",
                            height: "55px",
                            borderRadius: "50%"
                        }} />
                    </div>

                    <div
                        style={{
                            background: "#C3F6C7",
                            borderRadius: "10px",
                            padding: "10px",
                            marginTop: "5px",
                            display: "flex",
                            marginLeft: "7px",
                            width: getW(props.Line.mimetype)
                        }}
                    >

                        <div style={{
                            color: "#000"
                        }}>
                            <div style={{
                                color: "#000",
                                fontWeight: "700"
                            }}>+{props.Line.name}</div>
                            <div>
                                {
                                    (props.Line.type === "document") && <div>
                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            background: "#f7f7f7",
                                            padding: "5px",
                                            borderRadius: "5px"
                                        }}>
                                            <div style={{
                                                width: "30px"
                                            }}><img src={getIDoc(props.Line.mimetype)} style={{
                                                width: "30px"
                                            }} /></div>
                                            <div style={{
                                                marginLeft: "10px",
                                                marginRight: "10px",
                                                width: "200px"
                                            }}>{props.Line.caption.substr(0, 15)}...</div>
                                            <div style={{
                                                width: "30px",
                                                cursor: "pointer"
                                            }}>
                                                <a onClick={
                                                    () => {
                                                        window.open(`${(DEV) ? PHAT_DEV : PHAT_PROD}/static/${props.Line.fileName}`, '_blank');
                                                    }
                                                } src={`${(DEV) ? PHAT_DEV : PHAT_PROD}/static/${props.Line.fileName}`}>
                                                    <DowIcon style={{
                                                        width: "30px",
                                                        height: "30px"
                                                    }} />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {
                                    (props.Line.type === "audio") && <div>
                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "100%",
                                            borderRadius: "5px"
                                        }}>
                                            <div style={{
                                                width: "330px"
                                            }}>
                                                <audio src={`${(DEV) ? PHAT_DEV : PHAT_PROD}/static/${props.Line.fileName}`} style={{
                                                    width: "100%"
                                                }} type={props.Line.mimetype} controls />
                                            </div>
                                        </div>
                                    </div>
                                }

                                {
                                    (props.Line.type === "video") && <div>
                                        <div style={{
                                            padding: "5px",
                                            borderRadius: "5px"
                                        }}>
                                            <div style={{
                                                width: "370px"
                                            }}>
                                                <video src={`${(DEV) ? PHAT_DEV : PHAT_PROD}/static/${props.Line.fileName}`} style={{
                                                    width: "100%",
                                                    borderRadius: "10px"
                                                }} type={props.Line.mimetype} controls />
                                            </div>
                                        </div>
                                    </div>
                                }

                                {
                                    (props.Line.type === "image") && <div style={{
                                        maxWidth: "300px"
                                    }}>

                                        {
                                            (props.Line.mention.id != '') && <Repply mention={props.Line.mention} />
                                        }

                                        <img src={`${(DEV) ? PHAT_DEV : PHAT_PROD}/static/${props.Line.fileName}`} onClick={
                                            () => openImageViewer(0)
                                        } style={{
                                            width: "100%",
                                            borderRadius: "10px",
                                            marginTop: "10px",
                                            cursor: "pointer"
                                        }} />

                                    </div>
                                }


                                {
                                    (props.Line.type === "conversation") && <div>

                                        {
                                            (props.Line.mention.id != '') && <Repply mention={props.Line.mention} />
                                        }

                                        <div style={{
                                            marginTop: "5px"
                                        }}>{props.Line.message}</div>
                                    </div>
                                }



                                {
                                    (props.Line.type === "extendedText") && <div>

                                        {
                                            (props.Line.mention.id != '') && <Repply mention={props.Line.mention} />
                                        }

                                        <div style={{
                                            marginTop: "5px"
                                        }}>{props.Line.message}</div>
                                    </div>
                                }

                            </div>
                            <div style={{
                                fontSize: "11px",
                                display: "flex",
                                alignSelf: "flex-end",
                                color: "#000"
                            }}>Enviado desde: <b>{props.sender}</b> - {
                                    format(new Date(props.Line.createdAt))
                                }</div>
                        </div>

                    </div>



                </div>
            </SRLWrapper>
        </>
    );
}

export default Bubble;

