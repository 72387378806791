import React, { useState, useEffect, useContext } from 'react';

import MaskInput from 'react-maskinput';

import { WidgetContext } from '../../contexts/WidgetProvider';

import toast from 'react-hot-toast';

function Login(props) {

    const { initSocket, theme, setPage, names, setNames, surnames, setSurnames, email, setEmail, phone, setPhone } = useContext(WidgetContext);

    useEffect(() => {
        // Obtenemos la lista de mensajes del jid actual

    }, [])

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    return (
        <>
            <div style={{
                width: "100%",
                height: "100vh",
                display: "grid",
                gridTemplateColumns: "auto",
                gridTemplateRows: "140px auto 55px"
            }}>
                <div style={{
                    background: `rgba(${theme.colors.primary.r}, ${theme.colors.primary.g}, ${theme.colors.primary.b}, ${theme.colors.primary.a})`,
                    padding: "20px",
                    color: "#fff"
                }}>
                    <div style={{
                        fontSize: "35px",
                        fontWeight: "700"
                    }}>Hola</div>
                    <div>¡Pregúntanos lo que sea, estamos aquí para ayudarte!</div>
                </div>
                <div>
                    <div style={{
                        background: "#fff",
                        margin: "10px",
                        padding: "20px",
                        borderRadius: "10px"
                    }}>
                        <div style={{
                            fontWeight: "700"
                        }}>Continúa la conversación o inicia una nueva</div>
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "100px auto",
                            gridTemplateRows: "auto",
                            height: "80px"
                        }}>
                            <div>
                                <div style={{
                                    position: "relative",
                                }}>
                                    <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" width="50px" height="50px" style={{
                                        borderRadius: "50%",
                                        position: "absolute",
                                        top: "14px",
                                        left: "5px"
                                    }} />
                                    <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" width="50px" height="50px" style={{
                                        borderRadius: "50%",
                                        position: "absolute",
                                        top: "14px",
                                        left: "35px"
                                    }} />
                                </div>
                            </div>
                            <div style={{
                                alignSelf: "center"
                            }}>
                                <div style={{
                                    fontSize: "0.9em"
                                }}>Nuestro tiempo de respuesta habitual</div>
                                <div style={{
                                    fontWeight: "700",
                                    fontSize: "0.8em"
                                }}>Menos de 5 minutos</div>
                            </div>
                        </div>
                        <div>
                            <input type="text" className="form-control shadow-none" value={names} style={{
                                width: "100%",
                                background: "#fff",
                                border: "1px solid #c3c3c3",
                                marginTop: "5px"
                            }} onChange={(e) => {
                                setNames(e.target.value);
                            }} placeholder="Nombres" />
                        </div>
                        <div>
                            <input type="text" className="form-control shadow-none" value={surnames} style={{
                                width: "100%",
                                background: "#fff",
                                border: "1px solid #c3c3c3",
                                marginTop: "5px"
                            }} onChange={(e) => {
                                setSurnames(e.target.value);
                            }} placeholder="Apellidos" />
                        </div>
                        <div>
                            <input type="text" className="form-control shadow-none" value={email} style={{
                                width: "100%",
                                background: "#fff",
                                border: "1px solid #c3c3c3",
                                marginTop: "5px"
                            }} onChange={(e) => {
                                setEmail(e.target.value);
                            }} placeholder="Correo electrónico" />
                        </div>
                        <div>

                            <MaskInput alwaysShowMask maskChar="_" value={phone} onChange={
                                (e) => {
                                    setPhone(e.target.value);
                                }
                            } className="form-control shadow-none" mask="+57 (000) 000-00-00" style={{
                                width: "100%",
                                background: "#fff",
                                border: "1px solid #c3c3c3",
                                marginTop: "5px"
                            }} size={20} />

                        </div>
                        <div>
                            <button className="btn btn-primary shadow-none" style={{
                                width: "100%",
                                marginTop: "10px",
                                background: `rgba(${theme.colors.secondary.r}, ${theme.colors.secondary.g}, ${theme.colors.secondary.b}, ${theme.colors.secondary.a})`,
                                border: "0px"
                            }} onClick={
                                () => {

                                    if (validateEmail(email)) {
                                        if (window.localStorage.getItem('widget-email') != "") {

                                            initSocket();

                                            // Alamcenamos en el storage 

                                            window.localStorage.setItem("widget-names", names);

                                            window.localStorage.setItem("widget-surnames", surnames);

                                            window.localStorage.setItem("widget-email", email);

                                            window.localStorage.setItem("widget-phone", phone);

                                            //  Mostramos la interfaz donde se podrá seleccionar crea una nueva conversación

                                            setPage('lobby');

                                        } else {
                                            toast.error("Hola! para continuar necesitamos tu correo electronico, para asegurar el seguimiento a tu consulta");
                                        }
                                    }else{
                                        toast.error("Correo electrónico no válido");
                                    }



                                }
                            }>Continuar</button>
                        </div>
                    </div>
                </div>
                <div style={{
                    textAlign: "center",
                    fontWeight: "700"
                }}>
                    Power by Cloud City Colombia
                </div>
            </div>
        </>
    );
}

export default Login;